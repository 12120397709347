import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes, permitsKeys } from '@core/react-query/features/permits'
import { PageResponse } from '@core/entities/pagination'
import { IPermitBase, PermitBase } from '@core/entities/permit/PermitBase'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const dataTransformer = (response: PageResponse<IPermitBase>) => {
  return {
    ...response,
    content: response.content.map((permit) => PermitBase.new(permit)),
  }
}

export const useAllPermitsPage = (
  payload: RequestTypes['useAllOrganisationPermits'],
  options: UseQueryOptions<
    PageResponse<IPermitBase>,
    RequestErrorType,
    PageResponse<PermitBase>
  > = {},
) => {
  return useQuery<
    PageResponse<IPermitBase>,
    RequestErrorType,
    PageResponse<PermitBase>
  >({
    select: dataTransformer,
    ...permitsKeys.allPermitsPage(payload),
    keepPreviousData: true,
    ...options,
  })
}
