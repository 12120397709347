import { RequestTypes } from '../requestTypes'
import { registerKeys } from '../registerKeys'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
export const useAddRegisterDiagram = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['addRegisterDiagram']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...registerKeys.addRegisterDiagram,
    ...options,
    onSuccess: (_, { registerId }) => {
      queryClient.invalidateQueries(registerKeys.details({ registerId }))
    },
  })
}
