import { Box, Flex } from '@fundamentals'
import Image from 'next/image'
import React from 'react'
import { Grid } from '@mui/material'

type UnauthenticatedPageLayoutProps = {
  page: React.ReactElement
}

export const AuthPageLayout: React.FC<UnauthenticatedPageLayoutProps> = ({
  page,
}) => {
  return (
    <Flex
      mt={4}
      flexDirection={'column'}
      justifyContent='center'
      alignItems='center'
    >
      <Box mb={4}>
        <Image
          width={240}
          height={37}
          className='logo'
          alt='Logo'
          src={'/images/logo/logo-dark-wordmark.svg'}
        />
      </Box>
      <Grid
        container
        spacing={2}
        justifyContent='center'
        alignItems='center'
        maxWidth={'700px'}
      >
        <Grid item xs={11}>
          {page}
        </Grid>
      </Grid>
    </Flex>
  )
}
