import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { checklistsKeys } from '@core/react-query/features/checklists'
import { useMutateQueryData } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { IChecklist } from '@core/entities/checklist/Checklist'
import { OfflineEntityType } from '@core/react-query/defaultMutations'

export const getCreateChecklistDraftMutationOptions = (
  queryClient: QueryClient,
) => {
  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)
  return {
    ...checklistKeys.createDraft,
    meta: {
      name: 'Create Checklist Draft',
      offlineEnabled: true,
      variablesType: OfflineEntityType.CHECKLIST_SUBMISSION,
    },
    onSuccess: (draft) => {
      updateInfiniteQueryItem(
        checklistsKeys.drafts({
          organisationId: draft.template.organisationId,
          projectIds: [draft.projectId],
        }).queryKey,
        draft,
      )
    },
  }
}

export const useCreateChecklistDraft = () => {
  const queryClient = useQueryClient()
  return useMutation<IChecklist, RequestErrorType, RequestTypes['createDraft']>(
    getCreateChecklistDraftMutationOptions(queryClient),
  )
}
