//@ts-nocheck
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { localizedStrings } from '@core/strings' // we need this to make JSX compile

type ComponentType = {
  value: string
  startValue?: string
  pollFrequency: number
  expiredLabel?: string
  onExpire?: () => void
}

const getCountdown = (endDate, startValue, expiredLabel) => {
  if (!endDate) return { value: '', duration: Number.MAX_SAFE_INTEGER }

  const duration = moment.duration(
    moment(endDate).diff(
      startValue ? moment(startValue).valueOf() : new Date().valueOf(),
    ),
  )
  const diffDays = Math.floor(duration.asDays())
  const diffHours = duration.hours()
  const diffMins = duration.minutes()
  const diffSeconds = duration.seconds()

  const parts = []
  if (diffDays) {
    parts.push(`${diffDays}${localizedStrings.daysShort}`)
  }
  if (diffHours) {
    parts.push(`${diffHours}${localizedStrings.hoursShort}`)
  }
  if (diffMins) {
    parts.push(`${diffMins}${localizedStrings.minsShort}`)
  }
  if (diffSeconds) {
    parts.push(`${diffSeconds}${localizedStrings.secondsShort}`)
  }
  const firstTwo = parts.slice(0, 2).join(' ')

  const onExpiryLabel = !expiredLabel ? localizedStrings.expired : expiredLabel
  return {
    value: duration.asMilliseconds() <= 0 ? onExpiryLabel : firstTwo,
    duration: duration.asMilliseconds(),
  }
}

const Countdown: FunctionComponent<ComponentType> = ({
  value,
  startValue,
  pollFrequency,
  onExpire,
  expiredLabel,
}) => {
  const timer = useRef<number>(null)
  const expired = useRef(false)
  const [displayValue, setDisplayValue] = useState<string>(
    getCountdown(value, startValue, expiredLabel).value,
  )
  const [duration, setDuration] = useState<number>(
    getCountdown(value, startValue, expiredLabel).duration,
  )
  const _v = useRef(displayValue)
  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current)
    }
    timer.current = setInterval(() => {
      const newVal = getCountdown(value, startValue, expiredLabel)
      if (_v.current !== newVal.value) {
        _v.current = newVal.value
        setDisplayValue(newVal.value)
        setDuration(newVal.duration)
      }
    }, pollFrequency)
    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [value, pollFrequency])

  useEffect(() => {
    if (duration <= 0 && !expired.current) {
      expired.current = true
      if (onExpire) onExpire()
    }
  }, [duration, onExpire])

  useEffect(() => {}, [value])
  return <>{displayValue}</>
}

export default Countdown
