import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  organisationKeys,
  organisationMutationKeys,
} from '@core/react-query/features/organisations/organisation'
import { RequestTypes } from '@core/react-query/features/organisations/organisation/requestTypes'

export const useSetOrganisationUserRole = (
  options?: UseMutationOptions<
    string,
    string,
    RequestTypes['setOrganisationUserRole']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...organisationMutationKeys.setOrganisationUserRole,
    // We don't retry mutations since they're not idempotent
    // We can allow retries if we implement idempotency keys and idempotency checking on the back-end
    retry: 0,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        organisationKeys.byId({ organisationId: variables.organisationId })
          .queryKey,
        {},
      )
    },
  })
}
