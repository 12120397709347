import React from 'react'
import { Box, Flex, Text } from '@fundamentals'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { useTheme, Link } from '@mui/material'
import { localizedStrings } from '@core/strings'
import { useModal } from '@common/GlobalModal'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { PermitWarning } from '@core/entities/template/TemplateWarnings/PermitWarning'
import { useNavigationContext } from '@common/hooks'
import { DuplicatedImagePermitModal } from '@modules/permitDetails/modals/DuplicatedImagePermitModal'
import { Permit } from '@core/entities/permit/Permit'
import { ObjectDetectionWarningModal } from '@modules/permitDetails/modals/ObjectDetectionWarningModal'

type ComponentProps = {
  warning: PermitWarning
  permit: Permit
}

export const WarningDetailsLeggacy: React.FC<ComponentProps> = ({
  warning,
  permit,
}) => {
  const theme: any = useTheme()
  const { showModal } = useModal()
  const { organisationId } = useNavigationContext()

  const questionInfo = permit.template.currentVersion.getQuestionInfo(
    warning.questionId,
  )

  return (
    <Box sx={{ p: 1.5, borderColor: theme.palette.warning.main }}>
      <Flex alignItems='center'>
        <WarningAmberOutlinedIcon color='warning' />
        <Text variant='body2' sx={{ mx: 1, fontWeight: 500 }}>
          {warning.getTitle()}
        </Text>
      </Flex>
      <Flex>
        {questionInfo && (
          <Text variant='body2' sx={{ ml: 4, fontWeight: 500 }}>
            {localizedStrings.question}{' '}
            {`${questionInfo.groupIndex + 1}-${questionInfo.questionIndex + 1}`}
          </Text>
        )}
        <Link
          sx={{ ml: !questionInfo ? 4 : 1 }}
          component='button'
          variant='body2'
          onClick={() => {
            if (warning.type === 'CLONED_PERMIT') {
              showModal(
                <PermitDetailsModal
                  organisationId={warning.details.organisationId}
                  permitId={warning.details.id}
                />,
              )
            }

            if (warning.type === 'IMAGE_DUPLICATE') {
              showModal(
                <DuplicatedImagePermitModal
                  organisationId={organisationId}
                  imageDuplicates={warning.details?.duplicates || []}
                  timelineDownloadUrls={warning?.downloadUrls || []}
                />,
              )
            }
            if (warning.type === 'IMAGE_OBJECT_DETECTION') {
              showModal(<ObjectDetectionWarningModal warning={warning} />)
            }
          }}
        >
          {localizedStrings.viewDetails}
        </Link>
      </Flex>
    </Box>
  )
}
