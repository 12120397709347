import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'

import { useModal } from 'webapp/components/common/GlobalModal'
import { SignatureEdit } from 'webapp/components/common/Signature'
import { LoadingButton } from 'webapp/components/common/material'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { useApproveExtensionRequest } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  permit: Permit
}

export const ApproveExtensionRequestPermitModal: React.FC<ComponentType> = ({
  permit,
}) => {
  const approvePermitExtension = useApproveExtensionRequest()
  const { data: profile } = useGetProfile()
  const [error, setError] = useState('')
  const { hideModal } = useModal()
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.approveExtension}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <SignatureEdit readonly value={profile?.signatureUrl} />

        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal} data-test='cancel'>
          {localizedStrings.cancel}
        </Button>
        <LoadingButton
          variant='contained'
          onClick={() => {
            approvePermitExtension.mutate(
              {
                permitId: permit.id,
              },
              {
                onSuccess: () => {
                  hideModal()
                  toast.success(localizedStrings.permitExtended)
                },
                onError: (e) => setError(e.message),
              },
            )
          }}
          loading={approvePermitExtension.isLoading}
          data-test='jsExtendPermit'
        >
          {localizedStrings.extend}
        </LoadingButton>
      </DialogActions>
    </ModalDialogContainer>
  )
}
