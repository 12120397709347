import React, { forwardRef } from 'react'
import { Button, ButtonGroup } from '@mui/material'
import { Clear, KeyboardArrowDownOutlined } from '@mui/icons-material'

type UnfocusedFilter = {
  onClick: (e) => void
  onRemove: () => void
  text: string
  isPinned: boolean
  dataTest?: string
  icon?: React.ReactNode
  ref?: React.Ref<HTMLButtonElement>
}

export const UnfocusedFilter: React.FC<UnfocusedFilter> = forwardRef(
  (
    { onClick = () => {}, onRemove = () => {}, text, isPinned, dataTest, icon },
    ref,
  ) => {
    return (
      <ButtonGroup variant='outlined' sx={{ mr: 1 }}>
        <Button
          startIcon={icon}
          data-test={dataTest ? `${dataTest}-chip-button` : ''}
          onClick={(e) => {
            onClick(e)
          }}
          sx={{ py: 0.8 }}
          size={'small'}
          ref={ref}
        >
          {text}
        </Button>

        {!isPinned && (
          <Button size='small' onClick={onRemove}>
            <Clear fontSize='small' />
          </Button>
        )}

        {isPinned && (
          <Button size='small' onClick={onClick}>
            <KeyboardArrowDownOutlined fontSize='small' />
          </Button>
        )}
      </ButtonGroup>
    )
  },
)
