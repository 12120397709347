export const calculatePositionOfPopper = (x: number, y: number) => {
  return {
    getBoundingClientRect: (): DOMRect => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
      x: x,
      y: y,
      toJSON: () => ({}),
    }),
  }
}
