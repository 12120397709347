import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { useGetAuthGroups } from '@core/react-query/features/authGroups'
import { FilterHookArgumentsBase } from '@core/providers/filters'

type ProjectAuthGroupsFilterArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    multiple?: Multiple
    projectId: number
  }

export const useProjectAuthGroupsFilter: <Multiple extends boolean | undefined>(
  arg: ProjectAuthGroupsFilterArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = (args) => {
  const { data: authGroups } = useGetAuthGroups({
    projectId: args.projectId,
    size: 10000,
  })

  return useSelectFilter({
    ...args,
    options: authGroups?.content?.map((authGroup) => authGroup.id),
    valueToLabel: (v) => authGroups?.content?.find((t) => t.id === v)?.name,
  })
}
