import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { ProjectPlanDashboardCountByArea } from '../ProjectPlanDashboardCountByArea'
import { IProjectPlanDashboard } from './IProjectPlanDashboard'

export class ProjectPlanDashboard extends Entity<IProjectPlanDashboard> {
  totalElements: number

  @Type(() => ProjectPlanDashboardCountByArea)
  countByArea: ProjectPlanDashboardCountByArea[]

  public static new(payload: unknown): ProjectPlanDashboard {
    const entity = plainToInstance(ProjectPlanDashboard, payload)

    return entity
  }

  public getAreaTotalElements(annotationId: string): number {
    return (
      this.countByArea.find((annStats) => annStats.areaUuid === annotationId)
        ?.totalElements ?? 0
    )
  }

  public getSelectedAreaNames(selectedAreaIds: string[]): string[] {
    return this.countByArea
      .filter((area) => selectedAreaIds.includes(area.areaUuid))
      .map(
        (area, index, filteredAreas) =>
          `${area.areaName}${index < filteredAreas.length - 1 ? ', ' : ''}`,
      )
  }
}
