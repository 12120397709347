import { useDeepCompareCallback } from 'use-deep-compare'
import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import { localizedStrings } from '@core/strings'

export type AdvancedSelectFilterOperators =
  | 'ANY'
  | 'IS_PRESENT'
  | 'IS_NOT_PRESENT'

export const advancedSelectFilterOperatorsWithoutArguments: AdvancedSelectFilterOperators[] =
  ['IS_PRESENT', 'IS_NOT_PRESENT']

export type AdvancedSelectFilterValue = {
  operator: AdvancedSelectFilterOperators
  value: string[]
}

export const advancedSelectFilterValueIsPartial = (
  value: AdvancedSelectFilterValue,
) => {
  if (!value?.operator) {
    return true
  }

  if (advancedSelectFilterOperatorsWithoutArguments.includes(value?.operator)) {
    return false
  }

  if (_isEmpty(value?.value)) {
    return true
  }

  return false
}

export type AdvancedSelectFilterOption = {
  uuid: string
  label: string
}

export const advancedSelectFilterOptionConditions: {
  value: AdvancedSelectFilterOperators
  label: string
}[] = [
  { value: 'ANY', label: localizedStrings.any },
  { value: 'IS_PRESENT', label: localizedStrings.isPresent },
  { value: 'IS_NOT_PRESENT', label: localizedStrings.isNotPresent },
]

export type AdvancedSelectFilterHookArguments =
  FilterHookArgumentsBase<AdvancedSelectFilterValue> & {
    options: AdvancedSelectFilterOption[]
    renderItem?: (value: AdvancedSelectFilterOption) => React.ReactElement
    disableClientSideFilter?: boolean
    loading?: boolean
    noOptionsText?: string
  }

export type AdvancedSelectFilterInternalArguments<AdvancedSelectFilterValue> =
  AdvancedSelectFilterHookArguments &
    FilterInternalArgumentsBase<AdvancedSelectFilterValue, 'ADVANCED_SELECT'>

export const useAdvancedSelectFilter = (
  filter: AdvancedSelectFilterHookArguments,
): AdvancedSelectFilterInternalArguments<AdvancedSelectFilterValue> => {
  const unfocusedText = useDeepCompareCallback(
    (
      filterValue: AdvancedSelectFilterValue,
      options?: { readOnly?: boolean; noLabel?: boolean },
    ) => {
      const label = options?.noLabel ? '' : `${filter.label}: `

      if (filterValue?.operator === 'IS_PRESENT') {
        return `${label}${localizedStrings.isPresent}`
      }

      if (filterValue?.operator === 'IS_NOT_PRESENT') {
        return `${label}${localizedStrings.isNotPresent}`
      }

      const value = filterValue?.value
      const nothingSelectedText =
        filter.nothingSelectedText || 'No item selected'

      if (value && value.length === 1) {
        const [firstValue] = value

        const filterLabel = filter.options.find(
          (option) => option.uuid === firstValue,
        )?.label

        const operatorLabel = advancedSelectFilterOptionConditions.find(
          (operator) => operator.value === filterValue?.operator,
        )?.label
        return `${label}( ${operatorLabel} ) ${filterLabel}`
      }
      if (value && value.length > 1) {
        if (options?.readOnly) {
          const selectValues = filter?.options
            .filter(({ uuid }) => value.includes(uuid))
            .map(({ label }) => ` ${label}`)

          return `${label}${selectValues}`
        }
        return `${label}${value.length} selected`
      }
      return `${label}${nothingSelectedText}`
    },
    [filter],
  )

  return {
    type: 'ADVANCED_SELECT',
    unfocusedText,
    serialization: 'object',
    ...filter,
  }
}
