import { localizedStrings } from '@core/strings'
import React from 'react'
import moment from 'moment'
import { usePermitChips } from './usePermitChips'
import { Card, Button, Tooltip, Divider } from '@common/material'
import { Box, BoxProps, Flex, Text } from '@fundamentals'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { Permit } from '@core/entities/permit/Permit'
import {
  EventAvailableOutlined,
  EventBusy,
  MapOutlined,
  PinDropOutlined,
} from '@mui/icons-material'
import { TablePrimaryText } from '@common/Table'

export const PermitCard: React.FC<
  BoxProps & {
    permit: Permit
    onMouseOver?: () => void
    onMouseLeave?: () => void
    onClick?: () => void
    hovered?: boolean
    transparent?: boolean
    actions?: {
      label: string
      onClick: () => void
      icon?: any
    }[]
  }
> = ({
  permit,
  onMouseOver,
  onMouseLeave,
  onClick,
  hovered,
  transparent,
  actions,
  sx,
}) => {
  if (!permit) {
    return null
  }

  const { flags } = useFeatureFlags(['web_project_plans'])
  const { dateString, timeString, endDateString, endTimeString } =
    Utils.getDate(permit?.startsOn, permit?.endsOn)
  const startYear = moment(permit?.startsOn).format('YYYY')
  const endYear = moment(permit?.endsOn).format('YYYY')
  const { primaryChip } = usePermitChips()

  const permitZones: string[] = []
  if (
    'projectLocations' in permit &&
    permit?.projectLocations?.getSelectedPermits
  ) {
    permitZones.push(...(permit?.projectLocations.getSelectedPermits() ?? []))
  }

  return (
    <Card
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        borderColor: hovered && 'primary.main',
        py: 2,
        px: 1,
        mb: 1,
        mr: 2,
        backgroundColor: transparent ? 'rgba(255,255,255,0.9)' : null,
        ...sx,
      }}
    >
      <Text fontSize={13} color='text.secondary'>
        {permit?.template.name}
      </Text>
      {permit?.summary && (
        <TablePrimaryText pb={1} fontWeight='bold'>
          {permit?.summary}
        </TablePrimaryText>
      )}

      <Flex mb={1}>{primaryChip(permit)}</Flex>

      <Box mb={1}>
        <Flex alignItems='center'>
          <EventAvailableOutlined
            sx={{ fontSize: 14, color: 'text.secondary' }}
          />
          <Text mx={1} fontSize={13} color='text.secondary'>
            {localizedStrings.start}:
          </Text>
          <TablePrimaryText
            fontSize={13}
            fontWeight='bold'
          >{`${dateString} ${startYear} ${timeString}`}</TablePrimaryText>
        </Flex>
        <Flex alignItems='center'>
          <EventBusy sx={{ fontSize: 14, color: 'text.secondary' }} />
          <Text mx={1} fontSize={13} color='text.secondary'>
            {localizedStrings.end}:
          </Text>
          <TablePrimaryText
            fontSize={13}
            fontWeight='bold'
          >{`${endDateString} ${endYear} ${endTimeString}`}</TablePrimaryText>
        </Flex>
        <Flex alignItems='center'>
          <MapOutlined sx={{ fontSize: 14, color: 'text.secondary' }} />
          <Text mx={1} fontSize={13} color='text.secondary'>
            {localizedStrings.location}:
          </Text>
          <TablePrimaryText
            fontSize={13}
            fontWeight='bold'
          >{`${permit.location}`}</TablePrimaryText>
        </Flex>
        {flags.web_project_plans && !!permitZones?.length && (
          <Flex alignItems='center'>
            <PinDropOutlined sx={{ fontSize: 14, color: 'text.secondary' }} />
            <Text mx={1} fontSize={13} color='text.secondary'>
              {localizedStrings.zones}:
            </Text>
            <Tooltip title={`${permitZones.map((zone) => ` ${zone}`)}`}>
              <TablePrimaryText
                fontSize={13}
                fontWeight='bold'
                noWrap
              >{`${permitZones.map((zone) => ` ${zone}`)}`}</TablePrimaryText>
            </Tooltip>
          </Flex>
        )}
      </Box>

      <Text fontSize={12} color='text.secondary'>
        {localizedStrings.permitHolder}
      </Text>
      <Text>{`${permit?.permitHolder?.firstName} ${permit?.permitHolder?.lastName}`}</Text>

      <Divider sx={{ mt: 0.5, mb: 2 }} />

      <Flex flexDirection={'row'} justifyContent={'stretch'}>
        {actions?.map((action, index, actionsArray) => {
          return (
            <Button
              variant='contained'
              size='small'
              sx={{ flexGrow: 1, mr: index < actionsArray.length - 1 ? 1 : 0 }}
              endIcon={action.icon}
              key={index}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          )
        })}
      </Flex>
    </Card>
  )
}
