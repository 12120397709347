import { plainToInstance } from 'class-transformer'
import { ISiteBoxDocumentPdfVersion } from '@core/entities/SiteBoxDocumentPdfVersion'
import { SiteBoxDocumentVersionBase } from '@core/entities/SiteBoxDocumentVersionBase'

export class SiteBoxDocumentPdfVersion extends SiteBoxDocumentVersionBase<ISiteBoxDocumentPdfVersion> {
  imageKey: string
  imageGetUrl: string
  imageDownloadUrl: string
  pdfHeightInPoints: number
  pdfWidthInPoints: number
  imageWidthInPixels: number

  public static new(payload: ISiteBoxDocumentPdfVersion) {
    const entity = plainToInstance(SiteBoxDocumentPdfVersion, payload)

    return entity
  }
}
