import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  RequestTypes,
  permitKeys,
} from '@core/react-query/features/permits/permit'
import { IPermitDraft, PermitDraft } from '@core/entities/permit/PermitDraft'

export const usePermitDraftById = (
  payload: RequestTypes['getPermitDraft'],
  options?: UseQueryOptions<IPermitDraft, RequestErrorType, PermitDraft>,
) => {
  return useQuery({
    select: PermitDraft.new,
    ...permitKeys.draftById(payload),
    ...options,
  })
}
