import React from 'react'
import { Card } from '@common/material'
import {
  AuditResult,
  AuditResultScopeTypes,
} from '@core/entities/audit/AuditResult'
import { localizedStrings } from '@core/strings'
import { RegisterItem } from '@core/entities/register/RegisterItem'
import {
  TimelineEventBlock,
  TimelineEventHeader,
  TimelineLinkBlock,
  TimelineTextBlock,
  TimelineChecklistCommentBlocks,
  TimelineRegisterPropertiesBlocks,
  TimelineGroupPermissionsBlocks,
  TimelinePermitLocationBlock,
  TimelinePermitStatusTrailBlock,
  TimelineMediaBlock,
  TimelineSignatureBlock,
} from '@common/Timeline/blocks'

import { PageUrls } from '@core/page-urls'
import { palette } from '@core/theme/palette'
import { TimelineProjectStatusTrailBlock } from '@common/Timeline/blocks/TimelineProjectStatusTrailBlock'
import { TimelinePermissionAssignmentsBlocks } from '@common/Timeline/blocks/TimelinePermissionAssignmentsBlocks'
import { TimelineUserRoleUpdateBlock } from '@common/Timeline/blocks/TimelineUserRoleUpdateBlock'
import { OrganisationUser, ProjectUser } from '@core/entities/user'
import { TimelineUserBlocks } from '@common/Timeline/blocks/TimelineUserBlocks'
import { TimelineTemplateBlocks } from '@common/Timeline/blocks/TimelineTemplateBlocks'
import { TimelinePermitBlocks } from '@common/Timeline/blocks/TimelinePermitBlocks'
import { Collapsible } from '@common/Collapsible'

type TimelineItemProps = {
  auditResult: AuditResult
  auditResults: Array<AuditResult>
  registerItem?: RegisterItem
  hovered?: boolean
  scope?: AuditResultScopeTypes
}

export const TimelineItem: React.FC<TimelineItemProps> = ({
  auditResult,
  hovered,
  scope,
  auditResults,
}) => {
  return (
    <Card
      sx={{
        p: 2,
        mb: 2,
        outline: `${hovered ? palette.primary?.main : 'transparent'} solid 1px`,
        borderColor: hovered && palette.primary?.main,
        transition: 'outline 0.2s ease',
      }}
    >
      <TimelineEventHeader auditResult={auditResult} />
      <TimelineEventBlock auditResult={auditResult} />
      <TimelineUserBlocks auditResult={auditResult} />
      <TimelinePermitBlocks auditResult={auditResult} />
      {auditResult.hasComment() && (
        <TimelineChecklistCommentBlocks auditResult={auditResult} />
      )}
      {auditResult.register && (
        <TimelineTextBlock
          title={localizedStrings.register}
          text={auditResult.register.name}
        />
      )}
      {auditResult.hasInspection() && (
        <TimelineLinkBlock
          title={localizedStrings.checklistInformation}
          href={PageUrls.projectChecklistDetail({
            organisationId: auditResult.organisation?.id,
            projectId: auditResult.project?.id,
            checklistId: auditResult.metadata.inspectionChecklist.checklistId,
          })}
          text={auditResult.metadata.inspectionChecklist.templateName}
        />
      )}
      {auditResult.group && scope !== 'AUTH_GROUP' && (
        <TimelineLinkBlock
          title={localizedStrings.authorisationGroup}
          href={PageUrls.projectAuthorisationGroup({
            organisationId: auditResult.organisation?.id,
            projectId: auditResult.project?.id,
            groupId: auditResult.group?.id,
          })}
          text={auditResult.group.name}
        />
      )}
      {auditResult.info && (
        <TimelineTextBlock
          title={auditResult.getInfoTitle()}
          text={auditResult.info}
        />
      )}
      {!auditResult.hasComment() &&
        (auditResult.isUpdatingRegisterItem() ||
          auditResult.metadata.updateNote ||
          (auditResult.project &&
            !['PERMIT', 'CHECKLIST', 'PROJECT'].includes(scope)) ||
          auditResult.hasPermitStatusChange() ||
          auditResult.hasProjectStatusChange() ||
          auditResult.hasProofs() ||
          auditResult.signatureUrl ||
          auditResult.hasTemplateInformation() ||
          (auditResult.checklist && scope !== 'CHECKLIST') ||
          auditResult.isUpdatingProjectMemberRole() ||
          auditResult.isUpdatingOrganisationMemberRole() ||
          auditResult.isUpdatingGroupPermissions() ||
          auditResult.isUpdatingTemplatePermissionGroups()) && (
          <Collapsible
            label={{
              collapsed: localizedStrings.showMore,
              open: localizedStrings.hide,
            }}
            buttonProps={{
              size: 'small',
              sx: { mt: 1, mb: -0.5 },
            }}
            collapsed
          >
            {!!auditResult.isUpdatingRegisterItem() && (
              <TimelineRegisterPropertiesBlocks auditResult={auditResult} />
            )}
            {auditResult.isUpdatingTemplatePermissionGroups() && (
              <TimelinePermissionAssignmentsBlocks auditResult={auditResult} />
            )}
            {auditResult.isUpdatingProjectMemberRole() && (
              <TimelineUserRoleUpdateBlock
                preValues={auditResult.metadata.preProjectMemberData.roles.map(
                  (role) => ProjectUser.getRoleString(role),
                )}
                postValues={auditResult.metadata.postProjectMemberData.roles.map(
                  (role) => ProjectUser.getRoleString(role),
                )}
              />
            )}
            {auditResult.isUpdatingOrganisationMemberRole() && (
              <TimelineUserRoleUpdateBlock
                preValues={auditResult.metadata.preOrgMemberData.roles.map(
                  (role) => OrganisationUser.roleString(role),
                )}
                postValues={auditResult.metadata.postOrgMemberData.roles.map(
                  (role) => OrganisationUser.roleString(role),
                )}
              />
            )}
            {auditResult.hasTemplateInformation() && (
              <TimelineTemplateBlocks auditResult={auditResult} />
            )}
            {auditResult.checklist && scope !== 'CHECKLIST' && (
              <TimelineLinkBlock
                title={localizedStrings.checklist}
                href={
                  scope === 'PROJECT'
                    ? PageUrls.projectChecklistDetail({
                        organisationId: auditResult.organisation?.id,
                        projectId: auditResult.project?.id,
                        checklistId: auditResult.checklist.id,
                      })
                    : PageUrls.checklist(
                        auditResult.organisation?.id,
                        auditResult.checklist.id,
                      )
                }
                // backend doesn't return checklist name yet. we should remove this after backend fix
                text={auditResult.checklist.name || 'Checklist'}
              />
            )}
            {auditResult.metadata.updateNote && (
              <TimelineTextBlock
                title={localizedStrings.info}
                text={auditResult.metadata.updateNote}
              />
            )}
            {auditResult.project &&
              !['PERMIT', 'CHECKLIST', 'PROJECT'].includes(scope) && (
                <TimelineTextBlock
                  title={localizedStrings.project}
                  text={auditResult.project.name}
                />
              )}
            {auditResult.isUpdatingGroupPermissions() && (
              <TimelineGroupPermissionsBlocks
                permissionsUpdatedData={
                  auditResult.metadata.permissionsUpdatedData
                }
                scope={scope}
                organisationId={auditResult.organisation?.id}
                projectId={auditResult.project?.id}
              />
            )}
            {auditResult.hasPermitStatusChange() && (
              <TimelinePermitStatusTrailBlock
                permitStatusBefore={auditResult.metadata.permitStatusBefore}
                permitStatusAfter={auditResult.metadata.permitStatusAfter}
              />
            )}
            {auditResult.hasProjectStatusChange() && (
              <TimelineProjectStatusTrailBlock
                projectStatusBefore={auditResult.metadata.projectStatusBefore}
                projectStatusAfter={auditResult.metadata.projectStatusAfter}
              />
            )}
            <TimelinePermitLocationBlock
              auditResult={auditResult}
              auditResults={auditResults}
            />
            {auditResult.hasProofs() && (
              <TimelineMediaBlock auditResult={auditResult} />
            )}
            {auditResult.signatureUrl && (
              <TimelineSignatureBlock auditResult={auditResult} />
            )}
          </Collapsible>
        )}
    </Card>
  )
}
