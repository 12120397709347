import React, { useState } from 'react'
import _flatten from 'lodash/flatten'
import { useDeepCompareCallback } from 'use-deep-compare'
import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import { useOrganisationUserGetter } from '@core/react-query/features/organisations/organisation/hooks/useOrganisationUser'
import { useProjectUsers } from '@core/react-query/features/projects/project/hooks/useProjectUsers'
import { useOrganisationUsers } from '@core/react-query/features/organisations/organisation/hooks/useOrganisationUsers'
import { useDebouncedValue } from '@core/utils/useDebouncedValue'
import { User } from '@core/entities/user'
import { localizedStrings } from '@core/strings'
import { useToast } from '@core/toast'

export type AdvancedUserFilterOperators =
  | 'EQUALS'
  | 'IS_CURRENT_USER'
  | 'IS_PRESENT'
  | 'IS_NOT_PRESENT'

export const advancedUserFilterOperatorsWithoutArguments: AdvancedUserFilterOperators[] =
  ['IS_CURRENT_USER', 'IS_PRESENT', 'IS_NOT_PRESENT']

export type AdvancedUserFilterValue = {
  operator: AdvancedUserFilterOperators
  value?: number
}

export const advancedUserFilterValueIsPartial = (
  value: AdvancedUserFilterValue,
) => {
  if (!value?.operator) {
    return true
  }

  if (advancedUserFilterOperatorsWithoutArguments.includes(value?.operator)) {
    return false
  }

  if (!value?.value) {
    return true
  }

  return false
}

export const advancedUserFilterOptionOperators: {
  value: AdvancedUserFilterOperators
  label: string
}[] = [
  { value: 'EQUALS', label: '=' },
  { value: 'IS_CURRENT_USER', label: localizedStrings.currentUser },
  { value: 'IS_PRESENT', label: localizedStrings.isPresent },
  { value: 'IS_NOT_PRESENT', label: localizedStrings.isNotPresent },
]

export type AdvancedUserFilterHookArguments =
  FilterHookArgumentsBase<AdvancedUserFilterValue> & {
    organisationId: number
    projectId?: number
    renderItem?: (userId: any) => React.ReactElement
    valueToLabel?: (userId: number) => string
    loading?: boolean
  }

export type AdvancedUserFilterInternalArguments<AdvancedUserFilterValue> =
  AdvancedUserFilterHookArguments &
    FilterInternalArgumentsBase<AdvancedUserFilterValue, 'ADVANCED_USER'> & {
      options: number[]
    }

export const useAdvancedUserFilter = (
  filter: AdvancedUserFilterHookArguments,
): AdvancedUserFilterInternalArguments<AdvancedUserFilterValue> => {
  const toast = useToast()
  const [userCache, setUserCache] = useState<Record<string, User>>()
  const { fetchOrganisationUser } = useOrganisationUserGetter()
  const unfocusedText = useDeepCompareCallback(
    (
      filterValue: AdvancedUserFilterValue,
      { noLabel } = { noLabel: false },
    ) => {
      const label = noLabel ? '' : `${filter.label}: `
      const value = filterValue?.value

      const nothingSelectedText =
        filter.nothingSelectedText || 'No item selected'

      if (filterValue?.operator === 'IS_CURRENT_USER') {
        return `${label}${localizedStrings.currentUser}`
      }

      if (filterValue?.operator === 'IS_PRESENT') {
        return `${label}${localizedStrings.isPresent}`
      }

      if (filterValue?.operator === 'IS_NOT_PRESENT') {
        return `${label}${localizedStrings.isNotPresent}`
      }

      if (value) {
        if (!userCache?.[value]) {
          // If the user is not in the cache, then we fetch it and set state
          // This will cause a re-render, and the next time the function is called the user will be populated
          fetchOrganisationUser(filter.organisationId, value).then((user) => {
            setUserCache({ [value]: user })
          })
        }

        const filterLabel = userCache?.[value]?.email || ''

        const operatorLabel = advancedUserFilterOptionOperators[0]?.label
        return `${label}( ${operatorLabel} ) ${filterLabel}`
      }

      return `${label}${nothingSelectedText}`
    },
    [filter, userCache],
  )

  const [searchTerm, setSearchTerm] = useState<string>()
  const [users, setUsers] = useState<number[]>([])

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300)

  const onError = (e: any) => {
    toast.error(e)
  }

  const { isLoading: projectUsersLoading } = useProjectUsers(
    {
      projectId: !!filter?.projectId && filter.projectId,
      term: debouncedSearchTerm,
      size: 1000,
    },
    {
      enabled: !!filter?.projectId && !!debouncedSearchTerm,
      onSuccess: (usersData) => {
        const updatedCache = {}
        const updatedUsers = _flatten(usersData.pages).flatMap((userPage) => {
          return userPage.content.map((user) => {
            updatedCache[user.id] = user
            return user.id
          })
        })
        setUsers(updatedUsers)
        setUserCache({ ...userCache, ...updatedCache })
      },
      onError,
    },
  )

  const { isLoading: organisationUsersLoading } = useOrganisationUsers(
    {
      organisationId: filter.organisationId,
      term: debouncedSearchTerm,
      size: 1000,
    },
    {
      enabled: filter.organisationId && !!debouncedSearchTerm,
      onSuccess: (usersData) => {
        const updatedCache = {}
        const updatedUsers = _flatten(usersData.pages).flatMap((userPage) => {
          return userPage.content.map((user) => {
            updatedCache[user.id] = user
            return user.id
          })
        })
        setUsers(updatedUsers)
        setUserCache({ ...userCache, ...updatedCache })
      },
      onError,
    },
  )

  const onInputChange = (input: string) => {
    if (!input) setSearchTerm('')
    else if (input.length < 3) return
    setSearchTerm(input)
  }

  return {
    type: 'ADVANCED_USER',
    unfocusedText,
    serialization: 'object',
    options: users,
    valueToLabel: (userId) => userCache?.[userId]?.email,
    onInputChange,
    loading:
      !!debouncedSearchTerm &&
      (projectUsersLoading || organisationUsersLoading),
    ...filter,
    renderItem:
      filter?.renderItem &&
      ((userId) => filter?.renderItem(userCache?.[userId])),
  }
}
