import { useController, useWatch } from 'react-hook-form'
import { useQuestionVisibility } from '@core/providers/useQuestionVisibility'
import { ITemplateRule } from '@core/entities/template/TemplateRule'
import { ITemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import { useDeepCompareEffect } from 'use-deep-compare'

const HiddenQuestionsController = () => {
  const questions = useWatch({
    name: 'questions',
  }) as Array<ITemplateQuestionGroup>
  const rules = useWatch({
    name: 'rules',
  }) as Array<ITemplateRule>

  const {
    field: { onChange },
  } = useController({
    name: 'hiddenQuestionIds',
  })

  const { hiddenQuestionIds } = useQuestionVisibility({
    questions,
    rules,
  })

  useDeepCompareEffect(() => {
    onChange(hiddenQuestionIds)
  }, [hiddenQuestionIds, onChange])

  return null
}

export { HiddenQuestionsController }
