import React from 'react'
import { ModalDialogContainer } from '@common/modal'
import { ClickToCopy } from '@common/ClickToCopy'
import { Intent } from '@core/entities/intent'
import { Permit } from '@core/entities/permit/Permit'
import { Text } from '@fundamentals'
import { DialogContent } from '@common/material'

type BetaPermitModalProps = {
  permit: Permit
}

export const BetaPermitModal: React.FC<BetaPermitModalProps> = ({ permit }) => {
  const intent: Intent = {
    intentType: 'OPEN_PERMIT',
    organisationId: permit?.organisationId,
    projectId: permit?.project?.id,
    permitId: permit?.id,
  }
  const intentUrl = `${Project.appUrl}/intent?data=${encodeURIComponent(
    JSON.stringify(intent),
  )}`

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '80%', flex: 1 } }}>
      <DialogContent>
        {permit && (
          <>
            <Text>Intent URL</Text>
            <ClickToCopy value={intentUrl}>
              <Text>{intentUrl}</Text>
            </ClickToCopy>
          </>
        )}
      </DialogContent>
    </ModalDialogContainer>
  )
}
