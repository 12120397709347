import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '@core/react-query/features/templates/permitTemplates/permitTemplate/requestTypes'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'

const permitTemplateQueryKeys = createQueryKeys('permit-templates', {
  byTemplateId: ({ id }: RequestTypes['getPermitTemplate']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `${Project.api}permits/admin/templates/${id}`,
        )

        return data
      },
    }
  },
})

const permitTemplateMutationKeys = createMutationKeys('permit-templates', {
  updatePermitTemplate: {
    mutationKey: null,
    mutationFn: async ({
      id,
      ...rest
    }: RequestTypes['updatePermitTemplate']) => {
      const { data } = await APIAxiosInstance.put(
        `${Project.api}permits/admin/templates/${id}`,
        rest,
      )

      return data
    },
  },

  createPermitTemplate: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      ...rest
    }: RequestTypes['createPermitTemplate']) => {
      const queryString = createQueryKeyString({ organisationId })
      const { data } = await APIAxiosInstance.post(
        `${Project.api}permits/admin/templates${queryString}`,
        rest,
      )

      return data
    },
  },
  deletePermitTemplate: {
    mutationKey: null,
    mutationFn: async ({ id }: RequestTypes['deletePermitTemplate']) => {
      const { data } = await APIAxiosInstance.delete(
        `${Project.api}permits/admin/templates/${id}`,
      )

      return data
    },
  },
  archivePermitTemplate: {
    mutationKey: null,
    mutationFn: async ({ id }: RequestTypes['archivePermitTemplate']) => {
      const { data } = await APIAxiosInstance.put(
        `${Project.api}permits/admin/templates/${id}/archive`,
      )

      return data
    },
  },
  unarchivePermitTemplate: {
    mutationKey: null,
    mutationFn: async ({ id }: RequestTypes['unarchivePermitTemplate']) => {
      const { data } = await APIAxiosInstance.put(
        `${Project.api}permits/admin/templates/${id}/unarchive`,
      )

      return data
    },
  },
})

export const permitTemplateKeys = mergeQueryKeys(
  permitTemplateQueryKeys,
  permitTemplateMutationKeys,
)['permit-templates']
