import { localizedStrings } from '@core/strings'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'

import { useGetAllProjects } from '@core/react-query/features/projects'
import { FilterHookArgumentsBase } from '@core/providers/filters'
import { useToast } from '@core/toast'
import { ProjectStatus } from '@core/entities/project'
import { useMemo } from 'react'

type ProjectFilterHookArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    multiple?: Multiple
    organisationId: number
    businessUnitId?: number
    projectStatus?: ProjectStatus
    whitelist?: number[]
    readOnly?: boolean
  }

export const useProjectsFilter: <Multiple extends boolean | undefined>(
  arg: ProjectFilterHookArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = ({
  organisationId,
  businessUnitId,
  projectStatus,
  whitelist,
  ...rest
}) => {
  const toast = useToast()
  const { data: projects } = useGetAllProjects(
    {
      organisationId,
      businessUnitId,
      status: projectStatus,
    },
    {
      onError: (e) => toast.error(e.message),
    },
  )

  const filteredProjects = useMemo(() => {
    if (whitelist?.length) {
      return projects?.filter((p) => whitelist?.includes(p.id))
    }

    return projects
  }, [projects, whitelist])

  return useSelectFilter({
    ...rest,
    options: filteredProjects?.map((p) => p.id) ?? [],
    valueToLabel: (v) => filteredProjects?.find((p) => p.id == v)?.name,
    nothingSelectedText: localizedStrings.allProjects,
  })
}
