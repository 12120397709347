import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '@core/react-query/features/templates/checklistTemplates'
import {
  AvailableChecklistTemplatesWrapper,
  IAvailableChecklistTemplatesWrapper,
} from '@core/entities/checklist/ChecklistTemplate'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { checklistTemplateKeys } from '@core/react-query/features/templates/checklistTemplates/checklistTemplateKeys'

const checklistTemplateTransform = (
  data: IAvailableChecklistTemplatesWrapper,
) => AvailableChecklistTemplatesWrapper.new(data)

export const useAvailableChecklistTemplates = (
  payload: RequestTypes['getAvailableTemplates'],
  options?: UseQueryOptions<
    IAvailableChecklistTemplatesWrapper,
    RequestErrorType,
    AvailableChecklistTemplatesWrapper
  >,
) => {
  return useQuery<
    IAvailableChecklistTemplatesWrapper,
    RequestErrorType,
    AvailableChecklistTemplatesWrapper
  >({
    select: checklistTemplateTransform,
    ...checklistTemplateKeys.availableTemplates(payload),
    ...options,
  })
}
