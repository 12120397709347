import { localizedStrings } from '@core/strings'
import React from 'react'
import { Button, DropdownButton, IconButton } from '@common/material'
import { ArrowDropDown, MoreVert } from '@mui/icons-material'
import { DropdownButtonOption } from '@common/material/DropdownButton/types'
import { useModal } from '@common/GlobalModal'
import { Organisation } from '@core/entities/organisation'
import { OrganisationUser } from '@core/entities/user/OrganisationUser'
import { ChangeOrganisationUserPermissions } from '@modules/organisationDetail/modals/ChangeOrganisationUserPermissionsModal'
import { DisableOrganisationUserModal } from '@modules/organisationDetail/modals/DisableOrganisationUserModal'
import { ReactivateOrganisationUserModal } from '@modules/organisationDetail/modals/ReactivateOrganisationUserModal'
import { ResendInviteModal } from '@modules/organisationDetail/modals/ResendInviteModal'

type OrganisationUserActionsProps = {
  variant: 'three-dot' | 'button'
  organisation: Organisation
  user: OrganisationUser
}

export const OrganisationUserActions: React.FC<
  OrganisationUserActionsProps
> = ({ user, organisation, variant }) => {
  const { showModal } = useModal()

  const options: DropdownButtonOption[] = []

  if (organisation.canAddUser()) {
    options.push({
      title: localizedStrings.resendInvite,
      'data-test': 'dd-resend-invite',
      onSelect: () => {
        showModal(
          <ResendInviteModal organisationId={organisation.id} user={user} />,
        )
      },
    })
  }

  if (organisation.canChangeUserRole()) {
    options.push({
      title: localizedStrings.changeRole,
      'data-test': 'dd-change-role',
      onSelect: () => {
        showModal(
          <ChangeOrganisationUserPermissions
            organisation={organisation}
            user={user}
          />,
        )
      },
    })
  }

  if (organisation.canChangeUserRole() && user.status === 'ACTIVE') {
    options.push({
      title: localizedStrings.disableUser,
      'data-test': 'dd-disable-user',
      onSelect: () => {
        showModal(
          <DisableOrganisationUserModal
            organisation={organisation}
            user={user}
          />,
        )
      },
    })
  }

  if (organisation.canChangeUserRole() && user.status === 'DISABLED') {
    options.push({
      title: localizedStrings.reactivateUser,
      'data-test': 'dd-reactivate-user',
      onSelect: () => {
        showModal(
          <ReactivateOrganisationUserModal
            organisation={organisation}
            user={user}
          />,
        )
      },
    })
  }

  if (!options?.length) {
    return null
  }

  return (
    <DropdownButton
      label={localizedStrings.actions}
      options={options}
      renderButton={(onClick) => {
        if (variant === 'three-dot') {
          return (
            <IconButton
              onClick={(e) => {
                onClick(e)
                e.stopPropagation()
              }}
              onBlur={(e) => e.stopPropagation()}
              data-test='dd-organisation-user-actions'
            >
              <MoreVert />
            </IconButton>
          )
        }

        if (variant === 'button') {
          return (
            <Button
              onClick={(e) => {
                onClick(e)
                e.stopPropagation()
              }}
              onBlur={(e) => e.stopPropagation()}
              data-test='dd-organisation-user-actions'
              variant={'outlined'}
              endIcon={<ArrowDropDown />}
              sx={{
                fontSize: 16,
                mr: 1,
              }}
            >
              {localizedStrings.actions}
            </Button>
          )
        }
      }}
    />
  )
}
