import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import {
  projectKeys,
  RequestTypes,
} from '@core/react-query/features/projects/project'
import { PageResponse } from '@core/entities/pagination'
import { IProjectUser, ProjectUser } from '@core/entities/user'
import { usePageableQuery } from '@core/react-query/hooks'

const dataTransformer = (response: PageResponse<IProjectUser>) => {
  return {
    ...response,
    content: response.content.map((user) => ProjectUser.new(user)),
  }
}

export const useProjectUsers = (
  payload: RequestTypes['getProjectUsers'],
  options?: UseInfiniteQueryOptions<
    PageResponse<IProjectUser>,
    string,
    PageResponse<ProjectUser>
  >,
) => {
  const projectUsersQuery = projectKeys.byId(payload)._ctx.projectUsers(payload)
  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options?.enabled,
    queryKey: projectUsersQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<IProjectUser>,
    string,
    PageResponse<ProjectUser>
  >({
    ...projectUsersQuery,
    ...infiniteQueryOptions,
    ...options,
  })
}
