import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { permitsKeys, RequestTypes } from '@core/react-query/features/permits'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { Permit } from '@core/entities/permit/Permit'

const dataTransformer: (data) => Array<Permit> = (response) => {
  return response.permits.map((permit) => Permit.new(permit))
}

export const useProjectMapPermits = (
  payload: RequestTypes['getProjectMapPermits'],
  options?: UseQueryOptions<Permit[], RequestErrorType>,
) => {
  return useQuery({
    select: dataTransformer,
    ...permitsKeys.projectMapPermits(payload),
    ...options,
  })
}

export const prefetchProjectMapPermits = (
  queryClient: QueryClient,
  payload: RequestTypes['getProjectMapPermits'],
  options?: UseQueryOptions<Permit[], RequestErrorType>,
) => {
  return queryClient.prefetchQuery({
    select: dataTransformer,
    ...permitsKeys.projectMapPermits(payload),
    ...options,
    cacheTime: Infinity,
  })
}
