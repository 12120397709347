import { createContext, useContext, useMemo } from 'react'
import { UseQueryResult } from '@tanstack/react-query/src/types'
import { PageResponse } from '@core/entities/pagination'
import {
  Comment,
  CommentSecondaryReferenceObjectTypes,
} from '@core/entities/comments'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import _ from 'lodash'
import {
  RequestTypes,
  useGetComments,
} from '@core/react-query/features/comments'

export type CommentsContext = {
  commentsQuery?: UseQueryResult<PageResponse<Comment>, RequestErrorType>
  commentsBySubTypeId: _.Dictionary<Comment[]>
  commentsPayload?: Parameters<typeof useGetComments>[0]
  createCommentCreationPayload?: (args: {
    organisationId: number
    message: string
    attachmentsKeys?: Array<string>
    projectId?: number
    subTypeIdText?: string
    subTypeId?: number
    subType?: CommentSecondaryReferenceObjectTypes
  }) => RequestTypes['createComment']
}

export type CommentsContextCreator = (
  ...args: Parameters<typeof useGetComments>
) => CommentsContext

export const CommentsContext = createContext<CommentsContext>(undefined)

export const createCommentsContextValue: CommentsContextCreator = (
  commentsPayload,
  options,
) => {
  const { id, type, subType, subTypeId, subTypeIdText } = commentsPayload
  const commentsQuery = useGetComments(
    { id, type },
    {
      refetchOnMount: false,
      ...options,
    },
  )

  const createCommentCreationPayload = (args) => ({
    ...args,
    referenceObjectId: id,
    referenceObjectType: type,
    secondaryReferenceObjectId: args?.subTypeId || subTypeId,
    secondaryReferenceObjectIdText: args?.subTypeIdText || subTypeIdText,
    secondaryReferenceObjectType: args?.subType || subType,
  })

  const commentsBySubTypeId = useMemo(() => {
    return _.groupBy(
      commentsQuery.data?.content || [],
      ({ secondaryReferenceObjectId, secondaryReferenceObjectIdText }) => {
        return secondaryReferenceObjectId || secondaryReferenceObjectIdText
      },
    )
  }, [commentsQuery.data])

  return {
    commentsQuery,
    commentsPayload,
    createCommentCreationPayload,
    commentsBySubTypeId,
  }
}

export const useComments: () => CommentsContext = () =>
  useContext(CommentsContext) || { commentsBySubTypeId: {} }
