import React from 'react'
import { Button, LoadingButton } from '@common/material'
import { localizedStrings } from '@core/strings'
import { Box, Flex, Text } from '@fundamentals'
import { PermitActionCard } from '../PermitActionCard'
import { useModal } from '@common/GlobalModal'
import { Permit } from '@core/entities/permit/Permit'
import moment from 'moment'
import { CalendarTodayOutlined } from '@mui/icons-material'
import { RejectExtensionRequestPermitModal } from '../modals/RejectExtensionRequestPermitModal'
import { ApproveExtensionRequestPermitModal } from '../modals/ApproveExtensionRequestPermitModal'
import { alpha } from '@mui/material/styles'

type ReviewExtensionRequestPermitCardProps = {
  permit: Permit
}
export const ReviewExtensionRequestPermitCard: React.FC<
  ReviewExtensionRequestPermitCardProps
> = ({ permit }) => {
  const { showModal } = useModal()
  if (!permit.hasPermission('REVIEW_EXTENSION_REQUEST')) return null

  const { lastExtension } = permit
  const { requestedBy, extensionEndsOn, extensionReason, requestedOn } =
    lastExtension
  const extendedDatetimeString =
    moment(extensionEndsOn).format('D MMMM YYYY HH:mm')
  const datetimeString = moment(lastExtension.requestedOn).format(
    'D MMMM YYYY HH:mm',
  )

  return (
    <PermitActionCard
      title={localizedStrings.extensionRequested}
      info={
        <Box>
          <Box>{`${
            localizedStrings.requestedBy
          }: ${requestedBy.getFullName()}`}</Box>
          <Box>{`${localizedStrings.date}: ${datetimeString}`}</Box>
        </Box>
      }
      recipientSection={
        <>
          <CalendarTodayOutlined sx={{ color: alpha('#000', 0.54) }} />
          <Text sx={{ fontSize: '12px', fontWeight: 500, ml: 1 }}>
            {`${localizedStrings.extendedDate}: ${extendedDatetimeString}`}
          </Text>
        </>
      }
      description={extensionReason}
      BottomComponent={
        <Flex gap={1}>
          <Button
            data-test='reject-extension'
            color='error'
            variant='outlined'
            fullWidth
            onClick={() =>
              showModal(<RejectExtensionRequestPermitModal permit={permit} />)
            }
          >
            {localizedStrings.reject}
          </Button>
          <LoadingButton
            data-test='approve-extension'
            variant='contained'
            fullWidth
            onClick={() =>
              showModal(<ApproveExtensionRequestPermitModal permit={permit} />)
            }
          >
            {localizedStrings.approve}
          </LoadingButton>
        </Flex>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
