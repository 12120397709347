import { localizedStrings } from '@core/strings'
import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { TemplateEditorChecklistTemplateStatus } from './types'
import { TemplateEditorChecklistTemplateVersion } from '@core/entities/checklist/TemplateEditorChecklistTemplateVersion'
import { User } from '@core/entities/user'
import { FolderPath } from '@core/entities/folders/FolderResponse'
import { ITemplateEditorChecklistTemplate } from './ITemplateEditorChecklistTemplate'
import { ProcessTagsWrapper } from '@core/entities/processTags/ProcessTagsWrapper'

export class TemplateEditorChecklistTemplate extends Entity<ITemplateEditorChecklistTemplate> {
  id: number
  status: TemplateEditorChecklistTemplateStatus
  organisationId: number
  organisationName: string
  name: string
  description: string
  code: string
  @Type(() => TemplateEditorChecklistTemplateVersion)
  currentVersion?: TemplateEditorChecklistTemplateVersion
  @Type(() => User)
  createdBy?: User
  @Type(() => User)
  modifiedBy?: User
  createdOn?: string
  modifiedOn?: string
  folderId?: number
  folder?: {
    pathFromRoot?: FolderPath
  }
  pathFromRoot?: FolderPath

  @Type(() => ProcessTagsWrapper)
  processTags?: ProcessTagsWrapper

  public static new(payload: unknown): TemplateEditorChecklistTemplate {
    const entity = plainToInstance(TemplateEditorChecklistTemplate, payload)

    return entity
  }

  public static statusColor = (
    status: TemplateEditorChecklistTemplateStatus,
  ) => {
    switch (status) {
      case 'LIVE':
        return 'success'
      case 'ARCHIVED':
        return 'default'
      default:
        return 'default'
    }
  }

  public static statusText = (
    status: TemplateEditorChecklistTemplateStatus,
  ) => {
    switch (status) {
      case 'LIVE':
        return localizedStrings.live
      case 'ARCHIVED':
        return localizedStrings.archived
      case 'DRAFT':
        return localizedStrings.draft
      default:
        return status
    }
  }
}
