import React from 'react'
import { Flex } from '@fundamentals'
import { Chip } from '@common/material'
import { localizedStrings } from '@core/strings'
import EastIcon from '@mui/icons-material/East'
import { TimelineBlockBase } from '@common/Timeline'
import { Project, ProjectStatus } from '@core/entities/project'

type ComponentProps = {
  projectStatusBefore: ProjectStatus
  projectStatusAfter: ProjectStatus
}

export const TimelineProjectStatusTrailBlock: React.FC<ComponentProps> = ({
  projectStatusAfter,
  projectStatusBefore,
}) => {
  if (projectStatusAfter === projectStatusBefore) {
    return null
  }
  return (
    <TimelineBlockBase title={localizedStrings.statusTrail}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Chip
          size='small'
          color={Project.statusColour(projectStatusBefore)}
          label={Project.statusText(projectStatusBefore)}
        />
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Chip
          size='small'
          color={Project.statusColour(projectStatusAfter)}
          label={Project.statusText(projectStatusAfter)}
        />
      </Flex>
    </TimelineBlockBase>
  )
}
