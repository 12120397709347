import { Type, plainToInstance } from 'class-transformer'
import { FolderPath } from '@core/entities/folders/FolderResponse'
import { Entity } from '@core/entities/entity'
import { User } from '@core/entities/user'
import { FileFormat, StatusType } from '@core/entities/SiteBoxDocument'
import { ISiteBoxDocumentVersionBase } from '@core/entities/SiteBoxDocumentVersionBase'

export class SiteBoxDocumentVersionBase<
  ExtendedType extends ISiteBoxDocumentVersionBase = ISiteBoxDocumentVersionBase,
> extends Entity<ExtendedType> {
  id: number
  format: FileFormat
  documentId: number
  documentVersion: number
  documentKey: string
  documentGetUrl: string
  documentDownloadUrl: string
  status: StatusType

  @Type(() => User)
  modifiedBy: User

  modifiedOn: string

  @Type(() => User)
  createdBy: User

  createdOn: string
  folder: {
    folderId: number
    pathFromRoot: FolderPath
  }

  public static new(
    payload: ISiteBoxDocumentVersionBase,
  ): SiteBoxDocumentVersionBase {
    const entity = plainToInstance<
      SiteBoxDocumentVersionBase,
      ISiteBoxDocumentVersionBase
    >(SiteBoxDocumentVersionBase, payload)

    return entity
  }
}
