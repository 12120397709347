import { localizedStrings } from '@core/strings'
import React from 'react'
import { useGetAllProjects } from '@core/react-query/features/projects'
import { Checkbox, Chip, MenuItem, TextField } from '@common/material'
import { Text } from '@fundamentals'
import { Section } from '@common/Section'
import {
  Autocomplete,
  AutocompleteValue,
  SxProps,
  TextFieldProps,
} from '@mui/material'
import { IProjectSummary } from '@core/entities/project'

// Type for the value based on the `multiple` prop
type ProjectPickerValue<T extends boolean> = T extends true
  ? number[]
  : number | null
interface ProjectPickerProps<T extends boolean> {
  organisationId: number
  readOnly?: boolean
  multiple?: T
  disabled?: boolean
  value: ProjectPickerValue<T>
  onChange?: (value: ProjectPickerValue<T>) => void
  sx?: SxProps
  size?: 'small' | 'medium'
  textFieldProps?: TextFieldProps
}

const ProjectPicker = <T extends boolean>({
  organisationId,
  readOnly,
  disabled,
  multiple = false as T,
  value,
  onChange,
  sx,
  size = 'medium',
  textFieldProps,
}: ProjectPickerProps<T>) => {
  const { data: projects } = useGetAllProjects({ organisationId })

  if (readOnly) {
    const projectName = multiple
      ? projects
          ?.filter((project) => (value as number[]).includes(project.id))
          .map((project) => project.name)
          .join(', ')
      : projects?.find((project) => project.id === value)?.name

    return (
      <Section sx={sx} label={localizedStrings.project}>
        <Text>{projectName}</Text>
      </Section>
    )
  }

  // Derive the appropriate value for the Autocomplete component
  const selectedValue = multiple
    ? projects?.filter((project) => (value as number[]).includes(project.id)) ||
      []
    : projects?.find((project) => project.id === value) || null

  return (
    <Autocomplete
      multiple={multiple}
      value={
        selectedValue as AutocompleteValue<
          IProjectSummary,
          T,
          undefined,
          undefined
        >
      }
      disabled={disabled}
      size={size}
      sx={sx}
      options={projects || []}
      isOptionEqualToValue={(option: IProjectSummary, value: IProjectSummary) =>
        option.id === value.id
      }
      blurOnSelect={!multiple}
      selectOnFocus={false}
      getOptionLabel={(option: IProjectSummary) => option.name}
      disableCloseOnSelect={multiple}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label={textFieldProps?.label || localizedStrings.project}
          data-test='select-project-dropdown-btn'
          multiline={multiple}
          FormHelperTextProps={{
            error: textFieldProps?.error,
            sx: { pt: 0.5, pb: 2 },
          }}
        />
      )}
      renderOption={(props, option: IProjectSummary, { selected }) => (
        <MenuItem
          {...props}
          value={option.id}
          selected={selected}
          data-test={`select-project-dropdown-option-${props['data-option-index']}`}
        >
          {multiple && <Checkbox checked={selected} sx={{ p: 0.5, pr: 1 }} />}
          <Text>{option.name}</Text>
        </MenuItem>
      )}
      renderTags={
        multiple
          ? (selected) =>
              selected.map((option: IProjectSummary, index) => (
                <Chip
                  key={index}
                  size='small'
                  variant='filled'
                  label={
                    projects?.find(({ id }) => id === option.id)?.name || ''
                  }
                  sx={{ mr: 0.5, my: 0.5 }}
                />
              ))
          : undefined
      }
      onChange={(_, newValue) => {
        if (multiple) {
          const updatedValue = (newValue as IProjectSummary[]).map(
            (project) => project.id,
          )
          onChange?.(updatedValue as ProjectPickerValue<T>)
        } else {
          const updatedValue = (newValue as IProjectSummary | null)?.id ?? null
          onChange?.(updatedValue as ProjectPickerValue<T>)
        }
      }}
    />
  )
}

export { ProjectPicker }
