import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { IUser, User } from '@core/entities/user'
import {
  registerKeys,
  RequestTypes,
} from '@core/react-query/features/registers/registers/register'

const dataTransformer = (response: PageResponse<IUser>) => {
  return {
    ...response,
    content: response.content.map((user) => User.new(user)),
  }
}

export const useGetRegisterUsers = (
  payload: RequestTypes['getRegisterUsers'],
  options?: UseQueryOptions<
    PageResponse<IUser>,
    RequestErrorType,
    PageResponse<User>
  >,
) => {
  return useQuery<PageResponse<IUser>, RequestErrorType, PageResponse<User>>({
    ...registerKeys.userList(payload),
    select: dataTransformer,
    ...options,
  })
}
