import { PageUrls } from '@core/page-urls'
import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { RejectPermitModal } from '../modals/RejectPermitModal'
import { ApprovePermitModal } from '../modals/ApprovePermitModal'
import { Flex } from '@fundamentals'
import { RequestPermitChangesModal } from '../modals/RequestPermitChangesModal'
import { PermitActionCard } from '../PermitActionCard'
import { useModal } from '@common/GlobalModal'
import { useRouter } from 'next/router'
import { Permit } from '@core/entities/permit/Permit'

type ReviewPermitCardProps = {
  organisationId: number
  projectId: number
  permit: Permit
}
export const ReviewPermitCard: React.FC<ReviewPermitCardProps> = ({
  organisationId,
  projectId,
  permit,
}) => {
  const { showModal } = useModal()
  const router = useRouter()
  if (!permit.hasPermission('ISSUE')) return null

  const approvalData = permit.approvalReviews?.approvals.find(
    ({ approval }) => approval.id === permit.approvalReviews.nextApprovalId,
  )

  const hasQuestionsStep =
    approvalData?.approval?.approvalQuestions &&
    approvalData?.approval?.approvalQuestions?.questions.length > 0

  const hasStageDraft = permit?.permitStageDrafts?.find(
    (stagedDraft) =>
      stagedDraft.referenceObjectId === permit.getNextApproval()?.approval?.id,
  )

  return (
    <PermitActionCard
      title={localizedStrings.approvalRequest}
      info={
        hasStageDraft
          ? localizedStrings.approvalRequestInfoDraft
          : localizedStrings.approvalRequestInfo
      }
      BottomComponent={
        <>
          {permit.hasPermission('REQUEST_CHANGES') && (
            <Button
              data-test='request-changes'
              variant='outlined'
              fullWidth
              onClick={() =>
                showModal(<RequestPermitChangesModal permit={permit} />)
              }
            >
              {localizedStrings.requestChanges}
            </Button>
          )}
          <Flex gap={1}>
            <Button
              data-test='reject-permit'
              variant='outlined'
              fullWidth
              color='error'
              onClick={() => showModal(<RejectPermitModal permit={permit} />)}
            >
              {localizedStrings.reject}
            </Button>
            <Button
              data-test='approve-permit'
              variant='contained'
              fullWidth
              onClick={() => {
                if (!hasQuestionsStep) {
                  return showModal(<ApprovePermitModal permit={permit} />)
                }
                if (projectId) {
                  router.push(
                    PageUrls.projectPermitApproverQuestions(
                      organisationId,
                      projectId,
                      permit.id,
                    ),
                  )
                } else
                  router.push(
                    PageUrls.permitApproverQuestions(organisationId, permit.id),
                  )
              }}
            >
              {localizedStrings.approve}
            </Button>
          </Flex>
        </>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
