import {
  FilterInternalArgumentsBase,
  FilterHookArgumentsBase,
} from '@core/providers/filters'

export type BooleanFilterHookArguments = FilterHookArgumentsBase<boolean> & {
  placeholder?: string
}

export type BooleanFilterInternalArguments = BooleanFilterHookArguments &
  FilterInternalArgumentsBase<string, 'BOOLEAN'>

export const useBooleanFilter: (
  filter: BooleanFilterHookArguments,
) => BooleanFilterInternalArguments = (filter) => {
  const unfocusedText = () => filter.label

  return {
    type: 'BOOLEAN',
    unfocusedText,
    serialization: 'string',
    ...filter,
  }
}
