import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { SignatureEdit } from 'webapp/components/common/Signature'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import { Text } from '@fundamentals'
import ClearIcon from '@mui/icons-material/Clear'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { useMarkPermitComplete } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  permit: Permit
}

export const ClosePermitModal: React.FC<ComponentType> = ({ permit }) => {
  const completePermit = useMarkPermitComplete()
  const { hideModal } = useModal()
  const { data: profile } = useGetProfile()
  const [error, setError] = useState('')
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.closePermit}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Text>{localizedStrings.closePermitParagraph}</Text>
        <SignatureEdit readonly value={profile?.signatureUrl} />
        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            completePermit.mutate(
              {
                permitId: permit.id,
              },
              {
                onSuccess: () => {
                  toast.success(localizedStrings.permitClosed)
                  hideModal()
                },
                onError: (e) => {
                  setError(e.message)
                  toast.error(e.message)
                },
              },
            )
          }}
          data-test='close-permit-submit'
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
