import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  checklistTemplateEditorMutationKeys,
  checklistTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'

export const useActivateChecklistTemplateVersion = (
  options?: UseMutationOptions<
    string,
    string,
    RequestTypes['activateChecklistTemplateVersion']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...checklistTemplateEditorMutationKeys.activateTemplateVersion,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: checklistTemplateKeys._def,
      })
    },
  })
}
