import React, { useMemo, useRef } from 'react'
import { Layer, MapRef, Marker, NavigationControl, Source } from 'react-map-gl'
import { Image } from '@fundamentals'
import { Feature, FeatureCollection, Geometry, Point, Polygon } from 'geojson'
import { MapProps } from '@common/material/MapBox'
import { Map } from '@elements'
import { GeoPolygon } from '@elements/Geolocation'
import { calculateMapBounds } from '@modules/geolocation'

type TimelineMapProps = MapProps & {
  projectId: number
  featurePoint?: Feature<Point>
  featurePolygon?: Feature<Polygon> | FeatureCollection<Polygon>
  userFeaturePoint?: Feature<Point>
  featurePointAccuracyRadius?: Feature<Polygon>
  enableNavigationControl?: boolean
}

export const TimelineMap: React.FC<TimelineMapProps> = ({
  projectId,
  featurePoint,
  featurePolygon,
  userFeaturePoint,
  featurePointAccuracyRadius,
  enableNavigationControl = true,
  ...props
}) => {
  const mapRef = useRef<MapRef>()

  const initialViewState = useMemo(() => {
    const features: Array<Feature<Geometry>> = []
    if (featurePoint) features.push(featurePoint)
    if (userFeaturePoint) features.push(userFeaturePoint)
    if (featurePolygon) {
      if ('features' in featurePolygon) {
        features.push(...featurePolygon.features)
      } else {
        features.push(featurePolygon)
      }
    }
    if (featurePointAccuracyRadius) features.push(featurePointAccuracyRadius)

    return { bounds: calculateMapBounds(features) }
  }, [
    featurePoint,
    featurePolygon,
    userFeaturePoint,
    featurePointAccuracyRadius,
  ])

  return (
    <Map
      mapRef={mapRef}
      projectId={projectId}
      initialViewState={initialViewState}
      {...props}
    >
      {enableNavigationControl && <NavigationControl />}

      {featurePoint && (
        <Marker
          anchor='bottom'
          longitude={featurePoint.geometry.coordinates[0]}
          latitude={featurePoint.geometry.coordinates[1]}
        >
          <Image
            alt='point-marker'
            src='/images/icons/markers/permit_marker_red.svg'
          />
        </Marker>
      )}

      {featurePolygon && (
        <GeoPolygon
          key={'polygon'}
          id={'polygon'}
          promoteId='id'
          featurePolygon={featurePolygon}
        />
      )}

      {userFeaturePoint && (
        <Marker
          anchor='bottom'
          longitude={userFeaturePoint.geometry.coordinates[0]}
          latitude={userFeaturePoint.geometry.coordinates[1]}
        >
          <Image
            alt='user-marker'
            src='/images/icons/markers/user_marker_blue.svg'
          />
        </Marker>
      )}

      {featurePointAccuracyRadius && (
        <Source
          id='accuracy-radius'
          type='geojson'
          data={featurePointAccuracyRadius}
        >
          <Layer
            id='accuracy-radius-fill'
            type='fill'
            paint={{
              'fill-color': 'yellow',
              'fill-opacity': 0.2,
            }}
          />
        </Source>
      )}
    </Map>
  )
}
