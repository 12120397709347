import { localizedStrings } from '@core/strings'
import React from 'react'
import { TextField } from '@common/material'
import { Text } from '@fundamentals'
import { useHtml } from '@common/hooks'
import {
  AlphaNumericQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'

const AlphaNumericQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange, ...inputProps } = {},
  error,
  readOnly,
}) => {
  const { html } = useHtml(value?.submissionText)

  const onChangeText = ({ target: { value: submissionText } }) => {
    onChange({
      ...value,
      submissionText,
    })
  }

  return (
    <>
      {onChange && !readOnly ? (
        <TextField
          value={value?.submissionText}
          onChange={onChangeText}
          minRows={3}
          placeholder={localizedStrings.answerHere}
          multiline
          fullWidth
          data-test={`${question.id}-${'ALPHANUMERIC'}`}
          inputProps={{
            'data-test': 'request-alphanumeric-input',
          }}
          {...inputProps}
        />
      ) : (
        !!value?.submissionText && (
          <Text
            dangerouslySetInnerHTML={{
              __html: html,
            }}
            data-test={`${question.id}-${'ALPHANUMERIC'}`}
          />
        )
      )}
    </>
  )
}

AlphaNumericQuestion.rules = AlphaNumericQuestionRules

export { AlphaNumericQuestion }
