import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { SignatureEdit } from 'webapp/components/common/Signature'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { useDeclineTransfer } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'
import moment from 'moment'
import { Section } from '@common/Section'
import { Text } from '@fundamentals'
import { useForm } from 'react-hook-form'

type ComponentType = {
  permit: Permit
}

export const DeclinePermitTransferModal: React.FC<ComponentType> = ({
  permit,
}) => {
  const declineTransferPermit = useDeclineTransfer()
  const { hideModal } = useModal()
  const { data: profile } = useGetProfile()
  const [error, setError] = useState('')
  const toast = useToast()

  const { message, createdOn, sentTo, createdBy } = permit?.lastTransfer
  const datetimeString = moment(createdOn).format('D MMMM YYYY HH:mm')

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      permitId: permit.id,
      rejectionReason: '',
    },
  })

  const onSubmit = handleSubmit((data) => {
    declineTransferPermit.mutate(data, {
      onError: (e) => {
        setError(e.message)
        toast.error(e.message)
      },
      onSuccess: () => {
        toast.success(localizedStrings.transferDeclined)
        hideModal()
      },
    })
  })

  return (
    <ModalDialogContainer>
      <form onSubmit={onSubmit}>
        <DialogTitle
          variant='h5'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ pr: 1, fontSize: 20 }}
        >
          {localizedStrings.declineTransfer}
          <IconButton aria-label='close-modal' onClick={hideModal}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        >
          <Section label={localizedStrings.intendedRecipient} mt={0.5}>
            <Text>{sentTo?.getFullName()}</Text>
          </Section>

          <Section
            label={`Note from ${createdBy?.getFullName()} - ${datetimeString}`}
            mt={2}
          >
            <Text>{message}</Text>
          </Section>

          <TextField
            label={localizedStrings.rejectionDetails}
            placeholder={localizedStrings.reasonForRejectionDots}
            multiline
            rows={4}
            InputLabelProps={{ shrink: true }}
            sx={{ mt: 4 }}
            {...register('rejectionReason')}
          />
          <SignatureEdit readonly value={profile?.signatureUrl} />
          <ErrorMessage>{error}</ErrorMessage>
        </DialogContent>

        <DialogActions sx={{ pb: 2, pr: 3 }}>
          <Button variant='outlined' onClick={hideModal}>
            {localizedStrings.cancel}
          </Button>
          <Button
            variant='contained'
            type='submit'
            disabled={!isDirty}
            data-test='permit-transfer-decline'
          >
            {localizedStrings.submit}
          </Button>
        </DialogActions>
      </form>
    </ModalDialogContainer>
  )
}
