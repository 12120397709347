import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useChecklistPdfDownloadUrl = (
  options?: UseMutationOptions<
    { urlGET: string; urlDownload: string; expiryInMinutes: number },
    RequestErrorType,
    RequestTypes['getPdfDownloadUrl']
  >,
) => {
  return useMutation({
    ...checklistKeys.pdfDownloadUrl,
    ...options,
  })
}
