import { plainToInstance } from 'class-transformer'
import { IDateFilter } from '@core/entities/filters'
import { FilterBase } from '@core/entities/filters/FilterBase'
import { DateFilterOperatorTypes } from '../types'
import moment from 'moment'

export class DateFilter extends FilterBase<IDateFilter> {
  propertyType: 'DATE'
  operator: DateFilterOperatorTypes
  // for is_before | is_after context
  dateValue?: string
  // for is_between context
  startDate: string
  endDate: string

  public static new(payload: unknown): DateFilter {
    return plainToInstance(DateFilter, payload)
  }

  public getValue(): string {
    const dateString = (date) => moment(date).format('Do MMM YYYY')

    const startDateString = this.startDate ? dateString(this.startDate) : ''
    const endDateString = this.endDate ? ` - ${dateString(this.endDate)}` : ''

    const dateValueString = this.dateValue ? dateString(this.dateValue) : ''

    return this.dateValue
      ? `${dateValueString}`
      : `${startDateString}${endDateString}`
  }
}
