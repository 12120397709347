import { Entity } from '@core/entities/entity'
import { IRegisterTemplateFilterResponse } from './IRegisterTemplateFilterResponse'
import { RegisterTemplateFilter } from '../RegisterTemplateFilter'
import { Type, plainToInstance } from 'class-transformer'

export class RegisterTemplateFilterResponse extends Entity<IRegisterTemplateFilterResponse> {
  @Type(() => RegisterTemplateFilter)
  filters: RegisterTemplateFilter[]

  public static new(payload: unknown): RegisterTemplateFilterResponse {
    return plainToInstance(RegisterTemplateFilterResponse, payload)
  }
}
