import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  projectKeys,
  projectMutationKeys,
} from '@core/react-query/features/projects/project'

export const useSetProjectUserStatus = (
  options?: UseMutationOptions<
    string,
    string,
    RequestTypes['setProjectUserStatus']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...projectMutationKeys.setProjectUserStatus,
    // We don't retry mutations since they're not idempotent
    // We can allow retries if we implement idempotency keys and idempotency checking on the back-end
    retry: 0,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        projectKeys.byId({ projectId: variables.projectId }).queryKey,
        {},
      )
    },
  })
}
