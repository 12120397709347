import { localizedStrings } from '@core/strings'
import React from 'react'
import { Box, Text } from '@fundamentals'
import {
  LinkChecklistsQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import { LinkedChecklistResult } from '@core/entities/linkedProcesses'
import { Checklist } from '@core/entities/checklist/Checklist'
import { Grid } from '@mui/material'
import { ChecklistCard } from '../../Cards'
import { Button, IconButton, Tooltip } from '@common/material'
import { PageUrls } from '@core/page-urls'
import LaunchIcon from '@mui/icons-material/Launch'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { SelectOrganisationChecklistsModal } from '@elements'
import { useNavigationContext } from '@common/hooks'
import { useFormContext } from 'react-hook-form'

const LinkChecklistsQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange, ...inputProps } = {},
  readOnly,
}) => {
  const { organisationId } = useNavigationContext()
  const formContext = useFormContext()
  const projectId = formContext?.getValues('projectId')

  const onChangeSelectedChecklists = (
    checklistIds: Array<number>,
    checklists: Array<Checklist>,
  ) => {
    onChange({
      ...value,
      linkedChecklistsIds: checklistIds,
      linkedChecklistsResults:
        checklists as unknown as Array<LinkedChecklistResult>,
    })
  }

  const removeSelectedChecklist = (checklistId: number) => {
    onChange({
      ...value,
      linkedChecklistsIds: value.linkedChecklistsIds.filter(
        (id) => id !== checklistId,
      ),
      linkedChecklistsResults: value.linkedChecklistsResults.filter(
        (checklist) => checklist.id !== checklistId,
      ),
    })
  }

  return (
    <Box data-test={`${question.id}-${'LINK_CHECKLISTS'}`}>
      {readOnly && (
        <Text color='text.secondary' fontSize='12px' sx={{ mb: 1 }}>
          {localizedStrings.linkedDocuments}
        </Text>
      )}

      <Grid container spacing={1.5}>
        {question?.linkedChecklistsResults?.map(
          (linkedChecklistResult, index) => {
            const { id, templateName, ...linkedChecklistResultEntity } =
              LinkedChecklistResult.new(linkedChecklistResult)

            return (
              <Grid key={index} item xs={4} minWidth={300}>
                <ChecklistCard
                  templateName={templateName}
                  sx={{ height: '100%' }}
                  icon={
                    readOnly ? (
                      <IconButton
                        color='primary'
                        size='small'
                        onClick={() => {
                          window.open(
                            PageUrls.checklist(organisationId, id),
                            '_blank',
                          )
                        }}
                      >
                        <Tooltip title={localizedStrings.viewChecklist}>
                          <LaunchIcon fontSize='small' />
                        </Tooltip>
                      </IconButton>
                    ) : (
                      <IconButton
                        color='error'
                        onClick={() => removeSelectedChecklist(id)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    )
                  }
                  {...linkedChecklistResultEntity}
                />
              </Grid>
            )
          },
        )}
      </Grid>

      {!readOnly && (
        <SelectOrganisationChecklistsModal
          title={localizedStrings.selectChecklistsToLink}
          organisationId={organisationId}
          value={value.linkedChecklistsIds}
          onChange={onChangeSelectedChecklists}
          triggerComponent={(onClick) => (
            <Button variant='contained' onClick={onClick} sx={{ mt: 1.5 }}>
              {localizedStrings.addDocuments}
            </Button>
          )}
          filters={{
            templateIds: value?.checklistTemplateLinkingIds,
            statuses: value?.checklistTemplateLinkingStatuses,
            projectIds: [projectId],
          }}
          multiSelect
        />
      )}
    </Box>
  )
}

LinkChecklistsQuestion.rules = LinkChecklistsQuestionRules

export default LinkChecklistsQuestion
