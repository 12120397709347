import React, { MutableRefObject, useMemo, useRef } from 'react'
import { MapboxEvent, MapRef } from 'react-map-gl'
import useConstants from '@core/providers/useConstants'
import { useProjectMapConfiguration } from '@core/react-query/features/projects/maps'
import { MapBoxMap, MapProps as BaseMapProps } from '@common/material/MapBox'

/**
 * Map component loads map configuration and images for project-specific mapping functionality.
 *
 * Props:
 * - `projectId`: The ID of the project to load specific map configurations.
 * - `mapRef`: Reference to the Mapbox map instance.
 * - `children`: Optional children to be rendered within the map.
 *
 * Extends:
 * - `BaseMapProps` for additional MapBoxMap props.
 */
type MapProps = {
  projectId: number
  mapRef: MutableRefObject<MapRef>
  children?: React.ReactNode
} & BaseMapProps

const Map: React.FC<MapProps> = ({
  projectId,
  onLoad,
  mapRef: _mapRef,
  children,
  initialViewState: initialViewStateOverride,
  mapStyle: mapStyleOverride,
  ...props
}) => {
  // Use the provided mapRef or create a new one if not provided
  const mapRef = _mapRef || useRef<MapRef>()
  const { constants } = useConstants()

  // Fetch the project-specific map configuration using projectId
  const {
    data: projectMapConfigurationFromServer,
    isLoading: isProjectMapConfigurationLoading,
  } = useProjectMapConfiguration({
    projectId,
  })

  // Set the initial view state of the map, prioritizing:
  // 1. An override passed in as `initialViewStateOverride`.
  // 2. Project-specific configuration fetched from the server.
  // 3. Default values from constants if no other values are available.
  const initialViewState = useMemo(() => {
    if (initialViewStateOverride) {
      return initialViewStateOverride
    }

    if (projectMapConfigurationFromServer) {
      return {
        latitude: projectMapConfigurationFromServer.mapCenterLatitude,
        longitude: projectMapConfigurationFromServer.mapCenterLongitude,
        zoom: projectMapConfigurationFromServer.mapZoom,
      }
    }

    return {
      latitude: constants.mapbox_default_center_latitude,
      longitude: constants.mapbox_default_center_longitude,
      zoom: constants.mapbox_default_zoom,
    }
  }, [projectMapConfigurationFromServer])

  // Determine the map style URL, prioritizing:
  // 1. An override passed in as `mapStyleOverride`.
  // 2. Project-specific configuration fetched from the server.
  // 3. Default style URL from constants.
  const mapBoxStyleUrl = useMemo(() => {
    if (mapStyleOverride) {
      return mapStyleOverride
    }

    if (projectMapConfigurationFromServer) {
      return projectMapConfigurationFromServer.mapBoxStyleUrl
    }

    return constants.mapbox_default_style_url
  }, [projectMapConfigurationFromServer])

  // Handles map load event to load images needed for markers
  const _onLoad = (e: MapboxEvent) => {
    // Define images to be used as markers in the map
    const imagesToLoad = [
      {
        url: '/images/icons/markers/permit_marker_white.png',
        name: 'unselected-marker-icon',
      },
      {
        url: '/images/icons/markers/permit_marker_purple.png',
        name: 'selected-marker-icon',
      },
    ]

    // Load each image and add it to the map's symbol layers if not already present
    imagesToLoad.forEach(({ url, name }) => {
      mapRef.current?.loadImage(url, (error, image) => {
        if (error) return

        // Add the image only if it hasn't been added before
        if (!mapRef.current.hasImage(name)) mapRef.current.addImage(name, image)
      })
    })

    // Trigger the onLoad callback, if provided
    onLoad?.(e)
  }

  // Display a loader while the map configuration is being fetched
  if (isProjectMapConfigurationLoading) {
    return <Loader />
  }

  // Render the MapBoxMap with specified props and children
  return (
    <MapBoxMap
      mapRef={mapRef}
      onLoad={_onLoad}
      initialViewState={initialViewState}
      mapStyle={mapBoxStyleUrl}
      {...props}
    >
      {children}
    </MapBoxMap>
  )
}

export { Map }
