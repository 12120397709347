import { Palette } from './color-palette-siteassist'

type PaletteType = typeof palette

const palette = {
  primary: {
    main: Palette.Blue[700],
    light: '#4791DB',
    dark: '#115293',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: Palette.DeepPurple[400],
    light: '#8376CC',
    dark: '#463A86',
    contrastText: '#FFFFFF',
  },
  success: {
    main: Palette.Green[400],
    light: '#5EC297',
    dark: '#257D58',
    contrastText: '#FFFFFF',
  },
  info: {
    main: Palette.Cyan[500],
    light: '#33C6E0',
    dark: '#008097',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: Palette.Amber[700],
    light: '#FFBB33',
    dark: '#B27700',
    contrastText: '#FFFFFF',
  },
  error: {
    main: Palette.Red[500],
    light: '#FF7759',
    dark: '#B23C21',
    contrastText: '#FFFFFF',
  },
}

export { palette }
export type { PaletteType }
