import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import {
  IChecklistTemplateRole,
  ChecklistTemplateRole,
} from '@core/entities/checklist/ChecklistTemplateRoles'

const permitTemplateRolesTransform = (response: IChecklistTemplateRole[]) =>
  response.map(ChecklistTemplateRole.new)

export const useGetChecklistRoles = (
  payload: RequestTypes['getChecklistRoles'],
  options?: UseQueryOptions<
    IChecklistTemplateRole[],
    string,
    ChecklistTemplateRole[]
  >,
) => {
  return useQuery({
    select: permitTemplateRolesTransform,
    ...checklistKeys.byId(payload)._ctx.getRoles,
    ...options,
  })
}
