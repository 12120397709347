import React from 'react'
import { PermitDetailsProps } from '@modules/permitDetails/types'
import { PermitDetails } from '@modules/permitDetails/PermitDetails'
import { DialogContent } from '@common/material'
import { ModalDialogContainer } from '@common/modal'

export type PermitDetailsModalProps = PermitDetailsProps

export const PermitDetailsModal: React.FC<PermitDetailsModalProps> = ({
  ...props
}) => {
  return (
    <ModalDialogContainer
      PaperProps={{
        sx: {
          maxWidth: '80%',
        },
      }}
    >
      <DialogContent
        sx={{
          overflow: 'auto',
          height: '100vh',
          flexDirection: 'column',
          my: '20px',
          py: 0,
        }}
      >
        <PermitDetails {...props} isModal />
      </DialogContent>
    </ModalDialogContainer>
  )
}
