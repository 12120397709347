import { Feature } from 'geojson'
import { buffer, featureCollection } from '@turf/turf'
import bbox from '@turf/bbox'
import { MapRefType } from '@common/material/MapBox'

export const zoomToFeatures = (args: {
  mapRef: MapRefType
  features: Feature[]
  radius?: number
}) => {
  if (!args.features?.length || !args.mapRef.current) {
    return
  }

  const allFeatures = featureCollection(args?.features)

  const bufferedZones = buffer(allFeatures, args?.radius || 60, {
    units: 'meters',
  })

  // Calculate the bounding box of the buffered zones
  const boundingBox = bbox(bufferedZones)

  args.mapRef?.current?.fitBounds(
    [
      [boundingBox[0], boundingBox[1]],
      [boundingBox[2], boundingBox[3]],
    ],
    {
      duration: 1000,
    },
  )
}
