import { plainToInstance } from 'class-transformer'
import { FilterBase } from '@core/entities/filters/FilterBase'
import { IUserFilter } from './IUserFilter'
import { UserFilterOperatorTypes } from '../types'
import { User } from '@core/entities/user'

export class UserFilter extends FilterBase<IUserFilter> {
  propertyType: 'USER'
  operator: UserFilterOperatorTypes
  userId: number

  public static new(payload: unknown): UserFilter {
    return plainToInstance(UserFilter, payload)
  }

  public getValue = ({
    userRecord,
  }: {
    userRecord: Record<string, User>
  }): string => {
    return userRecord[this.userId]?.getFullName() ?? ''
  }
}
