import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { projectKeys } from '../projectKeys'
import { checklistTemplateKeys } from '@core/react-query/features/templates/checklistTemplates'

export const useAddProjectChecklistTemplates = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['addProjectPermitTemplates']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...projectKeys.addProjectChecklistTemplates,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(checklistTemplateKeys.getTemplates._def)
    },
  })
}
