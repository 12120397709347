import React, { ReactNode } from 'react'
import { Feature, FeatureCollection, Point, Polygon } from 'geojson'
import { DialogContent } from '@mui/material'
import { Flex } from '@fundamentals'
import { TimelineMap } from '@modules/geolocation'
import { ModalDialogContainer } from '@common/modal'

type SimpleMapModalProps = {
  projectId: number
  featurePoint?: Feature<Point>
  featurePolygon?: Feature<Polygon> | FeatureCollection<Polygon>
  userFeaturePoint?: Feature<Point>
  featurePointAccuracyRadius?: Feature<Polygon>
  footerActions?: ReactNode
}

export const SimpleMapModal: React.FC<SimpleMapModalProps> = ({
  projectId,
  featurePoint,
  featurePolygon,
  userFeaturePoint,
  featurePointAccuracyRadius,
  footerActions,
}) => {
  return (
    <ModalDialogContainer maxWidth={false}>
      <DialogContent>
        <TimelineMap
          projectId={projectId}
          featurePoint={featurePoint}
          featurePolygon={featurePolygon}
          userFeaturePoint={userFeaturePoint}
          featurePointAccuracyRadius={featurePointAccuracyRadius}
          style={{ height: '85vh', width: '100%' }}
        />
        {!!footerActions && (
          <Flex justifyContent='flex-end' sx={{ pb: 2, pr: 2, pt: 0 }}>
            {footerActions}
          </Flex>
        )}
      </DialogContent>
    </ModalDialogContainer>
  )
}
