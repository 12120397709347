import React, { useMemo } from 'react'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { localizedStrings } from '@core/strings'
import { TimelineTextBlock } from '@common/Timeline'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelinePermissionAssignmentsBlocks: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  const referenceObjectTypeName = useMemo(() => {
    switch (auditResult.metadata.permissionAssignmentReferenceObjectType) {
      case 'ORGANISATION':
        return localizedStrings.organisation
      case 'PROJECT':
        return localizedStrings.project
      case 'PERMIT_TEMPLATE':
        return localizedStrings.permitTemplate
      case 'CHECKLIST_TEMPLATE':
        return localizedStrings.checklistTemplate
      case 'REGISTER':
        return localizedStrings.register
      default:
        return ''
    }
  }, [auditResult.metadata.permissionAssignmentReferenceObjectType])

  const permissionAssignmentTypeTitle = useMemo(() => {
    switch (auditResult.metadata.permissionAssignmentType) {
      case 'PERMIT_INITIATION':
      case 'CHECKLIST_INITIATION':
        return localizedStrings.initiatorRoleQuestion
      case 'PERMIT_REVIEW_EXTENSION':
        return localizedStrings.extensionsReviewTitle
      case 'PERMIT_DO_FINAL_SIGN_OFF':
        return localizedStrings.finalSignoffSubtitle
      case 'REVIEW_PERMIT_TRANSFER_REQUEST':
        return localizedStrings.approvePermitTransfersTitle
      case 'PERMIT_APPROVE_SUSPENSION_RESUME_REQUEST':
        return localizedStrings.resumeAfterSuspensionTitle
      default:
        return auditResult.metadata.permissionAssignmentType
    }
  }, [auditResult.metadata.permissionAssignmentType])

  return (
    <>
      <TimelineTextBlock
        title={permissionAssignmentTypeTitle}
        text={auditResult.metadata.permissionAssignmentAuthGroupNames?.join(
          ', ',
        )}
      />
      <TimelineTextBlock
        title={referenceObjectTypeName}
        text={auditResult.metadata.permissionAssignmentReferenceObjectName}
      />
    </>
  )
}
