import { localizedStrings } from '@core/strings'
import React from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import { DialogTitle, IconButton, DialogContent } from '@common/material'
import { Box, Flex, Image, Text } from '@common/fundamentals'
import ClearIcon from '@mui/icons-material/Clear'
import { ModalDialogContainer } from '@common/modal'
import { PermitWarning } from '@core/entities/template/TemplateWarnings'
import WarningCard from '@modules/permitDetails/WarningCard'

interface ComponentType {
  warning: PermitWarning
}

export const ObjectDetectionWarningModal: React.FC<ComponentType> = ({
  warning,
}) => {
  const { hideModal, showModal } = useModal()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.hazardDetected}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <Box>
          <Text fontWeight='bold'>
            {localizedStrings.objectDetectionWarningModalDescription}
          </Text>
          {warning.downloadUrls && (
            <Flex flexWrap={'wrap'} gap={1} my={2}>
              {warning.downloadUrls?.map((imageUrl) => (
                <Image key={imageUrl} src={imageUrl} width={170} />
              ))}
            </Flex>
          )}
          <WarningCard warning={warning} />
        </Box>
      </DialogContent>
    </ModalDialogContainer>
  )
}
