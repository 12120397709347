import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { AuditResult, IAuditResult } from '@core/entities/audit/AuditResult'

const dataTransformer = (audits: Array<IAuditResult>) =>
  audits.map(AuditResult.new)

export const useGetChecklistAuditsByParticipantId = (
  payload: RequestTypes['getChecklistAuditsByParticipantId'],
  options?: UseQueryOptions<
    Array<IAuditResult>,
    RequestErrorType,
    Array<AuditResult>
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...checklistKeys.byId(payload)._ctx.auditsByParticipantId(payload),
    ...options,
  })
}
