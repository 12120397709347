import React from 'react'
import { RegisterItemUserProperty } from '@core/entities/register/RegisterItem'
import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { RegisterItemText } from '@modules/registers/items/RegisterItemText'
import { RegisterItemContentButtons } from '@modules/registers/propertyValue/RegisterItemPropertyValueButtons'
import { Box } from '@fundamentals'
import { Tooltip } from '@common/material'
import { emptyValue } from '@modules/registers/propertyValue/helpers'

type RegisterItemPropertyUserValueProps = {
  registerItemPropertyData: RegisterItemUserProperty<string>
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertyUserValue: React.FC<
  RegisterItemPropertyUserValueProps
> = ({ registerItemPropertyData, variant }) => {
  return (
    <Tooltip title={registerItemPropertyData?.selectedUser?.email} arrow>
      <RegisterItemContentButtons
        variant={variant}
        value={registerItemPropertyData?.selectedUser?.email}
        registerItemPropertyData={registerItemPropertyData}
      >
        {registerItemPropertyData?.selectedUser ? (
          <Box width='100%'>
            <RegisterItemText>{`${registerItemPropertyData?.selectedUser?.firstName} ${registerItemPropertyData?.selectedUser?.lastName}`}</RegisterItemText>
            {variant !== 'picker' && (
              <RegisterItemText>
                ({registerItemPropertyData?.selectedUser?.company})
              </RegisterItemText>
            )}
          </Box>
        ) : (
          <RegisterItemText>{emptyValue(variant)}</RegisterItemText>
        )}
      </RegisterItemContentButtons>
    </Tooltip>
  )
}
