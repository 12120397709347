import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  authGroupsKeys,
  RequestTypes,
} from '@core/react-query/features/authGroups'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useCreateOranisationAuthGroup = (
  options?: UseMutationOptions<
    any,
    RequestErrorType,
    RequestTypes['createOranisationAuthGroup']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...authGroupsKeys.createOranisationAuthGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: authGroupsKeys._def })
    },
    ...options,
  })
}
