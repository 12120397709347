import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import {
  checklistTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'
import {
  ITemplateEditorChecklistTemplate,
  TemplateEditorChecklistTemplate,
} from '@core/entities/checklist/TemplateEditorChecklistTemplate'

const dataTransformer = (
  response: PageResponse<ITemplateEditorChecklistTemplate>,
) => {
  return {
    ...response,
    content: response.content.map((template) =>
      TemplateEditorChecklistTemplate.new(template),
    ),
  }
}

export const useGetChecklistTemplates = (
  params: RequestTypes['getChecklistTemplates'],
  options: UseQueryOptions<
    PageResponse<ITemplateEditorChecklistTemplate>,
    string,
    PageResponse<TemplateEditorChecklistTemplate>
  > = {},
) => {
  return useQuery<
    PageResponse<ITemplateEditorChecklistTemplate>,
    string,
    PageResponse<TemplateEditorChecklistTemplate>
  >({
    select: dataTransformer,
    ...checklistTemplateKeys.getTemplates(params),
    ...options,
  })
}
