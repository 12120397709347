import { localizedStrings } from '@core/strings'
import React from 'react'
import { Flex } from '@fundamentals'
import { useModal } from 'webapp/components/common/GlobalModal'
import { CheckCircle, TimelapseOutlined } from '@mui/icons-material'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { ModalDialogContainer } from '@common/modal'
import { PermitFinalSignOffReviews } from '@core/entities/permit/PermitFinalSignOffReviews'

interface ComponentType {
  finalSignOffReviews: PermitFinalSignOffReviews
}

export const ViewFinalSignOffsPermitModal: React.FC<ComponentType> = ({
  finalSignOffReviews,
}) => {
  const { hideModal } = useModal()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.finalSignOffs}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        {finalSignOffReviews?.reviews?.map(
          ({ finalSignOff, createdBy }, index) => {
            const Icon = () =>
              index < finalSignOffReviews.getDoneReviewsIndex() ? (
                <CheckCircle color='success' sx={{ pr: 0.5 }} />
              ) : (
                <TimelapseOutlined color='action' sx={{ pr: 0.5 }} />
              )
            const label =
              index < finalSignOffReviews.getDoneReviewsIndex()
                ? `${createdBy?.getFullName()} (${finalSignOff?.label})`
                : finalSignOff?.label

            return (
              <Flex flexDirection='column' key={index}>
                <Flex alignItems='center'>
                  <Icon />
                  {label}
                </Flex>
              </Flex>
            )
          },
        )}
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='contained' onClick={hideModal}>
          {localizedStrings.ok}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
