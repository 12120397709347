import {
  FilterMap,
  FilterMapInternal,
  FilterValueMapInternal,
} from '@core/providers/filters'
import Router, { useRouter } from 'next/router'
import { FilterStore, useFilterValueStore } from '@core/providers/filters'
import { useEffect } from 'react'
import { FilterInternalArgumentsBase } from '@core/providers/filters'

export type FilterQueryParamMap<T extends FilterMap> = {
  [filterKey in keyof T]: string
}

type UseQueryParamFilterStoreArgs<FilterMapType extends FilterMapInternal> = {
  filters: FilterMapType
  // We force passing an explicit mapping to query param keys to make developers think
  // about backwards compatiblity with links that may be bookmarked by users
  queryParams: FilterQueryParamMap<FilterMapType>
  onChangeOverride?: Record<string, any>
  initialValues?: FilterValueMapInternal<FilterMapType>
  // If values is passed, then this is a controlled component
  values?: FilterValueMapInternal<FilterMapType>
  whitelistValues?: Partial<
    Record<keyof FilterQueryParamMap<FilterMapType>, Array<string>>
  >
}

export const useQueryParamFilterStore: <
  FilterMapType extends FilterMapInternal,
>(
  args: UseQueryParamFilterStoreArgs<FilterMapType>,
) => FilterStore<FilterMapType> = ({
  filters,
  queryParams,
  onChangeOverride,
  initialValues,
  values: valuesProp,
  whitelistValues,
}) => {
  const router = useRouter()

  const valuesFromUrl = () => {
    const filterValues = {}
    Object.keys(filters).map((filterKey) => {
      const filter = filters?.[filterKey]
      // ignore any query params that are not part of filters
      if (!filter) {
        return
      }

      const queryParamKey = queryParams[filterKey]
      const queryValue = router.query[queryParamKey]
      if (!queryValue) {
        return
      }

      if (Array.isArray(queryValue)) {
        filterValues[filterKey] = queryValue.map((v) => {
          if (
            whitelistValues?.[filterKey] &&
            !whitelistValues?.[filterKey]?.includes(v)
          ) {
            return
          }
          return parseFilterValue(filter, v)
        })
      } else {
        if (
          whitelistValues?.[filterKey] &&
          !whitelistValues?.[filterKey]?.includes(queryValue)
        ) {
          return
        }
        const parsedValue = parseFilterValue(filter, queryValue)
        if (filter.type === 'SELECT' && filter.multiple) {
          // For multiple select filters we have to pass the value as an array
          // If there is only one value in the url, the query value returned by next is not an array
          filterValues[filterKey] = [parsedValue]
        } else {
          filterValues[filterKey] = parsedValue
        }
      }
    })
    return filterValues
  }

  const store = useFilterValueStore({
    filters,
    initialValues: {
      ...initialValues,
      ...valuesFromUrl(),
    },
    values: valuesProp,
  })

  useEffect(() => {
    const onChange = (values: FilterValueMapInternal<FilterMapInternal>) => {
      const newQuery = {}
      const queryParamKeys = Object.keys(queryParams).map(
        (key) => queryParams[key],
      )

      // using the global Router to have access to the latest query parameters
      const queryParamsNotInFilters = Object.keys(Router.query).filter(
        (key) => !queryParamKeys.includes(key),
      )

      // Add query params that are not part of the filter
      queryParamsNotInFilters.forEach((key) => {
        newQuery[key] = Router.query[key]
      })

      onChangeOverride &&
        Object.keys(onChangeOverride).forEach((key) => {
          newQuery[key] = onChangeOverride[key]
        })

      // Add query params for filters
      Object.keys(values).forEach((filterKey) => {
        const queryParamKey = queryParams[filterKey]
        const filterValue = values[filterKey]
        const filter = filters[filterKey]
        if (Array.isArray(filterValue)) {
          newQuery[queryParamKey] = filterValue.map((v) =>
            serializeFilterValue(filter, v),
          )
        } else {
          newQuery[queryParamKey] = serializeFilterValue(filter, filterValue)
        }
      })

      router.query = newQuery
      router.push(router, null, { shallow: true })
    }

    store.addOnChangeListener(onChange)
  }, [])

  return store
}

const serializeFilterValue = (
  filter: FilterInternalArgumentsBase<any, any>,
  value: any,
) => {
  if (!value) {
    return null
  }

  switch (filter.serialization) {
    case 'object':
      return JSON.stringify(value)
    case 'number':
    case 'string':
    default:
      return value
  }
}

const parseFilterValue = (
  filter: FilterInternalArgumentsBase<any, any>,
  value: string,
) => {
  switch (filter.serialization) {
    case 'number':
      return parseInt(value)
    case 'object':
      return JSON.parse(value)
    case 'string':
    default:
      return value
  }
}
