import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import {
  IPermitTemplateRole,
  PermitTemplateRole,
} from '@core/entities/permit/PermitTemplateRoles'

const permitTemplateRolesTransform = (response: IPermitTemplateRole[]) =>
  response.map(PermitTemplateRole.new)

export const useGetPermitRoles = (
  payload: RequestTypes['getPermitRoles'],
  options?: UseQueryOptions<
    IPermitTemplateRole[],
    string,
    PermitTemplateRole[]
  >,
) => {
  return useQuery({
    select: permitTemplateRolesTransform,
    ...permitKeys.byId(payload)._ctx.getRoles,
    ...options,
  })
}
