import React, { forwardRef, Ref, useState } from 'react'
import DatePicker from 'components/DatePicker'
import { Box } from '@fundamentals'
import { TextField } from '@mui/material'
import { AnalyticsCustomDateRangeFilterValue } from '@core/providers/filters'
import { AnalyticsCustomDateRangeInternalArguments } from '@core/providers/filters'
import { IconButton, InputAdornment } from '@common/material'
import { Close } from '@mui/icons-material'

type AnalyticsDateRangeFilterProps = {
  filter: AnalyticsCustomDateRangeInternalArguments
  onChange: (value: AnalyticsCustomDateRangeFilterValue) => void
  onCalendarClose: () => void
  value: AnalyticsCustomDateRangeFilterValue
}

const AnalyticsDateRangeFilter: React.FC<AnalyticsDateRangeFilterProps> = ({
  filter,
  onChange,
  onCalendarClose,
  value,
}) => {
  const [dateRange, setDateRange] = useState(value || [])

  const _onChange = (dates) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates
      setDateRange([start, end])

      if (!start && !end) {
        setDateRange([])
        onChange([])
      }
    }
  }

  const _onCalendarClose = () => {
    if (dateRange?.[0] && dateRange?.[1]) {
      // The date picker returns timestamps at 00:00 at local time, transformed to UTC
      // This function takes that timestamp and turns it to 00:00 at UTC time
      const correctToUtc = (val: string) => {
        const date = new Date(val)
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      }
      const start = correctToUtc(dateRange?.[0]).toISOString()
      const end = correctToUtc(dateRange?.[1]).toISOString()
      // Extract the date part of the timestamp
      const startString = start.split('T')[0]
      const endString = end.split('T')[0]
      onChange([startString, endString])
    }
    onCalendarClose()
  }

  const MuiInput = forwardRef((props, ref: Ref<HTMLDivElement>) => (
    <TextField
      sx={{ width: 280 }}
      ref={ref}
      label='Date Range'
      size='small'
      multiline={false}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={() => {
                setDateRange([])
                onChange([])
              }}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  ))

  return (
    <Box
      sx={{
        pr: 1,
        '& .react-datepicker__input-container': {
          border: 'none !important',
          '& input': {
            paddingTop: 0,
            paddingBottom: 0,
            bgcolor: 'transparent',
            border: '1px solid transparent !important',
          },
        },
        '& .react-datepicker-popper': {
          zIndex: '11 !important',
        },
      }}
    >
      <DatePicker
        autoFocus
        value={null}
        customInput={<MuiInput />}
        onChange={_onChange}
        onCalendarClose={_onCalendarClose}
        startDate={dateRange?.[0] ? new Date(dateRange?.[0]) : null}
        endDate={dateRange?.[1] ? new Date(dateRange?.[1]) : null}
        selectsRange
        dateFormat='yyyy-MM-dd'
      />
    </Box>
  )
}

export default AnalyticsDateRangeFilter
