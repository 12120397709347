import React from 'react'
import { Permit } from '@core/entities/permit/Permit'
import { useGetImageDetails } from '@core/react-query/features/image-details'
import { BlurOnOutlined, SmartToyOutlined } from '@mui/icons-material'
import { Checklist } from '@core/entities/checklist/Checklist'
import { Chip } from '@common/material'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { useModal } from '@common/GlobalModal'
import { SubmissionProof } from '@core/entities/template/TemplateQuestion'
import { IImageDetails } from '@core/entities/permit/ImageDetails'
import { ObjectDetectionImageListModal } from '@elements/ObjectDetection/ObjectDetectionImageListModal'

type ObjectDetectionChipsPermitProps = {
  permit: Permit
}

type ObjectDetectionChipsChecklistProps = {
  checklist: Checklist
}

type ObjectDetectionS3KeyProps = {
  s3Key: string
}

export type ObjectDetectionChipsProps =
  | ObjectDetectionChipsChecklistProps
  | ObjectDetectionChipsPermitProps
  | ObjectDetectionS3KeyProps

const demoAcceptanceThreshold: number = 0.7

export const ObjectDetectionChips: React.FC<ObjectDetectionChipsProps> = (
  props,
) => {
  const { flags } = useFeatureFlags(['web_object_detection'])
  const { showModal } = useModal()

  const getImageKeys = () => {
    if ('permit' in props) {
      return props.permit.getImageS3Keys()
    }
    if ('checklist' in props) {
      return props.checklist.getImageS3Keys()
    }
    if ('s3Key' in props) {
      return [props.s3Key]
    }
  }

  const getProofs = () => {
    if ('permit' in props) {
      return props.permit.getProofs()
    }
    if ('checklist' in props) {
      return props.checklist.getProofs()
    }
    return []
  }

  const imageKeys = getImageKeys()
  const proofs = getProofs()

  const { data: imageDetails } = useGetImageDetails(
    {
      imageKeys,
    },
    {
      enabled: !!flags.web_object_detection && !!imageKeys?.length,
    },
  )
  const chips: {
    [key: string]: { label: string; icon: any; objectKey?: string }
  } = {}

  const isDemoPermitTemplate =
    'permit' in props ? [224, 254382].includes(props.permit.template.id) : false

  imageKeys.forEach((imageKey) => {
    const details = imageDetails?.[imageKey]
    if (details?.info?.blurry?.is_blurry) {
      chips.Blurry = {
        label: 'Blurry',
        icon: <BlurOnOutlined />,
        objectKey: 'blurry',
      }
    }

    Object.keys(details?.info?.object_detection?.objects || {}).forEach(
      (objectKey) => {
        const object = details?.info?.object_detection?.objects[objectKey]

        if (isDemoPermitTemplate) {
          const object_to_include: string[] = [
            'fire_extinguisher',
            'person_without_full_ppe',
            'screenshot_or_photo_of_photo',
          ]

          if (
            object_to_include.includes(objectKey) &&
            object?.probability > demoAcceptanceThreshold
          ) {
            chips[object.display_name] = {
              objectKey,
              label: object.display_name,
              icon: <SmartToyOutlined />,
            }
          }
        } else if (object?.present) {
          chips[object.display_name] = {
            objectKey,
            label: object.display_name,
            icon: <SmartToyOutlined />,
          }
        }
      },
    )
  })

  if (
    isDemoPermitTemplate &&
    !Object.keys(chips).includes('Fire Extinguisher')
  ) {
    chips['No Fire Extinguisher'] = {
      label: 'No Fire Extinguisher',
      icon: <SmartToyOutlined />,
    }
  }

  if (!flags.web_object_detection) {
    return null
  }

  return (
    <>
      {Object.values(chips).map((c) => (
        <Chip
          key={c.label}
          color={'info'}
          icon={c.icon}
          label={c.label}
          onClick={() => {
            showModal(
              <ObjectDetectionImageListModal
                objectName={c.label}
                proofs={findProofsForObjectKey(
                  proofs,
                  imageDetails,
                  c.objectKey,
                  isDemoPermitTemplate,
                )}
              />,
            )
          }}
        />
      ))}
    </>
  )
}

const findProofsForObjectKey = (
  proofs: SubmissionProof[],
  imageDetails: IImageDetails,
  objectKey: string,
  isDemoPermitTemplate: boolean,
) => {
  const proofsFound = proofs?.filter((proof) => {
    const details = imageDetails[proof.key]
    if (objectKey === 'blurry') {
      return details?.info?.blurry?.is_blurry
    }

    const predictionForObjectKey =
      details?.info?.object_detection?.objects?.[objectKey]
    if (isDemoPermitTemplate) {
      return predictionForObjectKey?.probability > demoAcceptanceThreshold
    }
    return predictionForObjectKey?.present
  })
  const proofsFoundKeys = proofsFound?.map((proof) => proof.key)

  const proofsNotFound = proofs?.filter(
    (proof) => !proofsFoundKeys.includes(proof.key),
  )

  return {
    present: proofsFound || [],
    notPresent: proofsNotFound || [],
  }
}
