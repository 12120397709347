import React from 'react'
import {
  TimelineExtensionBlock,
  TimelinePermitWarningsBlock,
  TimelineTextBlock,
  TimelineTransferBlock,
} from '@common/Timeline'
import { localizedStrings } from '@core/strings'
import { AuditResult } from '@core/entities/audit/AuditResult'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelinePermitBlocks: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  return (
    <>
      {auditResult.hasWarnings() && (
        <TimelinePermitWarningsBlock auditResult={auditResult} />
      )}
      {auditResult.isExtendingPermit() && (
        <TimelineExtensionBlock
          extensionEndsOn={auditResult.metadata?.permitExtensionEndsOn}
          previousEndsOn={auditResult.metadata?.permitPreviousEndsOn}
        />
      )}
      {auditResult.isShorteningPermit() && (
        <TimelineExtensionBlock
          title={localizedStrings.shortenInformation}
          extensionEndsOn={auditResult.metadata?.permitShortenedEndsOn}
          previousEndsOn={auditResult.metadata?.permitPreviousEndsOn}
        />
      )}
      {auditResult.isTransferring() && (
        <TimelineTransferBlock
          title={localizedStrings.transferInformation}
          user={auditResult.user}
          transferReceiver={auditResult.metadata?.transferReceiver}
        />
      )}
      {auditResult.isChangingPermitHolder() && (
        <TimelineTransferBlock
          title={localizedStrings.changePermitHolderInformation}
          user={auditResult.metadata?.changedPermitHolderFrom}
          transferReceiver={auditResult.metadata?.changedPermitHolderTo}
        />
      )}
      {(auditResult.metadata?.permitRoleName ||
        auditResult.metadata?.checklistRoleName) && (
        <TimelineTextBlock
          text={
            auditResult.metadata?.permitRoleName ||
            auditResult.metadata?.checklistRoleName
          }
          title={localizedStrings.briefedParticipant}
        />
      )}
    </>
  )
}
