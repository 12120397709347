import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { supportKeys } from '@core/react-query/features/support'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useCreateSupportUser = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['createSupportUser']
  >,
) => {
  return useMutation({
    mutationFn: (variables) =>
      supportKeys.createSupportUser(variables).queryFn(undefined),
    ...options,
  })
}
