import React from 'react'
import { Box } from '@fundamentals'
import { RenderAnswers } from '@elements/Questions/RenderAnswers'
import { Permit } from '@core/entities/permit/Permit'

type PostPermitChecksTabProps = {
  permit: Permit
}
export const PostPermitChecksTab: React.FC<PostPermitChecksTabProps> = ({
  permit,
}) => {
  return (
    <Box flex={1}>
      <RenderAnswers
        questionBuilderAnswers={permit.postPermitChecksAnswers}
        organisationId={permit?.template?.organisationId}
      />
    </Box>
  )
}
