import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  permitTemplateEditorKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplateEditor'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useUpdatePermitTemplateVersionSettings = (
  options?: UseMutationOptions<
    string,
    RequestErrorType,
    RequestTypes['updatePermitTemplateVersionSettings']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...permitTemplateEditorKeys.updateSettings,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: permitTemplateEditorKeys._def,
      })
    },
  })
}
