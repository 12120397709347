import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import {
  businessUnitsKeys,
  RequestTypes,
} from '@core/react-query/features/businessUnits'

export const useDeleteProjectFromBusinessUnit = (
  options?: UseMutationOptions<
    string,
    string,
    RequestTypes['deleteProjectFromBusinessUnit']
  >,
) => {
  return useMutation({
    ...businessUnitsKeys.deleteProjectFromBusinessUnit,
    retry: 0,
    ...options,
  })
}
