import {
  createMutationKeys,
  createQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { RequestTypes } from './requestTypes'
import Cognito from '@core/cognito'

export const authKeys = createQueryKeys('login', {
  getIdentityProvider: (payload: RequestTypes['getIdentityProvider']) => {
    return {
      queryKey: [payload.email],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `identity-provider?email=${encodeURIComponent(payload.email)}`,
        )

        return data
      },
    }
  },
  sendForgotPasswordEmail: (
    payload: RequestTypes['sendForgotPasswordEmail'],
  ) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.post(`users/forgot-password`, {
          ...payload,
        })

        return data
      },
    }
  },
  resetPassword: (payload: RequestTypes['resetPassword']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.post(`users/reset-password`, {
          ...payload,
        })

        return data
      },
    }
  },
  createPassword: (payload: RequestTypes['createPassword']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.post(`users/accept-invite`, {
          ...payload,
        })

        return data
      },
    }
  },
})

export const authMutationKeys = createMutationKeys('auth', {
  cognitoLogin: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['cognitoLogin']) => {
      const { username, password } = payload
      await Cognito.login(username, password)
      // Force the cognito user to be loaded if the user is logged in
      // This is important so that the cognito user is available in the useGetProfile hook
      const session = await Cognito.getSession()
      return session?.getAccessToken()?.getJwtToken()
    },
  },
  cognitoExchangeToken: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['cognitoExchangeToken']) => {
      // The user linked error is returned when we create a new user in cognito
      // For more information please see:
      //   https://www.notion.so/siteassist/Single-Sign-On-Implementation-9756470e57534ca3bccc2b6db549edb0
      const userLinkedErrorPrefix = 'SA_USER_LINKED_'
      if (payload.errorDescription) {
        if (payload.errorDescription.includes(userLinkedErrorPrefix)) {
          // Extract the identity provider.
          // The errorDescription string looks like this
          // "Some message that explains the error SA_USER_LINKED_<identityProvider>."
          const startIndex = payload.errorDescription.indexOf(
            userLinkedErrorPrefix,
          )
          const identityProvider = payload.errorDescription
            .slice(startIndex + userLinkedErrorPrefix.length)
            .replace('.', '')
            .trim()
          return {
            result: 'USER_LINKED',
            identityProvider,
          }
        } else {
          throw payload.errorDescription
        }
      }

      // Exchange the authorisation code we get from cognito for id_token, access_token, refresh_token
      const url =
        'https://siteassist.auth.eu-west-2.amazoncognito.com/oauth2/token'
      const clientId = API.isMobile()
        ? Project.cognitoMobile.userPoolWebClientId
        : Project.cognito.userPoolWebClientId
      const body = {
        'grant_type': 'authorization_code',
        'client_id': clientId,
        'code': payload.code,
        'redirect_uri': encodeURI(payload.redirectUri),
      }

      // Exchange the code for tokens
      const formBody = []
      Object.keys(body).forEach((property) => {
        const encodedKey = encodeURIComponent(property)
        const encodedValue = encodeURIComponent(body[property])
        formBody.push(encodedKey + '=' + encodedValue)
      })
      const formBodyString = formBody.join('&')
      const options = {
        timeout: 60000,
        method: 'POST',
        body: formBodyString,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
      const exchangeResult = await fetch(url, options)
      if (exchangeResult.status !== 200) {
        throw 'Error when exchanging code for access token'
      }
      const exchangeResultJson = await exchangeResult.json()

      // We use a random id, this is not the user's actual user id but it seems to work nonetheless
      const username = new Date().toString()
      return {
        result: 'TOKEN_EXCHANGED',
        username,
        idToken: exchangeResultJson.id_token,
        accessToken: exchangeResultJson.access_token,
        refreshToken: exchangeResultJson.refresh_token,
      }
    },
  },
})
