import { localizedStrings } from '@core/strings'
export const checklistSortOptions = [
  {
    label: localizedStrings.createdOnAscending,
    value: 'createdOn,ASC',
  },
  {
    label: localizedStrings.createdOnDescending,
    value: 'createdOn,DESC',
  },
]
