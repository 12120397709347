import { RequestTypes } from '@core/react-query/features/projects/project'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Dashboard, IDashboard } from '@core/entities/dashboard'
import { projectKeys } from '@core/react-query/features/projects/project'

const dataTransformer = (dashboard: IDashboard) => Dashboard.new(dashboard)

export const useGetProjectDashboard = (
  payload: RequestTypes['getProjectDashboard'],
  options: UseQueryOptions<IDashboard, string, Dashboard> = {},
) => {
  return useQuery({
    select: dataTransformer,
    ...projectKeys.byId(payload)._ctx.projectDashboard(payload),
    ...options,
  })
}
