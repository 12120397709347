import React from 'react'
import { RegisterItemNumberProperty } from '@core/entities/register/RegisterItem'
import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { RegisterItemText } from '@modules/registers/items/RegisterItemText'
import { RegisterItemContentButtons } from '@modules/registers/propertyValue/RegisterItemPropertyValueButtons'
import { emptyValue } from '@modules/registers/propertyValue/helpers'

type RegisterItemPropertyNumberValueProps = {
  registerItemPropertyData: RegisterItemNumberProperty<string>
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertyNumberValue: React.FC<
  RegisterItemPropertyNumberValueProps
> = ({ registerItemPropertyData, variant }) => {
  const displayNumber =
    registerItemPropertyData?.numberValue === undefined
      ? emptyValue(variant)
      : `${registerItemPropertyData?.numberValue}`
  return (
    <RegisterItemContentButtons
      variant={variant}
      value={displayNumber}
      registerItemPropertyData={registerItemPropertyData}
    >
      <RegisterItemText>{displayNumber}</RegisterItemText>
    </RegisterItemContentButtons>
  )
}
