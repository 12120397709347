import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { Box } from '@fundamentals'
import { Button, Collapse } from '@mui/material'
import * as Icon from '@mui/icons-material'
import { TransitionGroup } from 'react-transition-group'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { PermitWarning } from '@core/entities/template/TemplateWarnings'
import { QuestionGroup } from './QuestionGroup'

type RenderAnswersProps = {
  questionBuilderAnswers: TemplateFormAnswers
  permitId?: number
  organisationId: number
  warnings?: Array<PermitWarning>
  onlyShowWarnings?: boolean
}

export const RenderAnswers: React.FC<RenderAnswersProps> = ({
  questionBuilderAnswers = null,
  permitId = null,
  organisationId,
  warnings = [],
  onlyShowWarnings,
}) => {
  const [show, setShow] = useState(true)

  if (!questionBuilderAnswers?.answers?.length) {
    return null
  }

  if (onlyShowWarnings && !warnings?.length) {
    return null
  }

  const label = `Answered by ${
    questionBuilderAnswers?.submittedBy?.firstName
  } ${questionBuilderAnswers?.submittedBy?.lastName} -  ${moment(
    questionBuilderAnswers?.submittedOn,
  ).format('Do MMM HH:mm')}`

  const questionIds = useMemo(
    () =>
      questionBuilderAnswers?.answers?.flatMap((questionGroup) => {
        return questionGroup?.data?.map((question) => question.id)
      }),
    [questionBuilderAnswers],
  )
  const warningsInAnswers = useMemo(() => {
    return warnings?.filter((warning) =>
      questionIds?.includes(warning.questionId),
    )
  }, [questionIds, warnings])

  if (onlyShowWarnings && !warningsInAnswers?.length) {
    return null
  }

  return (
    <Box flex={1}>
      <Button
        color='inherit'
        variant='text'
        onClick={() => setShow(!show)}
        sx={{ mb: 1, pl: 0 }}
      >
        <Icon.KeyboardArrowDown
          sx={{
            transform: `rotate(${show ? 0 : 180}deg)`,
            transition: '.3s ease',
          }}
        />
        {label}
      </Button>
      <TransitionGroup>
        {show &&
          questionBuilderAnswers?.answers?.map((questionGroup, groupIndex) => {
            return (
              <Collapse key={groupIndex}>
                <QuestionGroup
                  questionGroup={questionGroup.toPlain()}
                  groupIndex={groupIndex}
                  permitId={permitId}
                  organisationId={organisationId}
                  warnings={warnings}
                  onlyShowWarnings={onlyShowWarnings}
                  readOnly
                />
              </Collapse>
            )
          })}
      </TransitionGroup>
    </Box>
  )
}
