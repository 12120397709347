import { localizedStrings } from '@core/strings'
import React from 'react'
import { Flex } from '@fundamentals'
import { useModal } from '@common/GlobalModal'
import { CheckCircle } from '@mui/icons-material'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { ModalDialogContainer } from '@common/modal'
import { Permit } from '@core/entities/permit/Permit'

interface ComponentType {
  permit: Permit
}

export const ViewChangesDone: React.FC<ComponentType> = ({ permit }) => {
  const { hideModal } = useModal()

  const { comment, addressedBy, requestedBy, requestedAt } =
    permit.lastRequestedChange
  const { firstName: firstNameRequestedBy, lastName: lastNameRequestedBy } =
    requestedBy
  const { firstName: firstNameAddressedBy, lastName: lastNameAddressedBy } =
    addressedBy

  const { dateString, timeString } = Utils.getDate(requestedAt)

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.changesDone}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <TextField
          label={`${firstNameRequestedBy} ${lastNameRequestedBy}, Permit Approver - ${dateString} ${timeString}`}
          multiline
          maxRows={4}
          value={comment}
          InputProps={{
            readOnly: true,
          }}
          sx={{ mt: 1 }}
        />
        <Flex alignItems='center' pt={2}>
          <CheckCircle color='success' sx={{ pr: 0.5 }} />
          {`Changes done by ${firstNameAddressedBy} ${lastNameAddressedBy}`}
        </Flex>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          data-test='jsViewChangesDoneContinue'
          variant='contained'
          onClick={hideModal}
        >
          {localizedStrings.ok}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
