import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IRegisterViews } from './IRegisterViews'

export class RegisterViews extends Entity<IRegisterViews> {
  views: {
    id: number
    name: string
    description: string
    modifiedOn: string
  }[]

  public static new(payload: unknown): RegisterViews {
    const entity = plainToInstance(RegisterViews, payload)

    return entity
  }
}
