import { localizedStrings } from '@core/strings'
import { Entity } from '@core/entities/entity'
import { IAuditResult } from './IAuditResult'
import {
  AuditAction,
  AuditResultPlatform,
} from '@core/entities/audit/AuditResult/types'
import { User } from '@core/entities/user'
import { plainToInstance, Type } from 'class-transformer'
import { RegisterItemWithLinksPropertyData } from '@core/entities/register/RegisterItem'
import moment from 'moment'
import { Feature, GeoJSON, Point } from 'geojson'
import { Polygon, Units, circle } from '@turf/turf'
import { AuthGroup } from '@core/entities/Groups/AuthGroups'
import { AuditResultMetadata } from '@core/entities/audit/AuditResult/AuditResultMetadata'

export class AuditResult extends Entity<IAuditResult> {
  id: number

  action: AuditAction

  platform: AuditResultPlatform

  signatureUrl?: string

  info?: string

  createdOn?: string

  @Type(() => User)
  user?: User

  @Type(() => User)
  organisationUser?: User

  @Type(() => User)
  appliedToUser?: User

  @Type(() => User)
  projectUser?: User

  @Type(() => AuditResultMetadata)
  metadata: AuditResultMetadata

  geoJSON?: GeoJSON

  project?: {
    id: number
    name: string
  }

  organisation?: {
    id: number
    name: string
  }

  permit?: {
    id: number
  }

  checklist?: {
    id: number
    name: string
  }

  register?: {
    id: number
    name: string
  }

  registerItem?: {
    id: number
    uniqueId: string
  }

  permitTemplate?: {
    id: number
    name: string
  }

  permitTemplateVersion?: {
    id: number
    version: number
  }

  checklistTemplate?: {
    id: number
    name: string
  }

  checklistTemplateVersion?: {
    id: number
    version: number
  }

  @Type(() => AuthGroup)
  group?: AuthGroup

  public static new(payload: unknown): AuditResult {
    const entity = plainToInstance(AuditResult, payload)

    return entity
  }

  public getPlatformText = () => {
    switch (this.platform) {
      case 'WEB':
        return localizedStrings.webBrowser
      case 'IOS':
        return localizedStrings.ios
      case 'ANDROID':
        return localizedStrings.android
      // The UNKNOWN platform exists because historically we didn't gather the type of device the person was using
      case 'UNKNOWN':
      default:
        return ''
    }
  }

  public getActionLabel(): string {
    switch (this.action) {
      case 'PERMIT_APPROVED':
        return localizedStrings.approved
      case 'PERMIT_RECEIVED':
        return localizedStrings.permitReceived
      case 'PERMIT_REQUESTED':
        return localizedStrings.requested
      case 'CHECKLIST_COMPLETED':
        return localizedStrings.initiated
      case 'PERMIT_OPENED':
        return localizedStrings.opened
      case 'PERMIT_POST_PERMIT_CHECK_COMPLETE':
        return localizedStrings.postPermitChecksComplete
      case 'PERMIT_SIGN_OFF_COMPLETE':
        return localizedStrings.finalSignOff
      case 'WORK_COMPLETE':
        return localizedStrings.permitClosed
      case 'PERMIT_TRANSFER_CANCELLED':
        return localizedStrings.transferCanceled
      case 'PERMIT_SIGNED':
        return localizedStrings.permitSigned
      case 'PERMIT_CHANGES_ADDRESSED':
        return localizedStrings.changesAddressed
      case 'PERMIT_PERIODIC_CHECKS_SUBMITTED':
        return localizedStrings.periodicCheckSubmitted
      case 'PERMIT_CHECKIN':
        return localizedStrings.checkin
      case 'PERMIT_DRAFT_CREATED':
      case 'CHECKLIST_DRAFT_CREATED':
        return localizedStrings.draftCreated
      case 'PARTICIPANT_REMOVED':
        return localizedStrings.participantRemoved
      case 'PERMIT_CHECKLIST_DONE':
        return localizedStrings.checklistCompleted
      case 'PARTICIPANT_BRIEFED':
        return localizedStrings.participantBriefed
      case 'PERMIT_EXTENSION_APPROVED':
        return localizedStrings.extensionApproved
      case 'PERMIT_TRANSFER_ACCEPTED':
        return localizedStrings.transferAccepted
      case 'PERMIT_TRANSFER_APPROVED':
        return localizedStrings.permitTransferApproved
      case 'PERMIT_TRANSFER_DECLINED':
        return localizedStrings.permitTransferDeclined
      case 'SENT_TO_PERMIT_HOLDER':
        return localizedStrings.sentToPermitHolder
      case 'PERMIT_EXTENSION_REQUESTED':
        return localizedStrings.extensionsRequested
      case 'PERMIT_CHANGES_REQUESTED':
        return localizedStrings.changesRequested
      case 'PERMIT_TRANSFER_INITIATED':
        return localizedStrings.transferInitiated
      case 'PERMIT_HOLDER_CHANGED':
        return localizedStrings.permitHolderChanged
      case 'PERMIT_REJECTED':
        return localizedStrings.permitRejected
      case 'PERMIT_EXTENSION_REJECTED':
        return localizedStrings.extensionRejected
      case 'PERMIT_TRANSFER_REJECTED':
        return localizedStrings.transferRejected
      case 'PERMIT_CANCELLED':
        return localizedStrings.cancelled
      case 'PERMIT_SUSPENDED':
        return localizedStrings.suspended
      case 'PERMIT_SUSPENDED_RESUME':
        return localizedStrings.resumed
      case 'PERMIT_SUSPENDED_RESUME_REQUESTED':
        return localizedStrings.resumeRequested
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED':
        return localizedStrings.resumeRequestRejected
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED':
        return localizedStrings.resumeRequestApproved
      case 'PERMIT_SHORTENED':
        return localizedStrings.shortened
      case 'PERMIT_DISCARDED':
        return localizedStrings.discarded
      case 'PERMIT_EXPIRED':
        return localizedStrings.expired
      case 'TASK_CANCELLED':
        return localizedStrings.cancelled
      case 'PROJECT_EXTERNAL_CODE_CHANGED':
        return localizedStrings.changedProjectExternalCode
      case 'PROJECT_STATUS_CHANGED':
        return localizedStrings.changedProjectStatus
      case 'PROJECT_MEMBER_ADDED':
        return localizedStrings.addedUserToProject
      case 'PROJECT_MEMBER_REMOVED':
        return localizedStrings.removedUserFromProject
      case 'PROJECT_MEMBER_ACTIVATED':
        return localizedStrings.activatedProjectMember
      case 'PROJECT_MEMBER_DISABLED':
        return localizedStrings.disabledProjectMember
      case 'PROJECT_MEMBER_ROLE_UPDATED':
        return localizedStrings.updatedProjectPermissions
      case 'USER_ADDED_TO_ORGANISATION':
        return localizedStrings.addedUserToOrganisation
      case 'USER_PROFILE_CHANGED':
        return localizedStrings.changedProfileInformation
      case 'USER_ROLE_UPDATED_IN_ORGANISATION':
        return localizedStrings.updatedOrganisationPermissions
      case 'ORGANISATION_MEMBER_ACTIVATED':
        return localizedStrings.activatedOrganisationMember
      case 'ORGANISATION_MEMBER_DISABLED':
        return localizedStrings.disabledOrganisationMember
      case 'CHECKLIST_TEMPLATE_VERSION_ACTIVATED':
        return localizedStrings.activatedChecklistVersion
      case 'CHECKLIST_TEMPLATE_VERSION_CREATED':
        return localizedStrings.createdChecklistVersion
      case 'CHECKLIST_TEMPLATE_VERSION_UPDATED':
        return localizedStrings.updatedChecklistVersion
      case 'CHECKLIST_TEMPLATE_VERSION_DISCARDED':
        return localizedStrings.discardedChecklistVersion
      case 'CHECKLIST_TEMPLATE_VERSION_MOVED_TO_DRAFT':
        return localizedStrings.movedChecklistVersionToDraft
      case 'CHECKLIST_TEMPLATE_CREATED':
        return localizedStrings.createdChecklistTemplate
      case 'CHECKLIST_TEMPLATE_ARCHIVED':
        return localizedStrings.archivedChecklistTemplate
      case 'CHECKLIST_TEMPLATE_UNARCHIVED':
        return localizedStrings.unarchivedChecklistTemplate
      case 'CHECKLIST_TEMPLATE_UPDATED':
        return localizedStrings.updatedChecklistTemplate
      case 'CHECKLIST_TEMPLATE_MOVED':
        return localizedStrings.movedChecklistTemplate
      case 'TEMPLATE_VERSION_CREATED':
        return localizedStrings.createdPermitTemplateVersion
      case 'TEMPLATE_VERSION_ACTIVATED':
        return localizedStrings.activatedPermitTemplateVersion
      case 'TEMPLATE_VERSION_UPDATED':
        return localizedStrings.updatedPermitTemplateVersion
      case 'TEMPLATE_VERSION_DELETED':
        return localizedStrings.deletedPermitTemplateVersion
      case 'TEMPLATE_VERSION_MOVED_TO_DRAFT':
        return localizedStrings.movedPermitTemplateVersionToDraft
      case 'TEMPLATE_CREATED':
        return localizedStrings.createdPermitTemplate
      case 'TEMPLATE_UPDATED':
        return localizedStrings.updatedPermitTemplate
      case 'TEMPLATE_PUBLISHED':
        return localizedStrings.publishedPermitTemplate
      case 'TEMPLATE_MOVED':
        return localizedStrings.movedPermitTemplate
      case 'TEMPLATE_UNARCHIVED':
        return localizedStrings.unarchivedPermitTemplate
      case 'TEMPLATE_ARCHIVED':
        return localizedStrings.archivedPermitTemplate
      case 'TEMPLATE_UNPUBLISHED':
        return localizedStrings.templateUnpublished
      case 'USER_ADDED_TO_ORGANISATION_AUTH_GROUP':
      case 'USER_ADDED_TO_PROJECT_AUTH_GROUP':
        return localizedStrings.addedUserToAuthGroup
      case 'USER_REMOVED_FROM_ORGANISATION_AUTH_GROUP':
      case 'USER_REMOVED_FROM_PROJECT_AUTH_GROUP':
        return localizedStrings.removedUserFromAuthGroup
      case 'PROJECT_PERMISSION_ASSIGNMENTS_UPDATED':
        return localizedStrings.updatedAuthGroupPermissions
      case 'PROJECT_TEMPLATE_ADDED':
        return localizedStrings.addedPermitTemplate
      case 'PROJECT_TEMPLATE_REMOVED':
        return localizedStrings.removedPermitTemplate
      case 'PROJECT_TEMPLATE_VERSION_UPDATED':
        return localizedStrings.updatedProjectPermitSettings
      case 'PROJECT_APPROVAL_ASSIGNMENTS_UPDATED':
        return localizedStrings.updatedPermitApprovalSettings
      case 'PROJECT_CHECKLIST_TEMPLATE_VERSION_UPDATED':
        return localizedStrings.updatedProjectChecklistVersionSettings
      case 'PROJECT_CHECKLIST_TEMPLATE_REMOVED':
        return localizedStrings.removedChecklistTemplateFromProject
      case 'PROJECT_CHECKLIST_TEMPLATE_ADDED':
        return localizedStrings.addedChecklistTemplateToProject
      case 'CREATED':
        return localizedStrings.itemCreated
      case 'UPDATED':
        return localizedStrings.itemUpdated
      case 'COMMENT_CREATED':
        return localizedStrings.newComment
      case 'IMAGE_WARNINGS_CREATED':
        return localizedStrings.warningDetected
      case 'AUTH_GROUP_CREATED':
        return localizedStrings.authGroupCreated
      case 'CHECKLIST_TEMPLATE_GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED':
        return localizedStrings.authGroupChecklistPermissionsUpdated
      case 'PERMIT_TEMPLATE_GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED':
        return localizedStrings.authGroupPermitPermissionsUpdated
      case 'REGISTER_GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED':
        return localizedStrings.authGroupRegisterPermissionsUpdated
      case 'AUTH_GROUP_NAME_UPDATED':
        return localizedStrings.authGroupNameUpdated
      case 'AUTH_GROUP_DELETED':
        return localizedStrings.authGroupDeleted
      case 'FOLDER_MOVED':
        return localizedStrings.folderMoved
      case 'FOLDER_RENAMED':
        return localizedStrings.folderRenamed
      case 'FOLDER_CREATED':
        return localizedStrings.folderCreated
      case 'SITE_BOX_DOCUMENT_VERSION_STATUS_CHANGED':
        return localizedStrings.siteBoxDocumentVersionStatusChanged
      case 'SITE_BOX_DOCUMENT_VERSION_CREATED':
        return localizedStrings.siteBoxDocumentVersionCreated
      case 'SITE_BOX_DOCUMENT_MOVED':
        return localizedStrings.siteBoxDocumentMoved
      case 'SITE_BOX_DOCUMENT_STATUS_CHANGED':
        return localizedStrings.siteBoxDocumentStatusChanged
      case 'SITE_BOX_DOCUMENT_UPDATED':
        return localizedStrings.siteBoxDocumentUpdated
      case 'SITE_BOX_DOCUMENT_CREATED':
        return localizedStrings.siteBoxDocumentCreated
      case 'ORGANISATION_PROJECT_ADDED':
        return localizedStrings.projectCreated
      case 'GROUP_PERMISSIONS_ASSIGNMENTS_UPDATED':
        return localizedStrings.updatedOrgAuthGroupPermissions
      case 'INSPECTION_EXPIRED':
        return localizedStrings.inspectionExpired
      case 'INSPECTION_SUBMITTED':
        return localizedStrings.inspectionDone
      default:
        return this.action
    }
  }

  public getActionColor(): string {
    switch (this.action) {
      case 'CHECKLIST_COMPLETED':
      case 'PERMIT_POST_PERMIT_CHECK_COMPLETE':
      case 'PERMIT_SIGN_OFF_COMPLETE':
      case 'WORK_COMPLETE':
      case 'PERMIT_SIGNED':
      case 'PERMIT_PERIODIC_CHECKS_SUBMITTED':
      case 'PERMIT_CHECKIN':
      case 'PERMIT_DRAFT_CREATED':
      case 'CHECKLIST_DRAFT_CREATED':
      case 'PERMIT_CHECKLIST_DONE':
      case 'PERMIT_SUSPENDED_RESUME_REQUESTED':
      case 'INSPECTION_SUBMITTED':
        return 'primary'

      case 'PERMIT_OPENED':
      case 'PARTICIPANT_BRIEFED':
      case 'PERMIT_EXTENSION_APPROVED':
      case 'PERMIT_TRANSFER_ACCEPTED':
      case 'PERMIT_TRANSFER_APPROVED':
      case 'PERMIT_CHANGES_ADDRESSED':
      case 'PERMIT_APPROVED':
      case 'PERMIT_RECEIVED':
      case 'PERMIT_SUSPENDED_RESUME':
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED':
      case 'CREATED':
      case 'UPDATED':
      case 'TEMPLATE_PUBLISHED':
      case 'PROJECT_TEMPLATE_ADDED':
      case 'TEMPLATE_VERSION_CREATED':
      case 'TEMPLATE_VERSION_ACTIVATED':
      case 'CHECKLIST_TEMPLATE_CREATED':
      case 'CHECKLIST_TEMPLATE_VERSION_CREATED':
      case 'CHECKLIST_TEMPLATE_VERSION_ACTIVATED':
      case 'FOLDER_CREATED':
      case 'SITE_BOX_DOCUMENT_VERSION_CREATED':
      case 'SITE_BOX_DOCUMENT_CREATED':
      case 'ORGANISATION_PROJECT_ADDED':
        return 'success'

      case 'PERMIT_REQUESTED':
      case 'SENT_TO_PERMIT_HOLDER':
      case 'PERMIT_EXTENSION_REQUESTED':
      case 'PERMIT_CHANGES_REQUESTED':
      case 'PERMIT_TRANSFER_INITIATED':
      case 'PERMIT_HOLDER_CHANGED':
      case 'IMAGE_WARNINGS_CREATED':
      case 'TEMPLATE_ARCHIVED':
      case 'TEMPLATE_UNPUBLISHED':
      case 'TEMPLATE_VERSION_MOVED_TO_DRAFT':
      case 'CHECKLIST_TEMPLATE_ARCHIVED':
      case 'CHECKLIST_TEMPLATE_VERSION_MOVED_TO_DRAFT':
        return 'warning'

      case 'PERMIT_REJECTED':
      case 'PERMIT_EXTENSION_REJECTED':
      case 'PERMIT_TRANSFER_REJECTED':
      case 'PERMIT_TRANSFER_DECLINED':
      case 'PERMIT_TRANSFER_CANCELLED':
      case 'PARTICIPANT_REMOVED':
      case 'PERMIT_CANCELLED':
      case 'PERMIT_SUSPENDED':
      case 'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED':
      case 'PERMIT_SHORTENED':
      case 'PERMIT_DISCARDED':
      case 'PERMIT_EXPIRED':
      case 'TASK_CANCELLED':
      case 'USER_REMOVED_FROM_PROJECT_AUTH_GROUP':
      case 'AUTH_GROUP_DELETED':
      case 'PROJECT_TEMPLATE_REMOVED':
      case 'TEMPLATE_VERSION_DELETED':
      case 'CHECKLIST_TEMPLATE_VERSION_DISCARDED':
      case 'PROJECT_CHECKLIST_TEMPLATE_REMOVED':
      case 'INSPECTION_EXPIRED':
        return 'error'

      case 'COMMENT_CREATED':
        return 'secondary'

      default:
        return 'primary'
    }
  }

  public hasAnyAuditActions(actions: Array<AuditAction>): boolean {
    return actions.some((action) => this.action === action)
  }

  getRegisterItemPropertyValues(
    registerItemProperty: RegisterItemWithLinksPropertyData,
  ): string {
    switch (registerItemProperty.type) {
      case 'STRING':
        return registerItemProperty?.textValue ?? ''
      case 'SELECT':
        return registerItemProperty?.selectedOption?.label ?? ''
      case 'LINK':
        return registerItemProperty?.parentRegisterItem?.uniqueId ?? ''
      case 'DATE':
        const dateRange = registerItemProperty?.dateValue
          ? moment.utc(registerItemProperty?.dateValue).format('D MMM YYYY')
          : ''
        const startDate = registerItemProperty?.startTime
          ? moment(registerItemProperty?.startTime).format('do MMM yyyy')
          : ''
        const endDate = registerItemProperty?.endTime
          ? moment(registerItemProperty?.endTime).format('do MMM yyyy')
          : ''
        return dateRange || startDate || endDate
      case 'NUMBER':
        return String(registerItemProperty?.numberValue ?? '')
      case 'USER':
        const user = registerItemProperty?.selectedUser
        return user ? `${user.firstName} ${user.lastName}` : ''
      default:
        return ''
    }
  }

  public getRegisterItemPropertyColor(
    registerItemProperty: RegisterItemWithLinksPropertyData,
  ): string {
    if (registerItemProperty.type === 'SELECT') {
      return registerItemProperty.selectedOption?.colour
    } else {
      return null
    }
  }

  public getCreatedRegisterItemProperties(): Array<{
    name: string
    value: string
    valueColor?: string
    preValue?: string
    preValueColor?: string
  }> {
    switch (this.action) {
      case 'CREATED':
        return this.metadata.delta.itemValues
          .map(
            (item: {
              postValue: RegisterItemWithLinksPropertyData
              preValue?: RegisterItemWithLinksPropertyData
            }) => ({
              name: item.postValue.name,
              value: this.getRegisterItemPropertyValues(item.postValue),
              valueColor: this.getRegisterItemPropertyColor(item.postValue),
            }),
          )
          .filter((item) => !!item.value)

      case 'UPDATED':
      case 'FORMULAS_RECALCULATED':
        return this.metadata.delta.itemValues.map(
          (item: {
            postValue: RegisterItemWithLinksPropertyData
            preValue?: RegisterItemWithLinksPropertyData
          }) => ({
            name: item.postValue.name,
            value: this.getRegisterItemPropertyValues(item.postValue),
            valueColor: this.getRegisterItemPropertyColor(item.postValue),
            preValue: this.getRegisterItemPropertyValues(item.preValue),
            preValueColor: this.getRegisterItemPropertyColor(item.preValue),
          }),
        )
      default:
        return null
    }
  }

  public getCoordinates(): Feature<Point> {
    if (!(this.geoJSON && this.geoJSON.type === 'FeatureCollection')) return
    const { geometry, ...feature } = this.geoJSON.features[0]
    if (geometry.type !== 'Point') return
    return { geometry, ...feature }
  }

  public getAuditPointAccuracyRadius(): Feature<Polygon> {
    if (!(this.geoJSON && this.geoJSON.type === 'FeatureCollection')) return
    const w3wFeaturePoint = this.geoJSON.features.find((feature) => {
      return feature.properties.inputType === 'WHAT_3_WORDS'
    }) as Feature<Point>

    const turfCircle =
      w3wFeaturePoint?.properties?.locationAccuracyInMeters &&
      circle(
        w3wFeaturePoint,
        w3wFeaturePoint?.properties?.locationAccuracyInMeters,
        {
          steps: 50,
          units: 'meters' as Units,
        },
      )

    return turfCircle
  }

  public getPermitCoordinates(): Feature<Point> {
    const { permitGeoJson } = this.metadata
    if (!(permitGeoJson && permitGeoJson.type === 'FeatureCollection')) return
    const { geometry, ...feature } = permitGeoJson.features[0]
    if (geometry.type !== 'Point') return
    return { geometry, ...feature }
  }

  public getInfoTitle(): string {
    switch (this.action) {
      case 'PERMIT_DISCARDED':
        return localizedStrings.automatedInformation
      case 'PERMIT_CHECKLIST_DONE':
        return localizedStrings.checklistInformation
      default:
        return localizedStrings.info
    }
  }

  public isUpdatingGroupPermissions(): boolean {
    return (
      !!this.metadata?.permissionsUpdatedData?.oldPermissions ||
      !!this.metadata?.permissionsUpdatedData?.newPermissions
    )
  }

  public hasWarnings(): boolean {
    return !!this.metadata?.warnings?.length
  }

  public isExtendingPermit(): boolean {
    return !!(
      this.metadata?.permitExtensionEndsOn &&
      this.metadata?.permitPreviousEndsOn
    )
  }

  public isShorteningPermit(): boolean {
    return !!(
      this.metadata?.permitShortenedEndsOn &&
      this.metadata?.permitPreviousEndsOn
    )
  }

  public isChangingPermitHolder(): boolean {
    return !!(
      this.metadata?.changedPermitHolderFrom &&
      this.metadata?.changedPermitHolderTo
    )
  }

  public isTransferring(): boolean {
    return !!(this.metadata?.transferReceiver && this.user)
  }

  public hasComment(): boolean {
    return !!this.metadata?.commentAnsweredQuestionDataResult
  }

  public hasPermitStatusChange(): boolean {
    return !!(
      this.metadata.permitStatusBefore && this.metadata.permitStatusAfter
    )
  }

  public hasProjectStatusChange(): boolean {
    return !!(
      this.metadata.projectStatusBefore && this.metadata.projectStatusAfter
    )
  }

  public hasProofs(): boolean {
    return this.metadata?.mediaUrlsWithType?.length > 0
  }

  public hasTemplateInformation(): boolean {
    return !!(
      this.permitTemplate ||
      this.permitTemplateVersion ||
      this.checklistTemplate ||
      this.checklistTemplateVersion
    )
  }

  public isUpdatingProjectMemberRole(): boolean {
    return !!(
      this.metadata.preProjectMemberData && this.metadata.postProjectMemberData
    )
  }

  public isUpdatingOrganisationMemberRole(): boolean {
    return !!(this.metadata.preOrgMemberData && this.metadata.postOrgMemberData)
  }

  public isUpdatingTemplatePermissionGroups(): boolean {
    return !!this.metadata.permissionAssignmentReferenceObjectId
  }

  public isUpdatingRegisterItem(): boolean {
    return !!this.getCreatedRegisterItemProperties()?.length
  }

  public hasInspection(): boolean {
    return !!this.metadata.inspectionChecklist
  }
}
