import { Entity } from '@core/entities/entity'
import { IChecklistListItem } from '@core/entities/checklist/ChecklistListItem/IChecklistListItem'
import { plainToInstance, Type } from 'class-transformer'
import { ListChecklist } from '@core/entities/checklist/ListChecklist'
import { ChecklistListItemType } from '@core/entities/checklist/ChecklistListItem/types'
import { PeriodicCheck } from '@core/entities/PeriodicCheck/PeriodicCheck'

export class ChecklistListItem extends Entity<IChecklistListItem> {
  type: ChecklistListItemType

  @Type(() => ListChecklist)
  checklist?: ListChecklist

  @Type(() => PeriodicCheck)
  periodicCheck?: PeriodicCheck
  createdOn: string

  public static new(payload: unknown): ChecklistListItem {
    const entity = plainToInstance(ChecklistListItem, payload)

    return entity
  }
}
