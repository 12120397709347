import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '@core/react-query/features/templates/permitTemplateEditor/requestTypes'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { IPermitTemplateVersion } from '@core/entities/permit-template-editor'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { uploadTemplateAnswerFiles } from '@core/react-query/features/permits/permit/helpers'

const permitTemplateEditorQueryKeys = createQueryKeys(
  'permit-template-editor',
  {
    periodicChecklists: (payload: RequestTypes['getPeriodicChecklists']) => ({
      queryKey: [payload.permitTemplateId, payload.permitTemplateVersionId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `permits/templates/${payload.permitTemplateId}/versions/${payload.permitTemplateVersionId}/periodic-checklists`,
        )
        return data
      },
    }),
    getVersion: (payload: RequestTypes['getPermitTemplateVersion']) => {
      return {
        queryKey: [payload.versionId],
        queryFn: async () => {
          const { data } = await APIAxiosInstance.get(
            `permits/admin/templates/${payload.templateId}/versions/${payload.versionId}`,
          )
          return data as IPermitTemplateVersion
        },
      }
    },
    getPermitTemplateVersions: (
      payload: RequestTypes['getPermitTemplateVersions'],
    ) => {
      return {
        queryKey: [payload.templateId, payload.page, payload.size],
        queryFn: async () => {
          const queryString = createQueryKeyString(payload)
          const { data } = await APIAxiosInstance.get(
            `permits/admin/templates/${payload.templateId}/versions${queryString}`,
          )

          return data
        },
      }
    },
    getWarningTypes: () => {
      return {
        queryKey: ['warningTypes'],
        queryFn: async () => {
          const { data } = await APIAxiosInstance.get(
            `permits/admin/templates/warnings/types`,
          )

          return data
        },
      }
    },
  },
)

const permitTemplateEditorMutationsKeys = createMutationKeys(
  'permit-template-editor',
  {
    addPeriodicChecklist: {
      mutationKey: null,
      mutationFn: async ({
        permitTemplateId,
        permitTemplateVersionId,
        ...rest
      }: RequestTypes['addPeriodicChecklist']) => {
        const { data } = await APIAxiosInstance.post(
          `permits/templates/${permitTemplateId}/versions/${permitTemplateVersionId}/periodic-checklists`,
          {
            ...rest,
          },
        )
        return data
      },
    },
    removePeriodicChecklist: {
      mutationKey: null,
      mutationFn: async ({
        permitTemplateId,
        permitTemplateVersionId,
        checklistTemplateId,
      }: RequestTypes['removePeriodicChecklist']) => {
        const { data } = await APIAxiosInstance.delete(
          `permits/templates/${permitTemplateId}/versions/${permitTemplateVersionId}/periodic-checklists/${checklistTemplateId}`,
        )
        return data
      },
    },
    activateTemplateVersion: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['activatePermitTemplateVersion'],
      ) => {
        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${payload.templateId}/current-version`,
          payload,
        )
        return data
      },
    },
    discartTemplateVersion: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['activatePermitTemplateVersion'],
      ) => {
        const { data } = await APIAxiosInstance.delete(
          `permits/admin/templates/${payload.templateId}/versions/${payload.versionId}`,
        )
        return data
      },
    },
    updateSettings: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['updatePermitTemplateVersionSettings'],
      ) => {
        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${payload.templateId}/versions/${payload.versionId}/settings`,
          payload,
        )
        return data
      },
    },
    updateAuthPersonQuestions: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['updatePermitTemplateVersionAuthPersonQuestions'],
      ) => {
        const uploadedQuestions = await uploadTemplateAnswerFiles({
          answers: payload.questionsWrapper.questions,
          templateId: payload.templateId,
        })
        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${payload.templateId}/versions/${payload.versionId}/auth-person-questions`,
          {
            ...payload,
            questionsWrapper: {
              ...payload.questionsWrapper,
              questions: uploadedQuestions,
            },
          },
        )
        return data
      },
    },
    updatePermitHolderQuestions: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['updatePermitTemplateVersionPermitHolderQuestions'],
      ) => {
        const uploadedQuestions = await uploadTemplateAnswerFiles({
          answers: payload.questionsWrapper.questions,
          templateId: payload.templateId,
        })
        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${payload.templateId}/versions/${payload.versionId}/permit-holder-questions`,
          {
            ...payload,
            questionsWrapper: {
              ...payload.questionsWrapper,
              questions: uploadedQuestions,
            },
          },
        )
        return data
      },
    },
    updateApprovals: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['updatePermitTemplateApprovals'],
      ) => {
        const approvalsUploaded = payload.approvals.map(async (approval) => {
          return {
            ...approval,
            approvalQuestions: {
              ...approval.approvalQuestions,
              questions: await uploadTemplateAnswerFiles({
                answers: approval.approvalQuestions.questions,
                templateId: payload.templateId,
              }),
            },
          }
        })

        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${payload.templateId}/versions/${payload.versionId}/approvals`,
          { ...payload, approvals: await Promise.all(approvalsUploaded) },
        )
        return data
      },
    },
    updatePostPermitChecks: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['updatePermitTemplateVersionPostPermitChecks'],
      ) => {
        const uploadedQuestions = await uploadTemplateAnswerFiles({
          answers: payload.questionsWrapper.questions,
          templateId: payload.templateId,
        })
        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${payload.templateId}/versions/${payload.versionId}/post-permit-questions`,
          {
            ...payload,
            questionsWrapper: {
              ...payload.questionsWrapper,
              questions: uploadedQuestions,
            },
          },
        )
        return data
      },
    },
    updateFinalSignOffs: {
      mutationKey: null,
      mutationFn: async (payload: RequestTypes['updateFinalSignOffs']) => {
        const finalSignOffUploaded = payload.finalSignOffs.map(
          async (finalSignOff) => {
            return {
              ...finalSignOff,
              finalSignOffQuestions: {
                ...finalSignOff.finalSignOffQuestions,
                questions: await uploadTemplateAnswerFiles({
                  answers: finalSignOff.finalSignOffQuestions.questions,
                  templateId: payload.templateId,
                }),
              },
            }
          },
        )

        const { data } = await APIAxiosInstance.put(
          `${Project.api}permits/admin/templates/${payload.templateId}/versions/${payload.templateVersionId}/final-signoffs`,
          {
            ...payload,
            finalSignOffs: await Promise.all(finalSignOffUploaded),
          },
        )

        return data
      },
    },
    createPermitTemplateVersion: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['createPermitTemplateVersion'],
      ) => {
        const { data } = await APIAxiosInstance.post(
          `permits/admin/templates/${payload.templateId}/versions`,
          payload,
        )
        return data
      },
    },
    updateGlobalPermitTemplateVersionWarning: {
      mutationKey: null,
      mutationFn: async ({
        templateId,
        versionId,
        ...warning
      }: RequestTypes['updateGlobalPermitTemplateVersionWarning']) => {
        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${templateId}/versions/${versionId}/global-warnings`,
          warning,
        )
        return data
      },
    },
    updatePermitTemplateVersionWarning: {
      mutationKey: null,
      mutationFn: async ({
        templateId,
        versionId,
        ...warning
      }: RequestTypes['updatePermitTemplateVersionWarning']) => {
        const { data } = await APIAxiosInstance.put(
          `permits/admin/templates/${templateId}/versions/${versionId}/question-warnings`,
          warning,
        )
        return data
      },
    },
    deletePermitTemplateVersionWarning: {
      mutationKey: null,
      mutationFn: async ({
        templateId,
        versionId,
        warningId,
        stage,
      }: RequestTypes['deletePermitTemplateVersionWarning']) => {
        const { data } = await APIAxiosInstance.delete(
          `permits/admin/templates/${templateId}/versions/${versionId}/question-warnings/${warningId}?stage=${stage}`,
        )
        return data
      },
    },
  },
)

export const permitTemplateEditorKeys = mergeQueryKeys(
  permitTemplateEditorQueryKeys,
  permitTemplateEditorMutationsKeys,
)['permit-template-editor']
