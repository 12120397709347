import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermit } from '@core/entities/permit/Permit'
import { permitsKeys } from '@core/react-query/features/permits'
import { useMutateQueryData } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useRejectExtensionRequest = () => {
  const queryClient = useQueryClient()
  const { updateInfiniteQueryItem } = useMutateQueryData()

  return useMutation<
    IPermit,
    RequestErrorType,
    RequestTypes['rejectExtensionRequest']
  >({
    ...permitKeys.rejectExtensionRequest,
    onSuccess: (permit) => {
      queryClient.setQueryData(
        permitKeys.byId({ permitId: permit.id }).queryKey,
        permit,
      )
      updateInfiniteQueryItem(
        permitsKeys
          .allPermits({
            organisationId: permit.organisationId,
            projectIds: [permit.project.id],
          })
          .queryKey.slice(0, -3),
        permit,
      )
    },
  })
}
