import React, { useMemo } from 'react'
import { Chip } from '@common/material'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import { localizedStrings } from '@core/strings'
import { useComments } from '@core/react-query/features/comments/hooks/CommentsContext'

export const TotalCommentsChip = () => {
  const { commentsQuery } = useComments()
  const commentsCount = useMemo(
    () => commentsQuery?.data?.content?.length,
    [commentsQuery],
  )
  return (
    commentsCount > 0 && (
      <Chip
        color='primary'
        variant='outlined'
        icon={<CommentOutlinedIcon fontSize='small' sx={{ pl: 0.5 }} />}
        label={localizedStrings.commentsCount(commentsCount)}
      />
    )
  )
}
