import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  registerKeys,
} from '@core/react-query/features/registers/registers/register'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useUpdateRegisterItemPermissionsSettings = (
  options?: UseMutationOptions<
    any,
    RequestErrorType,
    RequestTypes['updateRegisterItemPermissionsSettings']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...registerKeys.updateRegisterItemPermissionsSettings,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        registerKeys.details({ registerId: data.registerId }),
      )
      queryClient.invalidateQueries(registerKeys.registerSettings._def)
    },
  })
}
2
