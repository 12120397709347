import React, { useMemo } from 'react'
import { BoxProps, Flex, Text } from '@fundamentals'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

type AlertBoxProps = BoxProps & {
  type: 'info' | 'warning' | 'error'
  icon?: React.ReactNode
}

const AlertBox: React.FC<AlertBoxProps> = ({
  icon,
  type,
  children,
  sx,
  ...props
}) => {
  const color = useMemo(() => {
    switch (type) {
      case 'info':
        return 'info.main'
      case 'warning':
        return 'warning.main'
      case 'error':
        return 'error.main'
      default:
        return 'primary.main'
    }
  }, [type])
  return (
    <Flex
      sx={{
        border: '1px solid',
        borderRadius: 1,
        borderColor: color,
        p: 2,
        ...sx,
      }}
      {...props}
    >
      {icon || <InfoOutlinedIcon sx={{ color }} />}
      <Text color={color} pl={1}>
        {children}
      </Text>
    </Flex>
  )
}

export { AlertBox }
