import React from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { localizedStrings } from '@core/strings'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { TimelineBlockBase } from '@common/Timeline'
import EastIcon from '@mui/icons-material/East'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineRegisterPropertiesBlocks: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  return (
    <TimelineBlockBase
      title={
        auditResult.metadata.preValues.itemValues.length
          ? localizedStrings.updatedProperties
          : localizedStrings.addedProperties
      }
    >
      {!auditResult.metadata.preValues.itemValues.length &&
        auditResult.registerItem && (
          <Flex alignItems='center' justifyContent='flex-start'>
            <Text sx={{ mr: 1, fontWeight: 500 }}>
              {localizedStrings.uniqueId}:
            </Text>
            <Text>{auditResult.registerItem.uniqueId}</Text>
          </Flex>
        )}
      {auditResult.getCreatedRegisterItemProperties().map((property, index) => {
        return (
          <Flex key={index} alignItems='center' justifyContent='flex-start'>
            <Text sx={{ mr: 1, fontWeight: 500 }}>{property.name}:</Text>
            {property.preValue !== undefined && (
              <>
                {property.preValueColor && (
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: property.preValueColor,
                      mr: 1,
                    }}
                  />
                )}
                <Text>{property.preValue}</Text>
                <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
              </>
            )}
            {property.valueColor && (
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: property.valueColor,
                  mr: 1,
                }}
              />
            )}
            <Text>{property.value}</Text>
          </Flex>
        )
      })}
    </TimelineBlockBase>
  )
}
