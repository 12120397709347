import { useQuery } from '@tanstack/react-query'

import { RequestTypes } from '../requestTypes'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import { checklistsKeys } from '../checklistsKeys'
import { ParticipantListItem } from '@core/entities/participant/ParticipantListItem'

const participantsTransform = (data) => data.map(ParticipantListItem.new)

export const useGetChecklistRecentParticipants = (
  payload: RequestTypes['getChecklistParticipants'],
  options?: UseQueryOptions<ParticipantListItem[], string>,
) => {
  return useQuery({
    select: participantsTransform,
    ...checklistsKeys.getRecentParticipants(payload),
    ...options,
  })
}
