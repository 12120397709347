import React from 'react'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { localizedStrings } from '@core/strings'
import { Flex, Text } from '@fundamentals'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Link } from '@mui/material'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { DuplicatedImagePermitModal } from '@modules/permitDetails/modals/DuplicatedImagePermitModal'
import { ObjectDetectionWarningModal } from '@modules/permitDetails/modals/ObjectDetectionWarningModal'
import { useModal } from '@common/GlobalModal'
import { TimelineBlockBase } from '@common/Timeline'
import { usePermitById } from '@core/react-query/features/permits/permit'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelinePermitWarningsBlock: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  const { showModal } = useModal()
  const { data: permit } = usePermitById(
    { permitId: auditResult.permit?.id },
    {
      refetchOnMount: false,
      enabled: !!auditResult.permit?.id,
    },
  )
  return (
    <TimelineBlockBase title={localizedStrings.warningInformation}>
      {auditResult.metadata.warnings.map((warning) => {
        const questionInfo = permit?.template.currentVersion.getQuestionInfo(
          warning.questionId,
        )
        return (
          <Flex
            key={warning.id}
            alignItems='center'
            justifyContent='flex-start'
            sx={{ mt: 0.5 }}
          >
            <WarningAmberOutlinedIcon color='warning' fontSize='medium' />
            <Text variant='body2' sx={{ mx: 1, fontWeight: 500 }}>
              {warning.getTitle()}
            </Text>
            {questionInfo && (
              <Text variant='body2' sx={{ fontWeight: 500 }}>
                {` - ${localizedStrings.question} ${
                  questionInfo.groupIndex + 1
                }-${questionInfo.questionIndex + 1}`}
              </Text>
            )}
            <Link
              sx={{ ml: !questionInfo ? 4 : 1 }}
              component='button'
              variant='body2'
              onClick={() => {
                if (warning.type === 'CLONED_PERMIT') {
                  showModal(
                    <PermitDetailsModal
                      organisationId={warning.details.organisationId}
                      permitId={warning.details.id}
                    />,
                  )
                }

                if (warning.type === 'IMAGE_DUPLICATE') {
                  showModal(
                    <DuplicatedImagePermitModal
                      organisationId={warning.details.organisationId}
                      imageDuplicates={warning.details?.duplicates || []}
                      timelineDownloadUrls={warning?.downloadUrls || []}
                    />,
                  )
                }
                if (warning.type === 'IMAGE_OBJECT_DETECTION') {
                  showModal(<ObjectDetectionWarningModal warning={warning} />)
                }
              }}
            >
              {localizedStrings.viewDetails}
            </Link>
          </Flex>
        )
      })}
    </TimelineBlockBase>
  )
}
