import { createQueryKeys } from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'
import { attachAuditResultMediaTypes } from '@core/entities/audit/AuditResult'

export const supportKeys = createQueryKeys('support', {
  disableUser: (payload: RequestTypes['disableUser']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.post(
          `users/${payload.userId}/disable`,
          {},
        )

        return data
      },
    }
  },

  updateUserInformation: (
    payload: RequestTypes['updateSupportInformation'],
  ) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.put(
          `support/users/${payload.userId}`,
          {
            ...payload,
          },
        )

        return data
      },
    }
  },
  updateUserEmail: (payload: RequestTypes['updateUserEmail']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.put(
          `/users/${payload.userId}/email`,
          {
            ...payload,
          },
        )

        return data
      },
    }
  },
  getUser: (payload: RequestTypes['getUser']) => {
    return {
      queryKey: [payload.userId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`users/${payload.userId}`)

        return data
      },
    }
  },

  updateUserProfile: (payload: RequestTypes['updateUserProfile']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.put(
          `users/${payload.userId}/profile`,
          { ...payload },
        )

        return data
      },
    }
  },

  searchUsers: (payload: RequestTypes['searchUsers']) => {
    return {
      queryKey: [payload.searchTerm, payload.page],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `users/search?searchString=${payload.searchTerm}&page=${payload.page}&size=${payload.size}`,
        )

        return data
      },
    }
  },

  createSupportUser: (payload: RequestTypes['createSupportUser']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.post(`users/support-user`, {
          ...payload,
        })

        return data
      },
    }
  },

  getUserInviteLink: (payload: RequestTypes['getUserInviteLink']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `users/${payload.email}/invite-link`,
        )

        return data
      },
    }
  },

  impersonateUser: (payload: RequestTypes['impersonateUser']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.put(`users/impersonate`, {
          ...payload,
        })

        return data
      },
    }
  },

  unimpersonateUser: (payload: RequestTypes['unimpersonateUser']) => {
    return {
      queryKey: [null],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.put(`users/unimpersonate`, {
          ...payload,
        })

        return data
      },
    }
  },

  getUserTimeline: (payload: RequestTypes['getUserTimeline']) => {
    return {
      queryKey: [payload.userId, payload.page, payload.size],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `audits/users/${payload.userId}?page=${payload.page}&size=${payload.size}&sort=${payload.sort}`,
        )
        await Promise.all(data.content.map(attachAuditResultMediaTypes))

        return data
      },
    }
  },
})
