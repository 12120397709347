import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, TextField } from '@common/material'
import { localizedStrings } from '@core/strings'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'
import useConstants from '@core/providers/useConstants'
import { Flex, Text } from '@fundamentals'
import {
  CommentReferenceObjectTypes,
  CommentSecondaryReferenceObjectTypes,
} from '@core/entities/comments'
import { AttachmentTypes } from '@core/components/templateAnswersForm'
import { LoadingButton } from '@mui/lab'

type CommentFormProps = {
  onDismiss: () => void
  type?: CommentReferenceObjectTypes
  subType?: CommentSecondaryReferenceObjectTypes
  id?: string | number
  onSubmit: (values: {
    message: string
    attachmentsKeys: Array<string>
  }) => void
  submitting?: boolean
}

const MAX_COMMENT_CHARACTERS = 500

const AddCommentForm: React.FC<CommentFormProps> = ({
  onDismiss,
  onSubmit,
  submitting,
}) => {
  const { constants } = useConstants()

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      message: '',
      attachmentsKeys: [],
    },
  })

  const submitForm = (values) => {
    onSubmit(values)
    reset({ message: '', attachmentsKeys: [] })
  }

  const attachmentsKeys = watch('attachmentsKeys')
  const message = watch('message')

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Controller
        name='message'
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <TextField
            minRows={1}
            placeholder={localizedStrings.typeHere}
            multiline
            fullWidth
            size='small'
            helperText={`${
              field.value?.length || 0
            }/${MAX_COMMENT_CHARACTERS} characters`}
            onChange={(e) =>
              e.target.value.length <= MAX_COMMENT_CHARACTERS && onChange(e)
            }
            sx={{ mb: 1.5 }}
            {...field}
          />
        )}
      />
      <Controller
        name='attachmentsKeys'
        control={control}
        render={({ field }) => (
          <AttachmentInput
            size='small'
            mt={2}
            inputProps={{
              value: [
                ...(attachmentsKeys?.map((proof) => ({
                  type: proof.type,
                  ...proof,
                })) || []),
              ],
              onChange: (proofs) => {
                field.onChange(proofs)
                field.onBlur()
              },
            }}
            attachmentTypes={[AttachmentTypes.IMAGE, AttachmentTypes.PDF]}
            maxFileSize={constants.max_question_attachment_size_in_bytes}
            {...field}
          />
        )}
      />
      <Flex justifyContent='flex-end' sx={{ py: 1, mt: 1 }}>
        <Button variant='outlined' onClick={onDismiss} sx={{ mr: 1 }}>
          {localizedStrings.cancel}
        </Button>
        <LoadingButton
          loading={submitting}
          variant='contained'
          type='submit'
          loadingPosition='start'
          disabled={attachmentsKeys.length === 0 && !message}
        >
          <Text ml={submitting && 2.5} fontSize='inherit'>
            {localizedStrings.submit}
          </Text>
        </LoadingButton>
      </Flex>
    </form>
  )
}

export default AddCommentForm
