import {
  createQueryKeys,
  mergeQueryKeys,
  createMutationKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '.'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'

const registerQueryKeys = createQueryKeys('register', {
  details: (payload: RequestTypes['getRegisterDetails']) => ({
    queryKey: [payload.registerId],
    queryFn: async () => {
      const { data } = await APIAxiosInstance.get(
        `registers/${payload.registerId}`,
      )
      return data
    },
  }),
  registerSettings: ({ registerId }: RequestTypes['getRegisterSettings']) => ({
    queryKey: [registerId],
    queryFn: async () => {
      const { data } = await APIAxiosInstance.get(
        `registers/${registerId}/settings`,
      )
      return data
    },
  }),
  registerSettingsAuthGroups: ({
    registerId,
  }: RequestTypes['getRegisterSettingsAuthGroups']) => ({
    queryKey: [registerId],
    queryFn: async () => {
      const { data } = await APIAxiosInstance.get(
        `registers/${registerId}/settings/assignable-auth-groups`,
      )
      return data
    },
  }),
  viewsList: ({ registerId }: RequestTypes['getRegisterViews']) => ({
    queryKey: [registerId],
    queryFn: async () => {
      const { data } = await APIAxiosInstance.get(
        `registers/${registerId}/views`,
      )
      return data
    },
  }),
  filtersList: ({ registerId }: RequestTypes['getRegisterFilters']) => ({
    queryKey: [registerId],
    queryFn: async () => {
      const { data } = await APIAxiosInstance.get(
        `registers/${registerId}/filters`,
      )
      return data
    },
  }),
  userList: ({
    registerId,
    registerPropertyId,
    term,
    ...rest
  }: RequestTypes['getRegisterUsers']) => ({
    queryKey: [registerId, registerPropertyId, term, rest],
    queryFn: async () => {
      const queryString = createQueryKeyString({
        registerId,
        registerPropertyId,
        term,
        ...rest,
      })
      const { data } = await APIAxiosInstance.get(
        `registers/user-search${queryString}`,
      )
      return data
    },
  }),
})

const registerMutationKeys = createMutationKeys('register', {
  setRegisterStatus: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['setRegisterStatus']) => {
      const { data } = await APIAxiosInstance.put(
        `registers/${payload.registerId}/status`,
        payload,
      )
      return data
    },
  },
  editRegister: {
    mutationKey: null,
    mutationFn: async ({
      registerId,
      ...rest
    }: RequestTypes['editRegister']) => {
      const { data } = await APIAxiosInstance.put(`registers/${registerId}`, {
        ...rest,
      })
      return data
    },
  },
  updateRegisterUserPropertySettings: {
    mutationKey: null,
    mutationFn: async ({
      registerId,
      registerPropertyId,
      ...rest
    }: RequestTypes['updateRegisterUserPropertySettings']) => {
      const { data } = await APIAxiosInstance.put(
        `registers/${registerId}/settings/user-properties/${registerPropertyId}`,
        {
          ...rest,
        },
      )
      return data
    },
  },
  updateRegisterItemPermissionsSettings: {
    mutationKey: null,
    mutationFn: async ({
      registerId,
      ...rest
    }: RequestTypes['updateRegisterItemPermissionsSettings']) => {
      const queryString = createQueryKeyString({
        id: registerId,
      })
      const { data } = await APIAxiosInstance.put(
        `registers/${registerId}/settings/item-permissions${queryString}`,
        {
          ...rest,
        },
      )
      return data
    },
  },
  recalculateRegisterFormulas: {
    mutationKey: null,
    mutationFn: async ({
      registerId,
    }: RequestTypes['recalculateRegisterFormulas']) => {
      const { data } = await APIAxiosInstance.put(
        `registers/${registerId}/recalculate-formulas`,
        {},
      )
      return data
    },
  },
  createRegisterTrigger: {
    mutationKey: null,
    mutationFn: async ({
      registerId,
      ...rest
    }: RequestTypes['createRegisterTrigger']) => {
      const { data } = await APIAxiosInstance.post(
        `registers/${registerId}/settings/triggers`,
        {
          ...rest,
        },
      )
      return data
    },
  },
  updateRegisterTrigger: {
    mutationKey: null,
    mutationFn: async ({
      registerId,
      triggerId,
      ...rest
    }: RequestTypes['updateRegisterTrigger']) => {
      const { data } = await APIAxiosInstance.put(
        `registers/${registerId}/settings/triggers/${triggerId}`,
        {
          ...rest,
        },
      )
      return data
    },
  },
  deleteRegisterTrigger: {
    mutationKey: null,
    mutationFn: async ({
      triggerId,
      registerId,
    }: RequestTypes['deleteRegisterTrigger']) => {
      const { data } = await APIAxiosInstance.delete(
        `registers/${registerId}/settings/triggers/${triggerId}`,
      )
      return data
    },
  },
  addRegisterDiagram: {
    mutationKey: null,
    mutationFn: async ({
      registerId,
      ...rest
    }: RequestTypes['addRegisterDiagram']) => {
      const { data } = await APIAxiosInstance.post(
        `registers/${registerId}/diagrams`,
        rest,
      )
      return data
    },
  },
})

export const registerKeys = mergeQueryKeys(
  registerQueryKeys,
  registerMutationKeys,
).register
