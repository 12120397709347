import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { foldersKeys, RequestTypes } from '@core/react-query/features/folders'
import { IFolder } from '@core/entities/folders/Folder'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useRenameFolder = (
  options?: UseMutationOptions<
    IFolder,
    RequestErrorType,
    RequestTypes['renameFolder']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...foldersKeys.renameFolder,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: foldersKeys._def,
      })
    },
    ...options,
  })
}
