import { localizedStrings } from '@core/strings'
import { styled } from '@mui/material/styles'
import { Box, Flex } from '@fundamentals'
import React from 'react'
import { ChevronRight, MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { default as legacyTheme } from '../../../../styles/theme'
import { TableColumn } from '@common/Table/types'
import { DropdownButtonOption } from '@common/material/DropdownButton/types'
import { DropdownButton, IconButton } from '@common/material'

type TableRowProps<T> = {
  columns: Array<TableColumn<T>>
  rowData: T
  onClick?: (rowData: T) => void
  rowSx?: object
  withoutChevron?: boolean
  withoutCard?: boolean
  dataTest?: string
  dataId?: string
  rowAccentColor?: (rowData: T) => string
  rowActions?: (rowData: T) => DropdownButtonOption[]
}

export const TableRow = <T,>(props: TableRowProps<T>): React.ReactElement => {
  const {
    columns,
    rowData,
    onClick,
    rowSx,
    withoutChevron,
    withoutCard,
    dataTest,
    rowAccentColor,
    rowActions,
  } = props
  const onRowClick = onClick
    ? () => {
        onClick(rowData)
      }
    : null

  const accentColour = rowAccentColor ? rowAccentColor(rowData) : null
  const actions = rowActions ? rowActions(rowData) : []

  return (
    <StyledRow
      py='1rem'
      onClick={onRowClick}
      data-test={dataTest}
      data-id={props.dataId}
      sx={rowSx}
      px={withoutCard ? 0 : legacyTheme.variants.card.padding}
    >
      {accentColour && (
        <Box
          width={4}
          ml={-2}
          mr={1.5}
          mt={-2}
          mb={-2}
          minWidth={4}
          bgcolor={accentColour}
          alignSelf={'stretch'}
        />
      )}
      {columns.map((column, columnIndex) => {
        const {
          widthRatio = 1,
          transform,
          renderColumn,
          onColumnClick,
          ...props
        } = column

        const renderColumnInRow = () => {
          if (renderColumn) {
            return renderColumn(rowData, columnIndex)
          }

          if (transform) {
            return transform(rowData)
          }

          console.error(
            'No renderColumn or transform function has been passed into the column',
          )
        }

        return (
          <StyledColumn
            onClick={(e) => {
              if (onColumnClick) {
                // Stop the row onClick from being called if the column is clicked
                e.stopPropagation()
                onColumnClick(rowData)
              }
            }}
            key={columnIndex}
            flex={widthRatio}
            alignItems={'center'}
            data-test={`${dataTest}-${column.label}`}
            {...props}
          >
            {renderColumnInRow()}
          </StyledColumn>
        )
      })}
      {onClick && !withoutChevron && !actions?.length && (
        <Flex flex={0.2} justifyContent={'flex-end'}>
          <Flex flexDirection={'column'} justifyContent={'center'}>
            <ChevronRight />
          </Flex>
        </Flex>
      )}
      {!!actions?.length && (
        <Flex flex={0.2} justifyContent={'flex-end'} alignItems='center'>
          <DropdownButton
            label={localizedStrings.new}
            options={actions}
            fullWidth
            renderButton={(onClick) => (
              <IconButton
                onClick={(e) => {
                  onClick(e)
                  e.stopPropagation()
                }}
                onBlur={(e) => e.stopPropagation()}
              >
                <MoreHorizIcon />
              </IconButton>
            )}
          />
        </Flex>
      )}
    </StyledRow>
  )
}

const StyledRow = styled(Flex)<{ onClick?: any }>`
  ${({ theme, onClick }) => {
    return {
      '&:hover': {
        backgroundColor: theme.palette.legacy.lavender[3],
      },
      'cursor': onClick ? 'pointer' : '',
    }
  }}
`

const StyledColumn = styled(Flex)`
  vertical-align: middle;
`
