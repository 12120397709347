import React from 'react'
import { PermitQuestionsTabProps } from '@modules/permitDetails/types'
import { ModalDialogContainer } from '@common/modal'
import { PermitTab } from '@modules/permitDetails/tabs/PermitTab'
import { localizedStrings } from '@core/strings'
import { DialogContent, DialogTitle, IconButton } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'

export const PermitTabModal: React.FC<PermitQuestionsTabProps> = (props) => {
  const { hideModal } = useModal()

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '90%' } }}>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.warnings}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PermitTab {...props} />
      </DialogContent>
    </ModalDialogContainer>
  )
}
