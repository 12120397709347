import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import { localizedStrings } from '@core/strings'

export type AdvancedNumberFilterOperators =
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'LESS_OR_EQUAL_THAN'
  | 'GREATER_OR_EQUAL_THAN'
  | 'IS_PRESENT'
  | 'IS_NOT_PRESENT'

export const advancedNumberFilterOperatorsWithoutArguments: AdvancedNumberFilterOperators[] =
  ['IS_PRESENT', 'IS_NOT_PRESENT']

export type AdvancedNumberFilterValue = {
  operator: AdvancedNumberFilterOperators
  value: number
}

export const advancedNumberFilterValueIsPartial = (
  value: AdvancedNumberFilterValue,
) => {
  if (!value?.operator) {
    return true
  }

  if (advancedNumberFilterOperatorsWithoutArguments.includes(value?.operator)) {
    return false
  }

  if (!value?.value) {
    return true
  }

  return false
}

export const advancedNumberFilterOptionConditions: {
  value: AdvancedNumberFilterOperators
  label: string
}[] = [
  { value: 'EQUALS', label: '=' },
  { value: 'NOT_EQUALS', label: '≠' },
  { value: 'LESS_OR_EQUAL_THAN', label: '≤' },
  { value: 'GREATER_OR_EQUAL_THAN', label: '≥' },
  { value: 'IS_PRESENT', label: localizedStrings.isPresent },
  { value: 'IS_NOT_PRESENT', label: localizedStrings.isNotPresent },
]

export type AdvancedNumberFilterHookArguments =
  FilterHookArgumentsBase<AdvancedNumberFilterValue> & {
    // Any extra arguments here
  }

export type AdvancedNumberFilterInternalArguments =
  AdvancedNumberFilterHookArguments &
    FilterInternalArgumentsBase<AdvancedNumberFilterValue, 'ADVANCED_NUMBER'>

export const useAdvancedNumberFilter = (
  filter: AdvancedNumberFilterHookArguments,
): AdvancedNumberFilterInternalArguments => {
  const unfocusedText = (
    value: AdvancedNumberFilterValue,
    { noLabel } = { noLabel: false },
  ) => {
    const label = noLabel ? '' : `${filter.label}: `
    if (value?.operator === 'IS_PRESENT') {
      return `${label}${localizedStrings.isPresent}`
    }
    if (value?.operator === 'IS_NOT_PRESENT') {
      return `${label}${localizedStrings.isNotPresent}`
    }

    const emptyText = filter.nothingSelectedText || localizedStrings.search
    const operatorLabel = advancedNumberFilterOptionConditions.find(
      (operator) => operator.value === value?.operator,
    )?.label

    const textValue =
      value?.operator && value?.value
        ? `( ${operatorLabel} ) ${value?.value}`
        : emptyText

    return `${label}${textValue}`
  }
  return {
    ...filter,
    unfocusedText,
    type: 'ADVANCED_NUMBER',
    serialization: 'object',
  }
}
