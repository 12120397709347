import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { projectLocationsKeys } from '../locationsKeys'
import { RequestTypes } from '../requestTypes'

export const useDisableProjectLocation = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['disableProjectLocation']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...projectLocationsKeys.disableProjectLocation,
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectLocationsKeys._def })
    },
    ...options,
  })
}
