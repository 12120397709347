import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const useRemoveChecklistParticipant = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['removeParticipant']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...checklistKeys.removeParticipant,
    ...options,
    onSuccess: (data, { checklistId }) => {
      queryClient.invalidateQueries({
        queryKey: checklistKeys.byId({ checklistId }).queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: checklistKeys._def,
      })
    },
  })
}
