import { useState } from 'react'
import { useSelectFilter } from './fundamentals'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
} from './fundamentals'
import { useProjectUsers } from '@core/react-query/features/projects/project/hooks/useProjectUsers'
import { useOrganisationUsers } from '@core/react-query/features/organisations/organisation/hooks/useOrganisationUsers'
import _ from 'lodash'
import { useOrganisationUser } from '@core/react-query/features/organisations/organisation/hooks/useOrganisationUser'
import { User } from '@core/entities/user'
import { useProjectUser } from '@core/react-query/features/projects/project/hooks/useProjectUser'
import { useDebouncedValue } from '@core/utils/useDebouncedValue'
import { FilterHookArgumentsBase } from '@core/providers/filters'
import { useToast } from '@core/toast'

export type UserSearchFilterScopes =
  | UserSearchFilterOrganisationScope
  | UserSearchFilterProjectScope
  | UserSearchFilterBusinessUnitScope

export type UserSearchFilterOrganisationScope = {
  type: 'ORGANISATION'
  organisationId: number
}

export type UserSearchFilterProjectScope = {
  type: 'PROJECT'
  organisationId: number
  projectId: number
}

export type UserSearchFilterBusinessUnitScope = {
  type: 'BUSINESS_UNIT'
  organisationId: number
}

type UserSearchFilterArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    multiple?: Multiple
    scope?: UserSearchFilterScopes
    initialValue?: number
    renderItem?: (user: User) => React.ReactElement
    valueToLabel?: (user: User) => any
  }

export const useUserSearchFilter: <Multiple extends boolean | undefined>(
  arg: UserSearchFilterArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = (args) => {
  const [searchTerm, setSearchTerm] = useState<string>()
  const [users, setUsers] = useState([])
  const [userCache, setUserCache] = useState<Record<string, User>>()
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300)
  const toast = useToast()

  const onSuccessInitialLoad = (data: User) => {
    setUsers([data.id])
    setUserCache({ [data.id]: data })
  }

  // Load initial user if provided
  useOrganisationUser(
    {
      organisationId: args.scope.organisationId,
      userId: args.initialValue,
    },
    {
      enabled:
        !!args.initialValue &&
        (args.scope.type === 'ORGANISATION' ||
          args.scope.type === 'BUSINESS_UNIT'),
      onSuccess: onSuccessInitialLoad,
      onError: (e) => toast.error(e),
    },
  )

  // Load initial user if provided
  if (args.scope.type === 'PROJECT') {
    useProjectUser(
      {
        projectId: args.scope.projectId,
        userId: args.initialValue,
      },
      {
        enabled: !!args.initialValue && args.scope.type === 'PROJECT',
        onSuccess: onSuccessInitialLoad,
        onError: (e) => toast.error(e),
      },
    )
  }

  const onError = (e: any) => {
    toast.error(e)
  }

  const { isLoading: projectUsersLoading } = useProjectUsers(
    {
      projectId: args.scope.type === 'PROJECT' && args.scope.projectId,
      term: debouncedSearchTerm,
      size: 1000,
    },
    {
      enabled: args.scope.type === 'PROJECT' && !!debouncedSearchTerm,
      onSuccess: (usersData) => {
        const updatedCache = {}
        const updatedUsers = _.flatten(usersData.pages).flatMap((userPage) => {
          return userPage.content.map((user) => {
            updatedCache[user.id] = user
            return user.id
          })
        })
        setUsers(updatedUsers)
        setUserCache({ ...userCache, ...updatedCache })
      },
      onError,
    },
  )

  const { isLoading: organisationUsersLoading } = useOrganisationUsers(
    {
      organisationId: args.scope.organisationId,
      term: debouncedSearchTerm,
      size: 1000,
    },
    {
      enabled: args.scope.type === 'ORGANISATION' && !!debouncedSearchTerm,
      onSuccess: (usersData) => {
        const updatedCache = {}
        const updatedUsers = _.flatten(usersData.pages).flatMap((userPage) => {
          return userPage.content.map((user) => {
            updatedCache[user.id] = user
            return user.id
          })
        })
        setUsers(updatedUsers)
        setUserCache({ ...userCache, ...updatedCache })
      },
      onError,
    },
  )

  const onInputChange = (input: string) => {
    if (!input) setSearchTerm('')
    else if (input.length < 3) return
    setSearchTerm(input)
  }

  return useSelectFilter({
    ...args,
    valueToLabel: args.valueToLabel
      ? (userId) => args.valueToLabel(userCache?.[userId])
      : (userId) => userCache?.[userId]?.email,
    // The back-end does the filtering for us, we don't need to on the front-end
    disableClientSideFilter: true,
    renderItem: args?.renderItem
      ? (userId) => args?.renderItem(userCache?.[userId])
      : null,
    onInputChange,
    debounceInput: 100,
    noOptionsText: 'Search by name or email',
    options: users,
    serialization: 'object',
    loading:
      !!debouncedSearchTerm &&
      (projectUsersLoading || organisationUsersLoading),
  })
}
