import { localizedStrings } from '@core/strings'
import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'
import { Flex, Text } from '@fundamentals'

import { ModalDialogContainer } from '@common/modal'
import { Permit } from '@core/entities/permit/Permit'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'

type ComponentType = {
  permit: Permit
  title: string
}

const ReasonForSuspensionPermitModal: React.FC<ComponentType> = ({
  permit,
  title,
}) => {
  const { hideModal } = useModal()

  const getReason = (state) => {
    switch (state) {
      case 'RESUME_REQUEST_DECLINED':
        return permit.permitSuspensionResult?.resumeRequestDeclineReason
      case 'RESUME_REQUESTED':
        return permit.permitSuspensionResult?.resumeRequestReason
      case 'SUSPENDED':
        return permit.permitSuspensionResult?.reason
      default:
        return localizedStrings.noReasonProvided
    }
  }

  const resumeRequestDeclined =
    permit.permitSuspensionResult?.state === 'RESUME_REQUEST_DECLINED'

  const mediaUrls =
    permit.permitSuspensionResult?.[
      permit.permitSuspensionResult.state === 'RESUME_REQUESTED'
        ? 'resumeRequestedMediaUrls'
        : 'suspendedMediaUrls'
    ]

  const thumbnailList =
    mediaUrls?.map((img) => {
      return {
        type: img.includes('documents') ? 'pdf' : 'image',
        getUrl: img,
      }
    }) || []

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {title}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <Text sx={{ fontSize: 12 }} color='text.secondary' gutterBottom>
          {localizedStrings.reason}
        </Text>
        <Text>{getReason(permit.permitSuspensionResult.state)}</Text>
        {thumbnailList.length > 0 && !resumeRequestDeclined && (
          <>
            <Text
              sx={{ fontSize: 12, mt: 2 }}
              color='text.secondary'
              gutterBottom
            >
              {localizedStrings.proofs}
            </Text>
            <Flex flexWrap='wrap' my={2}>
              <AttachmentInput
                size='small'
                inputProps={{
                  value: thumbnailList,
                }}
                readOnly
                borderLess
              />
            </Flex>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          variant='contained'
          data-test='jsOkayCancelReason'
          onClick={hideModal}
        >
          {localizedStrings.ok}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}

export default ReasonForSuspensionPermitModal
