import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { checklistTemplateKeys } from '@core/react-query/features/templates/checklistTemplates'
import {
  IChecklistTemplateRole,
  ChecklistTemplateRole,
} from '@core/entities/checklist/ChecklistTemplateRoles'

const checklistTemplateRolesTransform = (response: IChecklistTemplateRole[]) =>
  response.map(ChecklistTemplateRole.new)

export const useGetChecklistTemplateRoles = (
  payload: RequestTypes['getChecklistTemplateRoles'],
  options?: UseQueryOptions<
    IChecklistTemplateRole[],
    string,
    ChecklistTemplateRole[]
  >,
) => {
  return useQuery({
    select: checklistTemplateRolesTransform,
    ...checklistTemplateKeys.getRoles(payload),
    ...options,
  })
}
