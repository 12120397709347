import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IRegisterTemplateFilter } from './IRegisterTemplateFilter'
import {
  AllFilterTypes,
  DateFilter,
  FilterBase,
  NumberFilter,
  SelectFilter,
  StringFilter,
  UserFilter,
} from '@core/entities/filters'

export class RegisterTemplateFilter extends Entity<IRegisterTemplateFilter> {
  id: number
  name: string

  @Type(() => FilterBase, {
    discriminator: {
      property: 'propertyType',
      subTypes: [
        { value: StringFilter, name: 'STRING' },
        { value: NumberFilter, name: 'NUMBER' },
        { value: DateFilter, name: 'DATE' },
        { value: SelectFilter, name: 'SELECT' },
        { value: UserFilter, name: 'USER' },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  filters: AllFilterTypes[]

  public static new(payload: unknown): RegisterTemplateFilter {
    return plainToInstance(RegisterTemplateFilter, payload)
  }
}
