import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { RegisterItemText } from '@modules/registers/items/RegisterItemText'
import { RegisterItemContentButtons } from '@modules/registers/propertyValue/RegisterItemPropertyValueButtons'
import moment from 'moment/moment'
import { emptyValue } from '@modules/registers/propertyValue/helpers'
import { RegisterItemDateProperty } from '@core/entities/register/RegisterItem'

type RegisterItemPropertyDateValueProps = {
  registerItemPropertyData: RegisterItemDateProperty<string>
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertyDateValue: React.FC<
  RegisterItemPropertyDateValueProps
> = ({ registerItemPropertyData, variant }) => {
  const displayString = registerItemPropertyData?.dateValue
    ? // Dates are stored as UTC datetime, we do not want to convert them to the user's local time
      moment.utc(registerItemPropertyData?.dateValue).format('D MMM YYYY')
    : emptyValue(variant)

  return (
    <RegisterItemContentButtons
      variant={variant}
      value={displayString}
      registerItemPropertyData={registerItemPropertyData}
    >
      <RegisterItemText>{displayString}</RegisterItemText>
    </RegisterItemContentButtons>
  )
}
