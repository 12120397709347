import React, { forwardRef, Ref, useState } from 'react'
import DatePicker from 'components/DatePicker'
import { addDays, startOfDay } from 'date-fns'
import { Box } from '@fundamentals'
import { TextField } from '@mui/material'
import { DateRangeFilterValue } from '@core/providers/filters'
import { DateRangeFilterInternalArguments } from '@core/providers/filters'
import { IconButton, InputAdornment } from '@common/material'
import { Close } from '@mui/icons-material'

type DateRangeFilterProps = {
  filter: DateRangeFilterInternalArguments
  onChange: (value: DateRangeFilterValue) => void
  onCalendarClose: () => void
  value: DateRangeFilterValue
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  filter,
  onChange,
  onCalendarClose,
  value,
}) => {
  const [dateRange, setDateRange] = useState(
    value || { startTime: null, endTime: null },
  )

  const _onChange = (dates) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates
      setDateRange({ startTime: start, endTime: end })

      if (!start && !end) {
        setDateRange({ startTime: null, endTime: null })
        onChange({ startTime: null, endTime: null })
      }
    }
  }

  const _onCalendarClose = () => {
    if (dateRange.startTime && dateRange.endTime) {
      onChange({
        startTime: startOfDay(new Date(dateRange.startTime)).toISOString(),
        endTime: startOfDay(
          addDays(new Date(dateRange.endTime), 1),
        ).toISOString(),
      })
    }
    onCalendarClose()
  }

  const MuiInput = forwardRef((props, ref: Ref<HTMLDivElement>) => (
    <TextField
      sx={{ width: 280 }}
      ref={ref}
      label='Date Range'
      size='small'
      multiline={false}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={() => {
                setDateRange({ startTime: null, endTime: null })
                onChange({ startTime: null, endTime: null })
              }}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  ))

  return (
    <Box
      sx={{
        pr: 1,
        '& .react-datepicker__input-container': {
          border: 'none !important',
          '& input': {
            paddingTop: 0,
            paddingBottom: 0,
            bgcolor: 'transparent',
            border: '1px solid transparent !important',
          },
        },
        '& .react-datepicker-popper': {
          zIndex: '11 !important',
        },
      }}
    >
      <DatePicker
        autoFocus
        value={null}
        customInput={<MuiInput />}
        onChange={_onChange}
        onCalendarClose={_onCalendarClose}
        startDate={dateRange.startTime ? new Date(dateRange.startTime) : null}
        endDate={dateRange.endTime ? new Date(dateRange.endTime) : null}
        selectsRange
        dateFormat='yyyy-MM-dd'
      />
    </Box>
  )
}

export default DateRangeFilter
