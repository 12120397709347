import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { ChecklistListItem } from '@core/entities/checklist/ChecklistListItem'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'

const checklistTransform: (data) => Array<ChecklistListItem> = (data) => {
  return data.map((checklistListItem) => {
    return ChecklistListItem.new(checklistListItem)
  })
}

export const usePermitChecklists = (
  payload: RequestTypes['getPermitChecklists'],
  options?: UseQueryOptions<Array<ChecklistListItem>, string>,
) => {
  return useQuery({
    select: checklistTransform,
    ...permitKeys.byId(payload)._ctx.checklists,
    ...options,
  })
}
