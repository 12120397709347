import {
  organisationKeys,
  RequestTypes,
} from '@core/react-query/features/organisations/organisation'
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { IOrganisationUser, OrganisationUser } from '@core/entities/user'

const dataTransformer = (user: IOrganisationUser) => OrganisationUser.new(user)

export const useOrganisationUser = (
  payload: RequestTypes['getOrganisationUser'],
  options: UseQueryOptions<IOrganisationUser, string, OrganisationUser> = {},
) => {
  return useQuery({
    select: dataTransformer,
    ...organisationKeys.byId(payload)._ctx.organisationUser(payload),
    ...options,
  })
}

export const useOrganisationUserGetter = () => {
  const queryClient = useQueryClient()
  const fetchOrganisationUser = async (
    organisationId: number,
    userId: number,
  ) => {
    const response = await queryClient.fetchQuery(
      organisationKeys
        .byId({ organisationId })
        ._ctx.organisationUser({ userId }),
    )
    return dataTransformer(response)
  }

  return { fetchOrganisationUser }
}
