import { AuditAction } from '@core/entities/audit/AuditResult/types'
import { IUser } from '@core/entities/user'
import { GeoJSON } from 'geojson'
import { getMediaUrlContentType } from '@core/react-query/helpers/mediaHelpers'
import { IAuditResultMetadata } from '@core/entities/audit/AuditResult/IAuditResultMetadata'

export const attachAuditResultMediaTypes = async (audit: IAuditResult) => {
  if (!audit.metadata?.mediaUrls) return
  await Promise.all(
    audit.metadata?.mediaUrls.map(async (mediaUrl) => {
      if (!audit.metadata.mediaUrlsWithType) {
        audit.metadata.mediaUrlsWithType = []
      }
      const mediaType = await getMediaUrlContentType(mediaUrl)
      audit.metadata.mediaUrlsWithType.push({
        getUrl: mediaUrl,
        type: mediaType,
      })
    }),
  )
}

export interface IAuditResult {
  id: number
  createdOn: string
  platform: 'WEB' | 'ANDROID' | 'IOS' | 'UNKNOWN'
  action: AuditAction
  signatureUrl?: string
  info?: string
  user?: IUser
  organisationUser?: IUser
  projectUser?: IUser
  metadata?: IAuditResultMetadata
  geoJSON?: GeoJSON
  coordinates?: {
    latitude: number
    longitude: number
    locationAccuracyInMeters?: number
    locationAccuracyStatus?: string
  }
  project?: {
    id: number
    name: string
  }
  organisation?: {
    id: number
    name: string
  }
  permit?: {
    id: number
  }
  checklist?: {
    id: number
    name: string
  }
  register?: {
    id: number
    name: string
  }
  registerItem?: {
    id: number
    uniqueId: string
  }
  permitTemplate?: {
    id: number
    name: string
  }
  permitTemplateVersion?: {
    id: number
    version: number
  }
  checklistTemplate?: {
    id: number
    name: string
  }
  checklistTemplateVersion?: {
    id: number
    version: number
  }
}
