import { PageUrls } from '@core/page-urls'
import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { PermitActionCard } from '../PermitActionCard'
import { useRouter } from 'next/router'
import { Permit } from '@core/entities/permit/Permit'

type FinalSignoffPermitCardProps = {
  organisationId: number
  projectId: number
  permit: Permit
}
export const FinalSignoffPermitCard: React.FC<FinalSignoffPermitCardProps> = ({
  organisationId,
  projectId,
  permit,
}) => {
  const router = useRouter()
  if (!permit.hasPermission('DO_FINAL_SIGNOFF')) return null

  return (
    <PermitActionCard
      title={localizedStrings.finalSignoff}
      info={localizedStrings.permitWorkflowFinalSignOffDescription}
      BottomComponent={
        <Button
          variant='contained'
          data-test='primary-permit-actions-final-signoff'
          fullWidth
          onClick={() => {
            if (projectId) {
              router.push(
                PageUrls.projectPermitFinalSignOffs(
                  organisationId,
                  projectId,
                  permit.id,
                ),
              )
            } else
              router.push(
                PageUrls.permitFinalSignOffs(organisationId, permit.id),
              )
          }}
        >
          {localizedStrings.permitWorkflowFinalSignOffComplete}
        </Button>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
