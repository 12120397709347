import { useQuery } from '@tanstack/react-query'

import { RequestTypes } from '../requestTypes'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import { planPermitsKeys } from '../planPermitsKeys'
import { ProjectPlanPermits } from '@core/entities/project/ProjectPlanPermits'

const planPermitsTransform = (data) => ProjectPlanPermits.new(data)

export const useGetPlanPermits = (
  payload: RequestTypes['getPlanPermits'],
  options?: UseQueryOptions<ProjectPlanPermits, string>,
) => {
  return useQuery({
    select: planPermitsTransform,
    ...planPermitsKeys.getPlanPermits(payload),
    ...options,
  })
}
