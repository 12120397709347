import { localizedStrings } from '@core/strings'
import React from 'react'
import { Box, Text } from '@fundamentals'
import { Button, IconButton, Tooltip } from '@common/material'
import {
  LinkPermitsQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import { LinkedPermitResult } from '@core/entities/linkedProcesses'
import { PermitCard } from '../../Cards/PermitCard'
import { SelectOrganisationPermitsModal } from '@elements'
import { PermitBase } from '@core/entities/permit/PermitBase'
import { Grid } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import LaunchIcon from '@mui/icons-material/Launch'
import { PageUrls } from '@core/page-urls'
import { useNavigationContext } from '@common/hooks'
import { useFormContext } from 'react-hook-form'

const LinkPermitsQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange, ...inputProps } = {},
  readOnly,
}) => {
  const { organisationId } = useNavigationContext()
  const formContext = useFormContext()
  const projectId = formContext?.getValues('projectId')
  const permitId = formContext?.getValues('permitId')

  const onChangeSelectedPermits = (
    permitIds: Array<number>,
    permits: Array<PermitBase>,
  ) => {
    onChange({
      ...value,
      linkedPermitsIds: permitIds,
      linkedPermitsResults: permits as unknown as Array<LinkedPermitResult>,
    })
  }

  const removeSelectedPermit = (permitId: number) => {
    onChange({
      ...value,
      linkedPermitsIds: value.linkedPermitsIds.filter((id) => id !== permitId),
      linkedPermitsResults: value.linkedPermitsResults.filter(
        (permit) => permit.id !== permitId,
      ),
    })
  }

  return (
    <Box data-test={`${question.id}-${'LINK_PERMITS'}`}>
      {readOnly && (
        <Text color='text.secondary' fontSize='12px' sx={{ mb: 1 }}>
          {localizedStrings.linkedDocuments}
        </Text>
      )}
      <Grid container spacing={1.5}>
        {value?.linkedPermitsResults?.map((linkedPermitResult, index) => {
          const { id, name, ...linkedPermitResultEntity } =
            LinkedPermitResult.new(linkedPermitResult)

          return (
            <Grid key={index} item xs={4} minWidth={300}>
              <PermitCard
                templateName={name}
                sx={{ height: '100%' }}
                icon={
                  readOnly ? (
                    <IconButton
                      color='primary'
                      size='small'
                      onClick={() => {
                        window.open(
                          PageUrls.permit(organisationId, id),
                          '_blank',
                        )
                      }}
                    >
                      <Tooltip title={localizedStrings.viewPermit}>
                        <LaunchIcon fontSize='small' />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <IconButton
                      color='error'
                      onClick={() => removeSelectedPermit(id)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )
                }
                {...linkedPermitResultEntity}
              />
            </Grid>
          )
        })}
      </Grid>

      {!readOnly && (
        <SelectOrganisationPermitsModal
          title={localizedStrings.selectPermitsToLink}
          organisationId={organisationId}
          value={value.linkedPermitsIds}
          onChange={onChangeSelectedPermits}
          triggerComponent={(onClick) => (
            <Button variant='contained' onClick={onClick} sx={{ mt: 1.5 }}>
              {localizedStrings.addDocuments}
            </Button>
          )}
          filters={{
            templateIds: value?.permitTemplateLinkingIds,
            statuses: value?.permitTemplateLinkingStatuses,
            projectIds: [projectId],
            excludeIds: [permitId],
          }}
          multiSelect
        />
      )}
    </Box>
  )
}

LinkPermitsQuestion.rules = LinkPermitsQuestionRules

export default LinkPermitsQuestion
