import { localizedStrings } from '@core/strings'
import { UserStatus } from '@core/entities/user'
import React from 'react'
import { Chip } from '@common/material'

type UserStatusChipProps = {
  status: UserStatus
}
export const UserStatusChip: React.FC<UserStatusChipProps> = ({
  status,
}: {
  status: UserStatus
}) => {
  const color = () => {
    switch (status) {
      case 'ACTIVE':
        return 'success'
      case 'DISABLED':
        return 'default'
      default:
        return 'default'
    }
  }

  const label = () => {
    switch (status) {
      case 'ACTIVE':
        return localizedStrings.active
      case 'DISABLED':
        return localizedStrings.disabled
      default:
        return status
    }
  }

  return <Chip color={color()} label={label()} />
}
