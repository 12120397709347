import {
  businessUnitsKeys,
  RequestTypes,
} from '@core/react-query/features/businessUnits'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Dashboard, IDashboard } from '@core/entities/dashboard'

const dataTransformer = (dashboard: IDashboard) => Dashboard.new(dashboard)

export const useGetBusinessUnitDashboard = (
  payload: RequestTypes['getBusinessUnitDashboard'],
  options?: UseQueryOptions<IDashboard, string, Dashboard>,
) => {
  return useQuery({
    select: dataTransformer,
    ...businessUnitsKeys.getBusinessUnitDashboard(payload),
    enabled: !!(payload.organisationId && payload.businessUnitId),
    retry: 3,
    ...options,
  })
}
