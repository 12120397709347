import { FolderType } from '@core/entities/folders/Folder'
import {
  useCreateFolder,
  useDeleteFolder,
  useGetFolder,
  useMoveFolder,
  useRenameFolder,
} from '@core/react-query/features/folders'
import { localizedStrings } from '@core/strings'
import { PermitTemplate } from '@core/entities/permit/PermitTemplate'
import { useToast } from '@core/toast'
import { ChecklistTemplate } from '@core/entities/checklist/ChecklistTemplate'
import { FolderResponseWithExcludedFields } from '@core/entities/folders/FolderResponse'
import { StatusType } from '@core/entities/SiteBoxDocument'

export type FileTypes = PermitTemplate | ChecklistTemplate
export type CommonFolderActions = {
  renameFolder: (
    requestParams: {
      folderId: number
      folderName: string
    },
    callbacks?: { onSuccess?: () => void },
  ) => void
  deleteFolder: (
    requestParams: {
      folderId: number
    },
    callbacks?: { onSuccess?: () => void },
  ) => void
  createFolder: (
    requestParams: {
      folderName: string
    },
    callbacks?: { onSuccess?: () => void },
  ) => void
  moveFile: (
    requestParams: {
      folderIds: number[]
      referenceObjectsIds: number[]
      destinationId: number
    },
    callbacks?: { onSuccess?: () => void },
  ) => void
}

type UseFileSystem = (props: {
  organisationId: number
  projectId?: number
  type: FolderType
  folderId: number
  status?: StatusType[]
}) => {
  folder: FolderResponseWithExcludedFields
  commonFolderActions: CommonFolderActions
  isLoading: boolean
}

export const useFileSystem: UseFileSystem = ({
  organisationId,
  projectId,
  type,
  folderId,
  status,
}) => {
  const toast = useToast()

  const { data: folder, isLoading } = useGetFolder(
    {
      organisationId,
      type,
      folderId,
      projectId,
      status,
    },
    {
      keepPreviousData: true,
    },
  )

  const renameFolder = useRenameFolder()
  const deleteFolder = useDeleteFolder()
  const createFolder = useCreateFolder()
  const moveFolder = useMoveFolder()

  const commonFolderActions: CommonFolderActions = {
    renameFolder: (requestParams, { onSuccess } = {}) =>
      renameFolder.mutate(
        {
          organisationId,
          projectId,
          type,
          ...requestParams,
        },
        {
          onSuccess: () => {
            toast.success(localizedStrings.folderRenamed)
            onSuccess?.()
          },
          onError: (e) => toast.error(e.message),
        },
      ),
    deleteFolder: ({ folderId }, { onSuccess } = {}) =>
      deleteFolder.mutate(
        {
          organisationId,
          projectId,
          type,
          folderId,
        },
        {
          onSuccess: () => {
            toast.success(localizedStrings.successfullyDeleted)
            onSuccess?.()
          },
          onError: (e) => toast.error(e.originalError.response.data),
        },
      ),
    createFolder: ({ folderName }, { onSuccess } = {}) =>
      createFolder.mutate(
        {
          organisationId,
          projectId,
          type,
          parentId: folder.getFolderId(),
          folderName,
        },
        {
          onSuccess: () => {
            toast.success(localizedStrings.folderCreated)
            onSuccess?.()
          },
          onError: (e) => toast.error(e.message),
        },
      ),
    moveFile: (requestParams, { onSuccess } = {}) =>
      moveFolder.mutate(
        {
          organisationId,
          projectId,
          type,
          ...requestParams,
        },
        {
          onSuccess: () => {
            toast.success(localizedStrings.succesfullyMovedFile)
            onSuccess?.()
          },
          onError: (e) => {
            toast.error(e.message)
          },
        },
      ),
  }

  return {
    folder,
    isLoading,
    commonFolderActions,
  }
}
