import { foldersKeys } from '@core/react-query/features/folders'
import {
  permitTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplates/permitTemplate'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const useUnarchivePermitTemplate = (
  options?: UseMutationOptions<
    Response,
    string,
    RequestTypes['unarchivePermitTemplate']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...permitTemplateKeys.unarchivePermitTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: permitTemplateKeys._def })
      queryClient.invalidateQueries({ queryKey: foldersKeys._def })
    },
    ...options,
  })
}
