import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermitBase } from '@core/entities/permit/PermitBase'
import { permitsKeys } from '@core/react-query/features/permits'
import { useMutateQueryData } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useDeletePermitDraft = () => {
  const { deleteInfiniteQueryItem } = useMutateQueryData()
  const queryClient = useQueryClient()

  return useMutation<
    IPermitBase,
    RequestErrorType,
    RequestTypes['deleteDraft']
  >({
    ...permitKeys.deleteDraft,
    onSuccess: (draft, variables) => {
      deleteInfiniteQueryItem(
        permitsKeys.drafts({
          organisationId: variables.organisationId,
          projectIds: [variables.projectId],
        }).queryKey,
        variables.draftId,
      )
      // Used for the web list to get refetch the drafts page
      queryClient.invalidateQueries(permitsKeys.draftsPage._def)
    },
  })
}
