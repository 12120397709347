import React from 'react'
import { useGetImageDetails } from '@core/react-query/features/image-details'
import { SmartToyOutlined } from '@mui/icons-material'
import { IconButton } from '@common/material'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { useModal } from '@common/GlobalModal'
import { ObjectDetectionDebugModal } from '@elements/ObjectDetection/ObjectDetectionDebugModal'
import { ObjectDetectionChipsProps } from '@elements/ObjectDetection/ObjectDetectionChips'

export const ObjectDetectionRobotChip: React.FC<ObjectDetectionChipsProps> = (
  props,
) => {
  const { flags } = useFeatureFlags(['web_object_detection'])
  const { showModal } = useModal()

  const getImageKeys = () => {
    if ('permit' in props) {
      return props.permit.getImageS3Keys()
    }
    if ('checklist' in props) {
      return props.checklist.getImageS3Keys()
    }
    if ('s3Key' in props) {
      return [props.s3Key]
    }
  }

  const imageKeys = getImageKeys()

  const { data: imageDetails } = useGetImageDetails(
    {
      imageKeys,
    },
    {
      enabled: !!flags.web_object_detection && !!imageKeys?.length,
    },
  )

  if (!flags.web_object_detection) {
    return null
  }

  return (
    <>
      {!!imageDetails && (
        <IconButton
          onClick={() => {
            showModal(
              <ObjectDetectionDebugModal
                response={imageDetails}
                chips={props}
              />,
            )
          }}
        >
          <SmartToyOutlined />
        </IconButton>
      )}
    </>
  )
}
