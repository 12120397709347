import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { projectsKeys } from '../projectsKeys'
import { PageResponse } from '@core/entities/pagination'
import { IProjectSummary } from '@core/entities/project'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const dataTransformer = ({ content }: PageResponse<IProjectSummary>) => content

export const useGetAllProjects = (
  payload: RequestTypes['getAllProjects'],
  options?: UseQueryOptions<
    PageResponse<IProjectSummary>,
    RequestErrorType,
    Array<IProjectSummary>
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...projectsKeys.getAllProjects(payload),
    ...options,
  })
}

export const prefetchGetAllProjects = (
  queryClient: QueryClient,
  payload: RequestTypes['getAllProjects'],
  options?: UseQueryOptions<
    PageResponse<IProjectSummary>,
    RequestErrorType,
    Array<IProjectSummary>
  >,
) => {
  return queryClient.prefetchQuery({
    select: dataTransformer,
    ...projectsKeys.getAllProjects(payload),
    ...options,
    cacheTime: Infinity,
  })
}
