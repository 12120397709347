import { localizedStrings } from '@core/strings'
import React from 'react'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Section } from '@common/Section'
import { Text } from '@fundamentals'
import { useAvailablePermitTemplates } from '@core/react-query/features/templates/permitTemplates/hooks/useAvailablePermitTemplates'
import { PermitTemplate } from '@core/entities/permit/PermitTemplate'

import { TemplatePickerWrapper } from '@elements/Pickers/TemplatePickerWrapper'

export type PermitTemplatePickerProps = TextFieldProps & {
  organisationId: number
  projectId: number
  value: PermitTemplate
  onChange?: (template: PermitTemplate) => void
  readOnly?: boolean
}

const PermitTemplatePicker: React.FC<PermitTemplatePickerProps> = ({
  organisationId,
  projectId,
  value,
  onChange,
  readOnly,
  error,
  ...props
}) => {
  const { data } = useAvailablePermitTemplates({
    projectId,
  })

  const findTemplateById = (id) => data?.templates?.find((t) => t.id === id)

  if (readOnly) {
    const templateName = data?.templates?.find(
      (template) => template.id === value?.id,
    )?.name

    return (
      <Section sx={props?.sx} label={localizedStrings.permitTemplate}>
        <Text>{templateName}</Text>
      </Section>
    )
  }

  return (
    <TemplatePickerWrapper
      label={localizedStrings.permitTemplate}
      availableTemplates={data}
      organisationId={organisationId}
      projectId={projectId}
      entityType='PERMIT_TEMPLATE'
      value={value}
      onChange={(templateId) => {
        const template = findTemplateById(templateId)
        onChange(template)
      }}
      error={error}
      {...props}
    />
  )
}

export { PermitTemplatePicker }
