import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  registerKeys,
  RequestTypes,
} from '@core/react-query/features/registers/registers/register'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  IRegisterViews,
  RegisterViews,
} from '@core/entities/register/RegisterViews'

const dataTransformer = (settings: IRegisterViews) =>
  RegisterViews.new(settings)

export const useGetRegisterViews = (
  payload: RequestTypes['getRegisterViews'],
  options?: UseQueryOptions<IRegisterViews, RequestErrorType, RegisterViews>,
) => {
  return useQuery({
    select: dataTransformer,
    ...registerKeys.viewsList(payload),
    ...options,
  })
}
