import { Box, Flex, BoxProps, Text } from '@fundamentals'

type SectionProps = BoxProps & {
  label?: string
  labelColor?: string
  icon?: React.ReactNode
  horizontal?: boolean
  tooltipText?: string
}

export const Section: React.FC<SectionProps> = ({
  label,
  labelColor = 'text.disabled',
  icon,
  horizontal,
  children,
  tooltipText,
  ...props
}) => {
  return (
    <Box
      display={horizontal && 'flex'}
      alignItems='center'
      flexWrap='nowrap'
      {...props}
    >
      <Flex alignItems='center' mr={horizontal && 0.5}>
        {icon}
        {label && (
          // This font size should be 12, but appears too small.
          // Maybe this is due to the font being incorrect?
          <Text fontSize={14} color={labelColor} ml={icon && 0.5}>
            {label}
          </Text>
        )}
      </Flex>

      {children}
    </Box>
  )
}
