import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
} from '@mui/material'

const Autocomplete: <
  T,
  G extends boolean,
  F extends boolean,
  O extends boolean,
>(
  props: AutocompleteProps<T, G, F, O>,
) => JSX.Element = (props) => {
  return <MuiAutocomplete {...props} />
}

export { Autocomplete }
