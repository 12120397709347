import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { authMutationKeys } from '@core/react-query/features/auth'

type ReturnType =
  | {
      result: 'USER_LINKED'
      identityProvider: string
    }
  | {
      result: 'TOKEN_EXCHANGED'
      username: string
      idToken: string
      accessToken: string
      refreshToken: string
    }
export const useCognitoExchangeToken = (
  options?: UseMutationOptions<
    ReturnType,
    string,
    RequestTypes['cognitoExchangeToken']
  >,
) => {
  return useMutation({
    ...authMutationKeys.cognitoExchangeToken,
    retry: 0,
    ...options,
  })
}
