import {
  permitTemplatesKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplates'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import {
  IPermitTemplate,
  PermitTemplate,
} from '@core/entities/permit/PermitTemplate'
import { usePageableQuery } from '@core/react-query/hooks'

const dataTransformer = ({ content }: PageResponse<IPermitTemplate>) => {
  return content.map((template) => PermitTemplate.new(template))
}

export const useOrganisationPermitTemplates = (
  payload: RequestTypes['getOrganisationTemplates'],
  options: UseInfiniteQueryOptions<
    PageResponse<IPermitTemplate>,
    string,
    Array<PermitTemplate>
  > = {},
) => {
  const organisationTemplatesQuery = permitTemplatesKeys.byOrganisation(payload)

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: organisationTemplatesQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<IPermitTemplate>,
    string,
    Array<PermitTemplate>
  >({
    ...organisationTemplatesQuery,
    ...infiniteQueryOptions,
    ...options,
  })
}
