import { Entity } from '@core/entities/entity'
import { IPeriodicCheck } from '@core/entities/PeriodicCheck/IPeriodicCheck'
import { TemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { ChecklistResultType } from '@core/entities/checklist/Checklist/types'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'

export class PeriodicCheck extends Entity<IPeriodicCheck> {
  id: number
  permitId: number
  templatePeriodicCheckId: number
  name: string
  createdOn: string
  projectResult?: {
    id: number
    name: string
    timezone: string
  }

  @Type(() => TemplateQuestionGroup)
  answers: Array<TemplateQuestionGroup>

  @Type(() => TemplateFormAnswers)
  answersBody?: TemplateFormAnswers

  @Type(() => User)
  submittedBy: User

  public static new(payload: unknown): PeriodicCheck {
    const entity = plainToInstance(PeriodicCheck, payload)

    return entity
  }

  public static filterByType(
    checklists: ChecklistResultType[] = [],
    type: 'CHECKLIST' | 'PERIODIC_CHECK',
  ) {
    return checklists.filter((item) => item.type === type)
  }
}
