import {
  Exclude,
  instanceToPlain,
  plainToInstance,
  Type,
} from 'class-transformer'
import {
  FolderPath,
  FolderResponseWithExcludedFields,
  IFolderResponse,
} from '@core/entities/folders/FolderResponse'
import { Folder } from '@core/entities/folders/Folder'
import { Entity } from '@core/entities/entity'
import { PermitTemplate } from '@core/entities/permit/PermitTemplate'
import { ChecklistTemplate } from '@core/entities/checklist/ChecklistTemplate'
import { SiteBoxDocument } from '@core/entities/SiteBoxDocument'
import _remove from 'lodash/remove'

export class FolderResponse extends Entity<IFolderResponse> {
  public toPlain(): IFolderResponse {
    const { files, ...base } = instanceToPlain(this)
    base.templates = _remove(
      this.files,
      (file) => file instanceof PermitTemplate,
    )
    base.checklistTemplates = _remove(
      this.files,
      (file) => file instanceof ChecklistTemplate,
    )
    base.siteBoxDocuments = this.files

    return base as IFolderResponse
  }

  @Type(() => Folder)
  currentFolder: Folder

  @Type(() => Folder)
  folders: Folder[]

  pathFromRoot: FolderPath

  @Exclude()
  templates: PermitTemplate[]

  @Exclude()
  checklistTemplates: ChecklistTemplate[]

  @Exclude()
  siteBoxDocuments: SiteBoxDocument[]

  files: Array<PermitTemplate | ChecklistTemplate | SiteBoxDocument>

  public static new(
    payload: IFolderResponse,
  ): FolderResponseWithExcludedFields {
    const entity = plainToInstance(FolderResponse, {
      ...payload,
      files: [
        ...payload.templates.map((template) => PermitTemplate.new(template)),
        ...payload.checklistTemplates.map((template) =>
          ChecklistTemplate.new(template),
        ),
        ...payload.siteBoxDocuments.map((document) =>
          SiteBoxDocument.new(document),
        ),
      ],
    })

    return entity
  }

  public isRootFolder(): boolean {
    return this.currentFolder.displayName === 'ROOT'
  }

  public getFolderId(): number {
    return this.currentFolder.id
  }

  public getParentFolderId(): number {
    return this.currentFolder.parentId
  }
}
