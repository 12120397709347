import React from 'react'
import { Box, BoxProps } from '@fundamentals'
import { Palette } from 'styles/color-palette-siteassist'

type ProcessTagColorBadgeProps = BoxProps & {
  color: string
}

export const ProcessTagColorBadge: React.FC<ProcessTagColorBadgeProps> = ({
  color,
  sx,
  ...props
}) => {
  function isTooBright(hexColor) {
    if (!hexColor) return false
    hexColor = hexColor?.replace('#', '').toLowerCase()
    return (
      hexColor.substring(0, 1) === 'f' &&
      hexColor.substring(2, 3) === 'f' &&
      hexColor.substring(4, 5) === 'f'
    )
  }

  return (
    <Box
      sx={{
        background: color,
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        border: isTooBright(color) && `0.5px solid ${Palette.Grey[500]}`,
        ...sx,
      }}
      {...props}
    />
  )
}
