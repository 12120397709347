import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  permitTemplatesKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplates'

export const useUpdatePermitTemplateRole = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['updatePermitRole']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...permitTemplatesKeys.updatePermitRole,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: permitTemplatesKeys.getRoles({
          templateId: variables.templateId,
          templateVersionId: variables.templateVersionId,
        }).queryKey,
      })
    },
    ...options,
  })
}
