import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { LinkedProcessItemResult } from '@core/entities/linkedProcesses'

const permitLinkedProcessTransform: (data) => Array<LinkedProcessItemResult> = (
  data,
) => {
  return data?.linkedProcessItemResults.map((linkedProcessItemResult) => {
    return LinkedProcessItemResult.new(linkedProcessItemResult)
  })
}

export const useGetPermitLinkedProcesses = (
  payload: RequestTypes['getPermitLinkedProcesses'],
  options?: UseQueryOptions<LinkedProcessItemResult[], string>,
) => {
  return useQuery({
    select: permitLinkedProcessTransform,
    ...permitKeys.byId(payload)._ctx.linkedProcesses,
    ...options,
  })
}
