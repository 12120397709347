import { Type, plainToInstance } from 'class-transformer'
import { FolderPath } from '@core/entities/folders/FolderResponse'
import { Entity } from '@core/entities/entity'
import { User } from '@core/entities/user'
import {
  ISiteBoxDocument,
  PermittedAction,
  StatusType,
} from '@core/entities/SiteBoxDocument'
import { localizedStrings } from '@core/strings'
import { SiteBoxDocumentPdfVersion } from '@core/entities/SiteBoxDocumentPdfVersion'
import { SiteBoxDocumentVersionBase } from '@core/entities/SiteBoxDocumentVersionBase'

export class SiteBoxDocument extends Entity<ISiteBoxDocument> {
  id: number
  name: string

  @Type(() => User)
  modifiedBy: User

  modifiedOn: string

  @Type(() => User)
  createdBy: User

  createdOn: string
  folder: {
    folderId: number
    pathFromRoot: FolderPath
  }
  status: StatusType
  permittedActions: PermittedAction[]

  @Type(() => SiteBoxDocumentVersionBase, {
    discriminator: {
      property: 'propertyType',
      subTypes: [{ value: SiteBoxDocumentPdfVersion, name: 'PDF' }],
    },
    keepDiscriminatorProperty: true,
  })
  currentVersion?: SiteBoxDocumentPdfVersion

  totalVersions?: number

  public static new(payload: ISiteBoxDocument) {
    const entity = plainToInstance(SiteBoxDocument, payload)

    return entity
  }

  public getStatusColor() {
    switch (this.status) {
      case 'LIVE':
        return 'success'
      case 'ARCHIVED':
        return 'default'
      default:
        return 'default'
    }
  }

  public getStatusText() {
    switch (this.status) {
      case 'LIVE':
        return localizedStrings.live
      case 'ARCHIVED':
        return localizedStrings.archived
      default:
        return this.status
    }
  }

  public isLive() {
    return this.status === 'LIVE'
  }
}
