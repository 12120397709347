import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'
import { IChecklistTemplateRole } from './IChecklistTemplateRole'

export class ChecklistTemplateRole extends Entity<IChecklistTemplateRole> {
  id: number
  templateVersionId: number
  name: string
  description?: string
  briefingText?: string
  permissions: Array<
    | 'ADD_PARTICIPANTS'
    | 'UPLOAD_MEDIA'
    | 'LIST_CHECKLIST_TEMPLATE_VERSION_ROLES'
  >
  groupIds?: Array<number>
  @Type(() => User)
  createdBy: User
  createdOn: string
  @Type(() => User)
  modifiedBy: User
  modifiedOn: string

  public static new(payload: unknown): ChecklistTemplateRole {
    const entity = plainToInstance(ChecklistTemplateRole, payload)

    return entity
  }
}
