import { localizedStrings } from '@core/strings'
import { Card, CardProps, Chip } from '@common/material'
import React from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { Section } from '@common/Section'
import moment from 'moment'
import EventAvailableOutlined from '@mui/icons-material/EventAvailableOutlined'
import MapOutlined from '@mui/icons-material/MapOutlined'
import { User } from '@core/entities/user'
import { Checklist, ChecklistStatus } from '@core/entities/checklist/Checklist'

type ChecklistCardProps = Omit<CardProps, 'id'> & {
  templateName: string
  summary: string
  status: ChecklistStatus
  location?: string
  createdBy: User
  createdOn?: string
  icon?: React.ReactNode
}

export const ChecklistCard: React.FC<ChecklistCardProps> = ({
  templateName,
  summary,
  status,
  createdOn,
  createdBy,
  location,
  icon,
  sx = {},
  children,
  ...props
}) => {
  return (
    <Card
      sx={{
        pl: 2,
        pr: icon ? 1.5 : 2,
        py: 1.5,
        ...sx,
      }}
      {...props}
    >
      <Flex>
        <Box flex={1}>
          <Text fontSize={13} color='text.secondary'>
            {templateName}
          </Text>
          <Text fontSize={15}>{summary}</Text>
          <Chip
            color={Checklist.getStatusChipColor(status)}
            label={Checklist.getStatusString(status)}
            sx={{ mt: 0.5, mb: 1 }}
            size='small'
          />
          {!!location && (
            <Section
              icon={<MapOutlined sx={{ fontSize: 16, color: 'grey' }} />}
              label={localizedStrings.location}
              horizontal
            >
              <Text fontSize={14.5}>{location}</Text>
            </Section>
          )}
          {!!createdOn && (
            <Section
              label={localizedStrings.created}
              icon={
                <EventAvailableOutlined sx={{ fontSize: 16, color: 'grey' }} />
              }
              horizontal
            >
              <Text sx={{ fontSize: 14.5 }}>
                {moment(createdOn).format('DD MMM YYYY HH:mm')}
              </Text>
            </Section>
          )}
          <Section label={localizedStrings.createdBy} mt={0.5}>
            <Text fontSize={14.5}>{createdBy.getFullName()}</Text>
          </Section>
        </Box>
        {icon && <Box>{icon}</Box>}
      </Flex>
      {children}
    </Card>
  )
}
