import {
  projectKeys,
  RequestTypes,
} from '@core/react-query/features/projects/project'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { IProjectUser, ProjectUser } from '@core/entities/user'

const dataTransformer = (user: IProjectUser) => ProjectUser.new(user)

export const useProjectUser = (
  payload: RequestTypes['getProjectUser'],
  options: UseQueryOptions<IProjectUser, string, ProjectUser> = {},
) => {
  return useQuery({
    select: dataTransformer,
    ...projectKeys.byId(payload)._ctx.projectUser(payload),
    ...options,
  })
}
