import { localizedStrings } from '@core/strings'
import React from 'react'
import { ButtonText } from '../../Button'
import { Box, BoxProps } from '@fundamentals'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'

type ComponentType = BoxProps & {
  icon: string
  secondaryIcon?: string
  children
  disabled: boolean
  rootProps: DropzoneRootProps
  inputProps: DropzoneInputProps
  proofRequired: boolean
  proofRequiredText: string
  proofOptionalText?: string
}

const UploadDropBox: React.FC<ComponentType> = ({
  icon,
  secondaryIcon,
  children,
  disabled,
  rootProps,
  inputProps,
  proofRequired,
  proofRequiredText,
  proofOptionalText = localizedStrings.proofOptional,
  ...props
}) => {
  return (
    <Box className='upload' {...rootProps} {...props}>
      <Box>
        <input {...inputProps} />
        <ButtonText
          icon={icon}
          secondaryIcon={secondaryIcon}
          disabled={disabled}
        >
          {children}
        </ButtonText>
        <p className='text-center mb-0 upload__limitbytes-text'>
          {proofRequired ? (
            <span>
              <span className='text-red text-bold'>*</span>
              {proofRequiredText}
            </span>
          ) : (
            <span>{proofOptionalText}</span>
          )}
        </p>
      </Box>
    </Box>
  )
}

export default UploadDropBox
