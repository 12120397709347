import { plainToInstance, Type } from 'class-transformer'
import { IRegisterSettings } from './IRegisterSettings'
import { Entity } from '@core/entities/entity'
import { RegisterSettingsAuthGroups } from '../RegisterSettingsAuthGroup'
import { RegisterItemPermissions } from './types'
import { RegisterTrigger } from '@core/entities/register/RegisterTrigger'

export class RegisterSettings extends Entity<IRegisterSettings> {
  userPropertySettings: {
    userProperty: {
      id: number
      name: string
    }
    authGroups: RegisterSettingsAuthGroups['authGroups']
  }[]

  itemPermissions: {
    permission: RegisterItemPermissions
    authGroups: RegisterSettingsAuthGroups['authGroups']
  }[]

  @Type(() => RegisterTrigger)
  triggers: RegisterTrigger[]

  public static new(payload: unknown): RegisterSettings {
    const entity = plainToInstance(RegisterSettings, payload)

    return entity
  }
}
