import { localizedStrings } from '@core/strings'
import { ProjectUser } from '@core/entities/user'
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import { useModal } from '@common/GlobalModal'
import { TableUserSummaryCard } from '@common/Table'
import { Project } from '@core/entities/project'
import { useSetProjectUserStatus } from '@core/react-query/features/projects/project/hooks/useSetProjectUserStatus'
import { Flex, Text } from '@fundamentals'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

type DisableProjectUserModalProps = {
  project: Project
  user: ProjectUser
}

export const DisableProjectUserModal: React.FC<
  DisableProjectUserModalProps
> = ({ project, user }) => {
  const { hideModal } = useModal()
  const toast = useToast()
  const { mutate } = useSetProjectUserStatus()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.disableUser} – {project.name}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Flex flexDirection={'column'}>
          <Alert severity='error'>
            <Text mb={1}>{localizedStrings.disableUserWarning}</Text>
            <Text mb={2}>
              {localizedStrings.userSettingsOnOtherProjectsUnchanged}
            </Text>
            <TableUserSummaryCard user={user} />
          </Alert>
        </Flex>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          data-test='confirm-yes'
          color={'error'}
          onClick={() => {
            mutate(
              {
                projectId: project.id,
                userId: user.id,
                status: 'DISABLED',
              },
              {
                onSuccess: () => {
                  toast.success(localizedStrings.userDisabled)
                  hideModal()
                },
                onError: (e) => toast.error(e),
              },
            )
          }}
        >
          {localizedStrings.disableUser}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
