import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  permitTemplateEditorKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplateEditor'

export const useAddPermitTemplateVersionPeriodicChecklist = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['addPeriodicChecklist']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...permitTemplateEditorKeys.addPeriodicChecklist,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        permitTemplateEditorKeys.periodicChecklists._def,
        {},
      )
    },
    ...options,
  })
}
