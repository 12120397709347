import React from 'react'
import { Flex } from '@fundamentals'
import { localizedStrings } from '@core/strings'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'
import { TimelineBlockBase } from '@common/Timeline'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineMediaBlock: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  return (
    <TimelineBlockBase title={localizedStrings.proofs}>
      <Flex flexWrap='wrap' my={2}>
        <AttachmentInput
          size='small'
          inputProps={{
            value: auditResult.metadata.mediaUrlsWithType,
          }}
          readOnly
          borderLess
        />
      </Flex>
    </TimelineBlockBase>
  )
}
