import { MapRef } from 'react-map-gl'

/**
 * Sets up hover effects for a map layer.
 *
 * @param {mapboxgl.Map} map - The Mapbox map instance.
 * @param {string | Array<string>} layerIds - The ID(s) of the layer(s) to attach hover effects.
 * @param {string | Array<string>} sourceIds - The source ID(s) associated with the layer(s).
 * @param {(id: string | null, eventType: 'select' | 'deselect') => void} onHoverCallback - Callback function triggered on hover events.
 */
export function setupHoverEffect(
  map: MapRef,
  layerIds: string | Array<string>,
  sourceIds: string | Array<string>,
  onHoverCallback: (
    id: string | number | null,
    eventType: 'select' | 'deselect',
  ) => void,
) {
  let previouslyHoveredEntityId = null
  let previouslyHoveredSourceId = null

  const handleMouseMove = (e) => {
    if (e.features.length > 0) {
      const { id, source } = e.features[0]

      // Check if the source of the hovered feature is one of the specified sources
      if (!sourceIds.includes(source)) return

      if (
        previouslyHoveredEntityId !== null &&
        previouslyHoveredSourceId !== null
      ) {
        map.setFeatureState(
          { source: previouslyHoveredSourceId, id: previouslyHoveredEntityId },
          { selected: false },
        )
      }

      previouslyHoveredEntityId = id
      previouslyHoveredSourceId = source

      map.setFeatureState({ source, id }, { selected: true })
      onHoverCallback && onHoverCallback(id, 'select')
    }
  }

  const handleMouseLeave = () => {
    if (
      previouslyHoveredEntityId !== null &&
      previouslyHoveredSourceId !== null
    ) {
      map.setFeatureState(
        { source: previouslyHoveredSourceId, id: previouslyHoveredEntityId },
        { selected: false },
      )
    }
    previouslyHoveredEntityId = null
    previouslyHoveredSourceId = null
    onHoverCallback && onHoverCallback(null, 'deselect')
  }

  map.on('mousemove', layerIds, handleMouseMove)
  map.on('mouseleave', layerIds, handleMouseLeave)

  // Return cleanup function
  return () => {
    map.off('mousemove', layerIds, handleMouseMove)
    map.off('mouseleave', layerIds, handleMouseLeave)
  }
}
