import { useEffect, useState } from 'react'
import { Flex } from '@fundamentals'
import { useRouter } from 'next/router'
import { ArrayDataType, NavigationTabsPropsType, TabBuilder } from './types'
import { Tab, Tabs } from '@common/material'

export const NavigationAwareTabs: React.FC<NavigationTabsPropsType> & {
  TabBuilder: TabBuilder
} = ({
  tabs,
  initialTab = 0,
  onChangeTab = () => {},
  tabParam = 'tab',
  fullHeightContent,
  children,
  queryOnChangeTab,
  tabHeaderSx,
  isModal,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(initialTab)
  const [tabLength, setTabLength] = useState(tabs.length)
  const router = useRouter()
  const tabString = !isModal ? router.query[tabParam] : undefined

  const changeTab: (tab: { name?: string }, index) => void = async (
    { name },
    index,
  ) => {
    await setActiveTab(index)
    await onChangeTab(index, name)

    if (!isModal) {
      if (queryOnChangeTab) {
        router.query = queryOnChangeTab(router.query)
      }

      if (name) {
        router.query[tabParam] = name
        router.push(router, undefined, { shallow: true })
      }
    }
  }

  const goToFirstTab = (pushUrl = false) => {
    setActiveTab(0)
    onChangeTab(0, tabs[0]?.name)
    if (isModal) {
      return
    }
    if (pushUrl && tabs[0]?.name) {
      router.query[tabParam] = tabs[0]?.name
      router.push(router, undefined, { shallow: true })
    }
  }

  useEffect(() => {
    if (tabString) {
      const index = tabs.findIndex((tab) => tab?.name === tabString)
      if (index !== -1 && index !== activeTab) {
        setActiveTab(index)
        onChangeTab(index, tabString as string)
      }
    } else {
      goToFirstTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  useEffect(() => {
    const resetTabs = () => {
      setTabLength(tabs.length)
      goToFirstTab(true)
    }

    if (tabLength !== tabs.length) {
      resetTabs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs])

  useEffect(() => {
    if (!tabString) {
      goToFirstTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDirection='column' alignItems='flex-start' flex={1} {...props}>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => changeTab(tabs[newValue], newValue)}
        sx={{
          width: '100%',
          borderBottom: 1,
          borderColor: 'divider',
          ...tabHeaderSx,
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            label={tab.label}
            key={index}
            data-test={`tab${tab.dataTest || index}`}
            sx={{
              textTransform: 'none',
              '&:focus': {
                outline: 'none',
              },
            }}
          />
        ))}
      </Tabs>
      <Flex
        flexDirection='column'
        role='tabpanel'
        width='100%'
        pt={2}
        flex={fullHeightContent ? 1 : null}
      >
        {tabs[activeTab]?.component}
      </Flex>
    </Flex>
  )
}

NavigationAwareTabs.TabBuilder = function () {
  const tabs = [] as NavigationTabsPropsType['tabs']
  const builder = {
    addTab: (tab: ArrayDataType<NavigationTabsPropsType['tabs']>) => {
      tabs.push(tab)
      return builder
    },
    build: () => tabs,
  }
  return builder
}
