import React from 'react'
import { OrganisationRole, OrganisationUser } from '@core/entities/user'
import { Chip } from '@common/material'

type OrganisationUserPermissionsChipsProps = {
  roles: OrganisationRole[]
}

export const OrganisationUserPermissionsChips: React.FC<
  OrganisationUserPermissionsChipsProps
> = ({ roles }) => {
  return (
    <>
      {roles?.map((role) => (
        <Chip
          sx={{ mr: 1 }}
          color={'primary'}
          variant={'outlined'}
          key={role}
          label={OrganisationUser.roleString(role)}
        />
      ))}
    </>
  )
}
