import { localizedStrings } from '@core/strings'
import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Flex, Text } from '@fundamentals'
import { DropdownButton, CircularProgress } from '@common/material'
import { useRouter } from 'next/router'
import { OrganisationsMenuProps } from './types'
import {
  ArrowDropDown,
  CorporateFareOutlined,
  SupervisedUserCircle,
} from '@mui/icons-material'
import { alpha, experimental_sx as sx } from '@mui/material/styles'
import { PageUrls } from '@core/page-urls'
import { useDeepCompareCallback } from 'use-deep-compare'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { useGetOrganisation } from '@core/react-query/features/organisations/organisation/hooks/useGetOrganisation'

export const StyledOrganisationButton = styled(Flex)`
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid;
  ${sx({
    borderColor: 'grey.400',
    pb: 1,
    '&:hover': {
      bgcolor: alpha('#000', 0.04),
    },
  })}
`

const OrganisationMenu: React.FC<OrganisationsMenuProps> = ({
  organisationId,
  collapse = false,
}) => {
  const { data: profile, isFetching: profileLoading } = useGetProfile()
  const { data: organisation, isFetching: organisationLoading } =
    useGetOrganisation({ organisationId })
  const router = useRouter()

  const renderOrganisationButton = useDeepCompareCallback(
    (onClick) => {
      return (
        <StyledOrganisationButton onClick={onClick}>
          <Box ml={1}>
            <CorporateFareOutlined />
          </Box>
          {!collapse && (
            <>
              <Flex
                flex={1}
                flexDirection='column'
                justifyContent='center'
                fontSize='16px'
              >
                {profileLoading ||
                !profile ||
                !organisation ||
                organisationLoading ? (
                  <CircularProgress size={18} sx={{ color: 'common.white' }} />
                ) : (
                  <>
                    <Text pl={1} mt={0.5} fontWeight='bold' lineHeight='1.2'>
                      {organisation.name || ''}
                    </Text>
                  </>
                )}
              </Flex>
              <ArrowDropDown />
            </>
          )}
        </StyledOrganisationButton>
      )
    },
    [collapse, organisation, profile, profileLoading],
  )

  return (
    <>
      <DropdownButton
        label={localizedStrings.organisation}
        options={profile?.organisations?.map((org) => ({
          title: org.name,
          value: org.id,
          icon: <SupervisedUserCircle />,
        }))}
        onSelect={(orgId) => router.push(PageUrls.organisation(orgId))}
        fullWidth
        renderButton={renderOrganisationButton}
        collapsed={collapse}
      />
    </>
  )
}

export { OrganisationMenu }
