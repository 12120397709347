import _ from 'lodash'
import React, { useMemo } from 'react'
import { Box, Text } from '@fundamentals'
import { QuestionWrapper } from './QuestionWrapper'
import { Card } from '@common/material'
import { useTheme } from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { QuestionGroupProps } from '@core/components/templateAnswersForm'
import { ConditionalQuestionGroup } from './ConditionalQuestionGroup'

const QuestionGroup: React.FC<QuestionGroupProps> = ({
  questionGroup: { conditionalQuestionGroup, ...questionGroup },
  questionsFormKey = 'questions',
  groupIndex,
  readOnly,
  warnings,
  onlyShowWarnings,
}) => {
  const theme: any = useTheme()

  const formContext = useFormContext()

  const hiddenQuestionIds: Array<string> = !formContext
    ? null
    : useWatch({
        name: 'hiddenQuestionIds',
      })

  const questionIndicesById = useMemo(() => {
    return questionGroup.data.reduce((acc, question, index) => {
      acc[question.id] = index
      return acc
    }, {})
  }, [])

  const visibleQuestionIdsSet = useMemo(() => {
    const hiddenQuestionIdsSet = new Set(
      hiddenQuestionIds ||
        questionGroup.data
          .filter((question) => question.isHidden)
          .map((question) => question.id),
    )
    const visibleQuestionIds = questionGroup.data.reduce((acc, question) => {
      if (!hiddenQuestionIdsSet.has(question.id)) acc.add(question.id)

      return acc
    }, new Set<string>())

    return visibleQuestionIds
  }, [hiddenQuestionIds])

  const lastQuestionIndex = useMemo(() => {
    return _.findLastIndex(questionGroup.data, (question) => {
      return visibleQuestionIdsSet.has(question.id)
    })
  }, [visibleQuestionIdsSet])

  const isQuestionGroupHidden =
    (hiddenQuestionIds === null && questionGroup?.isHidden) ||
    hiddenQuestionIds?.includes(questionGroup.id) ||
    (visibleQuestionIdsSet?.size === 0 && !conditionalQuestionGroup)

  const questionGroupWarnings = useMemo(() => {
    return warnings?.filter((warning) =>
      visibleQuestionIdsSet?.has(warning?.questionId),
    )
  }, [warnings, visibleQuestionIdsSet])

  if (isQuestionGroupHidden) return null

  if (onlyShowWarnings && !questionGroupWarnings?.length) {
    // Hide groups that have no warnings if the onlyShowWarnings flag is passed
    return null
  }

  return (
    <Card
      sx={{
        mb: 2,
        p: 3,
      }}
      data-test={`question-group-${groupIndex}`}
      data-id={questionGroup.id}
    >
      {questionGroup.title && (
        <Text
          sx={{
            color: 'info.light',
            fontSize: '12px',
            mr: 2,
            mb: 1,
          }}
        >
          {questionGroup.title}
        </Text>
      )}

      {conditionalQuestionGroup &&
        (!formContext ? (
          <ConditionalQuestionGroup
            conditionalQuestionGroup={conditionalQuestionGroup}
            readOnly={readOnly}
          />
        ) : (
          <Controller
            name={`${questionsFormKey}.${groupIndex}.conditionalQuestionGroup.submissionText`}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => {
              return (
                <Box
                  sx={{
                    mt: error ? 2 : 0,
                    outlineWidth: error ? '2px' : '0',
                    // @ts-ignore
                    outlineColor: theme.palette.error.main,
                    outlineStyle: 'solid',
                    outlineOffset: '5px',
                    borderRadius: 0.1,
                    transition: 'all 0.3s',
                    padding: error ? 1.5 : 0,
                    pb: error ? 2 : 0,
                  }}
                >
                  <ConditionalQuestionGroup
                    conditionalQuestionGroup={conditionalQuestionGroup}
                    inputProps={field}
                    readOnly={readOnly}
                  />
                </Box>
              )
            }}
          />
        ))}
      {questionGroup.data.map((question, questionIndex) => {
        if (!visibleQuestionIdsSet?.has(question.id)) return null
        if (
          onlyShowWarnings &&
          !questionGroupWarnings?.find(
            (warning) => warning.questionId === question.id,
          )
        ) {
          return null
        }

        const isLastQuestion = questionIndex === lastQuestionIndex

        return (
          <Box key={question.id} mb={!isLastQuestion && 2}>
            <QuestionWrapper
              groupIndex={groupIndex}
              questionIndex={questionIndex}
              questionNumber={questionIndicesById[question.id] + 1}
              answer={!formContext && question}
              readOnly={readOnly}
              questionsFormKey={questionsFormKey}
              warnings={warnings?.filter(
                (warning) => warning?.questionId === question?.id,
              )}
            />
          </Box>
        )
      })}
    </Card>
  )
}

export { QuestionGroup }
