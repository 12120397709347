import { localizedStrings } from '@core/strings'
import React from 'react'
import { Button } from '@common/material'
import { NextRouter, useRouter } from 'next/router'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import FullscreenIcon from '@mui/icons-material/Fullscreen'

export const FullScreenToggle = () => {
  const router = useRouter()

  const isFullscreen = (router: NextRouter): boolean => {
    return router.query?.fullScreen === 'true'
  }

  return (
    <Button
      variant='text'
      size='small'
      endIcon={
        isFullscreen(router) ? <FullscreenExitIcon /> : <FullscreenIcon />
      }
      onClick={() => {
        router.query.fullScreen = `${!isFullscreen(router)}`
        router.push(router, undefined, { shallow: true })
      }}
    >
      {isFullscreen(router)
        ? localizedStrings.minimise
        : localizedStrings.fullScreen}
    </Button>
  )
}
