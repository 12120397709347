import { plainToInstance } from 'class-transformer'
import { FilterBase } from '@core/entities/filters/FilterBase'
import { INumberFilter } from '@core/entities/filters/NumberFilter/INumberFilter'
import { NumberFilterOperatorTypes } from '../types'

export class NumberFilter extends FilterBase<INumberFilter> {
  propertyType: 'NUMBER'
  operator: NumberFilterOperatorTypes
  numberValue: number

  public static new(payload: unknown): NumberFilter {
    return plainToInstance(NumberFilter, payload)
  }

  public getNumberValue(): number {
    return this.numberValue
  }
}
