import React from 'react'
import { Link } from '@common/material'
import { localizedStrings } from '@core/strings'
import { Text } from '@fundamentals'
import { User } from '@core/entities/user'
import { TimelineBlockBase } from '@common/Timeline'

type ComponentProps = {
  user: User
  onEdit?: () => void
}

export const TimelineUserBlock: React.FC<ComponentProps> = ({
  user,
  onEdit,
}) => {
  if (!user) return null
  return (
    <TimelineBlockBase title={localizedStrings.user}>
      {onEdit ? (
        <Link
          href=''
          onClick={(e) => {
            e.preventDefault()
            onEdit()
          }}
        >
          {user.getFullName()}
        </Link>
      ) : (
        <Text variant='body2'>{user.getFullName()}</Text>
      )}
      {user.company && <Text fontSize={12}>{user.company}</Text>}
      {user.email && <Text fontSize={12}>{user.email}</Text>}
    </TimelineBlockBase>
  )
}
