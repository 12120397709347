import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { useMutateQueryData } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { IPermitDraft } from '@core/entities/permit/PermitDraft'
import { permitsKeys } from '@core/react-query/features/permits'

export const useMigratePermitDraftToLatestVersion = () => {
  const queryClient = useQueryClient()

  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)

  return useMutation<
    IPermitDraft,
    RequestErrorType,
    RequestTypes['migrateDraftToLatestVersion']
  >({
    ...permitKeys.migrateDraftToLatestVersion,
    onSuccess: (draft) => {
      queryClient.setQueryData(
        permitKeys.draftById({ draftId: draft.id }).queryKey,
        draft,
      )

      updateInfiniteQueryItem(
        permitsKeys.drafts({
          organisationId: draft.organisationId,
          projectIds: [draft.projectId],
        }).queryKey,
        draft,
      )
    },
  })
}
