import { localizedStrings } from '@core/strings'
import { Entity } from '@core/entities/entity'
import { ITask } from './ITask'
import { plainToInstance } from 'class-transformer'
import { PermittedActions, TaskStatus, TaskType } from './types'
import { User } from '@core/entities/user'

export class Task extends Entity<ITask> {
  id: number

  name: string

  type: TaskType

  status: TaskStatus

  ordinal: number

  permitId: number

  assignedTo: User

  organisationId: number

  projectId: number

  checklistTemplateId?: number

  checklistId?: number
  createdOn: string

  modifiedOn: string

  permittedActions: Array<PermittedActions>

  permitTemplateVersionRoleId?: number
  permitTemplateVersionRoleName?: string
  permitTemplateVersionRoleGroupIds?: Array<number>

  public static new(payload: unknown): Task {
    const entity = plainToInstance(Task, payload)

    return entity
  }

  public static getNumberOfTasksByStatus(
    tasks: ITask[],
    status: TaskStatus,
  ): number {
    return tasks?.filter((task) => task.status === status).length
  }

  public checkTaskType(type: TaskType): boolean {
    return this.type === type
  }

  public checkTaskStatus(status: TaskStatus): boolean {
    return this.status === status
  }

  public checkForMultipleTaskTypes(types: TaskType[]): boolean {
    return types.includes(this.type)
  }

  public checkForMultipleTaskStatuses(statuses: TaskStatus[]): boolean {
    return statuses.includes(this.status)
  }

  public getStatusText(status: TaskStatus): string {
    switch (status) {
      case 'TO_DO':
        return localizedStrings.toDo
      case 'DONE':
        return localizedStrings.completed
      case 'CANCELLED':
        return localizedStrings.cancelled
      default:
        return ''
    }
  }

  public getStatusColor() {
    switch (this.status) {
      case 'TO_DO':
        return 'warning'
      case 'DONE':
        return 'primary'
      case 'CANCELLED':
        return 'error'
      default:
        return 'warning'
    }
  }

  public hasPermissionsToCompleteAction() {
    return this.permittedActions.includes('DO_TASK')
  }
}
