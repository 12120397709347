import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'

const projectQueryKeys = createQueryKeys('projects', {
  getAllProjects: (payload: RequestTypes['getAllProjects']) => {
    return {
      queryKey: [
        payload.organisationId,
        payload.businessUnitId,
        payload.excludeBusinessUnitId,
        payload.status,
      ],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects${createQueryKeyString({
            ...payload,
            // This hook is used when we want to fetch all the projects so we pass a large page size
            size: 10000,
          })}`,
        )

        return data
      },
    }
  },
  getProjects: (payload: RequestTypes['getProjects']) => {
    return {
      queryKey: [payload],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects${createQueryKeyString({
            ...payload,
          })}`,
        )

        return data
      },
    }
  },
})

const projectMutationKeys = createMutationKeys('projects', {})

export const projectsKeys = mergeQueryKeys(
  projectQueryKeys,
  projectMutationKeys,
).projects
