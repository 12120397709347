export const selectPSPDFKit = (state) => state.context.PSPDFKit

export const selectInstance = (state) => state.context.instance

export const selectProjectPlan = (state) => state.context.projectPlan

export const selectProjectPlanPSPDFAreas = (state) =>
  state.context.projectPlanPSPDFAreas ?? []

export const selectProjectPlanPSPDFSelectedAreas = (state) =>
  state.context.selectedAreas ?? []

export const selectRequestAreas = (state) => state.context.requestAreas

export const selectColorOpacity = (state) => state.context.colorOpacity
