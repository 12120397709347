import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { RequestTypes } from './requestTypes'

const projectLocationsQueryKeys = createQueryKeys('project-locations', {
  byProject: ({ projectId, statuses }: RequestTypes['getProjectLocations']) => {
    const statusesQueryParam = statuses ? `statuses=${statuses}` : ''
    return {
      queryKey: [projectId, statuses],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects/${projectId}/locations/nodes?${statusesQueryParam}`,
          {},
        )

        return data
      },
    }
  },
  byProjectFiltered: ({
    projectId,
  }: RequestTypes['useGetProjectLocationsCreateList']) => {
    return {
      queryKey: [projectId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects/${projectId}/locations/create-list`,
          {},
        )

        return data
      },
    }
  },
})

const projectLocationsMutationKeys = createMutationKeys('project-locations', {
  createProjectLocation: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createProjectLocation']) => {
      const { data } = await APIAxiosInstance.post(
        `projects/${payload.projectId}/locations`,
        {
          ...payload,
        },
      )

      return data
    },
  },
  archiveProjectLocation: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['archiveProjectLocation']) => {
      const { data } = await APIAxiosInstance.post(
        `projects/${payload.projectId}/locations/${payload.id}/archive`,
        {
          ...payload,
        },
      )

      return data
    },
  },
  enableProjectLocation: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['enableProjectLocation']) => {
      const { data } = await APIAxiosInstance.post(
        `projects/${payload.projectId}/locations/enable`,
        {
          ...payload,
        },
      )

      return data
    },
  },
  disableProjectLocation: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['disableProjectLocation']) => {
      const { data } = await APIAxiosInstance.post(
        `projects/${payload.projectId}/locations/disable`,
        {
          ...payload,
        },
      )

      return data
    },
  },
  updateProjectLocation: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateProjectLocation']) => {
      const { data } = await APIAxiosInstance.put(
        `projects/${payload.projectId}/locations/${payload.id}`,
        {
          ...payload,
        },
      )

      return data
    },
  },
})

const projectLocationsKeys = mergeQueryKeys(
  projectLocationsQueryKeys,
  projectLocationsMutationKeys,
)['project-locations']

export { projectLocationsKeys }
