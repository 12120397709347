import { createQueryKeys } from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'

export const imageDetails = createQueryKeys('image-details', {
  byImageKeys: (payload: RequestTypes['useGetImageDetails']) => {
    return {
      queryKey: [payload.imageKeys],
      queryFn: async () => {
        const queryString = payload.imageKeys
          .map((key) => `s3_keys=${key}`)
          .join('&')

        const { data } = await APIAxiosInstance.get(
          `${Project.apiBaseUrl}data/image-details/details?${queryString}`,
          {},
        )

        return data
      },
    }
  },
})
