import { localizedStrings } from '@core/strings'
import {
  Alert,
  Card,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import { Clear, EditOutlined } from '@mui/icons-material'
import React from 'react'
import { useModal } from '@common/GlobalModal'
import { Box, Flex, Text } from '@fundamentals'
import { ProjectUserActions } from '@modules/projectTeamAndRoles/ProjectUserActions'
import { useProjectUser } from '@core/react-query/features/projects/project/hooks/useProjectUser'
import { SupportUserInAppContext } from '@modules/support/SupportUserInAppContext'
import BackdropLoader from '@common/fundamentals/BackdropLoader'
import { Property } from '@elements/Property/Property'
import { UserStatusChip } from '@elements/User/UserStatusChip'
import { useOrganisationUser } from '@core/react-query/features/organisations/organisation/hooks/useOrganisationUser'
import { ProjectUser } from '@core/entities/user'
import { ModalDialogContainer } from '@common/modal'
import { ProjectUserPermissionsSelect } from '@modules/projectTeamAndRoles/ProjectUserPermissionsSelect'
import { ChangeProjectUserPermissions } from '@modules/projectTeamAndRoles/modals/ChangeProjectUserPermissions'
import { useGetProfile } from '@core/react-query/features/profile'
import { Project } from '@core/entities/project'
import { useGetProject } from '@core/react-query/features/projects/project'

type UserDetailsModalProps = {
  organisationId?: number
  project?: Project
  projectId?: number
  userId: number
}

export const ProjectUserDetailsModal: React.FC<UserDetailsModalProps> = ({
  organisationId,
  project: projectProp,
  projectId,
  userId,
}) => {
  const { hideModal, showModal } = useModal()
  const { data: profile } = useGetProfile()
  const { data: projectData, isLoading: projectIsLoading } = useGetProject(
    { projectId },
    { enabled: !!projectId && !projectProp },
  )
  const project = projectProp || projectData

  const { data: projectUser, isFetching: projectUserIsLoading } =
    useProjectUser(
      {
        projectId: project?.id,
        userId: userId,
      },
      { enabled: !!project },
    )

  const { data: organisationUser, isFetching: organisationUserIsLoading } =
    useOrganisationUser(
      {
        organisationId,
        userId: userId,
      },
      { enabled: !!organisationId },
    )
  const isLoading =
    (projectId && (projectIsLoading || projectUserIsLoading)) ||
    (organisationId && organisationUserIsLoading)

  const user = projectUser || organisationUser

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '80%', flex: 1 } }}>
      <BackdropLoader open={isLoading} />
      {!!user && (
        <>
          <DialogTitle
            variant='h5'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ pr: 1, fontSize: 20 }}
          >
            {project?.name} - {projectUser?.getFullName()}
            <Box flexGrow={1} />
            {user && !!project && (
              <ProjectUserActions
                variant={'button'}
                project={project}
                user={user as ProjectUser}
              />
            )}
            <Box data-test={'close-user-details-modal'}>
              <IconButton
                sx={{ ml: 1 }}
                aria-label='close-modal'
                onClick={hideModal}
              >
                <Clear />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent>
            {user && (
              <Box>
                {profile?.isSuperUser() && (
                  <Card sx={{ p: 2, mb: 1 }}>
                    <SupportUserInAppContext userId={user.id} />
                  </Card>
                )}
                <Card sx={{ p: 2, mb: 1 }}>
                  {!project?.canChangeUserRole() && (
                    <Alert sx={{ mb: 1 }} severity='info'>
                      {localizedStrings.newUserInvitedToProjectPermissionsInfo}
                    </Alert>
                  )}
                  <Property
                    heading={localizedStrings.name}
                    subHeading={user.getFullName()}
                  />
                  <Property
                    heading={localizedStrings.company}
                    subHeading={user.company}
                  />
                  <Property
                    heading={localizedStrings.email}
                    subHeading={user.email}
                  />
                  <Property
                    heading={localizedStrings.status}
                    subHeadingComponent={
                      <UserStatusChip status={user.status} />
                    }
                  />
                  <Property
                    heading={localizedStrings.telephoneNumber}
                    subHeading={user.phone || localizedStrings.none}
                  />
                </Card>
                <Card sx={{ p: 2, mb: 1 }}>
                  <Flex mb={1}>
                    <Text
                      sx={{ fontSize: 15, mb: 2 }}
                      color='text.secondary'
                      gutterBottom
                    >
                      {localizedStrings.permissions}
                    </Text>
                    <Box flexGrow={1} />
                    {!!project?.canEdit() && (
                      <IconButton
                        size='small'
                        onClick={() => {
                          showModal(
                            <ChangeProjectUserPermissions
                              project={project}
                              user={user as ProjectUser}
                            />,
                          )
                        }}
                      >
                        <EditOutlined />
                      </IconButton>
                    )}
                  </Flex>
                  {!!project && (
                    <ProjectUserPermissionsSelect
                      project={project}
                      roles={projectUser?.roles}
                      authGroups={projectUser?.authGroups?.map((a) => a.id)}
                      readOnly
                    />
                  )}
                </Card>
              </Box>
            )}
          </DialogContent>
        </>
      )}
    </ModalDialogContainer>
  )
}
