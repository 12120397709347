import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { projectsKeys } from '../projectsKeys'
import { PageResponse } from '@core/entities/pagination'
import { IProjectSummary } from '@core/entities/project'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { ProjectSummary } from '@core/entities/project/ProjectSummary/ProjectSummary'

export const useGetProjects = (
  payload: RequestTypes['getProjects'],
  options?: UseQueryOptions<
    PageResponse<ProjectSummary>,
    RequestErrorType,
    PageResponse<IProjectSummary>
  >,
) => {
  return useQuery({
    select: (response) => {
      return {
        ...response,
        content: response?.content.map((project) =>
          ProjectSummary.new(project),
        ),
      }
    },
    ...projectsKeys.getProjects(payload),
    keepPreviousData: true,
    ...options,
  })
}
