import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { foldersKeys, RequestTypes } from '@core/react-query/features/folders'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  FolderResponse,
  IFolderResponse,
} from '@core/entities/folders/FolderResponse'

const dataTransformer = (response: IFolderResponse) =>
  FolderResponse.new(response)

export const useGetFolder = (
  payload: RequestTypes['getFolder'],
  options?: UseQueryOptions<IFolderResponse, RequestErrorType, FolderResponse>,
) => {
  return useQuery({
    ...foldersKeys.byId(payload),
    enabled: !!payload.folderId,
    select: dataTransformer,
    ...options,
  })
}
