import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { Box, Flex } from '@fundamentals'
import { DialogContent, DialogTitle, IconButton } from '@mui/material'
import * as Icon from '@mui/icons-material'
import PspdfkitComponent from './PspdfkitComponent'
import { useModal } from '@common/GlobalModal'
import { ModalDialogContainer } from '@common/modal'

interface ModalPdfViewer {
  children?: React.ReactNode
  title: React.ReactNode
  onDismiss: () => void
  toggle: () => void
  downloadUrl?: string
  previewUrl: string
  showAcknowledgeButton?: boolean
  onAcknowledge?: () => void
  onSaveAnnotation?: (blob: Blob, url: string) => void
  readOnly?: boolean
}

const zoomConfig = {
  initialState: 100,
  unit: 25,
  maxZoom: 600,
  minZoom: 50,
}

const ModalPdfViewer: React.FC<ModalPdfViewer> = ({
  onDismiss,
  toggle,
  children,
  downloadUrl,
  previewUrl,
  showAcknowledgeButton = false,
  onAcknowledge = () => {},
  readOnly,
  onSaveAnnotation,
}) => {
  const { hideModal } = useModal()
  const onDissmissClick = () => {
    if (onDismiss) {
      onDismiss()
    }
    toggle()
  }
  const initialState = {
    blob: null,
    url: null,
  }
  const [annotationData, setAnnotationData] = useState(initialState)
  const [download, setDownload] = React.useState<boolean>(false)
  const [zoom, setZoom] = useState(zoomConfig.initialState)
  const src = `${previewUrl}#toolbar=0&navpanes=0&scrollbar=0&zoom=${zoom}`

  const handleZoomIn = () => {
    if (zoom < zoomConfig.maxZoom) {
      setZoom(zoom + zoomConfig.unit)
    }
  }

  const handleZoomOut = () => {
    if (zoom > zoomConfig.minZoom) {
      setZoom(zoom - zoomConfig.unit)
    }
  }

  React.useEffect(() => {
    if (download) {
      setTimeout(() => {
        setDownload(false)
      }, 500)
    }
  }, [download])

  const handleSaveAnnotation = async () => {
    console.log('annotationData - CALLED', annotationData)
    await onSaveAnnotation(annotationData.blob, annotationData.url)
    onDissmissClick()
    setAnnotationData(initialState)
  }

  const saveButton = {
    type: 'custom',
    id: 'save-button',
    title: 'Save Changes',
    onPress: handleSaveAnnotation,
  }

  const handleAnnotations = (blob, url) => {
    setAnnotationData({ blob, url })
  }

  React.useEffect(() => {}, [annotationData])

  return (
    <ModalDialogContainer
      PaperProps={{
        sx: {
          maxWidth: '80%',
          minHeight: '80vh',
          maxHeight: '90vh',
        },
      }}
    >
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        <Box>
          {downloadUrl && (
            <Row style={{ width: '100%' }} space>
              <i
                onClick={() => setDownload(true)}
                className='fas fa-download clickable'
                style={{ color: '#a4b5d2' }}
              />
              {download && (
                <embed
                  height={0}
                  width={0}
                  src={downloadUrl}
                  type='application/pdf'
                />
              )}
            </Row>
          )}
        </Box>

        {previewUrl && annotationData.blob && !readOnly && (
          <ButtonPrimary onClick={handleSaveAnnotation}>
            Save changes
          </ButtonPrimary>
        )}
        <IconButton onClick={hideModal}>
          <Icon.Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className='d-flex flex-fill'>
        {previewUrl && (
          <Box sx={{ width: '100%' }} className='PDF-viewer'>
            <PspdfkitComponent
              document={previewUrl}
              readOnly={readOnly}
              onAnnotate={handleAnnotations}
            />
          </Box>
        )}
        {children}
        <Flex
          justifyContent='center'
          mb={5}
          className='pdf-controller-container'
        >
          {showAcknowledgeButton && (
            <ButtonPrimary
              ml={4}
              onClick={() => {
                onAcknowledge()
                hideModal()
              }}
            >
              {localizedStrings.acknowledge}
            </ButtonPrimary>
          )}
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}

export default ModalPdfViewer
