import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { RequestTypes, permitsKeys } from '@core/react-query/features/permits'
import { PageResponse } from '@core/entities/pagination'
import { usePageableQuery } from '@core/react-query/hooks'
import { IPermitBase, PermitBase } from '@core/entities/permit/PermitBase'

const dataTransformer = ({ content }: PageResponse<IPermitBase>) => {
  return content.map((permit) => PermitBase.new(permit))
}

export const useAllOrganisationPermits = (
  payload: RequestTypes['useAllOrganisationPermits'],
  options: UseInfiniteQueryOptions<
    PageResponse<IPermitBase>,
    string,
    Array<PermitBase>
  > = {},
) => {
  const allPermitsQuery = permitsKeys.allPermits(payload)

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: allPermitsQuery.queryKey,
    dataTransformer,
  })
  return useInfiniteQuery<PageResponse<IPermitBase>, string, Array<PermitBase>>(
    {
      ...infiniteQueryOptions,
      ...permitsKeys.allPermits(payload),
      ...options,
    },
  )
}
