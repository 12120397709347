import { commentsKeys, RequestTypes } from '@core/react-query/features/comments'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { IComment } from '@core/entities/comments'
import { PageResponse } from '@core/entities/pagination'
import { getMediaUrlContentType } from '@core/react-query/helpers/mediaHelpers'

export const useCreateComment = (
  options?: UseMutationOptions<
    IComment,
    RequestErrorType,
    RequestTypes['createComment']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...commentsKeys.createComment,
    ...options,
    onSuccess: async (comment, variables) => {
      const queryKey = commentsKeys
        .getComments({
          id: comment.referenceObjectId,
          type: comment.referenceObjectType,
          subType: comment.secondaryReferenceObjectType,
          subTypeId: comment.secondaryReferenceObjectId,
          subTypeIdText: comment.secondaryReferenceObjectIdText,
        })
        .queryKey.slice(0, -4)

      await Promise.all(
        comment.mediaUrls.map(async (mediaUrl) => {
          const attachmentType = await getMediaUrlContentType(mediaUrl)

          if (!comment.mediaUrlsWithType) comment.mediaUrlsWithType = []

          comment.mediaUrlsWithType.push({
            getUrl: mediaUrl,
            type: attachmentType,
          })
        }),
      )

      queryClient.setQueriesData<PageResponse<IComment>>(
        { queryKey },
        (data) => {
          if (!data) return data
          return {
            ...data,
            totalElements: data.totalElements + 1,
            content: [...data.content, comment],
          }
        },
      )
    },
  })
}
