import React from 'react'
import { Text } from '@fundamentals'
import { TimelineBlockBase } from '@common/Timeline'

type ComponentProps = {
  title: string
  text: string
}

export const TimelineTextBlock: React.FC<ComponentProps> = ({
  title,
  text,
}) => {
  return (
    <TimelineBlockBase title={title}>
      <Text variant='body2'>{text}</Text>
    </TimelineBlockBase>
  )
}
