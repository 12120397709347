import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { AuditResult, IAuditResult } from '@core/entities/audit/AuditResult'

const auditResultTransform: (
  data: Array<IAuditResult>,
) => Array<AuditResult> = (data) => {
  return data.map((audit) => AuditResult.new(audit))
}

export const useChecklistTimeline = (
  { checklistId }: RequestTypes['getChecklistTimeline'],
  options?: UseQueryOptions<Array<IAuditResult>, string, Array<AuditResult>>,
) => {
  return useQuery({
    select: auditResultTransform,
    ...checklistKeys.byId({ checklistId })._ctx.timeline,
    ...options,
  })
}
