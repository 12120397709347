import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IBusinessUnit } from './IBusinessUnit'
import { User } from '@core/entities/user'

export class BusinessUnit extends Entity<IBusinessUnit> {
  id: number

  name: string

  code: string

  description: string

  organisationId: number

  projectsCount: number

  @Type(() => User)
  createdBy: User

  public static new(payload: unknown): BusinessUnit {
    const entity = plainToInstance(BusinessUnit, payload)

    return entity
  }
}
