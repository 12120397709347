import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  authGroupsKeys,
  RequestTypes,
} from '@core/react-query/features/authGroups'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  GroupPermissionsResponse,
  IGroupPermissionsResponse,
} from '@core/entities/Groups/GroupsPermissions'
import { orderBy } from 'lodash'

const dataTransformer = ({
  groupPermissions: unorderedGroupPermissions,
}: IGroupPermissionsResponse) => {
  const groupPermissions = orderBy(
    unorderedGroupPermissions,
    (p) =>
      p?.templateResult?.name ||
      p?.registerResult?.name ||
      p?.projectResult?.name ||
      p?.organisationResult?.name,
    ['asc'],
  )
  return GroupPermissionsResponse.new({ groupPermissions })
}

export const useGetAuthGroupPermissions = (
  payload: RequestTypes['getGroupPermissions'],
  options?: UseQueryOptions<
    IGroupPermissionsResponse,
    RequestErrorType,
    GroupPermissionsResponse
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...authGroupsKeys.byId(payload)._ctx.getGroupPermissions(payload),
    enabled: !!payload.groupId,
    ...options,
  })
}
