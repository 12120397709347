import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { Box } from '@fundamentals'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useCancelPermit } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'
import { TextEditor } from '@common/TextEditor'

interface ComponentType {
  permitId: number
}

export const CancelPermitModal: React.FC<ComponentType> = ({ permitId }) => {
  const cancelPermit = useCancelPermit()
  const [reason, setReason] = useState<string>(null)
  const { hideModal } = useModal()
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.cancelPermit}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box pb={3}>{localizedStrings.cancelPermitReason}</Box>
        <TextEditor
          value={reason}
          onChange={setReason}
          name='reason'
          placeholderChar={localizedStrings.enterReason}
          plainText
          sx={{ mb: 3 }}
        />
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            cancelPermit.mutate(
              { permitId, reason },
              {
                onSuccess: () => {
                  hideModal()
                  toast.success(localizedStrings.permitCancelled)
                },
                onError: (e) => toast.error(e.message),
              },
            )
          }}
          disabled={!reason?.length}
          data-test='jsCancelPermit'
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
