import {
  AdvancedTextInputFilterInternalArguments,
  AdvancedNumberFilterInternalArguments,
  AnalyticsCustomDateRangeFilterHookArguments,
  AnalyticsCustomDateRangeInternalArguments,
  DateRangeFilterHookArguments,
  DateRangeFilterInternalArguments,
  SelectFilterHookArguments,
  SelectFilterInternalArguments,
  TextInputFilterHookArguments,
  TextInputFilterInternalArguments,
  AdvancedSelectFilterInternalArguments,
  AdvancedDateFilterInternalArguments,
  AdvancedUserFilterInternalArguments,
  AdvancedInspectionFilterInternalArguments,
} from '@core/providers/filters'
import {
  AnalyticsTabFilterHookArguments,
  AnalyticsTabFilterInternalArguments,
} from '@core/providers/filters/fundamentals/hooks/useAnalyticsTabFilter'
import { AdvancedDiagramFilterInternalArguments } from './hooks/useAdvancedDiagramFilter'
import {
  BooleanFilterHookArguments,
  BooleanFilterInternalArguments,
} from './hooks/useBooleanFilter'

export type FilterInternalArguments =
  | SelectFilterInternalArguments<unknown, boolean | undefined>
  | TextInputFilterInternalArguments
  | DateRangeFilterInternalArguments
  | AnalyticsCustomDateRangeInternalArguments
  | AdvancedNumberFilterInternalArguments
  | AdvancedTextInputFilterInternalArguments
  | AdvancedSelectFilterInternalArguments<unknown>
  | AdvancedDateFilterInternalArguments
  | AdvancedUserFilterInternalArguments<unknown>
  | AdvancedDiagramFilterInternalArguments<unknown>
  | AdvancedInspectionFilterInternalArguments<unknown>
  | AnalyticsTabFilterInternalArguments
  | BooleanFilterInternalArguments

export type FilterHookArguments =
  | SelectFilterHookArguments<unknown, boolean | undefined>
  | TextInputFilterHookArguments
  | DateRangeFilterHookArguments
  | AnalyticsCustomDateRangeFilterHookArguments
  | AdvancedNumberFilterInternalArguments
  | AdvancedTextInputFilterInternalArguments
  | AdvancedSelectFilterInternalArguments<unknown>
  | AdvancedDateFilterInternalArguments
  | AdvancedUserFilterInternalArguments<unknown>
  | AdvancedDiagramFilterInternalArguments<unknown>
  | AdvancedInspectionFilterInternalArguments<unknown>
  | AnalyticsTabFilterHookArguments
  | BooleanFilterHookArguments

export type FilterMap = {
  [key: string]: FilterHookArguments
}

export type FilterValueMap<T extends FilterMap> = Partial<{
  [filterKey in keyof T]: Parameters<T[filterKey]['onChange']>[0]
}>

export type FilterMapInternal = {
  [key: string]: FilterInternalArguments
}

export type FilterValueMapInternal<T extends FilterMapInternal> = Partial<{
  [filterKey in keyof T]: Parameters<T[filterKey]['onChange']>[0]
}>

export const AdvancedFilterTypes = [
  'ADVANCED_NUMBER',
  'ADVANCED_TEXT_INPUT',
  'ADVANCED_SELECT',
  'ADVANCED_DATE',
  'ADVANCED_USER',
  'ADVANCED_DIAGRAM',
  'ADVANCED_INSPECTION',
]
