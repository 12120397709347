import React from 'react'
import { Flex } from '@fundamentals'
import { Chip } from '@common/material'
import { localizedStrings } from '@core/strings'
import EastIcon from '@mui/icons-material/East'
import { TimelineBlockBase } from '@common/Timeline'
import { PermitStatus } from '@core/entities/permit/PermitBase'

type ComponentProps = {
  permitStatusBefore: PermitStatus
  permitStatusAfter: PermitStatus
}

export const TimelinePermitStatusTrailBlock: React.FC<ComponentProps> = ({
  permitStatusAfter,
  permitStatusBefore,
}) => {
  if (permitStatusAfter === permitStatusBefore) {
    return null
  }
  return (
    <TimelineBlockBase title={localizedStrings.statusTrail}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Chip
          size='small'
          color={Utils.getPermitStatusColor(permitStatusBefore)}
          label={Utils.getTextForStatus(permitStatusBefore)}
        />
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Chip
          size='small'
          color={Utils.getPermitStatusColor(permitStatusAfter)}
          label={Utils.getTextForStatus(permitStatusAfter)}
        />
      </Flex>
    </TimelineBlockBase>
  )
}
