import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { RequestTypes } from './requestTypes'

const projectPlansQueryKeys = createQueryKeys('project-plan', {
  byProjectLocationPlan: ({
    planId,
    projectId,
    nodeId,
  }: RequestTypes['useGetProjectPlan']) => {
    return {
      queryKey: [projectId, nodeId, planId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects/${projectId}/locations/nodes/${nodeId}/plans/${planId}`,
          {},
        )

        return data
      },
    }
  },
  byProject: ({ projectId }: RequestTypes['useGetProjectPlans']) => {
    return {
      queryKey: [projectId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects/${projectId}/locations/plans`,
          {},
        )

        return data.plans
      },
    }
  },
})

const projectPlansMutationKeys = createMutationKeys('project-plan', {
  updateProjectPlan: {
    mutationKey: null,
    mutationFn: async ({
      projectId,
      nodeId,
      planId,
      areas,
    }: RequestTypes['updateProjectPlan']) => {
      const { data } = await APIAxiosInstance.put(
        `projects/${projectId}/locations/nodes/${nodeId}/plans/${planId}`,
        {
          areas,
        },
      )

      return data
    },
  },
  publishProjectPlan: {
    mutationKey: null,
    mutationFn: async ({
      projectId,
      nodeId,
      planId,
    }: RequestTypes['publishProjectPlan']) => {
      const { data } = await APIAxiosInstance.put(
        `projects/${projectId}/locations/nodes/${nodeId}/plans/${planId}/publish`,
        {},
      )

      return data
    },
  },
  deleteProjectPlan: {
    mutationKey: null,
    mutationFn: async ({
      projectId,
      nodeId,
      planId,
    }: RequestTypes['deleteProjectPlan']) => {
      const { data } = await APIAxiosInstance.delete(
        `projects/${projectId}/locations/nodes/${nodeId}/plans/${planId}`,
      )

      return data
    },
  },
})

const projectPlansKeys = mergeQueryKeys(
  projectPlansQueryKeys,
  projectPlansMutationKeys,
)['project-plan']

export { projectPlansKeys }
