import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import {
  businessUnitsKeys,
  RequestTypes,
} from '@core/react-query/features/businessUnits'
import { PageResponse } from '@core/entities/pagination'
import { usePageableQuery } from '@core/react-query/hooks'
import { IBusinessUnit, BusinessUnit } from '@core/entities/businessUnit'

const dataTransformer = (response: PageResponse<IBusinessUnit>) => {
  return {
    ...response,
    content: response.content.map((businessUnit) =>
      BusinessUnit.new(businessUnit),
    ),
  }
}

export const useGetBusinessUnits = (
  payload: RequestTypes['getBusinessUnits'],
  options?: UseInfiniteQueryOptions<
    PageResponse<IBusinessUnit>,
    string,
    PageResponse<BusinessUnit>
  >,
) => {
  const businessUnitsQuery = businessUnitsKeys.getBusinessUnits(payload)
  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options?.enabled,
    queryKey: businessUnitsQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<IBusinessUnit>,
    string,
    PageResponse<BusinessUnit>
  >({
    cacheTime: 0,
    keepPreviousData: true,
    retry: 3,
    ...businessUnitsQuery,
    ...infiniteQueryOptions,
    ...options,
  })
}
