import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { permitTemplateEditorKeys } from '@core/react-query/features/templates/permitTemplateEditor'

import { RequestErrorType } from '@core/utils/api-axios-instance'
import { TempateWarningConfigType } from '@core/entities/template/TemplateWarnings/types'

export const useGetTemplateWarningTypes = (
  options?: UseQueryOptions<
    Array<TempateWarningConfigType>,
    RequestErrorType,
    Array<TempateWarningConfigType>
  >,
) => {
  return useQuery({
    ...permitTemplateEditorKeys.getWarningTypes(),
    ...options,
  })
}
