import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { useMemo, useState } from 'react'
import { useGetProjectLocationsCreateList } from '@core/react-query/features/projects/locations'
import { FilterHookArgumentsBase } from '@core/providers/filters'

type ProjectLocationFilterHookArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<
    SelectFilterValue<
      {
        label: string
        value?: string
        onSelect?: () => void
      },
      Multiple
    >
  > & {
    multiple?: Multiple
    projectId?: number
  }

export const useProjectLocationFilter: <Multiple extends boolean | undefined>(
  arg: ProjectLocationFilterHookArgs<Multiple>,
) => SelectFilterInternalArguments<
  { label: string; value?: string; onSelect?: () => void },
  Multiple
> & { previousPage?: () => void } = ({ projectId, ...args }) => {
  const { data: projectLocations } = useGetProjectLocationsCreateList({
    projectId,
  })

  const [currentLocationContext, setCurrentLocationContext] = useState<number>()

  const locationsById = useMemo(() => {
    const locationsById = {}
    const addLocation = (currentLocation, acc, parentId?) => {
      const nestedLocationIds = currentLocation.locations?.map?.(({ id }) => id)
      acc[currentLocation.id] = {
        ...currentLocation,
        locations: nestedLocationIds,
        parentId,
      }

      currentLocation?.locations?.forEach?.((nestedLocation) => {
        addLocation(nestedLocation, acc, currentLocation.id)
      })
      return acc
    }

    if (projectLocations) addLocation(projectLocations, locationsById)
    return locationsById
  }, [projectId, projectLocations])

  const selectLocation = (location) => {
    if (location.locations?.length) setCurrentLocationContext(location.id)
  }

  // If the location hierarchy is not enabled for the project then we hide this
  const isHidden = args.isHidden || !projectLocations?.enabled

  const locations = useMemo(() => {
    return (
      locationsById[
        currentLocationContext || projectLocations?.id
      ]?.locations?.map((locationId) => locationsById[locationId]) || []
    )
  }, [currentLocationContext, locationsById, projectId, projectLocations])

  const filter = useSelectFilter({
    ...args,
    isHidden,
    options:
      locations?.map(({ id }) => {
        const location = locationsById[id]
        return {
          label: location.name,
          value: id,
          hasLocations: !!location.locations?.length,
          onSelect: () => selectLocation(location),
        }
      }) || [],
    valueToLabel: (v) => locationsById[v]?.name,
  })

  const previousPage = useMemo(() => {
    if (currentLocationContext) {
      const parentLocationId = locationsById[currentLocationContext]?.parentId
      if (parentLocationId) {
        return () => setCurrentLocationContext(parentLocationId)
      }
    }
    return undefined
  }, [currentLocationContext, locationsById])

  return {
    locationsById,
    previousPage,
    ...filter,
  }
}
