import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { RequestTypes } from './requestTypes'

const planPermitsQueryKeys = createQueryKeys('plan-permits', {
  getPlanPermits: ({ planId, ...rest }: RequestTypes['getPlanPermits']) => {
    return {
      queryKey: [
        rest.projectId,
        planId,
        rest.statuses,
        rest.templateIds,
        rest.permitUUID,
        rest.startTime,
        rest.endTime,
      ],
      queryFn: async () => {
        const queryParamsString = createQueryKeyString(rest)

        const { data } = await APIAxiosInstance.get(
          `permits/plans/${planId}${queryParamsString}`,
          {},
        )

        return data
      },
    }
  },
})

const planPermitsKeys = mergeQueryKeys(planPermitsQueryKeys)['plan-permits']

export { planPermitsKeys }
