import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectMap } from './IProjectMap'

export class ProjectMap extends Entity<IProjectMap> {
  mapBoxStyleUrl: string

  @Type(() => Number)
  mapCenterLatitude: number

  @Type(() => Number)
  mapCenterLongitude: number

  @Type(() => Number)
  mapZoom: number

  public static new(payload: unknown): ProjectMap {
    const entity = plainToInstance(ProjectMap, payload)

    return entity
  }
}
