import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  projectKeys,
  projectMutationKeys,
} from '@core/react-query/features/projects/project'

export const useUpdateProject = (
  options?: UseMutationOptions<any, string, RequestTypes['updateProject']>,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...projectMutationKeys.updateProject,
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        projectKeys.byId({ projectId: variables.projectId }).queryKey,
        {},
      )
    },
  })
}
