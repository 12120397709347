import {
  checklistsKeys,
  RequestTypes,
} from '@core/react-query/features/checklists'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import { usePageableQuery } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  ChecklistDraft,
  IChecklistDraft,
} from '@core/entities/checklist/ChecklistDraft'

const dataTransformer = ({ content }: PageResponse<IChecklistDraft>) => {
  return content.map((checklist) => ChecklistDraft.new(checklist))
}

export const useOrganisationDraftChecklists = (
  payload: RequestTypes['getDrafts'],
  options: UseInfiniteQueryOptions<
    PageResponse<IChecklistDraft>,
    RequestErrorType,
    Array<ChecklistDraft>
  > = {},
) => {
  const permitDraftsQuery = checklistsKeys.drafts(payload)

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: permitDraftsQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<IChecklistDraft>,
    RequestErrorType,
    Array<ChecklistDraft>
  >({
    ...infiniteQueryOptions,
    ...permitDraftsQuery,
    ...options,
  })
}
