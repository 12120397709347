import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  RequestTypes,
  projectPlansKeys,
} from '@core/react-query/features/projects/plans'
import { projectLocationsKeys } from '@core/react-query/features/projects/locations'
import { ProjectPlan } from '@core/entities/project/ProjectPlan/ProjectPlan'

export const useDeleteProjectPlan = () => {
  const queryClient = useQueryClient()
  return useMutation<ProjectPlan, string, RequestTypes['deleteProjectPlan']>({
    ...projectPlansKeys.deleteProjectPlan,
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: projectLocationsKeys._def,
      })
      queryClient.invalidateQueries({
        queryKey: projectPlansKeys._def,
      })
    },
  })
}
