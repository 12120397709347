import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  IChecklistTemplate,
  ChecklistTemplate,
} from '@core/entities/checklist/ChecklistTemplate'
import { useToast } from '@core/toast'

export const useGetChecklistTemplateById = (
  payload: RequestTypes['getChecklistTemplate'],
  options?: UseQueryOptions<
    IChecklistTemplate,
    RequestErrorType,
    ChecklistTemplate
  >,
) => {
  return useQuery({
    select: ChecklistTemplate.new,
    ...checklistKeys.templateById(payload),
    ...options,
  })
}

export const useGetChecklistTemplateByIdGetter = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const getChecklistTemplate = async (
    payload: RequestTypes['getChecklistTemplate'],
  ) => {
    const key = checklistKeys.templateById({
      projectId: payload.projectId,
      templateId: payload.templateId,
    })

    try {
      const response = await queryClient.fetchQuery<IChecklistTemplate>({
        queryKey: key.queryKey,
        queryFn: key.queryFn,
      })

      return { data: ChecklistTemplate.new(response) }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  return { getChecklistTemplate }
}
