import { localizedStrings } from '@core/strings'
import { Entity } from '@core/entities/entity'
import { ProjectLocationStatus } from './types'
import { ProjectPlanStatus } from '../ProjectPlan'
import { Type, plainToInstance } from 'class-transformer'
import { IProjectLocationNode } from './IProjectLocationNode'

export class ProjectLocationNode extends Entity<IProjectLocationNode> {
  id: string

  name: string

  status: ProjectLocationStatus

  planId?: number

  planImageKey?: string

  planStatus: ProjectPlanStatus

  @Type(() => ProjectLocationNode)
  locations: ProjectLocationNode[]

  public static new(payload: unknown): ProjectLocationNode {
    const entity = plainToInstance(ProjectLocationNode, payload)

    return entity
  }

  public isActive(): boolean {
    return this.status === 'ACTIVE'
  }

  public hasSubLocations(): boolean {
    return this.locations.length > 0
  }

  public isArchived(): boolean {
    return this.status === 'ARCHIVED'
  }

  public isPlanEditable(): boolean {
    return this.planStatus === 'UNPUBLISHED' && this.status === 'ACTIVE'
  }

  public getStatusChipColor() {
    switch (this.planStatus) {
      case 'ARCHIVED':
      case 'UNPUBLISHED':
        return 'default'
      default:
        return 'success'
    }
  }

  public getStatusString(): string {
    switch (this.planStatus) {
      case 'UNPUBLISHED':
        return localizedStrings.unpublishedPlan
      case 'PUBLISHED':
        return localizedStrings.publishedPlan
      case 'ARCHIVED':
        return localizedStrings.archivedPlan
      default:
        return this.planStatus
    }
  }
}
