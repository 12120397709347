import { localizedStrings } from '@core/strings'
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Tooltip,
} from '@common/material'
import { Box, Flex, Text } from '@fundamentals'
import { useState } from 'react'
import {
  AdvancedSelectFilterInternalArguments,
  AdvancedSelectFilterValue,
  advancedSelectFilterOptionConditions,
  advancedSelectFilterOperatorsWithoutArguments,
} from '@core/providers/filters'
import { AdvancedFilterPopover } from './AdvancedFilterPopover'

type AdvancedSelectFilterProps = {
  filter: AdvancedSelectFilterInternalArguments<unknown>
  onChange: ({ value, operator }: AdvancedSelectFilterValue) => void
  storeValue: AdvancedSelectFilterValue
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (el: HTMLButtonElement | null) => void
}

export const AdvancedSelectFilter: React.FC<AdvancedSelectFilterProps> = ({
  onChange,
  storeValue,
  filter,
  anchorEl,
  setAnchorEl,
}) => {
  const [term, setTerm] = useState('')
  const filteredProperties = filter.options.filter((property) =>
    property.label.toLowerCase().includes(term.toLowerCase()),
  )

  const operator =
    storeValue?.operator ?? advancedSelectFilterOptionConditions[0].value

  const hideArguments =
    advancedSelectFilterOperatorsWithoutArguments.includes(operator)

  return (
    <AdvancedFilterPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <Flex flexDirection='column' p={2} pr={1}>
        <TextField
          value={operator}
          select
          size='small'
          label='Select filter operator'
          sx={{
            pr: 1,
            mb: 2,
            minWidth: 200,
          }}
          SelectProps={{
            onChange(e) {
              onChange({
                value: storeValue?.value,
                operator: e.target
                  .value as AdvancedSelectFilterValue['operator'],
              })
            },
          }}
        >
          {advancedSelectFilterOptionConditions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {!hideArguments && (
          <>
            <TextField
              sx={{ bgcolor: 'white', pr: 1 }}
              fullWidth
              placeholder={localizedStrings.typeHere}
              onChange={(e) => {
                setTerm(e.target.value)
              }}
              size='small'
            />

            <Flex>
              <Button
                variant={'text'}
                onClick={() => {
                  onChange({
                    value: filter?.options?.map((option) => option.uuid),
                    operator,
                  })
                }}
              >
                {localizedStrings.selectAll}
              </Button>
              <Box flexGrow={1} />
              <Button
                sx={{ mr: 1 }}
                variant={'text'}
                onClick={() => {
                  onChange({
                    value: [],
                    operator,
                  })
                }}
              >
                {localizedStrings.deselectAll}
              </Button>
            </Flex>

            <Flex flexDirection='column'>
              {filteredProperties.map((property) => (
                <FormControlLabel
                  key={property.uuid}
                  sx={{ mb: 0 }}
                  control={
                    <Checkbox
                      checked={
                        !!storeValue?.value?.find(
                          (value) => value === property.uuid,
                        )
                      }
                      onClick={() => {
                        onChange({
                          value: storeValue?.value?.find(
                            (value) => value === property.uuid,
                          )
                            ? storeValue?.value?.filter(
                                (value) => value !== property.uuid,
                              )
                            : [...(storeValue?.value ?? []), property?.uuid],
                          operator:
                            advancedSelectFilterOptionConditions[0].value,
                        })
                      }}
                    />
                  }
                  disableTypography
                  label={
                    <Tooltip title={property.label}>
                      <Text noWrap>{property.label}</Text>
                    </Tooltip>
                  }
                />
              ))}
            </Flex>
          </>
        )}
      </Flex>
    </AdvancedFilterPopover>
  )
}
