export const ALL_REGISTER_TRIGGER_TYPES = [
  'PROPERTY_CHANGE',
  'ITEM_ADDED',
] as const
type RegisterTriggerTypeTuple = typeof ALL_REGISTER_TRIGGER_TYPES

export type RegisterTriggerType = RegisterTriggerTypeTuple[number]

export const ALL_REGISTER_TRIGGER_ACTION_TYPES = ['SEND_EMAIL'] as const
type RegisterTriggerActionTypeTuple = typeof ALL_REGISTER_TRIGGER_ACTION_TYPES
export type RegisterTriggerActionType = RegisterTriggerActionTypeTuple[number]
