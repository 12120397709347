import { Checkbox, MenuItem, TextField } from '@common/material'
import { Box, Flex } from '@fundamentals'
import { AdvancedFilterPopover } from './AdvancedFilterPopover'
import { Autocomplete } from '@mui/material'
import {
  AdvancedDiagramFilterInternalArguments,
  AdvancedDiagramFilterOptionOperators,
  AdvancedDiagramFilterValue,
} from '@core/providers/filters/fundamentals/hooks/useAdvancedDiagramFilter'

type AdvancedDiagramFilterProps = {
  filter: AdvancedDiagramFilterInternalArguments<unknown>
  onChange: ({ value, operator }: AdvancedDiagramFilterValue) => void
  storeValue: AdvancedDiagramFilterValue
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (el: HTMLButtonElement | null) => void
}

export const AdvancedDiagramFilter: React.FC<AdvancedDiagramFilterProps> = ({
  onChange,
  storeValue,
  filter,
  anchorEl,
  setAnchorEl,
}) => {
  const value = storeValue?.value
  const operator =
    storeValue?.operator ?? AdvancedDiagramFilterOptionOperators[0].value

  return (
    <AdvancedFilterPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <Flex flexDirection='column' p={2} pr={1}>
        <TextField
          value={operator}
          select
          size='small'
          label='Select filter operator'
          sx={{
            pr: 1,
            mb: 2,
            minWidth: 200,
          }}
          SelectProps={{
            onChange(e) {
              onChange({
                value: storeValue?.value,
                operator: e.target
                  .value as AdvancedDiagramFilterValue['operator'],
              })
            },
          }}
        >
          {AdvancedDiagramFilterOptionOperators.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Box minWidth={350}>
          <Autocomplete
            value={value}
            options={filter?.options || []}
            getOptionLabel={(o: number) => {
              if (!o) return ''

              return filter.valueToLabel(o)
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li
                  {...props}
                  data-test={`${filter?.dataTest}-option-${props['data-option-index']}`}
                >
                  <Checkbox checked={selected} mr={1} />
                  {/*@ts-ignore*/}
                  {!filter.renderItem && filter.valueToLabel(option)}
                  {/*@ts-ignore*/}
                  {filter.renderItem && filter.renderItem(option)}
                </li>
              )
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={filter.label}
                  multiline
                  sx={{ pr: 1 }}
                  data-test={filter?.dataTest}
                />
              )
            }}
            onChange={(_, value) => {
              onChange({
                value: value as AdvancedDiagramFilterValue['value'],
                operator: AdvancedDiagramFilterOptionOperators[0].value,
              })
            }}
            onInputChange={(e, input) => {
              // Don't fire on when option is selected in the dropdown
              if (e?.type === 'click') {
                return
              }
              // If selection is single then the input is changed by Autocomplete when the filter is focused
              // This stops it firing unnecessarily
              if (input === filter.valueToLabel(value)) {
                return
              }
              if (filter?.onInputChange) {
                filter.onInputChange(input)
              }
            }}
            style={{ minWidth: 200, maxWidth: 420 }}
            sx={{
              '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                minWidth: 0,
              },
            }}
            limitTags={1}
            componentsProps={{ popper: { placement: 'bottom-start' } }}
            size='small'
            disableCloseOnSelect
            loading={filter?.loading}
          />
        </Box>
      </Flex>
    </AdvancedFilterPopover>
  )
}
