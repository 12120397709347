// SiteAssist Color Palette 2024-06-12

const Blue = {
  50: 'rgb(227, 242, 253)', // #E3F2FD
  100: 'rgb(187, 222, 251)', // #BBDEFB
  200: 'rgb(144, 202, 249)', // #90CAF9
  300: 'rgb(100, 181, 246)', // #64B5F6
  400: 'rgb(66, 165, 245)', // #42A5F5
  500: 'rgb(32, 149, 243)', // #2095F3
  600: 'rgb(30, 135, 229)', // #1E87E5
  700: 'rgb(25, 118, 210)', // #1976D2
  800: 'rgb(21, 100, 192)', // #1564C0
  900: 'rgb(13, 70, 161)', // #0D46A1
  A100: 'rgb(130, 177, 255)', // #82B1FF
  A200: 'rgb(68, 138, 255)', // #448AFF
  A400: 'rgb(41, 121, 255)', // #2979FF
  A700: 'rgb(41, 98, 255)', // #2962FF
}

const DeepPurple = {
  50: 'rgb(234, 230, 246)', // #EAE6F6
  100: 'rgb(201, 194, 232)', // #C9C2E8
  200: 'rgb(165, 154, 217)', // #A59AD9
  300: 'rgb(129, 113, 203)', // #8171CB
  400: 'rgb(100, 84, 192)', // #6454C0
  500: 'rgb(69, 57, 181)', // #4539B5
  600: 'rgb(58, 52, 175)', // #3A34AF
  700: 'rgb(40, 45, 166)', // #282DA6
  800: 'rgb(17, 39, 158)', // #11279E
  900: 'rgb(0, 29, 144)', // #001D90
  A100: 'rgb(179, 136, 255)', // #B388FF
  A200: 'rgb(124, 77, 255)', // #7C4DFF
  A400: 'rgb(101, 31, 255)', // #651FFF
  A700: 'rgb(98, 0, 234)', // #6200EA
}

const Green = {
  50: 'rgb(227, 244, 236)', // #E3F4EC
  100: 'rgb(188, 227, 208)', // #BCE3D0
  200: 'rgb(145, 209, 178)', // #91D1B2
  300: 'rgb(96, 192, 149)', // #60C095
  400: 'rgb(54, 179, 126)', // #36B37E
  500: 'rgb(0, 166, 105)', // #00A669
  600: 'rgb(0, 151, 95)', // #00975F
  700: 'rgb(0, 133, 82)', // #008552
  800: 'rgb(0, 116, 70)', // #007446
  900: 'rgb(0, 85, 48)', // #005530
  A100: 'rgb(185, 246, 202)', // #B9F6CA
  A200: 'rgb(105, 240, 174)', // #69F0AE
  A400: 'rgb(0, 230, 118)', // #00E676
  A700: 'rgb(0, 200, 83)', // #00C853
}

const Red = {
  50: 'rgb(250, 233, 232)', // #FAE9E8
  100: 'rgb(254, 204, 191)', // #FECCBF
  200: 'rgb(254, 171, 150)', // #FEAB96
  300: 'rgb(254, 138, 108)', // #FE8A6C
  400: 'rgb(255, 111, 77)', // #FF6F4D
  500: 'rgb(255, 86, 48)', // #FF5630
  600: 'rgb(244, 80, 44)', // #F4502C
  700: 'rgb(230, 73, 39)', // #E64927
  800: 'rgb(215, 67, 35)', // #D74323
  900: 'rgb(190, 55, 27)', // #BE371B
  A100: 'rgb(255, 138, 128)', // #FF8A80
  A200: 'rgb(255, 82, 82)', // #FF5252
  A400: 'rgb(255, 23, 68)', // #FF1744
  A700: 'rgb(213, 0, 0)', // #D50000
}

const Yellow = {
  50: 'rgb(255, 254, 230)', // #FFFEF6
  100: 'rgb(255, 250, 193)', // #FFFAC1
  200: 'rgb(254, 246, 151)', // #FEF697
  300: 'rgb(253, 241, 107)', // #FDF16B
  400: 'rgb(251, 237, 70)', // #FBED46
  500: 'rgb(248, 232, 20)', // #F8E814
  600: 'rgb(255, 220, 28)', // #FFDC1C
  700: 'rgb(255, 195, 15)', // #FFC30F
  800: 'rgb(255, 171, 0)', // #FFAB00
  900: 'rgb(254, 127, 0)', // #FE7F00
  A100: 'rgb(255, 255, 141)', // #FFFF8D
  A200: 'rgb(255, 255, 0)', // #FFFF00
  A400: 'rgb(255, 234, 0)', // #FFEA00
  A700: 'rgb(255, 214, 0)', // #FFD600
}

const Cyan = {
  50: 'rgb(224, 246, 251)', // #E0F6FB
  100: 'rgb(178, 234, 245)', // #B2EAF5
  200: 'rgb(127, 220, 239)', // #7FDCF3
  300: 'rgb(76, 205, 231)', // #4CCDE7
  400: 'rgb(35, 195, 224)', // #23C3E0
  500: 'rgb(0, 184, 217)', // #00B8D9
  600: 'rgb(0, 169, 198)', // #00A9C6
  700: 'rgb(0, 148, 171)', // #0094AB
  800: 'rgb(0, 128, 147)', // #008093
  900: 'rgb(0, 94, 103)', // #005E67
  A100: 'rgb(132, 255, 255)', // #84FFFF
  A200: 'rgb(24, 255, 255)', // #18FFFF
  A400: 'rgb(0, 229, 255)', // #00E5FF
  A700: 'rgb(0, 184, 212)', // #00B8D4
}

const Purple = {
  50: 'rgb(243, 229, 245)', // #F3E5F5
  100: 'rgb(225, 190, 231)', // #E1BEE7
  200: 'rgb(206, 147, 216)', // #CE93D8
  300: 'rgb(186, 104, 200)', // #BA68C8
  400: 'rgb(171, 71, 188)', // #AB47BC
  500: 'rgb(156, 39, 176)', // #9C27B0
  600: 'rgb(142, 36, 170)', // #8E24AA
  700: 'rgb(123, 31, 162)', // #7B1FA2
  800: 'rgb(106, 27, 154)', // #6A1B9A
  900: 'rgb(74, 20, 140)', // #4A148C
  A100: 'rgb(179, 136, 255)', // #B388FF
  A200: 'rgb(124, 77, 255)', // #7C4DFF
  A400: 'rgb(101, 31, 255)', // #651FFF
  A700: 'rgb(98, 0, 234)', // #6200EA
}

const Teal = {
  50: 'rgb(224, 242, 241)', // #E0F2F1
  100: 'rgb(178, 223, 219)', // #B2DFDB
  200: 'rgb(128, 203, 196)', // #80CBC4
  300: 'rgb(77, 182, 172)', // #4DB6AC
  400: 'rgb(38, 166, 154)', // #26A69A
  500: 'rgb(0, 150, 136)', // #009688
  600: 'rgb(0, 137, 123)', // #00897B
  700: 'rgb(0, 121, 107)', // #00796B
  800: 'rgb(0, 105, 92)', // #00695C
  900: 'rgb(0, 77, 64)', // #004D40
  A100: 'rgb(167, 255, 235)', // #A7FFEB
  A200: 'rgb(100, 255, 218)', // #64FFD8
  A400: 'rgb(29, 233, 182)', // #1DE9B6
  A700: 'rgb(0, 191, 165)', // #00BFA5
}

const Orange = {
  50: 'rgb(255, 243, 224)', // #FFF3E0
  100: 'rgb(255, 224, 178)', // #FFE0B2
  200: 'rgb(255, 204, 128)', // #FFCC80
  300: 'rgb(255, 183, 77)', // #FFB74D
  400: 'rgb(255, 167, 38)', // #FFA726
  500: 'rgb(255, 152, 0)', // #FF9800
  600: 'rgb(251, 140, 0)', // #FB8C00
  700: 'rgb(245, 124, 0)', // #F57C00
  800: 'rgb(239, 108, 0)', // #EF6C00
  900: 'rgb(230, 81, 0)', // #E65100
  A100: 'rgb(255, 209, 128)', // #FFD180
  A200: 'rgb(255, 171, 64)', // #FFAB40
  A400: 'rgb(255, 145, 0)', // #FF9100
  A700: 'rgb(255, 109, 0)', // #FF6D00
}

const Amber = {
  50: 'rgb(255, 248, 225)', // #FFF8E1
  100: 'rgb(255, 236, 179)', // #FFECB3
  200: 'rgb(255, 224, 130)', // #FFE082
  300: 'rgb(255, 213, 79)', // #FFD54F
  400: 'rgb(255, 202, 40)', // #FFCA28
  500: 'rgb(255, 193, 7)', // #FFC107
  600: 'rgb(255, 179, 0)', // #FFB300
  700: 'rgb(255, 160, 0)', // #FFA000
  800: 'rgb(255, 143, 0)', // #FF8F00
  900: 'rgb(255, 111, 0)', // #FF6F00
  A100: 'rgb(255, 229, 127)', // #FFE57F
  A200: 'rgb(255, 215, 64)', // #FFD740
  A400: 'rgb(255, 196, 0)', // #FFC400
  A700: 'rgb(255, 171, 0)', // #FFAB00
}

const Indigo = {
  50: 'rgb(232, 234, 246)', // #E8EAF6
  100: 'rgb(197, 202, 233)', // #C5CAE9
  200: 'rgb(159, 168, 218)', // #9FA8DA
  300: 'rgb(121, 134, 203)', // #7986CB
  400: 'rgb(92, 107, 192)', // #5C6BC0
  500: 'rgb(63, 81, 181)', // #3F51B5
  600: 'rgb(57, 73, 171)', // #3949AB
  700: 'rgb(48, 63, 159)', // #303F9F
  800: 'rgb(40, 53, 147)', // #283593
  900: 'rgb(26, 35, 126)', // #1A237E
  A100: 'rgb(140, 158, 255)', // #8C9EFF
  A200: 'rgb(83, 109, 254)', // #536DFE
  A400: 'rgb(61, 90, 254)', // #3D5AFE
  A700: 'rgb(48, 79, 254)', // #304FFE
}

const Pink = {
  50: 'rgb(252, 228, 236)', // #FCE4EC
  100: 'rgb(248, 187, 208)', // #F8BBD0
  200: 'rgb(244, 143, 177)', // #F48FB1
  300: 'rgb(240, 98, 146)', // #F06292
  400: 'rgb(236, 64, 122)', // #EC407A
  500: 'rgb(233, 30, 99)', // #E91E63
  600: 'rgb(216, 27, 96)', // #D81B60
  700: 'rgb(194, 24, 91)', // #C2185B
  800: 'rgb(173, 20, 87)', // #AD1457
  900: 'rgb(136, 14, 79)', // #880E4F
  A100: 'rgb(245, 0, 87)', // #F50057
  A200: 'rgb(255, 64, 129)', // #FF4081
  A400: 'rgb(245, 0, 87)', // #F50057
  A700: 'rgb(197, 17, 98)', // #C51162
}

const LightBlue = {
  50: 'rgb(225, 245, 254)', // #E1F5FE
  100: 'rgb(179, 229, 252)', // #B3E5FC
  200: 'rgb(129, 212, 250)', // #81D4FA
  300: 'rgb(79, 195, 247)', // #4FC3F7
  400: 'rgb(41, 182, 246)', // #29B6F6
  500: 'rgb(3, 169, 244)', // #03A9F4
  600: 'rgb(3, 155, 229)', // #039BE5
  700: 'rgb(2, 136, 209)', // #0288D1
  800: 'rgb(2, 119, 189)', // #0277BD
  900: 'rgb(1, 87, 155)', // #01579B
  A100: 'rgb(128, 216, 255)', // #80D8FF
  A200: 'rgb(64, 196, 255)', // #40C4FF
  A400: 'rgb(0, 176, 255)', // #00B0FF
  A700: 'rgb(0, 145, 234)', // #0091EA
}

const LightGreen = {
  50: 'rgb(241, 248, 233)', // #F1F8E9
  100: 'rgb(220, 237, 200)', // #DCEDC8
  200: 'rgb(197, 225, 165)', // #C5E1A5
  300: 'rgb(174, 213, 129)', // #AED581
  400: 'rgb(156, 204, 101)', // #9CCC65
  500: 'rgb(139, 195, 74)', // #8BC34A
  600: 'rgb(124, 179, 66)', // #7CB342
  700: 'rgb(104, 159, 56)', // #689F38
  800: 'rgb(85, 139, 47)', // #558B2F
  900: 'rgb(51, 105, 30)', // #33691E
  A100: 'rgb(204, 255, 144)', // #CCFF90
  A200: 'rgb(178, 255, 89)', // #B2FF59
  A400: 'rgb(118, 255, 3)', // #76FF03
  A700: 'rgb(100, 221, 23)', // #64DD17
}

const Lime = {
  50: 'rgb(249, 251, 231)', // #F9FBE7
  100: 'rgb(240, 244, 195)', // #F0F4C3
  200: 'rgb(230, 238, 156)', // #E6EE9C
  300: 'rgb(220, 231, 117)', // #DCE775
  400: 'rgb(212, 225, 87)', // #D4E157
  500: 'rgb(205, 220, 57)', // #CDDC39
  600: 'rgb(192, 202, 51)', // #C0CA33
  700: 'rgb(175, 180, 43)', // #AFB42B
  800: 'rgb(158, 157, 36)', // #9E9D24
  900: 'rgb(130, 119, 23)', // #827717
  A100: 'rgb(244, 255, 129)', // #F4FF81
  A200: 'rgb(238, 255, 65)', // #EEFF41
  A400: 'rgb(198, 255, 0)', // #C6FF00
  A700: 'rgb(174, 234, 0)', // #AEEF00
}

const DeepOrange = {
  50: 'rgb(251, 233, 231)', // #FBE9E7
  100: 'rgb(255, 204, 188)', // #FFCCBC
  200: 'rgb(255, 171, 145)', // #FFAB91
  300: 'rgb(255, 138, 101)', // #FF8A65
  400: 'rgb(255, 112, 67)', // #FF7043
  500: 'rgb(255, 87, 72)', // #FF5748
  600: 'rgb(244, 81, 30)', // #F4511E
  700: 'rgb(230, 74, 25)', // #E64A19
  800: 'rgb(216, 67, 21)', // #D84315
  900: 'rgb(191, 54, 12)', // #BF360C
  A100: 'rgb(255, 158, 128)', // #FF9E80
  A200: 'rgb(255, 110, 64)', // #FF6E40
  A400: 'rgb(255, 61, 0)', // #FF3D00
  A700: 'rgb(221, 44, 0)', // #DD2C00
}

const Brown = {
  50: 'rgb(239, 235, 233)', // #EFEBE9
  100: 'rgb(215, 204, 200)', // #D7CCC8
  200: 'rgb(188, 170, 164)', // #BCAAA4
  300: 'rgb(161, 136, 127)', // #A1887F
  400: 'rgb(141, 110, 99)', // #8D6E63
  500: 'rgb(121, 85, 72)', // #795548
  600: 'rgb(109, 76, 65)', // #6D4C41
  700: 'rgb(93, 64, 55)', // #5D4037
  800: 'rgb(78, 52, 46)', // #4E342E
  900: 'rgb(62, 39, 35)', // #3E2723
}

const Grey = {
  50: 'rgb(250, 250, 250)', // #FAFAFA
  100: 'rgb(245, 245, 245)', // #F5F5F5
  200: 'rgb(238, 238, 238)', // #EEEEEE
  300: 'rgb(224, 224, 224)', // #E0E0E0
  400: 'rgb(189, 189, 189)', // #BDBDBD
  500: 'rgb(158, 158, 158)', // #9E9E9E
  600: 'rgb(117, 117, 117)', // #757575
  700: 'rgb(97, 97, 97)', // #616161
  800: 'rgb(66, 66, 66)', // #424242
  900: 'rgb(33, 33, 33)', // #212121
  A100: 'rgb(213, 213, 213)', // #D5D5D5
  A200: 'rgb(170, 170, 170)', // #AAAAAA
  A400: 'rgb(97, 97, 97)', // #616161
  A700: 'rgb(48, 48, 48)', // #303030 //#808080
}

const BlueGrey = {
  50: 'rgb(236, 239, 241)', // #ECEFF1
  100: 'rgb(207, 216, 220)', // #CFD8DC
  200: 'rgb(176, 190, 197)', // #B0BEC5
  300: 'rgb(144, 164, 174)', // #90A4AE
  400: 'rgb(120, 144, 156)', // #78909C
  500: 'rgb(96, 125, 139)', // #607D8B
  600: 'rgb(84, 110, 122)', // #546E7A
  700: 'rgb(69, 90, 100)', // #455A64
  800: 'rgb(55, 71, 79)', // #37474F
  900: 'rgb(38, 50, 56)', // #263238
}

const Common = {
  White: 'rgb(255, 255, 255)', // #FFFFFF
  Black: 'rgb(0, 0, 0)', // #000000
}

export const Palette = {
  Blue,
  DeepPurple,
  Green,
  Red,
  Yellow,
  Cyan,
  Purple,
  Teal,
  Orange,
  Amber,
  Indigo,
  Pink,
  LightBlue,
  Lime,
  DeepOrange,
  Brown,
  Grey,
  BlueGrey,
  Common,
}
