import { localizedStrings } from '@core/strings'
import React from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { Chip, Button } from '@common/material'
import { useModal } from '@common/GlobalModal'
import {
  QuestionTypeComponentType,
  AcknowledgementQuestionRules,
} from '@core/components/templateAnswersForm'
import { alpha } from '@mui/material'
import { PdfModal } from '@common/PdfModal'

const AcknowledgeQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange } = {},
  readOnly,
}) => {
  const { showModal, hideModal } = useModal()

  const handleAcknowledge = () => {
    onChange?.({
      ...value,
      acknowledged: true,
    })
  }

  return (
    <Flex
      onClick={() =>
        showModal(
          <PdfModal
            readOnly
            title={localizedStrings.pdf}
            previewUrl={question?.acknowledgeInformationDocumentDownloadUrl}
            footerActions={
              !question?.acknowledged && (
                <Button
                  variant='contained'
                  onClick={() => {
                    handleAcknowledge()
                    hideModal()
                  }}
                >
                  {localizedStrings.acknowledge}
                </Button>
              )
            }
          />,
        )
      }
      sx={{
        p: 2,
        border: `1px solid ${alpha('#000', 0.23)}`,
      }}
    >
      <Box
        sx={{
          width: 52,
          height: 52,
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <embed
          src={question?.acknowledgeInformationDocumentDownloadUrl}
          style={{ pointerEvents: 'none' }}
          type='application/pdf'
          height='100%'
          width='100%'
        />
      </Box>
      <Flex alignItems='center' justifyContent='space-between' flex={5} pl={2}>
        {question?.acknowledged ? (
          <Chip color='success' label={localizedStrings.acknowledged} />
        ) : (
          <Text>{localizedStrings.confirmAcknowledgeText}</Text>
        )}

        <Button variant={readOnly ? 'outlined' : 'contained'}>
          {localizedStrings.view}
        </Button>
      </Flex>
    </Flex>
  )
}

AcknowledgeQuestion.rules = AcknowledgementQuestionRules

export default AcknowledgeQuestion
