import { localizedStrings } from '@core/strings'
import {
  SelectFilterHookArguments,
  SelectFilterInternalArguments,
  useSelectFilter,
} from './fundamentals'
import { useGetRegisterFilters } from '@core/react-query/features/registers/registers/register/hooks'

type RegisterFiltersFilterHookArgs = Omit<
  SelectFilterHookArguments<number, false>,
  'valueToLabel' | 'options'
> & {
  registerId: number
}

export const useRegisterFiltersFilter: (
  arg: RegisterFiltersFilterHookArgs,
) => SelectFilterInternalArguments<number, false> = (args) => {
  const { data: registerFilters } = useGetRegisterFilters(
    {
      registerId: args.registerId,
    },
    {
      enabled: !!args.registerId,
    },
  )

  return useSelectFilter({
    ...args,
    options: [
      // ID 0 is the default filter
      0,
      ...(registerFilters?.filters
        ? registerFilters?.filters?.map((p) => p.id)
        : []),
    ],
    valueToLabel: (id) => {
      if (id === 0) {
        return localizedStrings.noFilter
      }
      return registerFilters?.filters?.find((t) => t.id === Number(id))?.name
    },
    nothingSelectedText: localizedStrings.noFilter,
  })
}
