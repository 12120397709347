import { localizedStrings } from '@core/strings'
import { Card, CardProps, Chip } from '@common/material'
import React from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { PermitBase } from '@core/entities/permit/PermitBase'
import { PermitStatus } from '@core/entities/permit/PermitBase'
import { Section } from '@common/Section'
import moment from 'moment'
import EventAvailableOutlined from '@mui/icons-material/EventAvailableOutlined'
import EventBusy from '@mui/icons-material/EventBusy'
import MapOutlined from '@mui/icons-material/MapOutlined'
import { User } from '@core/entities/user'

type PermitCardProps = Omit<CardProps, 'id'> & {
  templateName: string
  summary: string
  status: PermitStatus
  createdOn?: string
  startsOn?: string
  endsOn?: string
  location?: string
  permitHolder: User
  icon?: React.ReactNode
}

export const PermitCard: React.FC<PermitCardProps> = ({
  templateName,
  summary,
  status,
  createdOn,
  startsOn,
  endsOn,
  location,
  permitHolder,
  icon,
  sx = {},
  children,
  ...props
}) => {
  return (
    <Card
      sx={{
        pl: 2,
        pr: icon ? 1.5 : 2,
        py: 1.5,
        ...sx,
      }}
      {...props}
    >
      <Flex>
        <Box flex={1}>
          <Text fontSize={13} color='text.secondary'>
            {templateName}
          </Text>
          <Text fontSize={15}>{summary}</Text>
          <Chip
            color={PermitBase.getStatusChipColor(status)}
            label={PermitBase.getStatusString(status)}
            sx={{ mt: 0.5, mb: 1 }}
            size='small'
          />
          {!!createdOn && (
            <Section
              label={localizedStrings.created}
              icon={
                <EventAvailableOutlined sx={{ fontSize: 16, color: 'grey' }} />
              }
              horizontal
            >
              <Text sx={{ fontSize: 14.5 }}>
                {moment(createdOn).format('DD MMM YYYY HH:mm')}
              </Text>
            </Section>
          )}
          {!!startsOn && (
            <Section
              label={localizedStrings.starts}
              icon={
                <EventAvailableOutlined sx={{ fontSize: 16, color: 'grey' }} />
              }
              horizontal
            >
              <Text fontSize={14.5}>
                {moment(startsOn).format('DD MMM YYYY HH:mm')}
              </Text>
            </Section>
          )}

          <Section
            label={localizedStrings.ends}
            icon={<EventBusy sx={{ fontSize: 16, color: 'grey' }} />}
            horizontal
          >
            <Text fontSize={14.5}>
              {moment(endsOn).format('DD MMM YYYY HH:mm')}
            </Text>
          </Section>
          {!!location && (
            <Section
              icon={<MapOutlined sx={{ fontSize: 16, color: 'grey' }} />}
              label={localizedStrings.location}
              horizontal
            >
              <Text fontSize={14.5}>{location}</Text>
            </Section>
          )}
          <Section label={localizedStrings.permitHolder} mt={0.5}>
            <Text fontSize={14.5}>{permitHolder.getFullName()}</Text>
          </Section>
        </Box>
        {icon && <Box>{icon}</Box>}
      </Flex>
      {children}
    </Card>
  )
}
