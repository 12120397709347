import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitTemplatesKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplates'
import { AvailablePermitsTemplatesWrapper } from '@core/entities/permit/PermitTemplate'

const permitTemplateTransform = (data: ResponseType) =>
  AvailablePermitsTemplatesWrapper.new(data)

export const useAvailablePermitTemplates = (
  payload: RequestTypes['getAvailableTemplates'],
  options?: UseQueryOptions<
    AvailablePermitsTemplatesWrapper,
    string,
    AvailablePermitsTemplatesWrapper
  >,
) => {
  return useQuery({
    select: permitTemplateTransform,
    ...permitTemplatesKeys.availableTemplates(payload),
    ...options,
  })
}

export const prefetchAvailablePermitTemplates = (
  queryClient: QueryClient,
  payload: RequestTypes['getAvailableTemplates'],
  options?: UseQueryOptions<
    AvailablePermitsTemplatesWrapper,
    string,
    AvailablePermitsTemplatesWrapper
  >,
) => {
  return queryClient.prefetchQuery({
    select: permitTemplateTransform,
    ...permitTemplatesKeys.availableTemplates(payload),
    ...options,
    cacheTime: Infinity,
  })
}
