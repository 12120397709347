import { localizedStrings } from '@core/strings'
import React, { useEffect, useState } from 'react'
import { Text, Box } from '@fundamentals'
import { SignatureEdit } from 'webapp/components/common/Signature'
import DatePicker from 'components/DatePicker'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import { useModal } from 'webapp/components/common/GlobalModal'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { useRequestPermitExtension } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  permit: Permit
}

export const RequestExtensionModal: React.FC<ComponentType> = ({ permit }) => {
  const requestPermitExtension = useRequestPermitExtension()
  const { hideModal } = useModal()
  const toast = useToast()
  const { data: profile } = useGetProfile()
  const [error, setError] = useState('')
  const [endDateError, setEndDateError] = useState('')
  const [endDate, setEndDate] = useState<string>(permit.endsOn)
  const [reason, setReason] = useState<string>('')

  useEffect(() => {
    if (!!endDate && moment(endDate).isSameOrBefore(moment(permit.endsOn))) {
      setEndDateError(localizedStrings.extendEndTimeError)
    } else {
      setEndDateError(null)
    }
  }, [endDate])

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.requestExtension}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <Box>
          <label className='d-block'>{localizedStrings.endDateAndTime}</label>
          <DatePicker
            name='endDate'
            showTimeSelect
            timeIntervals={15}
            onChange={(v) => setEndDate(moment(v).toISOString())}
            minDate={permit.endsOn}
            value={endDate ? new Date(endDate) : new Date(permit.endsOn)}
            dateFormat='do MMM yyyy, h:mm aa'
            selected={endDate ? new Date(endDate) : new Date(permit.endsOn)}
          />
          <Box mt={2}>
            <ErrorMessage>{endDateError}</ErrorMessage>
          </Box>
        </Box>

        <TextField
          name='extension-reason'
          label={localizedStrings.extensionRequestedDetails}
          placeholder={`${localizedStrings.reasonForExtension}...`}
          multiline
          rows={4}
          required
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
          sx={{ mt: 4 }}
        />

        <Text pt={4} fontSize={12} color='text.secondary'>
          {localizedStrings.extensionRequestDisclaimer}
        </Text>

        <SignatureEdit readonly value={profile?.signatureUrl} />
        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={() =>
            requestPermitExtension.mutate(
              {
                permitId: permit.id,
                reason,
                endsOn: endDate,
              },
              {
                onError: (e) => {
                  toast.error(e.message)
                  setError(e.message)
                },
                onSuccess: () => {
                  toast.success(localizedStrings.requestedExtension)
                  hideModal()
                },
              },
            )
          }
          disabled={
            requestPermitExtension.isLoading ||
            !reason ||
            !endDate ||
            !!endDateError
          }
          data-test='modal-extension-request'
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
