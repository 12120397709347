import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  projectMutationKeys,
} from '@core/react-query/features/projects/project'

export const useCreateProject = (
  options?: UseMutationOptions<any, string, RequestTypes['createProject']>,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...projectMutationKeys.createProject,
    ...options,
    onSuccess: (data, variables, context) => {
      // TODO: invalidate project list query
    },
  })
}
