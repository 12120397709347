import { localizedStrings } from '@core/strings'
import React from 'react'
import Image from 'next/image'
import { Box, Flex, Icon, Text } from '@fundamentals'
import moment from 'moment'
import theme from '../../styles/theme'
import { Participant } from '@core/entities/participant/Participant'

interface ParticipantItemProps {
  dark?: boolean
  label?: string
  participant: Partial<Participant>
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({
  dark = false,
  label,
  participant,
}) => {
  const participantRoleAndCompany = `${
    participant?.roles?.length ? participant?.roles[0].name : ''
  } ${participant?.company ? `(${participant?.company})` : ''}`
  return (
    <Box
      sx={{
        minWidth: 308,
        minHeight: 79,
      }}
    >
      <Flex flexDirection='column'>
        <Box>
          {label && (
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 15,
                color: dark ? '#FFFFFF' : 'black',
                marginBottom: 7,
              }}
            >
              {label}
            </Text>
          )}
          <Flex>
            <Image
              alt='profile'
              width={39}
              height={39}
              className='profile-list-item__image'
              src={require('public/images/placeholders/avatar.svg')}
            />
            <Flex flexDirection='column' ml={3}>
              <Text
                sx={{
                  fontWeight: 'normal',
                  fontSize: 15,
                  color: dark ? '#FFFFFF' : 'black',
                }}
              >
                {`${participant?.firstName} ${participant?.lastName}`}
              </Text>
              <Text
                sx={{
                  fontWeight: 'bold',
                  fontSize: 13,
                  color: 'blue.steel.10',
                }}
              >
                {participantRoleAndCompany}
              </Text>
              {participant?.status === 'REMOVED' && (
                <Text
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 13,
                    color: 'legacy.primary.10',
                  }}
                >
                  {participant?.status}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex mt={2}>
            {participant?.email && (
              <Flex
                alignItems='center'
                px={2}
                py={1}
                sx={{
                  border: '1px solid #A4B5D2',
                  borderRadius: theme.radii.medium,
                  maxHeight: 29,
                  color: 'blue.steel.10',
                }}
              >
                <Icon icon='envelope' />
                <Text
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 13,
                    color: 'blue.steel.10',
                    marginLeft: 1,
                  }}
                >
                  {participant?.email}
                </Text>
              </Flex>
            )}
            {participant?.phone && (
              <Flex
                alignItems='center'
                px={2}
                py={1}
                ml={2}
                sx={{
                  border: '1px solid #A4B5D2',
                  borderRadius: theme.radii.medium,
                  maxHeight: 29,
                  color: 'blue.steel.10',
                }}
              >
                <Icon icon='phone' />
                <Text
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 13,
                    color: 'blue.steel.10',
                    marginLeft: 1,
                  }}
                >
                  {participant?.phone}
                </Text>
              </Flex>
            )}
          </Flex>
        </Box>
        {participant?.briefing && (
          <Box mt={1.5}>
            <Text>{localizedStrings.lastBriefed}</Text>
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: 13,
                color: 'blue.steel.10',
              }}
            >
              {moment(participant.briefing?.lastBriefedOn).format('lll')}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export default ParticipantItem
