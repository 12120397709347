import { Box } from '@common/fundamentals'
import {
  DocumentQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import React from 'react'

const DocumentQuestion: QuestionTypeComponentType = () => <Box />

DocumentQuestion.rules = DocumentQuestionRules

export { DocumentQuestion }
