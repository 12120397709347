import { localizedStrings } from '@core/strings'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { ChecklistTemplateStatus } from '@core/entities/checklist/ChecklistTemplate/types'
import { FilterHookArgumentsBase } from '@core/providers/filters'
import { useGetChecklistTemplates } from '@core/react-query/features/templates/checklistTemplates'
import { useMemo } from 'react'

type ChecklistTemplateFilterHookArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    organisationId: number
    projectIds?: number[]
    status?: ChecklistTemplateStatus
    multiple?: Multiple
    whitelist?: number[]
  }

export const useChecklistTemplatesFilter: <
  Multiple extends boolean | undefined,
>(
  arg: ChecklistTemplateFilterHookArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = ({
  organisationId,
  projectIds,
  whitelist,
  ...args
}) => {
  const { data: checklistTemplates } = useGetChecklistTemplates({
    organisationId,
    projectIds,
    page: 1,
    size: 1000,
  })

  const getValueToLabel = (v, list) => {
    return list?.find((t) => t.id === v)?.name
  }

  const filteredTemplates = useMemo(() => {
    if (whitelist?.length) {
      return checklistTemplates?.content.filter((template) =>
        whitelist?.includes(template.id),
      )
    }

    return checklistTemplates?.content
  }, [checklistTemplates, whitelist])

  return useSelectFilter({
    ...args,
    options: filteredTemplates?.map((t) => t?.id) || [],
    valueToLabel: (v) => getValueToLabel(v, filteredTemplates),
    nothingSelectedText: localizedStrings.allChecklists,
  })
}
