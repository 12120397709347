import { PageResponse } from '@core/entities/pagination'
import {
  ITemplateEditorChecklistTemplateVersion,
  TemplateEditorChecklistTemplateVersion,
} from '@core/entities/checklist/TemplateEditorChecklistTemplateVersion'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'

const dataTransformer = ({
  content,
  ...response
}: PageResponse<ITemplateEditorChecklistTemplateVersion>) => {
  return {
    ...response,
    content: content.map((template) =>
      TemplateEditorChecklistTemplateVersion.new(template),
    ),
  }
}

export const useGetChecklistTemplateVersions = (
  params: RequestTypes['getChecklistTemplateVersions'],
  options?: UseQueryOptions<
    PageResponse<ITemplateEditorChecklistTemplateVersion>,
    string,
    PageResponse<TemplateEditorChecklistTemplateVersion>
  >,
) => {
  return useQuery<
    PageResponse<ITemplateEditorChecklistTemplateVersion>,
    string,
    PageResponse<TemplateEditorChecklistTemplateVersion>
  >({
    select: dataTransformer,
    ...checklistTemplateKeys.getVersions(params),
    ...options,
  })
}
