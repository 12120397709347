import React from 'react'
import { Popover } from '@mui/material'

type AdvancedFilterPopoverProps = {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (element: HTMLButtonElement | null) => void
}

export const AdvancedFilterPopover: React.FC<AdvancedFilterPopoverProps> = ({
  children,
  anchorEl,
  setAnchorEl,
}) => {
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  )
}
