import { localizedStrings } from '@core/strings'
import {
  Alert,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@common/material'
import { ProjectRole, ProjectUser } from '@core/entities/user'
import React from 'react'
import { useGetAuthGroups } from '@core/react-query/features/authGroups'
import { HelpOutlined } from '@mui/icons-material'
import { Box } from '@fundamentals'
import { Controller, useForm } from 'react-hook-form'
import { useDeepCompareEffect } from 'use-deep-compare'
import { Project } from '@core/entities/project'

type ProjectUserPermissionsSelectProps = {
  project: Project
  roles: ProjectRole[]
  authGroups: number[]
  readOnly?: boolean
  onRolesChange?: (roles: ProjectRole[]) => void
  onAuthGroupsChange?: (authGroups: number[]) => void
}

type FormSchema = {
  authGroups: number[]
  topLevelRole: ProjectRole
  projectPermissions: ProjectRole[]
}

export const ProjectUserPermissionsSelect: React.FC<
  ProjectUserPermissionsSelectProps
> = ({
  project,
  roles = [],
  authGroups: authGroupsProp,
  onRolesChange,
  onAuthGroupsChange,
  readOnly,
}) => {
  const initialProjectPermissions = () => {
    const topLevelRoles = ['PROJECT_ADMIN', 'PROJECT_VIEWER', 'SUPERVISOR']
    return roles.filter((role) => !topLevelRoles.includes(role))
  }
  const initialTopLevelRole = () => {
    if (roles?.includes('PROJECT_ADMIN')) {
      return 'PROJECT_ADMIN'
    } else if (roles?.includes('PROJECT_VIEWER')) {
      return 'PROJECT_VIEWER'
    } else if (roles?.includes('SUPERVISOR') || roles?.includes('OPERATIVE')) {
      return 'SUPERVISOR'
    } else if (initialProjectPermissions()?.length) {
      return 'SUPERVISOR'
    } else {
      return null
    }
  }

  const { watch, setValue, getValues, control } = useForm<FormSchema>({
    defaultValues: {
      authGroups: authGroupsProp,
      topLevelRole: initialTopLevelRole(),
      projectPermissions: initialProjectPermissions(),
    },
  })

  // When the component is readOnly, we set the values in the form, making it a fully controlled component
  // This is necessary so that when we refresh the user details the read only component shows the latest values from the API
  useDeepCompareEffect(() => {
    if (readOnly) {
      setValue('topLevelRole', initialTopLevelRole())
      setValue('projectPermissions', initialProjectPermissions())
      setValue('authGroups', authGroupsProp)
    }
  }, [authGroupsProp, roles])

  const topLevelRole = watch('topLevelRole')
  const projectPermissions = watch('projectPermissions')
  const authGroups = watch('authGroups')

  const topLevelOptions: ProjectRole[] = ['PROJECT_VIEWER', 'SUPERVISOR']
  if (project.canEdit() || readOnly) {
    topLevelOptions.push('PROJECT_ADMIN')
  }
  const projectPermissionOptions: ProjectRole[] = ['AUTHORISED_PERSON']

  const { data: authGroupOptions } = useGetAuthGroups(
    {
      projectId: project?.id,
      size: 10000,
    },
    {
      enabled: !!project,
    },
  )

  // This function is called after any field in the form changes to update the roles and authGroups in the parent component
  // The reason for using a separate function is that the API is not aware of the concept of the top level role
  // TODO: move this logic to the backend in a new endpoint
  const onFormChange = () => {
    if (readOnly) {
      return
    }

    // We fetch the values so that we get the latest
    const values = getValues()

    let roles = []
    let authGroups = values.authGroups

    if (
      values.topLevelRole &&
      !(
        values.topLevelRole === 'SUPERVISOR' &&
        values.projectPermissions?.length
      )
    ) {
      roles.push(values.topLevelRole)
    }

    values.projectPermissions?.forEach((p) => {
      roles.push(p)
    })

    // For Project Viewers we remove all project permissions and auth groups
    if (values.topLevelRole === 'PROJECT_VIEWER') {
      roles = ['PROJECT_VIEWER']
      authGroups = []
    }

    // For Project Admins we remove all project permissions since they already have them
    if (values.topLevelRole === 'PROJECT_ADMIN') {
      roles = ['PROJECT_ADMIN']
    }

    onRolesChange(roles)
    onAuthGroupsChange(authGroups)
  }

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id='role-select-label'>
          {localizedStrings.userType}
        </InputLabel>
        <Controller
          name={'topLevelRole'}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              labelId='role-select-label'
              id='role-select'
              disabled={readOnly}
              sx={{ mb: 1 }}
              label={localizedStrings.userType}
              value={value}
              onChange={(values) => {
                onChange(values)
                onFormChange()
              }}
              onBlur={onBlur}
            >
              {topLevelOptions?.map((role) => (
                <MenuItem key={role} value={role}>
                  {ProjectUser.getRoleString(role)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>

      <TopLevelRoleInfo role={topLevelRole} />

      {project.canEdit() &&
        ['SUPERVISOR'].includes(topLevelRole) &&
        (!readOnly || (readOnly && !!initialProjectPermissions().length)) && (
          <FormControl fullWidth>
            <InputLabel id='auth-group-select-label'>
              {localizedStrings.projectPermissions}
            </InputLabel>
            <Controller
              name={'projectPermissions'}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  labelId='auth-group-select-label'
                  id='auth-group-select'
                  sx={{ mb: 2 }}
                  multiple
                  disabled={readOnly}
                  label={localizedStrings.projectPermissions}
                  value={value}
                  onChange={(event) => {
                    onChange(event)
                    onFormChange()
                  }}
                  onBlur={onBlur}
                  renderValue={(selected) => {
                    return selected?.map((value) => (
                      <Chip
                        sx={{ mr: 1 }}
                        key={value}
                        label={ProjectUser.getRoleString(value)}
                      />
                    ))
                  }}
                >
                  {projectPermissionOptions?.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {ProjectUser.getRoleString(role)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        )}

      {project.canEdit() &&
        ['PROJECT_ADMIN', 'SUPERVISOR'].includes(topLevelRole) &&
        (!readOnly || (readOnly && !!authGroups.length)) && (
          <FormControl fullWidth>
            <InputLabel id='auth-group-select-label'>
              {localizedStrings.authorisationGroups}
            </InputLabel>
            <Controller
              name={'authGroups'}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  labelId='auth-group-select-label'
                  id='auth-group-select'
                  sx={{ mb: 2 }}
                  multiple
                  disabled={readOnly}
                  label={localizedStrings.authorisationGroups}
                  value={value}
                  onChange={(values) => {
                    onChange(values)
                    onFormChange()
                  }}
                  onBlur={onBlur}
                  renderValue={(selected) => {
                    return selected?.map((value) => (
                      <Chip
                        sx={{ mr: 1 }}
                        key={value}
                        label={
                          authGroupOptions?.content?.find((a) => a.id === value)
                            ?.name
                        }
                      />
                    ))
                  }}
                >
                  {authGroupOptions?.content?.map((authGroup) => (
                    <MenuItem key={authGroup.id} value={authGroup.id}>
                      {authGroup.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        )}
    </>
  )
}

const TopLevelRoleInfo: React.FC<{ role: ProjectRole }> = ({ role }) => {
  const infoText = () => {
    switch (role) {
      case 'PROJECT_ADMIN':
        return localizedStrings.projectAdminInfo
      case 'PROJECT_VIEWER':
        return localizedStrings.projectViewerInfo
      case 'SUPERVISOR':
        return localizedStrings.operativeInfo
      default:
        return ''
    }
  }

  return (
    <>
      {role && (
        <Alert sx={{ mb: 2, flexDirection: 'row' }} severity='info'>
          {infoText()}
          <Box flexGrow={1} />
          <Button
            startIcon={<HelpOutlined />}
            onClick={() => {
              window.open(
                'https://help.siteassist.co.uk/en/articles/6882465-project-roles',
                '_blank',
              )
            }}
          >
            {localizedStrings.readMore}
          </Button>
        </Alert>
      )}
    </>
  )
}
