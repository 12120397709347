import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { AuditResult, IAuditResult } from '@core/entities/audit/AuditResult'

const auditResultTransform: (
  data: Array<IAuditResult>,
) => Array<AuditResult> = (data) => {
  return data.map((audit) => AuditResult.new(audit))
}

export const usePermitParticipantTimeline = (
  {
    permitId,
    participantId,
  }: RequestTypes['getTimelineByParticipant'] & RequestTypes['getPermit'],
  options?: UseQueryOptions<Array<IAuditResult>, string, Array<AuditResult>>,
) => {
  return useQuery({
    select: auditResultTransform,
    ...permitKeys
      .byId({ permitId })
      ._ctx.participantTimeline({ participantId }),
    ...options,
  })
}
