import { AllFilterTypes } from '@core/entities/filters/types'

export const registerFilterToStoreValue = (filter: AllFilterTypes) => {
  switch (filter.propertyType) {
    default:
    case 'STRING':
      return filter.textValue
    case 'SELECT':
      return filter.selectedOptionUuids
    case 'DATE':
      return {
        startTime: filter?.startDate,
        endTime: filter?.endDate,
        dateValue: filter?.dateValue,
      }
    case 'NUMBER':
      return filter.numberValue
    case 'USER':
      return filter.userId
    case 'DIAGRAM':
      return filter.annotationSetId
    case 'INSPECTION':
      return filter.inspectionId
  }
}
