import { localizedStrings } from '@core/strings'
import { MenuItem, TextField } from '@common/material'
import { Box, Flex } from '@fundamentals'
import DatePicker from 'components/DatePicker'
import { useState } from 'react'
import {
  AdvancedDateFilterInternalArguments,
  AdvancedDateFilterValue,
  advancedDateFilterOptionConditions,
  advancedDateFilterOperatorsWithoutArguments,
} from '@core/providers/filters'
import { AdvancedFilterPopover } from './AdvancedFilterPopover'

type AdvancedDateFilterProps = {
  filter: AdvancedDateFilterInternalArguments
  onChange: ({ value, operator }: AdvancedDateFilterValue) => void
  storeValue: AdvancedDateFilterValue
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (el: HTMLButtonElement | null) => void
}

export const AdvancedDateFilter: React.FC<AdvancedDateFilterProps> = ({
  filter,
  onChange,
  storeValue,
  anchorEl,
  setAnchorEl,
}) => {
  const [dateCondition, setDateCondition] = useState(
    storeValue?.operator ?? advancedDateFilterOptionConditions[0].value,
  )
  const hideArguments =
    advancedDateFilterOperatorsWithoutArguments.includes(dateCondition)

  const startDate = storeValue?.value?.startTime as string
  const endDate = storeValue?.value?.endTime as string
  const dateValue = storeValue?.value?.dateValue as string

  const [dateRange, setDateRange] = useState(
    (startDate && endDate && [new Date(startDate), new Date(endDate)]) ?? [],
  )

  const _onChange = (dates) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates
      setDateRange([start, end])

      if (!start && !end) {
        setDateRange([])
      }
    }
  }

  const isRange = dateCondition === 'IS_BETWEEN'

  return (
    <AdvancedFilterPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <Flex flexDirection='column' p={2}>
        <TextField
          value={dateCondition}
          select
          size='small'
          label='Select filter operator'
          sx={{
            mb: 2,
            minWidth: 200,
          }}
          SelectProps={{
            onChange(e) {
              const operator = e.target
                .value as AdvancedDateFilterValue['operator']

              setDateCondition(
                e.target.value as AdvancedDateFilterValue['operator'],
              )
              setDateRange([])

              if (
                advancedDateFilterOperatorsWithoutArguments.includes(operator)
              ) {
                onChange({
                  value: null,
                  operator,
                })
                return
              }

              storeValue?.value?.dateValue &&
                e.target.value !== 'IS_BETWEEN' &&
                onChange({
                  value: {
                    dateValue: storeValue?.value?.dateValue,
                  },
                  operator: e.target
                    .value as AdvancedDateFilterValue['operator'],
                })
            },
          }}
        >
          {advancedDateFilterOptionConditions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {!hideArguments && (
          <Box
            sx={{
              '.react-datepicker__input-container input': {
                height: 'auto',
                background: 'none',
                border: 'none!important',
                color: 'inherit!important',
              },
              width: '300px',
              height: '310px',
            }}
          >
            <DatePicker
              UTC={filter.UTC}
              name='test'
              open={true}
              selected={
                storeValue?.value?.dateValue ? new Date(dateValue) : null
              }
              dateFormat='do MMM yyyy'
              selectsRange={isRange}
              placeholderText={localizedStrings.date}
              startDate={dateRange?.[0] ? new Date(dateRange?.[0]) : null}
              endDate={dateRange?.[1] ? new Date(dateRange?.[1]) : null}
              onChange={(date) => {
                _onChange(date)
                isRange
                  ? !!date?.[1] &&
                    onChange({
                      value: {
                        startTime: date?.[0]?.toISOString(),
                        endTime: date?.[1]?.toISOString(),
                      },
                      operator: dateCondition,
                    })
                  : onChange({
                      value: {
                        dateValue: date?.toISOString(),
                      },
                      operator: dateCondition,
                    })
              }}
              customInput={
                <TextField
                  fullWidth
                  size='small'
                  label={'Select date'}
                  placeholder={localizedStrings.typeHere}
                  InputLabelProps={{ shrink: true }}
                />
              }
            />
          </Box>
        )}
      </Flex>
    </AdvancedFilterPopover>
  )
}
