import { localizedStrings } from '@core/strings'
import { Box, Flex, Image, Text } from '@fundamentals'
import { useTheme } from '@mui/material/styles'
import React, { FunctionComponent } from 'react'
import { Collapse, Divider, TypographyProps } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { Card, LinearProgress } from '@common/material'
import { TablePagination } from '@elements'
import { UserAvatar } from '@common/UserAvatar'
import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'
import { TableProps } from '@common/Table/types'

export const Table: <T>(p: TableProps<T>) => React.ReactElement = ({
  columns,
  data,
  rowKey,
  onRowClick,
  rowSx,
  wrapperSx,
  dataTestPrefix,
  rowDataId,
  withoutChevron = false,
  withoutCard = false,
  noItemsFoundText = localizedStrings.noResultsFound,
  pagination,
  noHeader,
  loading,
  rowAccentColor,
  rowActions,
}) => {
  const theme = useTheme()
  const Wrapper = withoutCard ? Box : Card
  return (
    <Wrapper
      data-test={dataTestPrefix}
      sx={{
        borderBottom: 0,
        ...wrapperSx,
      }}
    >
      {!noHeader && (
        <TableHeader
          columns={columns}
          theme={theme}
          withoutCard={withoutCard}
          withoutChevron={withoutChevron}
        />
      )}
      {loading && <LinearProgress />}
      <Box>
        <TransitionGroup>
          {data?.length > 0 ? (
            data?.map((rowData, index) => {
              const key = rowKey ? rowKey(rowData) : index
              return (
                <Collapse key={key}>
                  <TableRow
                    key={key}
                    columns={columns}
                    rowData={rowData}
                    onClick={onRowClick}
                    rowSx={rowSx}
                    withoutChevron={withoutChevron}
                    dataTest={
                      dataTestPrefix ? `${dataTestPrefix}-row-${index}` : ''
                    }
                    dataId={rowDataId ? `${rowDataId(rowData)}` : ''}
                    withoutCard={withoutCard}
                    rowAccentColor={rowAccentColor}
                    rowActions={rowActions}
                  />
                  <Divider />
                </Collapse>
              )
            })
          ) : (
            <Collapse>
              <Flex data-test='no-results' p={3}>
                <TablePrimaryText>{noItemsFoundText}</TablePrimaryText>
              </Flex>
              <Divider />
            </Collapse>
          )}
        </TransitionGroup>
      </Box>
      {loading && <LinearProgress />}
      {pagination?.page && (
        <TablePagination
          page={pagination?.page?.number}
          size={pagination?.page?.size}
          count={pagination?.page?.totalElements}
          withoutCard={withoutCard}
          onChange={pagination?.onPageChange}
        />
      )}
    </Wrapper>
  )
}

export const TablePrimaryText: FunctionComponent<TypographyProps> =
  React.forwardRef(({ children, ...props }, ref) => {
    return (
      <Text
        ref={ref}
        data-test='table-primary-text'
        fontSize={14}
        fontWeight={400}
        {...props}
      >
        {children}
      </Text>
    )
  })

export const TableSecondaryText: FunctionComponent<
  {
    wordBreak?: 'break-all' | 'break-word'
  } & TypographyProps
> = ({ children, ...rest }) => {
  return (
    <Text
      data-test='table-secondary-text'
      fontSize={14}
      sx={{
        opacity: 0.6,
      }}
      {...{ wordBreak: 'break-word', ...rest }}
    >
      {children}
    </Text>
  )
}

type TableUserSummaryProps = {
  user: {
    id: number
    avatarUrl?: string
    firstName: string
    lastName: string
    email: string
    company: string
  }
}

export const TableUserSummaryCard: React.FC<TableUserSummaryProps> = ({
  user,
}) => (
  <Flex data-test={`user-${user?.id}`}>
    {user?.avatarUrl ? (
      <Image
        data-test='avatar'
        alt='profile'
        width={36}
        height={36}
        src={user.avatarUrl}
      />
    ) : (
      <UserAvatar user={user} />
    )}
    <Box ml={2} overflow='hidden'>
      <TablePrimaryText>
        {`${user?.firstName} ${user?.lastName}`}
      </TablePrimaryText>
      <TableSecondaryText noWrap>{user?.email}</TableSecondaryText>
      <TableSecondaryText noWrap>{user?.company}</TableSecondaryText>
    </Box>
  </Flex>
)
