import { localizedStrings } from '@core/strings'
import React from 'react'
import {
  Breadcrumbs as MaterialBreadcrumbs,
  Link as MaterialLink,
} from '@common/material'
import { useDeepCompareMemo } from 'use-deep-compare'
import Link from 'next/link'
import { useNavigationContext } from '@common/hooks'
import { PageUrls } from '@core/page-urls'
import { useGetPermitTemplate } from '@core/react-query/features/templates/permitTemplates/permitTemplate'
import { PageLayoutVariant } from 'webapp/components/elements/PageLayouts/types'
import { useQueryClient } from '@tanstack/react-query'
import { BusinessUnit } from '@core/entities/businessUnit'
import { useGetOrganisation } from '@core/react-query/features/organisations/organisation/hooks/useGetOrganisation'
import { OrganisationLogo } from '../OrganisationLogo'
import { Box } from '@common/fundamentals'
import { useRouter } from 'next/router'
import { useGetProject } from '@core/react-query/features/projects/project/hooks/useGetProject'
import {
  useGetChecklistTemplate,
  useGetChecklistTemplateVersion,
} from '@core/react-query/features/templates/checklistTemplates'
import { usePermitById } from '@core/react-query/features/permits/permit'
import { useGetPermitTemplateVersion } from '@core/react-query/features/templates/permitTemplateEditor'

export type NavigationAwareSidebarProps = {
  variant?: PageLayoutVariant
}

export const NavigationAwareBreadcrumbs: React.FC<
  NavigationAwareSidebarProps
> = ({ variant }) => {
  const router = useRouter()
  const color = variant === 'dashboard' ? 'white' : 'inherit'
  const context = useNavigationContext()
  const {
    organisationId,
    projectId,
    permitId,
    templateId,
    businessUnitId,
    templateVersionId,
    checklistTemplateVersionId,
    checklistTemplateId,
  } = context
  const queryClient = useQueryClient()
  const businessUnit: BusinessUnit = queryClient.getQueryData([
    'business-units',
    'getBusinessUnit',
    businessUnitId,
  ])

  const { data: organisation } = useGetOrganisation({ organisationId })
  const { data: project } = useGetProject({ projectId })
  const { data: permit } = usePermitById({ permitId }, { enabled: !!permitId })

  const { data: template } = useGetPermitTemplate({
    id: templateId,
  })

  const { data: permitTemplateVersion } = useGetPermitTemplateVersion(
    {
      templateId: templateId,
      versionId: templateVersionId,
    },
    {
      enabled: !!templateId && !!templateVersionId,
    },
  )
  const { data: checklistTemplate } = useGetChecklistTemplate(
    {
      templateId: checklistTemplateId,
    },
    {
      enabled: !!checklistTemplateId,
    },
  )

  const { data: checklistTemplateVersion } = useGetChecklistTemplateVersion(
    {
      templateId: checklistTemplateId,
      templateVersionId: checklistTemplateVersionId,
    },
    {
      enabled: !!checklistTemplateId && !!checklistTemplateVersionId,
    },
  )

  const breadCrumbs = useDeepCompareMemo(() => {
    const crumbs: { href: string; label: string }[] = []
    if (context.inSupportContext) {
      return []
    }

    if (context.inBusinessUnitContext) {
      crumbs.push({
        href: PageUrls.businessUnits(context.organisationId),
        label: localizedStrings.businessUnits,
      })
    }

    if (businessUnit?.name) {
      crumbs.push({
        href: PageUrls.businessUnitOverview({
          id: context.organisationId,
          businessUnitId: context.businessUnitId,
        }),
        label: businessUnit?.name,
      })
    }

    if (context.inProjectContext) {
      crumbs.push({
        href: PageUrls.projects(context.organisationId),
        label: localizedStrings.projects,
      })
    }

    if (project?.name) {
      crumbs.push({
        href: PageUrls.projectOverview({
          organisationId: context.organisationId,
          projectId: context.projectId,
        }),
        label: project.name,
      })
    }

    if (templateId === template?.id && template?.name) {
      crumbs.push({
        href: context.projectId
          ? PageUrls.projectPermitTemplates({
              organisationId: context.organisationId,
              projectId: context.projectId,
            })
          : PageUrls.editTemplate(context.organisationId, context.templateId),
        label: template?.name,
      })
    }

    if (checklistTemplate?.name) {
      crumbs.push({
        href: context.projectId
          ? PageUrls.projectViewChecklistTemplateVersion({
              orgId: context.organisationId,
              projectId: context.projectId,
              templateId: context.checklistTemplateId,
              versionId: context.checklistTemplateVersionId,
            })
          : PageUrls.editChecklistTemplate(
              context.organisationId,
              context.checklistTemplateId,
            ),
        label: checklistTemplate?.name,
      })
    }

    if (permitTemplateVersion?.version) {
      crumbs.push({
        href: context.projectId
          ? PageUrls.projectViewTemplateVersion({
              orgId: context.organisationId,
              projectId: context.projectId,
              templateId: context.templateId,
              versionId: context.templateVersionId,
            })
          : PageUrls.editTemplateVersion({
              orgId: context.organisationId,
              templateId: context.templateId,
              versionId: context.templateVersionId,
            }),
        label: `V${permitTemplateVersion?.version}`,
      })
    }

    if (checklistTemplateVersion?.version) {
      crumbs.push({
        href: context.projectId
          ? PageUrls.projectViewChecklistTemplateVersion({
              orgId: context.organisationId,
              projectId: context.projectId,
              templateId: context.checklistTemplateId,
              versionId: context.checklistTemplateVersionId,
            })
          : PageUrls.editChecklistTemplateVersion({
              orgId: context.organisationId,
              templateId: context.checklistTemplateId,
              versionId: context.checklistTemplateVersionId,
            }),
        label: `${checklistTemplateVersion?.version}`,
      })
    }

    if (context.inAnalyticsContext) {
      crumbs.push({
        href: context.inProjectContext
          ? PageUrls.projectAnalytics({
              organisationId: context.organisationId,
              projectId: context.projectId,
            })
          : PageUrls.organisationAnalytics({
              organisationId: context.organisationId,
            }),
        label: 'Analytics',
      })
    }

    if (context.authorisationGroupId) {
      crumbs.push({
        href: context.inProjectContext
          ? PageUrls.projectAuthorisationGroupList({
              organisationId,
              projectId,
            })
          : PageUrls.organisationAuthorisationGroupList({
              organisationId,
            }),
        label: 'Groups',
      })
      crumbs.push({
        href: context.inProjectContext
          ? PageUrls.projectAuthorisationGroup({
              organisationId: context.organisationId,
              projectId: context.projectId,
              groupId: context.authorisationGroupId,
            })
          : PageUrls.organisationAuthorisationGroup({
              organisationId: context.organisationId,
              groupId: context.authorisationGroupId,
            }),
        label: `${context.authorisationGroupId}`,
      })
    }

    const breadcrumbs = crumbs.map((c) => (
      <Link key={c.href} href={c.href}>
        <MaterialLink
          href={c.href}
          color={color}
          underline={'hover'}
          sx={{
            '&:hover': {
              color: color,
            },
          }}
        >
          {c.label}
        </MaterialLink>
      </Link>
    ))

    const logo =
      !organisation && !project
        ? []
        : [
            <Box
              key={'organisation-logo'}
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                router.push(PageUrls.organisation(organisation.id))
              }
            >
              <OrganisationLogo
                url={project ? project.logoUrl : organisation.logoUrl}
              />
            </Box>,
          ]

    return [...logo, ...breadcrumbs]
  }, [
    context,
    organisation?.name,
    project?.name,
    template?.name,
    permit?.template?.name,
    businessUnit?.name,
    permitTemplateVersion?.version,
    checklistTemplate?.name,
    checklistTemplateVersion?.version,
    organisation,
    project,
  ])

  const dashboardOverrides = {
    color: 'white',
    '& .MuiBreadcrumbs-separator': {
      color: 'white',
    },
    '& .MuiBreadcrumbs-li': {
      opacity: 1,
    },
  }

  return (
    <MaterialBreadcrumbs sx={variant === 'dashboard' && dashboardOverrides}>
      {breadCrumbs}
    </MaterialBreadcrumbs>
  )
}
