import { localizedStrings } from '@core/strings'
import { ProjectUser } from '@core/entities/user'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LoadingButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useState } from 'react'
import { useModal } from '@common/GlobalModal'
import { TableUserSummaryCard } from '@common/Table'
import { Box, Flex } from '@fundamentals'
import { Project } from '@core/entities/project'
import { useSetProjectUserAuthGroups } from '@core/react-query/features/projects/project/hooks/useSetProjectUserAuthGroups'
import { useSetProjectUserRoles } from '@core/react-query/features/projects/project/hooks/useSetProjectUserRoles'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'
import { ProjectUserPermissionsSelect } from '@modules/projectTeamAndRoles/ProjectUserPermissionsSelect'

type ChangeProjectUserAuthGroupsModalProps = {
  project: Project
  user: ProjectUser
}

export const ChangeProjectUserPermissions: React.FC<
  ChangeProjectUserAuthGroupsModalProps
> = ({ project, user }) => {
  const { hideModal } = useModal()
  const [roles, setRoles] = useState(user?.roles)
  const setAuthGroupsMutation = useSetProjectUserAuthGroups()
  const [selectedAuthGroups, setSelectedAuthGroups] = useState<number[]>(
    user?.authGroups?.map((a) => a.id),
  )
  const setRoleMutation = useSetProjectUserRoles()
  const toast = useToast()

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '80%', flex: 1 } }}>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {project.name} - {localizedStrings.permissions}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Flex flexDirection={'column'} justifyContent={'flex-start'}>
          <Box mb={4}>
            <TableUserSummaryCard user={user} />
          </Box>
          <ProjectUserPermissionsSelect
            project={project}
            roles={roles}
            authGroups={selectedAuthGroups}
            onRolesChange={(roles) => setRoles(roles)}
            onAuthGroupsChange={setSelectedAuthGroups}
          />
        </Flex>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <LoadingButton
          variant='contained'
          data-test='confirm-yes'
          loading={setAuthGroupsMutation.isLoading || setRoleMutation.isLoading}
          onClick={async () => {
            await setAuthGroupsMutation.mutateAsync(
              {
                projectId: project.id,
                userId: user.id,
                authorisationGroups: selectedAuthGroups,
              },
              {
                onError: (e) => toast.error(e),
              },
            )

            await setRoleMutation.mutateAsync(
              {
                projectId: project.id,
                userId: user.id,
                roles: roles,
              },
              {
                onError: (e) => toast.error(e),
              },
            )
            toast.success(localizedStrings.userUpdated)
            hideModal()
          }}
        >
          {localizedStrings.confirm}
        </LoadingButton>
      </DialogActions>
    </ModalDialogContainer>
  )
}
