import React, { forwardRef } from 'react'
import cn from 'classnames'
import { Box, BoxProps, Flex, Text } from '@fundamentals'

type CardProps = BoxProps & {
  className?: any
  action?: React.ReactNode
  title?: string | React.ReactNode
  icon?: string
  children?: React.ReactNode
  onClick?: () => void
  ref?:
    | React.RefObject<HTMLDivElement>
    | ((instance: React.RefObject<HTMLDivElement>) => void)
    | React.MutableRefObject<React.RefObject<HTMLDivElement>>
}

const Card: React.FC<CardProps> = forwardRef<
  React.RefObject<HTMLDivElement>,
  CardProps
>(({ className, icon, title, action, children, ...props }, ref) => (
  <Box
    ref={ref}
    className={cn({ panel: true, 'card': true }, className)}
    {...props}
  >
    <Flex className='card__heading' justifyContent='space-between'>
      <Flex flex='1' mb={title ? 2 : 0}>
        {icon && (
          <span className='panel-icon'>
            {/*// @ts-ignore*/}
            <ion className={cn({ icon: true }, icon)} />
          </span>
        )}
        {title && <Text variant='h5'>{title}</Text>}
      </Flex>
      {action}
    </Flex>
    {children}
  </Box>
))

Card.displayName = 'Card'
export default Card
