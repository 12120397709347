import { plainToInstance, Type } from 'class-transformer'
import { IFolder, FolderType } from '@core/entities/folders/Folder'
import { User } from '@core/entities/user'
import { Entity } from '@core/entities/entity'

export class Folder extends Entity<IFolder> {
  id: number
  organisationId: number
  parentId: number
  displayName: string
  type: FolderType
  createdOn: string
  modifiedOn: string

  @Type(() => User)
  createdBy: User

  public static new(payload: unknown): Folder {
    const entity = plainToInstance(Folder, payload)

    return entity
  }
}
