import { Box, Flex, Text } from '@fundamentals'
import { alpha } from '@mui/material'
import React from 'react'
import { default as legacyTheme } from '../../../../styles/theme'

export const TableHeader = ({
  columns,
  theme,
  withoutCard,
  withoutChevron,
}) => {
  return (
    <>
      <Flex
        fontWeight={800}
        borderBottom={legacyTheme.variants.table.header.borderBottom}
        borderColor={alpha('#000', 0.12)}
        py={legacyTheme.variants.card.padding}
        px={withoutCard ? 0 : legacyTheme.variants.card.padding}
        bgcolor={alpha(theme.palette.primary.main, 0.08)}
        position='sticky'
      >
        {columns.map(
          (
            { label, widthRatio = 1, renderHeader, renderColumn, ...props },
            index,
          ) => (
            <Box key={index} flex={widthRatio} {...props}>
              {renderHeader ? (
                renderHeader(label)
              ) : (
                <Text color='text.primary' fontSize={14} fontWeight='bold'>
                  {label}
                </Text>
              )}
            </Box>
          ),
        )}
        {!withoutChevron && <Box flex={0.2} />}
      </Flex>
    </>
  )
}
