import React from 'react'
import { TimelineUserBlock } from '@common/Timeline'
import { ProjectUserDetailsModal } from '@modules/projectTeamAndRoles/modals/ProjectUserDetailsModal'
import { OrganisationUserDetailsModal } from '@modules/organisationDetail/modals/OrganisationUserDetailsModal'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { useModal } from '@common/GlobalModal'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineUserBlocks: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  const { showModal } = useModal()
  return (
    <>
      {auditResult.projectUser && (
        <TimelineUserBlock
          user={auditResult.projectUser}
          onEdit={() => {
            showModal(
              <ProjectUserDetailsModal
                projectId={auditResult.project.id}
                userId={auditResult.projectUser.id}
              />,
            )
          }}
        />
      )}
      {auditResult.organisationUser && (
        <TimelineUserBlock
          user={auditResult.organisationUser}
          onEdit={() => {
            showModal(
              <OrganisationUserDetailsModal
                organisationId={auditResult.organisation.id}
                userId={auditResult.organisationUser.id}
              />,
            )
          }}
        />
      )}
      {auditResult.appliedToUser && (
        <TimelineUserBlock user={auditResult.appliedToUser} />
      )}
    </>
  )
}
