import React, { FC, useMemo } from 'react'
import dynamic from 'next/dynamic'
import { Box } from './fundamentals'
import { FormLabel, SxProps, Theme } from '@mui/material'
import { TextInput } from './material'
import 'react-quill/dist/quill.snow.css'

type TextEditorProps = {
  value: string
  onChange?: (data: string) => void
  label?: string
  name?: string
  'data-test'?: string
  containerId?: string
  placeholderChar?: string
  plainText?: boolean
  sx?: SxProps<Theme>
  error?: boolean
}

export const TextEditor: FC<TextEditorProps> = ({
  value,
  onChange,
  placeholderChar,
  name,
  label,
  'data-test': dataTest,
  containerId,
  plainText,
  error,
  sx,
}) => {
  const ReactQuill = useMemo(
    () => dynamic(() => import('react-quill'), { ssr: false }),
    [],
  )

  if (Constants.E2E || plainText) {
    return (
      <TextInput
        name={name}
        data-test={dataTest}
        value={value}
        label={label}
        placeholder={placeholderChar}
        onChange={(e) => onChange(e.target.value)}
        sx={sx}
      />
    )
  }

  return (
    <>
      <Box
        sx={{
          '.ql-toolbar': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderColor: error && 'error.main',
          },
          '.ql-container': {
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            borderColor: error && 'error.main',
          },
          ...sx,
        }}
      >
        <FormLabel error={error}>{label}</FormLabel>
        <ReactQuill
          theme='snow'
          bounds={containerId}
          data-test={dataTest}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'link'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ],
          }}
          value={value}
          onChange={onChange}
        />
      </Box>
    </>
  )
}
