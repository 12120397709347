import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectPlanDashboardCountByArea } from './IProjectPlanDashboardCountByArea'

export class ProjectPlanDashboardCountByArea extends Entity<IProjectPlanDashboardCountByArea> {
  areaUuid: string

  areaName: string

  areaColor: string

  totalElements: number

  public static new(payload: unknown): ProjectPlanDashboardCountByArea {
    const entity = plainToInstance(ProjectPlanDashboardCountByArea, payload)

    return entity
  }
}
