import { localizedStrings } from '@core/strings'
import React from 'react'
import { PageUrls } from '@core/page-urls'
import { useRouter } from 'next/router'
import { Text, Flex } from '@fundamentals'
import { Button } from '@common/material'
import { UserAvatar } from '@common/UserAvatar'
import { useUnimpersonateUser } from '@core/react-query/features/support/hooks/useUnimpersonateUser'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import useFeatureFlags from '@core/providers/useFeatureFlags'

type NavbarProps = {
  variant: 'dashboard' | 'default'
}

const Profile: React.FC<NavbarProps> = ({ variant }) => {
  const { data: profile } = useGetProfile()
  const { flags } = useFeatureFlags(['web_notifications'])

  const unimpersonateMutation = useUnimpersonateUser()
  const router = useRouter()

  const handleUnimpersonateUser = async () => {
    await unimpersonateMutation.mutateAsync({
      unimpersonatedUserId: profile.id,
    })
    setTimeout(() => {
      router.push('/')
    }, 500)
  }

  if (!profile) return null

  return (
    <Flex mr={2} p={1} alignItems='center'>
      {profile.impersonated ? (
        <Flex>
          <Button
            variant='contained'
            size='medium'
            sx={{ mr: 2 }}
            data-test='impersonate-user-button'
            onClick={handleUnimpersonateUser}
          >
            {localizedStrings.unimpersonateUser}
          </Button>
          <Text
            alignSelf='center'
            fontSize={15}
            color={variant === 'dashboard' ? 'info' : 'primary'}
          >
            {profile.impersonated ? 'Impersonated' : null}
          </Text>
        </Flex>
      ) : null}

      {!flags.web_notifications && (
        <Text
          mr={2}
          alignSelf='center'
          color={variant === 'dashboard' ? 'white' : 'inherit'}
        >
          {profile?.firstName} {profile?.lastName}
        </Text>
      )}

      <UserAvatar
        user={profile}
        onClick={() =>
          router.push(
            PageUrls.profile({
              organisationId: profile?.organisations?.[0]?.id,
            }),
          )
        }
      />
    </Flex>
  )
}

export { Profile }
