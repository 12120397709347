import { localizedStrings } from '@core/strings'
import React from 'react'
import { Button, DropdownButton, IconButton } from '@common/material'
import { ArrowDropDown, MoreVert } from '@mui/icons-material'
import { ProjectUser } from '@core/entities/user'
import { DropdownButtonOption } from '@common/material/DropdownButton/types'
import { useModal } from '@common/GlobalModal'
import { Project } from '@core/entities/project'
import { DisableProjectUserModal } from '@modules/projectTeamAndRoles/modals/DisableProjectUserModal'
import { ReactivateProjectUserModal } from '@modules/projectTeamAndRoles/modals/ReactivateProjectUserModal'
import { ChangeProjectUserPermissions } from '@modules/projectTeamAndRoles/modals/ChangeProjectUserPermissions'

type ProjectUserListActionsProps = {
  variant: 'three-dot' | 'button'
  project: Project
  user: ProjectUser
}

export const ProjectUserActions: React.FC<ProjectUserListActionsProps> = ({
  user,
  project,
  variant,
}) => {
  const { showModal } = useModal()

  const options: DropdownButtonOption[] = []

  if (project.canChangeUserStatus() && user.status === 'ACTIVE') {
    options.push({
      title: localizedStrings.disableUser,
      'data-test': 'dd-disable-user',
      onSelect: () => {
        showModal(<DisableProjectUserModal project={project} user={user} />)
      },
    })
  }

  if (project.canChangeUserStatus() && user.status === 'DISABLED') {
    options.push({
      title: localizedStrings.reactivateUser,
      'data-test': 'dd-reactivate-user',
      onSelect: () => {
        showModal(<ReactivateProjectUserModal project={project} user={user} />)
      },
    })
  }

  if (project.canUpdateUserAuthGroups()) {
    options.push({
      title: localizedStrings.changePermissions,
      'data-test': 'dd-change-auth-groups',
      onSelect: () => {
        showModal(
          <ChangeProjectUserPermissions project={project} user={user} />,
        )
      },
    })
  }

  if (!options?.length) {
    return null
  }

  return (
    <DropdownButton
      label={localizedStrings.actions}
      options={options}
      renderButton={(onClick) => {
        if (variant === 'three-dot') {
          return (
            <IconButton
              onClick={(e) => {
                onClick(e)
                e.stopPropagation()
              }}
              onBlur={(e) => e.stopPropagation()}
              data-test='dd-project-user-actions'
            >
              <MoreVert />
            </IconButton>
          )
        }

        if (variant === 'button') {
          return (
            <Button
              onClick={(e) => {
                onClick(e)
                e.stopPropagation()
              }}
              onBlur={(e) => e.stopPropagation()}
              data-test='dd-project-user-actions'
              variant={'outlined'}
              endIcon={<ArrowDropDown />}
              sx={{
                fontSize: 16,
                mr: 1,
              }}
            >
              {localizedStrings.actions}
            </Button>
          )
        }
      }}
    />
  )
}
