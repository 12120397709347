import { localizedStrings } from '@core/strings'
import React, { FC, useState } from 'react'
import { alpha, Collapse, Divider, Typography, useTheme } from '@mui/material'
import { Box, Flex, Image, Text } from '@fundamentals'
import { Button, Chip } from '@common/material'

import { AuditResult } from '@core/entities/audit/AuditResult'
import moment from 'moment/moment'
import ComputerIcon from '@mui/icons-material/Computer'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { getTimelineStatusColor } from '@core/utils/timeline-helpers'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  OpenInNew,
} from '@mui/icons-material'

import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { useModal } from '@common/GlobalModal'
import ChecklistDetailsModal from '@modules/permitDetails/modals/ChecklistDetailsModal'
import { ProjectUserDetailsModal } from '@modules/projectTeamAndRoles/modals/ProjectUserDetailsModal'
import { OrganisationUserDetailsModal } from '@modules/organisationDetail/modals/OrganisationUserDetailsModal'
import { useNavigationContext } from '@common/hooks'
import { Property } from '@elements/Property/Property'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'

export type SupportUserTimelineItemProps = {
  auditResult: AuditResult
}

export const TimelineItemLeggacy: FC<SupportUserTimelineItemProps> = ({
  auditResult,
}) => {
  const { showModal } = useModal()
  const { organisationId } = useNavigationContext()

  const {
    signatureUrl,
    metadata,
    createdOn,
    platform,
    user,
    info,
    action,
    organisation,
    project,
    permit,
    checklist,
    organisationUser,
    projectUser,
    group,
    appliedToUser,
  } = auditResult
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const theme: any = useTheme()

  const month = moment(createdOn).format('MMM')
  const year = moment(createdOn).format('YYYY')
  const date = moment(createdOn).format('DD')
  const time = moment(createdOn).format('HH:mm')
  const platformText = () => {
    switch (platform) {
      case 'WEB':
        return `| ${localizedStrings.webBrowser}`
      case 'IOS':
        return `| ${localizedStrings.ios}`
      case 'ANDROID':
        return `| ${localizedStrings.android}`
      // The UNKNOWN platform exists because historically we didn't gather the type of device the person was using
      case 'UNKNOWN':
      default:
        return ''
    }
  }

  const platformIcon = () => {
    switch (platform) {
      case 'WEB':
        return <ComputerIcon sx={{ ml: 1 }} fontSize='small' />
      case 'IOS':
        return <PhoneIphoneIcon sx={{ ml: 1 }} fontSize='small' />
      case 'ANDROID':
        return <PhoneAndroidIcon sx={{ ml: 1 }} fontSize='small' />
      default:
        return null
    }
  }

  const timelineElementColors: {
    warning: { normal: 'warning'; alpha: string }
    error: { normal: 'error'; alpha: string }
    success: { normal: 'success'; alpha: string }
    primary: { normal: 'primary'; alpha: string }
    info: { normal: 'info'; alpha: string }
  } = {
    warning: {
      normal: 'warning',
      alpha: alpha(theme.palette.warning.main, 0.04),
    },
    error: {
      normal: 'error',
      alpha: alpha(theme.palette.error.main, 0.04),
    },
    success: {
      normal: 'success',
      alpha: alpha(theme.palette.success.main, 0.04),
    },
    primary: {
      normal: 'primary',
      alpha: alpha(theme.palette.primary.main, 0.04),
    },
    info: {
      normal: 'info',
      alpha: alpha(theme.palette.info.main, 0.04),
    },
  }

  return (
    <Flex sx={{ mb: 2 }}>
      <Box pr={2}>
        <Typography pt={2} fontSize={12} color='text.secondary'>
          {month.toUpperCase()}
        </Typography>
        <Typography>{date}</Typography>
        <Typography fontSize={12} color='text.secondary'>
          {year}
        </Typography>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Flex
        ml={2}
        p='16px 24px 16px 24px'
        borderRadius={1}
        bgcolor={
          // TODO: implement in audit result entity
          getTimelineStatusColor(action, timelineElementColors).cardColor
        }
        flex={1}
        flexDirection='column'
      >
        <Flex justifyContent='space-between'>
          <Box mb={2}>
            <Typography
              fontSize={12}
              color='text.secondary'
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {`${time} ${platformText()}`} {platformIcon()}
            </Typography>
            {user ? (
              <>
                <Flex alignItems='center' flexDirection='row'>
                  <Typography>{user.getFullName()}</Typography>
                </Flex>
                <Typography fontSize={12} color='text.secondary'>
                  {user.company}
                </Typography>
              </>
            ) : (
              <Typography pb={2}>{localizedStrings.automatedEvent}</Typography>
            )}
            {organisation && (
              <Typography sx={{ mt: 1 }}>{organisation.name}</Typography>
            )}
            {project && (
              <Typography fontSize={12} color='text.secondary'>
                {project.name}
              </Typography>
            )}
          </Box>
          <Flex flexDirection={'column'} alignItems={'flex-end'}>
            <Chip
              size='small'
              color={
                // TODO: implement in audit result entity
                getTimelineStatusColor(action, timelineElementColors).chipColor
              }
              label={auditResult.getActionLabel()}
            />
            <Box flexGrow={1} />
            {permit && (
              <Button
                sx={{ mt: 2 }}
                startIcon={<OpenInNew />}
                size={'small'}
                onClick={() =>
                  showModal(
                    <PermitDetailsModal
                      organisationId={organisation.id}
                      permitId={permit.id}
                    />,
                  )
                }
              >
                {localizedStrings.permit}
              </Button>
            )}
            {checklist && (
              <Button
                sx={{ mt: 2 }}
                startIcon={<OpenInNew />}
                size={'small'}
                onClick={() =>
                  showModal(
                    <ChecklistDetailsModal checklistId={checklist.id} />,
                  )
                }
              >
                {localizedStrings.checklist}
              </Button>
            )}
            {projectUser && (
              <Button
                sx={{ mt: 2 }}
                startIcon={<OpenInNew />}
                size={'small'}
                onClick={() =>
                  showModal(
                    <ProjectUserDetailsModal
                      projectId={project.id}
                      userId={projectUser.id}
                    />,
                  )
                }
              >
                {projectUser.getFullName()}
              </Button>
            )}
            {organisationUser && (
              <Button
                sx={{ mt: 2 }}
                startIcon={<OpenInNew />}
                size={'small'}
                onClick={() =>
                  showModal(
                    <OrganisationUserDetailsModal
                      organisationId={organisation.id}
                      userId={organisationUser.id}
                    />,
                  )
                }
              >
                {organisationUser.getFullName()}
              </Button>
            )}
          </Flex>
        </Flex>

        {info && (
          <Text pt={2} fontWeight='bold' fontSize={12}>
            {info}
          </Text>
        )}

        {appliedToUser && (
          <Property
            heading={localizedStrings.user}
            subHeading={appliedToUser.getFullName()}
          />
        )}

        {group && (
          <Property
            heading={localizedStrings.authorisationGroup}
            subHeading={group.name}
          />
        )}

        {(info ||
          signatureUrl ||
          auditResult.getCoordinates() ||
          metadata.mediaUrls.length > 0) &&
          !expanded && (
            <Box>
              <Button
                variant='text'
                size='medium'
                endIcon={<ExpandMoreIcon />}
                onClick={handleExpandClick}
                sx={{ mt: 2, ml: '-8px', pb: 0, opacity: expanded ? 0 : 1 }}
              >
                {localizedStrings.showInfo}
              </Button>
            </Box>
          )}
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          {auditResult.metadata.mediaUrlsWithType?.length > 0 && (
            <>
              <Text
                sx={{ fontSize: 12, mt: 2 }}
                color='text.secondary'
                gutterBottom
              >
                {localizedStrings.proofs}
              </Text>
              <Flex flexWrap='wrap' my={2}>
                <AttachmentInput
                  size='small'
                  inputProps={{
                    value: auditResult.metadata.mediaUrlsWithType,
                  }}
                  readOnly
                  borderLess
                />
              </Flex>
            </>
          )}

          {signatureUrl && (
            <>
              <Text
                sx={{ fontSize: 12, mt: 2 }}
                color='text.secondary'
                gutterBottom
              >
                {localizedStrings.signature}
              </Text>
              <Image mt={2} src={signatureUrl} width={170} />
            </>
          )}

          <Box>
            <Button
              variant='text'
              size='medium'
              endIcon={<ExpandLessIcon />}
              onClick={handleExpandClick}
              sx={{ mt: 2, ml: '-8px', pb: 0, opacity: !expanded ? 0 : 1 }}
            >
              {localizedStrings.hideAll}
            </Button>
          </Box>
        </Collapse>
      </Flex>
    </Flex>
  )
}
