import { localizedStrings } from '@core/strings'
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import { useModal } from '@common/GlobalModal'
import { TableUserSummaryCard } from '@common/Table'
import { Text } from '@fundamentals'
import { Organisation } from '@core/entities/organisation'
import { OrganisationUser } from '@core/entities/user/OrganisationUser'
import { useSetOrganisationUserStatus } from '@core/react-query/features/organisations/organisation/hooks/useSetOrganisationUserStatus'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

type ReactivateProjectUserModalProps = {
  organisation: Organisation
  user: OrganisationUser
}

export const ReactivateOrganisationUserModal: React.FC<
  ReactivateProjectUserModalProps
> = ({ organisation, user }) => {
  const { hideModal } = useModal()
  const { mutate } = useSetOrganisationUserStatus()
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.reactivateUser} – {organisation.name}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Alert severity='info'>
          <Text mb={1}>{localizedStrings.reactivateUserMessage}</Text>
          <TableUserSummaryCard user={user} />
        </Alert>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          data-test='confirm-yes'
          onClick={() => {
            mutate(
              {
                organisationId: organisation.id,
                userId: user.id,
                status: 'ACTIVE',
              },
              {
                onSuccess: () => {
                  toast.success(localizedStrings.userUpdated)
                  hideModal()
                },
                onError: (e) => toast.error(e),
              },
            )
          }}
        >
          {localizedStrings.confirm}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
