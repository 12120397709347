import React from 'react'
import { localizedStrings } from '@core/strings'
import { Box, Flex, Text } from '@fundamentals'
import EastIcon from '@mui/icons-material/East'
import moment from 'moment/moment'
import { TimelineBlockBase } from '@common/Timeline'

type ComponentProps = {
  extensionEndsOn: string
  previousEndsOn: string
  title?: string
}

export const TimelineExtensionBlock: React.FC<ComponentProps> = ({
  extensionEndsOn,
  previousEndsOn,
  title = localizedStrings.extensionInformation,
}) => {
  return (
    <TimelineBlockBase title={title}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Box>
          <Text>{moment(previousEndsOn).format('DD MMM YYYY')}</Text>
          <Text fontSize={12}>{moment(previousEndsOn).format('HH:mm')}</Text>
        </Box>
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Box>
          <Text>{moment(extensionEndsOn).format('DD MMM YYYY')}</Text>
          <Text fontSize={12}>{moment(extensionEndsOn).format('HH:mm')}</Text>
        </Box>
      </Flex>
    </TimelineBlockBase>
  )
}
