const colours = {
  borderColour: '#7302fd',
  selectedFillColour: '#ff975b',
  unselectedFillColour: '#835bfc',
}

export const existingZoneFillStyleWeb = () => ({
  'fill-color': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    colours.selectedFillColour,
    colours.unselectedFillColour,
  ],
  'fill-opacity': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    0.7,
    0.5,
  ],
})

export const existingZoneLineStyleWeb = () => ({
  'line-color': colours.borderColour,
  'line-gap-width': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    1,
    0,
  ],
  'line-width': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    2,
    3,
  ],
  'line-opacity': 1,
})

export const existingZoneExtrusionStyleWeb = () => ({
  'fill-extrusion-color': '#ff975b',
  'fill-extrusion-opacity': 0.7,
  'fill-extrusion-height': 1,
})

export const existingZoneFillStyleMobile = () => ({
  fillColor: [
    'case',
    ['get', 'selected'],
    colours.selectedFillColour, // If true
    colours.unselectedFillColour, // If false
  ],
  fillOpacity: [
    'case',
    ['get', 'selected'],
    0.7, // If true
    0.5, // If false
  ],
})

export const existingZoneLineStyleMobile = () => ({
  lineColor: colours.borderColour, // Assuming colour is constant; adapt if needed
  lineGapWidth: [
    'case',
    ['get', 'selected'],
    1.5, // If true
    0, // If false
  ],
  lineWidth: [
    'case',
    ['get', 'selected'],
    2, // If true
    3, // If false
  ],
  lineOpacity: 1,
})

export const existingZoneFillExtrusionStyleMobile = () => ({
  /* !!!HACK ALERT!!!
   * Property `visibility` is crashing on Android devices and not working on iOS.
   * So we use `fillExtrusionColor` to set the colour of the extrusion. When selected, it will be orange, otherwise purple.
   */
  fillExtrusionColor: [
    'case',
    ['get', 'selected'],
    colours.selectedFillColour, // If true
    colours.unselectedFillColour, // If false
  ],
  fillExtrusionOpacity: 0.5,
  fillExtrusionHeight: 1,
})

export const newZoneFillStyleMobile = (hovered?: boolean) => ({
  fillColor: hovered ? '#28eaa8' : 'orange',
  fillOpacity: 0.2,
})

export const newZoneLineStyleMobile = (hovered?: boolean) => ({
  lineColor: 'orange',
  lineGapWidth: hovered ? 1.5 : 0,
  lineWidth: hovered ? 2 : 3,
  lineOpacity: 1,
})
