import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { RequestTypes } from './requestTypes'

const warningsQueryKeys = createQueryKeys('warnings', {})

const warningsMutationKeys = createMutationKeys('warnings', {
  provideWarningFeedback: {
    mutationKey: null,
    mutationFn: async ({
      warningId,
      comment,
    }: RequestTypes['provideWarningFeedback']) => {
      const { data } = await APIAxiosInstance.post(
        `warnings/${warningId}/feedback`,
        { comment },
      )

      return data
    },
  },
})

const warningsKeys = mergeQueryKeys(
  warningsQueryKeys,
  warningsMutationKeys,
).warnings

export { warningsKeys }
