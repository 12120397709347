import { RegisterItemStringProperty } from '@core/entities/register/RegisterItem'
import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { Button, Tooltip } from '@common/material'
import { OpenInNew } from '@mui/icons-material'
import { localizedStrings } from '@core/strings'
import { PageUrls } from '@core/page-urls'
import { RegisterItemText } from '@modules/registers/items/RegisterItemText'
import { RegisterItemContentButtons } from '@modules/registers/propertyValue/RegisterItemPropertyValueButtons'
import { Box } from '@fundamentals'
import { emptyValue } from '@modules/registers/propertyValue/helpers'

type RegisterItemPropertyStringValueProps = {
  registerItemPropertyData: RegisterItemStringProperty<string>
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertyStringValue: React.FC<
  RegisterItemPropertyStringValueProps
> = ({ registerItemPropertyData, variant }) => {
  const expression =
    // eslint-disable-next-line
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  const regex = new RegExp(expression)
  const isUrl =
    (registerItemPropertyData?.textValue?.startsWith('http') ||
      registerItemPropertyData?.textValue?.startsWith('https')) &&
    registerItemPropertyData?.textValue?.match(regex)

  const maxChars = variant === 'picker' ? 75 : 250

  if (isUrl) {
    return (
      <Tooltip title={registerItemPropertyData?.textValue} arrow>
        <Button
          startIcon={<OpenInNew />}
          variant={'outlined'}
          onClick={() =>
            window.open(
              registerItemPropertyData?.textValue,
              '_blank',
              'noopener, noreferrer',
            )
          }
        >
          {localizedStrings.open}
        </Button>
      </Tooltip>
    )
  }

  const truncatedText = () => {
    if (!registerItemPropertyData?.textValue) {
      return {
        isTruncated: false,
        text: emptyValue(variant),
      }
    }
    if (registerItemPropertyData?.textValue?.length <= maxChars) {
      return {
        isTruncated: false,
        text: registerItemPropertyData?.textValue,
      }
    }
    return {
      isTruncated: true,
      text: registerItemPropertyData?.textValue?.substring(0, maxChars) + '...',
    }
  }

  const truncated = truncatedText()

  if (registerItemPropertyData?.linkedItem) {
    return (
      <Tooltip title={localizedStrings.openLinkedItem}>
        <Button
          variant='text'
          sx={{
            textDecoration: 'underline',
            justifyContent: 'flex-start',
            userSelect: 'text',
            padding: 0,
            flex: 1,
          }}
          onClick={() => {
            const url = registerItemPropertyData?.linkedItem.projectId
              ? PageUrls.projectRegisterItemDetails({
                  organisationId:
                    registerItemPropertyData.linkedItem.organisationId,
                  projectId: registerItemPropertyData.linkedItem.projectId,
                  registerId: registerItemPropertyData.linkedItem.registerId,
                  registerItemId:
                    registerItemPropertyData.linkedItem.registerItemId,
                })
              : PageUrls.registerItemDetails({
                  organisationId:
                    registerItemPropertyData.linkedItem.organisationId,
                  registerId: registerItemPropertyData.linkedItem.registerId,
                  registerItemId:
                    registerItemPropertyData.linkedItem.registerItemId,
                })
            window.open(url, '_blank', 'noopener, noreferrer')
          }}
        >
          <RegisterItemText>
            {registerItemPropertyData?.textValue}
          </RegisterItemText>
        </Button>
      </Tooltip>
    )
  }
  const inner = (
    <RegisterItemContentButtons
      variant={variant}
      value={registerItemPropertyData?.textValue}
      registerItemPropertyData={registerItemPropertyData}
    >
      <RegisterItemText>{truncated.text}</RegisterItemText>
    </RegisterItemContentButtons>
  )
  if (truncated.isTruncated) {
    return (
      <>
        <Tooltip title={registerItemPropertyData?.textValue} arrow>
          {/*Need to use a box for the tooltip to work*/}
          <Box>{inner}</Box>
        </Tooltip>
      </>
    )
  } else {
    return inner
  }
}
