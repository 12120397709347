import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

type ComponentType = {
  id: string | number
  children: React.ReactNode
}

export const DraggableItem: React.FC<ComponentType> = ({ children, id }) => {
  const { transform, transition, setNodeRef } = useSortable({ id })
  const style = {
    transform: CSS.Translate.toString({ ...transform, scaleY: 1 }),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  )
}
