import React, { useRef } from 'react'
import Map, { MapProps as MapBoxProps, MapRef } from 'react-map-gl'

export type MapRefType = React.MutableRefObject<MapRef>
export type MapProps = MapBoxProps & { mapRef?: MapRefType }

// Useful examples: https://github.com/visgl/react-map-gl/tree/master/examples
const MapBoxMap: React.FC<MapProps> = ({ children, ...props }) => {
  // If we pass in a mapRef we have access direct to the mapbox-gl map object giving us full flexibility
  const mapRef = props?.mapRef || useRef<MapRef>()

  const onLoad = (e) => {
    if (props?.onLoad) {
      props.onLoad(e)
    }
    // If we don't pass an initial viewport the map is black
    // This does a small zoom which fixes the issue
    if (!props?.initialViewState) {
      mapRef.current.zoomTo(mapRef.current.getZoom() - 0.001)
    }
  }

  const mapboxProps: MapBoxProps = {
    mapboxAccessToken: Project.mapBox.web,
    // Makes the map fill the parent container
    style: { width: '100%', height: '100%', borderRadius: 5 },
    ...props,
    onLoad: onLoad,
  }

  return (
    <Map {...mapboxProps} ref={mapRef}>
      {children}
    </Map>
  )
}

export { MapBoxMap }
