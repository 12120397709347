import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'

export const foldersQueryKeys = createQueryKeys('folders', {
  byId: ({ organisationId, ...rest }: RequestTypes['getFolder']) => {
    return {
      queryKey: [organisationId, rest],
      queryFn: async () => {
        const queryString = createQueryKeyString(rest)

        const { data } = await APIAxiosInstance.get(
          `folders/${organisationId}${queryString}`,
        )

        return data
      },
    }
  },
})

const foldersMutationsKeys = createMutationKeys('folders', {
  createFolder: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      ...rest
    }: RequestTypes['createFolder']) => {
      const { data } = await APIAxiosInstance.post(
        `folders/${organisationId}`,
        rest,
      )

      return data
    },
  },
  moveFolder: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      ...rest
    }: RequestTypes['moveFolder']) => {
      const { data } = await APIAxiosInstance.put(
        `folders/${organisationId}/move`,
        rest,
      )

      return data
    },
  },
  renameFolder: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      folderId,
      ...rest
    }: RequestTypes['renameFolder']) => {
      const { data } = await APIAxiosInstance.put(
        `folders/${organisationId}/${folderId}`,
        rest,
      )

      return data
    },
  },
  deleteFolder: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      folderId,
      ...rest
    }: RequestTypes['deleteFolder']) => {
      const queryString = createQueryKeyString(rest)

      const { data } = await APIAxiosInstance.delete(
        `folders/${organisationId}/${folderId}${queryString}`,
      )

      return data
    },
  },
})

export const foldersKeys = mergeQueryKeys(
  foldersQueryKeys,
  foldersMutationsKeys,
).folders
