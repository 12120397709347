import { ProjectPlanPSPDFArea } from '@core/entities/project'
import { useState } from 'react'

type UsePlanZonesTooltipPositionProps = () => {
  hoveredAnnotation: {
    annotation: ProjectPlanPSPDFArea
    x: number
    y: number
  } | null
  calcPosition: (
    polygon: number[][],
    elementRect: DOMRect,
    annotation: ProjectPlanPSPDFArea,
    isSidebarExpanded: boolean,
  ) => void
  resetPosition: () => void
}

const EXPANDED_SIDEBAR_WIDTH = 18.5 * 16
const COLLAPSED_SIDEBAR_WIDTH = 4 * 16
const TOPBAR_HEIGHT = 121.78

export const usePlanZonesTooltipPosition: UsePlanZonesTooltipPositionProps =
  () => {
    const [hoveredAnnotation, setHoveredAnnotation] = useState<{
      annotation: ProjectPlanPSPDFArea
      x: number
      y: number
    } | null>(null)

    const calcPosition = (
      polygon: number[][],
      elementRect: DOMRect,
      annotation: ProjectPlanPSPDFArea,
      isSidebarExpanded = true,
    ): void => {
      const sideBarWidth = isSidebarExpanded
        ? EXPANDED_SIDEBAR_WIDTH
        : COLLAPSED_SIDEBAR_WIDTH

      if (polygon?.length) {
        let lowestPointX = polygon[0][0] // Assume the first point has the lowest combined value
        let lowestPointY = polygon[0][1] // Assume the first point has the lowest combined value

        for (const currentPoint of polygon) {
          if (currentPoint[0] < lowestPointX) lowestPointX = currentPoint[0]
          if (currentPoint[1] < lowestPointY) lowestPointY = currentPoint[1]
        }

        const scaledY = elementRect.height / annotation.bbox[3]
        const scaledX = elementRect.width / annotation.bbox[2]

        const [x, y] = getCentroid(polygon)
        const polygonAnnotationX =
          elementRect.x + sideBarWidth + (x - lowestPointX) * scaledX
        const polygonAnnotationY =
          elementRect.y + TOPBAR_HEIGHT + (y - lowestPointY) * scaledY

        setHoveredAnnotation({
          x: polygonAnnotationX,
          y: polygonAnnotationY,
          annotation,
        })
      } else {
        setHoveredAnnotation({
          x: elementRect.x + sideBarWidth + elementRect.width / 2,
          y: elementRect.y + TOPBAR_HEIGHT + elementRect.height / 2,
          annotation,
        })
      }
    }

    const resetPosition = () => {
      setHoveredAnnotation(null)
    }

    return {
      hoveredAnnotation,
      calcPosition,
      resetPosition,
    }
  }

const calculateCentroid = (polygon: number[][]): number[] => {
  const numPoints = polygon.length

  // Calculate the sum of x-coordinates and y-coordinates
  let sumX = 0
  let sumY = 0

  for (let i = 0; i < numPoints; i++) {
    const [x, y] = polygon[i]
    sumX += x
    sumY += y
  }

  // Calculate the centroid coordinates
  const centroidX = sumX / numPoints
  const centroidY = sumY / numPoints

  return [centroidX, centroidY]
}

const moveCentroidTowardsNonIntersectingPoints = (
  centroid: number[],
  polygon: number[][],
  isCentroidNegative: boolean,
): number[] => {
  const tolerance = 7 // Small distance to move centroid inward

  // Move the centroid towards the inside of the polygon
  const [centroidX, centroidY] = centroid
  let movedCentroidX = centroidX - tolerance
  let movedCentroidY = centroidY - tolerance

  if (isCentroidNegative) {
    movedCentroidX = centroidX + tolerance
    movedCentroidY = centroidY + tolerance
  }

  const newCentroid = [movedCentroidX, movedCentroidY]

  if (!isPointInsidePolygon(newCentroid, polygon)) {
    return newCentroid
  }

  return centroid // If intersection occurs, return the original centroid
}

const isPointInsidePolygon = (
  point: number[],
  polygon: number[][],
): boolean => {
  const [x, y] = point
  let isInside = false

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const [xi, yi] = polygon[i]
    const [xj, yj] = polygon[j]

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) {
      isInside = !isInside
    }
  }

  return isInside
}

const getCentroid = (points: number[][]): number[] => {
  const centroid = calculateCentroid(points)

  let newCentroid = centroid
  let previousCentroid
  let isCentroidNegative = false

  while (previousCentroid !== newCentroid) {
    previousCentroid = newCentroid
    newCentroid = moveCentroidTowardsNonIntersectingPoints(
      newCentroid,
      points,
      isCentroidNegative,
    )
    if (newCentroid[0] < 0 || newCentroid[1] < 0) {
      isCentroidNegative = true
    }
  }
  return newCentroid
}
