import React from 'react'
import { Flex } from '@fundamentals'

type MultiFiltersWrapperProps = {}
export const MultiFiltersWrapper: React.FC<MultiFiltersWrapperProps> = ({
  children,
}) => {
  return (
    <Flex alignItems='stretch' flexWrap='wrap' data-test='list-filters'>
      {children}
    </Flex>
  )
}
