import { ModalDialogContainer } from '@common/modal'
import { useModal } from '@common/GlobalModal'
import { DialogContent, DialogTitle, IconButton } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import Editor from '@monaco-editor/react'
import React from 'react'
import {
  ObjectDetectionChips,
  ObjectDetectionChipsProps,
} from '@elements/ObjectDetection/ObjectDetectionChips'
import { Box } from '@fundamentals'

type DebugModalProps = {
  response: any
  chips: ObjectDetectionChipsProps
}
export const ObjectDetectionDebugModal: React.FC<DebugModalProps> = ({
  response,
  chips,
}) => {
  const { hideModal } = useModal()

  return (
    <ModalDialogContainer
      PaperProps={{
        sx: {
          maxWidth: '80%',
        },
      }}
    >
      <DialogTitle
        variant='h5'
        display='flex'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20, gap: 1 }}
      >
        <ObjectDetectionChips {...chips} />
        <Box flexGrow={1} />
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Editor
          width='40vw'
          height='90vh'
          defaultLanguage='json'
          defaultValue={JSON.stringify(response, null, 2)}
        />
      </DialogContent>
    </ModalDialogContainer>
  )
}
