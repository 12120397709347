import { localizedStrings } from '@core/strings'
import React from 'react'
import { useRouter } from 'next/router'
import { Box, Flex, Text } from '@fundamentals'
import {
  ArrowDropDown,
  CorporateFareOutlined,
  SupervisedUserCircle,
} from '@mui/icons-material'
import { CircularProgress, DropdownButton } from '@common/material'
import { PageUrls } from '@core/page-urls'
import { StyledOrganisationButton } from '../NavigationAwareSidebar/OrganisationMenu'
import { useGetAllProjects } from '@core/react-query/features/projects'

export type SidebarProjectPickerProps = {
  organisationId: number
  projectId: number
  collapse?: boolean
}

export const SidebarProjectPicker: React.FC<SidebarProjectPickerProps> = ({
  organisationId,
  projectId,
  collapse,
}) => {
  const { data: projects, isLoading: projectsLoading } = useGetAllProjects({
    organisationId,
  })
  const router = useRouter()
  const currentProject = projects?.find((p) => p.id === projectId)

  const renderButton = (onClick) => {
    return (
      <StyledOrganisationButton onClick={onClick}>
        <Box ml={1}>
          <CorporateFareOutlined />
        </Box>
        {!collapse && (
          <Flex
            flex={1}
            flexDirection='column'
            justifyContent='center'
            fontSize='16px'
          >
            {!currentProject || projectsLoading ? (
              <CircularProgress size={18} sx={{ color: 'common.white' }} />
            ) : (
              <>
                <Text pl={1} mt={0.5} fontWeight='bold' lineHeight='1.2'>
                  {currentProject?.name || ''}
                </Text>
              </>
            )}
          </Flex>
        )}
        <ArrowDropDown />
      </StyledOrganisationButton>
    )
  }
  return (
    <>
      <DropdownButton
        label={localizedStrings.organisation}
        options={projects?.map((project) => ({
          title: project.name,
          value: project.id,
          icon: <SupervisedUserCircle />,
        }))}
        onSelect={(projectId) =>
          router.push(PageUrls.projectOverview({ organisationId, projectId }))
        }
        fullWidth
        renderButton={renderButton}
      />
    </>
  )
}
