import React from 'react'
import { LinearProgress } from '@common/material'
import { TransitionGroup } from 'react-transition-group'
import { Collapse } from '@mui/material'
import {
  AuditResult,
  AuditResultScopeTypes,
} from '@core/entities/audit/AuditResult'
import { RegisterItem } from '@core/entities/register/RegisterItem'

import { TimelineItem } from './TimelineItem'

type TimelineProps = {
  isLoading?: boolean
  auditResults: Array<AuditResult>
  registerItem?: RegisterItem
  scope?: AuditResultScopeTypes
}

export const Timeline: React.FC<TimelineProps> = ({
  isLoading,
  auditResults = [],
  registerItem,
  scope,
}) => {
  return (
    <TransitionGroup>
      {isLoading && (
        <Collapse>
          <LinearProgress />
        </Collapse>
      )}
      {!isLoading &&
        auditResults.map((auditResult) => {
          return (
            <Collapse key={auditResult.id}>
              <TimelineItem
                scope={scope}
                auditResult={auditResult}
                registerItem={registerItem}
                auditResults={auditResults}
              />
            </Collapse>
          )
        })}
    </TransitionGroup>
  )
}
