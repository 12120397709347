import React from 'react'
import { Box, Text } from '@fundamentals'
import { Comment } from '@core/entities/comments'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'

type CommentProps = {
  comment: Comment
  showInfo?: boolean
}

const CommentItem: React.FC<CommentProps> = ({ comment, showInfo = true }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#1976D214',
          mt: 2,
          p: 2,
          borderRadius: 1,
          position: 'relative',
          border: `1px solid #1976D24D`,
        }}
      >
        {comment?.message && (
          <Text
            variant='body2'
            color='grey.800'
            mb={comment.mediaUrlsWithType?.length > 0 ? 2 : 0}
          >
            {comment.message}
          </Text>
        )}
        <AttachmentInput
          inputProps={{
            value: comment.mediaUrlsWithType,
          }}
          readOnly
          borderLess
          size='small'
          mt={-1}
        />
      </Box>
      {showInfo && (
        <Text
          variant='body2'
          align='right'
          color='grey.600'
          fontSize={12}
          sx={{ mt: 1 }}
        >
          {comment?.getInfo()}
        </Text>
      )}
    </>
  )
}

export default CommentItem
