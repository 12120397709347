import { localizedStrings } from '@core/strings'
import React, { useCallback, useEffect } from 'react'
import { BoxProps, Flex, Text } from '@fundamentals'
import { Button, LoadingButton, MenuItem, Tooltip } from '@common/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useRouter } from 'next/router'
import { useIsMounted } from '@core/providers/useIsMounted'
import { useFormContext } from 'react-hook-form'
import { alpha } from '@mui/material'
import { LoadingButtonProps } from '@mui/lab'

export type StagedNavigationLayoutProps<WithSidebar extends boolean = boolean> =
  BoxProps & {
    resetOnMount?: boolean
    withStageSidebar?: WithSidebar
    navigateWithSidebar?: boolean
    stages: Array<
      (WithSidebar extends true ? { label: string } : { label?: string }) & {
        key: string
        component: React.ReactNode
        actionButtons?: Array<
          LoadingButtonProps & { dataTest?: string; tooltip?: string }
        >
        onEntry?: () => void
        onExit?: () => void
        onNextFailed?: () => void
        disabled?: boolean
        tooltip?: string
        dataTest?: string
        header?: React.ReactNode
      }
    >
    actionButtons?: Array<
      LoadingButtonProps & { dataTest?: string; tooltip?: string }
    >
    ordered?: boolean
    leftPanel?: React.ReactNode
    rightPanel?: React.ReactNode
    onBack?: () => void
    disableNavigation?: boolean
  }
type ArrayDataType<T extends any[]> = T extends (infer U)[] ? U : never

type StageBuilder = () => {
  addStage: (
    stage: ArrayDataType<StagedNavigationLayoutProps['stages']>,
  ) => ReturnType<StageBuilder>
  build: () => StagedNavigationLayoutProps['stages']
}

const StagedNavigationLayout: React.FC<StagedNavigationLayoutProps> & {
  StageBuilder: StageBuilder
} = ({
  title,
  stages,
  actionButtons,
  ordered,
  children,
  leftPanel,
  rightPanel,
  withStageSidebar,
  onBack: onBackProp,
  disableNavigation,
  resetOnMount = true,
  ...props
}) => {
  const isMounted = useIsMounted()
  const router = useRouter()
  const queryStage = router.query?.stage
  const formContext = useFormContext()

  const nextStage = useCallback(() => {
    const index = stages.findIndex((stage) => stage.key === queryStage)
    if (index !== -1 && index !== stages.length - 1) {
      stages[index].onExit?.()
      router.query.stage = stages[index + 1].key
      window.scrollTo({ top: 0 })
      router.push(router, undefined, { shallow: true })
    }
  }, [queryStage])

  const onBack = useCallback(() => {
    const index = stages.findIndex((stage) => stage.key === queryStage)
    stages[index].onExit?.()
    router.back()
  }, [queryStage])

  const {
    component,
    actionButtons: stageActionButtons,
    label,
    onEntry,
    onNextFailed,
  } = stages.find((stage) => stage.key === queryStage) ?? {}

  useEffect(() => {
    onEntry?.()
  }, [queryStage])

  if (
    !!stages.length &&
    (!queryStage ||
      (!isMounted() && queryStage !== stages[0].key && resetOnMount))
  ) {
    router.query.stage = stages[0].key
    router.replace(router, undefined, { shallow: true })
    return
  }

  return (
    <Flex flexDirection='column' flex={1} data-test='staged-navigation-layout'>
      <Flex
        p={3}
        bgcolor='white'
        borderBottom='1px solid lightgrey'
        py={2}
        position='sticky'
        top={0}
        zIndex={100}
      >
        <Button
          variant='outlined'
          disabled={disableNavigation}
          onClick={onBackProp || onBack}
          sx={{ mr: 2 }}
          data-test='staged-navigation-layout-back-button'
        >
          <ArrowBack sx={{ fontSize: 27 }} />
        </Button>

        <Flex flex={1} alignItems='center'>
          {label && <Text mr={1.5}>{label}</Text>}
          {children}
        </Flex>
        {[...(actionButtons ?? []), ...(stageActionButtons ?? [])].map(
          ({ sx, dataTest, ...actionButton }, index) => (
            <Tooltip key={index} title={actionButton?.tooltip}>
              <span>
                <LoadingButton
                  key={index}
                  sx={{ minWidth: 150, ml: 1.5, ...sx }}
                  loadingPosition='end'
                  data-test={dataTest}
                  {...actionButton}
                />
              </span>
            </Tooltip>
          ),
        )}
        {ordered && queryStage !== stages[stages.length - 1].key && (
          <Button
            key='next'
            variant='contained'
            data-test='submit-btn'
            sx={{ width: 150, ml: 1.5 }}
            onClick={
              formContext?.handleSubmit(nextStage, onNextFailed) || nextStage
            }
          >
            {localizedStrings.next}
          </Button>
        )}
      </Flex>
      <Flex flex={1} overflow='hidden'>
        {withStageSidebar && (
          <Flex
            sx={{
              borderRight: '1px solid',
              borderColor: alpha('#000', 0.12),
              minWidth: '16rem',
              flexDirection: 'column',
              bgcolor: 'white',
              pt: 2,
            }}
          >
            {stages?.map((stage, index) => (
              <React.Fragment key={stage.key}>
                {stage.header && stage.header}
                <Tooltip title={stage?.tooltip}>
                  <span>
                    <MenuItem
                      disabled={stage.disabled}
                      data-test={stage.dataTest}
                      selected={queryStage === stage.key}
                      sx={{
                        px: '2rem',
                        py: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => {
                        router.query.stage = stage.key
                        router.push(router, undefined, { shallow: true })
                      }}
                    >
                      {stage.label}
                    </MenuItem>
                  </span>
                </Tooltip>
              </React.Fragment>
            ))}
          </Flex>
        )}

        {leftPanel}
        <Flex flexDirection='column' flex={1} height='100%'>
          <Flex
            flexDirection='column'
            {...props}
            sx={{ flex: 1 }}
            overflow='hidden'
          >
            {component}
          </Flex>
        </Flex>
        {rightPanel}
      </Flex>
    </Flex>
  )
}

StagedNavigationLayout.StageBuilder = function () {
  const stages = [] as StagedNavigationLayoutProps['stages']
  const builder = {
    addStage: (stage: ArrayDataType<StagedNavigationLayoutProps['stages']>) => {
      stages.push(stage)
      return builder
    },
    build: () => stages,
  }
  return builder
}

export { StagedNavigationLayout }
