import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  projectMapsKeys,
  RequestTypes,
} from '@core/react-query/features/projects/maps'
import { ProjectMap } from '@core/entities/project/ProjectMap'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useProjectMapConfiguration = (
  payload: RequestTypes['getProjectMapConfiguration'],
  options?: UseQueryOptions<ProjectMap, RequestErrorType>,
) => {
  return useQuery({
    select: ProjectMap.new,
    ...projectMapsKeys.byProjectMapConfiguration(payload),
    ...options,
  })
}

export const prefetchProjectMapConfiguration = (
  queryClient: QueryClient,
  payload: RequestTypes['getProjectMapConfiguration'],
  options?: UseQueryOptions<ProjectMap, RequestErrorType>,
) => {
  return queryClient.prefetchQuery({
    select: ProjectMap.new,
    ...projectMapsKeys.byProjectMapConfiguration(payload),
    ...options,
    cacheTime: Infinity,
  })
}
