import { localizedStrings } from '@core/strings'
import React from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import QRCode from 'react-qr-code'
import { Flex, Text } from '@fundamentals'
import { ModalDialogContainer } from '@common/modal'
import { Checklist } from '@core/entities/checklist/Checklist'
import { ContentCopyOutlined } from '@mui/icons-material'

interface ComponentType {
  checklist: Checklist
}

export const ChecklistBriefParticipantsModal: React.FC<ComponentType> = ({
  checklist,
}) => {
  const { hideModal } = useModal()
  const url = checklist.getBriefingIntentUrl(document.location.origin)

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.briefParticipants}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <QRCode value={url} />
          <Button
            sx={{ mt: 2, mb: 2, width: 200 }}
            variant={'outlined'}
            startIcon={<ContentCopyOutlined />}
            onClick={() => {
              copyTextToClipboard(url)
            }}
          >
            {localizedStrings.copyToClipboard}
          </Button>
          <Text mt={2}>
            {localizedStrings.briefParticipantsChecklistDescription}
          </Text>
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}

const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}
