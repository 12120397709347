import { PageResponse } from '@core/entities/pagination'
import { Checklist, IChecklist } from '@core/entities/checklist/Checklist'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistsKeys,
  RequestTypes,
} from '@core/react-query/features/checklists'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const dataTransformer = (response: PageResponse<IChecklist>) => {
  return {
    ...response,
    content: response.content.map((checklist) => Checklist.new(checklist)),
  }
}

export const useAllChecklists = (
  payload: RequestTypes['getAllChecklists'],
  options: UseQueryOptions<
    PageResponse<IChecklist>,
    RequestErrorType,
    PageResponse<Checklist>
  > = {},
) => {
  return useQuery({
    select: dataTransformer,
    ...checklistsKeys.allChecklists(payload),
    keepPreviousData: true,
    ...options,
  })
}
