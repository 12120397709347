import React, { useEffect, useState } from 'react'
import { Box } from '@fundamentals'
import { IconButton, Tooltip } from '@common/material'
import { ContentCopyOutlined } from '@mui/icons-material'
import { localizedStrings } from '@core/strings'

type ClickToCopyProps = {
  value: string
  extraButton?: React.ReactNode
}
export const ClickToCopy: React.FC<ClickToCopyProps> = ({
  children,
  value,
  extraButton,
}) => {
  const [buttonVisible, setButtonVisible] = useState(false)
  const [showCopiedSuccess, setShowCopiedSuccess] = useState(false)

  useEffect(() => {
    if (showCopiedSuccess) {
      const timeout = setTimeout(() => {
        setShowCopiedSuccess(false)
      }, 1000)
      return () => clearTimeout(timeout)
    }
  }, [showCopiedSuccess])

  return (
    <Box
      position={'relative'}
      width={'100%'}
      onMouseEnter={() => setButtonVisible(true)}
      onMouseLeave={() => setButtonVisible(false)}
    >
      {children}
      {buttonVisible && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: 'calc(50% - 20px)',
              left: 'calc(100% - 45px)',
            }}
          >
            <Tooltip
              title={
                showCopiedSuccess
                  ? localizedStrings.copiedToClipBoard
                  : localizedStrings.copyToClipboard
              }
              arrow
            >
              <IconButton
                onClick={() => {
                  setShowCopiedSuccess(true)
                  copyTextToClipboard(value)
                }}
              >
                <ContentCopyOutlined fontSize={'small'} />
              </IconButton>
            </Tooltip>
          </Box>
          {extraButton && (
            <Box
              sx={{
                position: 'absolute',
                top: 'calc(50% - 20px)',
                left: 'calc(100% - 15px)',
              }}
            >
              {extraButton}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}
