import { localizedStrings } from '@core/strings'
import { SelectFilterInternalArguments } from './fundamentals'
import { useSelectFilter } from '@core/providers/filters'
import { SelectFilterValue } from '@core/providers/filters'
import { UserStatus } from '@core/entities/user/UserStatus'
import { FilterHookArgumentsBase } from '@core/providers/filters'

type UserStatusFilterArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<string, Multiple>>

export const useUserStatusFilter: (
  args: UserStatusFilterArgs<false>,
) => SelectFilterInternalArguments<string, false> = (args) => {
  const options: UserStatus[] = ['ACTIVE', 'DISABLED']

  return useSelectFilter({
    label: localizedStrings.status,
    valueToLabel: (status) => {
      switch (status) {
        case 'ACTIVE':
          return localizedStrings.active
        case 'DISABLED':
          return localizedStrings.disabled
        default:
          return status
      }
    },
    options: options,
    multiple: false,
    nothingSelectedText: localizedStrings.all,
    ...args,
  })
}
