import { localizedStrings } from '@core/strings'
import React from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import { DialogTitle, IconButton, DialogContent } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { PageUrls } from '@core/page-urls'
import QRCode from 'react-qr-code'
import { Flex, Text } from '@fundamentals'
import { Permit } from '@core/entities/permit/Permit'
import { ModalDialogContainer } from '@common/modal'

interface ComponentType {
  permit: Permit
  organisationId: number
}

export const PermitQRCodeModal: React.FC<ComponentType> = ({
  permit,
  organisationId,
}) => {
  const { hideModal } = useModal()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.showQRCode}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <QRCode
            value={`${Project.appUrl}${PageUrls.projectPermitDetail({
              organisationId,
              projectId: permit.project.id,
              permitId: permit.id,
            })}`}
          />
          <Text mt={2}>{localizedStrings.permitQRCodeDescription}</Text>
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}
