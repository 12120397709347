import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import { localizedStrings } from '@core/strings'
import _isEmpty from 'lodash/isEmpty'

export type AdvancedTextInputFilterOperators =
  | 'CONTAINS_IGNORE_CASE'
  | 'IS_PRESENT'
  | 'IS_NOT_PRESENT'

export const advancedTextInputFilterOperatorsWithoutArguments: AdvancedTextInputFilterOperators[] =
  ['IS_PRESENT', 'IS_NOT_PRESENT']

export type AdvancedTextInputFilterValue = {
  operator: AdvancedTextInputFilterOperators
  value: string
}

export const advancedTextFilterValueIsPartial = (
  value: AdvancedTextInputFilterValue,
) => {
  if (!value?.operator) {
    return true
  }

  if (
    advancedTextInputFilterOperatorsWithoutArguments.includes(value?.operator)
  ) {
    return false
  }

  if (_isEmpty(value?.value)) {
    return true
  }

  return false
}

export const advancedTextInputFilterOptionConditions: {
  value: AdvancedTextInputFilterOperators
  label: string
}[] = [
  { value: 'CONTAINS_IGNORE_CASE', label: localizedStrings.contains },
  { value: 'IS_PRESENT', label: localizedStrings.isPresent },
  { value: 'IS_NOT_PRESENT', label: localizedStrings.isNotPresent },
]

export type AdvancedTextInputFilterHookArguments =
  FilterHookArgumentsBase<AdvancedTextInputFilterValue> & {
    // Any extra arguments here
  }

export type AdvancedTextInputFilterInternalArguments =
  AdvancedTextInputFilterHookArguments &
    FilterInternalArgumentsBase<
      AdvancedTextInputFilterValue,
      'ADVANCED_TEXT_INPUT'
    >

export const useAdvancedTextInputFilter = (
  filter: AdvancedTextInputFilterHookArguments,
): AdvancedTextInputFilterInternalArguments => {
  const unfocusedText = (
    value: AdvancedTextInputFilterValue,
    { noLabel } = { noLabel: false },
  ) => {
    const label = noLabel ? '' : `${filter.label}: `
    if (value?.operator === 'IS_PRESENT') {
      return `${label}${localizedStrings.isPresent}`
    }

    if (value?.operator === 'IS_NOT_PRESENT') {
      return `${label}${localizedStrings.isNotPresent}`
    }

    const emptyText = filter.nothingSelectedText || localizedStrings.search
    const operatorLabel = advancedTextInputFilterOptionConditions.find(
      (operator) => operator.value === value?.operator,
    )?.label

    const textValue =
      value?.operator && value?.value
        ? `( ${operatorLabel} ) ${value?.value}`
        : emptyText

    return `${label}${textValue}`
  }
  return {
    ...filter,
    unfocusedText,
    type: 'ADVANCED_TEXT_INPUT',
    serialization: 'object',
  }
}
