import React from 'react'
import {
  RegisterItem,
  RegisterItemInspectionProperty,
} from '@core/entities/register/RegisterItem'
import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { Flex, Text } from '@fundamentals'
import { IconButton } from '@common/material'
import { PageUrls } from '@core/page-urls'
import { localizedStrings } from '@core/strings'
import moment from 'moment'
import {
  AddCircleOutlineOutlined,
  OpenInNewOutlined,
  Warning,
} from '@mui/icons-material'
import { useModal } from '@common/GlobalModal'
import { NewInspectionModal } from '../modals/NewInspectionModal'
import { useRegisterContext } from '../details/RegisterContext'
import { useRouter } from 'next/router'

type RegisterItemPropertyInspectionValueProps = {
  registerItemPropertyData: RegisterItemInspectionProperty<string>
  registerItem?: RegisterItem
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertyInspectionValue: React.FC<
  RegisterItemPropertyInspectionValueProps
> = ({ registerItemPropertyData, registerItem }) => {
  const { scope, register } = useRegisterContext()
  const router = useRouter()
  const { showModal } = useModal()

  const inspections = (registerItem?.data?.filter(
    (item) => item.type === 'INSPECTION',
  ) ?? []) as RegisterItemInspectionProperty<string>[]

  const inspection = registerItemPropertyData?.inspection

  const lastInspected = moment(
    registerItemPropertyData?.inspection?.lastInspectionTime,
  ).format('DD MMM YYYY HH:mm')

  const hasNextInspection = !!inspection?.nextInspectionDue
  const nextInspectionDue = moment(inspection?.nextInspectionDue).format(
    'DD MMM YYYY HH:mm',
  )
  const lastInspectedBy = registerItemPropertyData?.inspection?.lastInspectedBy

  const hasInspectionExpired = moment().isAfter(inspection?.nextInspectionDue)

  if (!lastInspectedBy)
    return (
      <Flex alignItems='center'>
        <Text width='10rem'>---</Text>
        <IconButton
          color='primary'
          sx={{ height: 'fit-content', alignSelf: 'center' }}
          onClick={() => {
            const isOnlyOneInspectionAndTemplate =
              inspections.length === 1 &&
              inspections[0]?.inspection?.checklistTemplates?.length === 1

            if (isOnlyOneInspectionAndTemplate) {
              router.push({
                pathname: PageUrls.newChecklistProject({
                  organisationId: scope.organisationId,
                  projectId: register.projectId,
                }),
                query: {
                  registerId: register?.id,
                  registerItemId: registerItem.id,
                  projectId: register.projectId,
                  inspectionId: inspections[0].inspection.inspectionId,
                  inspectionTemplateId:
                    inspections[0].inspection.checklistTemplates[0].id,
                },
              })
            } else {
              showModal(
                <NewInspectionModal
                  organisationId={scope.organisationId}
                  projectId={register.projectId}
                  registerId={register.id}
                  registerItem={registerItem}
                  inspectionId={inspection.inspectionId}
                  inspections={inspections}
                />,
              )
            }
          }}
        >
          <AddCircleOutlineOutlined />
        </IconButton>
      </Flex>
    )

  return (
    <Flex gap={4}>
      <Flex flexDirection='column'>
        <Text noWrap fontSize={12} color='text.disabled'>
          {localizedStrings.lastCompleted}
        </Text>
        <Text noWrap fontSize={14}>
          {lastInspected}
        </Text>
        {lastInspectedBy && (
          <Text
            noWrap
            fontSize={14}
            color='text.secondary'
          >{`${lastInspectedBy.firstName} ${lastInspectedBy.lastName}`}</Text>
        )}
      </Flex>

      {hasNextInspection && (
        <Flex flexDirection='column'>
          <Text
            noWrap
            fontSize={12}
            color={hasInspectionExpired ? 'error' : 'text.disabled'}
          >
            {localizedStrings.nextInspectionDue}
          </Text>
          <Flex gap={1}>
            {hasInspectionExpired && <Warning fontSize='small' color='error' />}
            <Text noWrap fontSize={14} color={hasInspectionExpired && 'error'}>
              {nextInspectionDue}
            </Text>
          </Flex>
        </Flex>
      )}

      <IconButton
        color='primary'
        sx={{ height: 'fit-content', alignSelf: 'center' }}
        onClick={() => {
          const checklist = inspection?.lastSubmittedChecklist
          const url = PageUrls.projectChecklistDetail({
            organisationId: checklist.organisationId,
            projectId: checklist.project.id,
            checklistId: checklist.id,
          })

          window.open(url, '_blank', 'noopener, noreferrer')
        }}
      >
        <OpenInNewOutlined />
      </IconButton>
    </Flex>
  )
}
