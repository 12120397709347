import { Entity } from '@core/entities/entity'
import { IRegisterTrigger } from './IRegisterTrigger'
import { plainToInstance } from 'class-transformer'
import {
  RegisterTriggerActionType,
  RegisterTriggerType,
} from '@core/entities/register/RegisterTrigger/types'
import { localizedStrings } from '@core/strings'

export class RegisterTrigger extends Entity<IRegisterTrigger> {
  trigger: {
    id: number
    type: RegisterTriggerType
    properties?: {
      id: string
      name: string
    }[]
  }
  action: {
    type: RegisterTriggerActionType
    emailAddress?: string
  }

  public triggerText(): string {
    switch (this.trigger.type) {
      case 'ITEM_ADDED':
        return localizedStrings.itemAdded
      case 'PROPERTY_CHANGE':
        return `${
          localizedStrings.propertyChange
        } – ${this.trigger.properties.map((p) => p.name)}`
      default:
        return ''
    }
  }

  public actionText(): string {
    switch (this.action.type) {
      case 'SEND_EMAIL':
        return `${localizedStrings.sendEmail} – ${this.action.emailAddress}`
      default:
        return ''
    }
  }

  public static triggerTypeText(triggerType: RegisterTriggerType): string {
    switch (triggerType) {
      case 'ITEM_ADDED':
        return localizedStrings.itemAdded
      case 'PROPERTY_CHANGE':
        return localizedStrings.propertyChange
      default:
        return ''
    }
  }

  public static actionTypeText(actionType: RegisterTriggerActionType): string {
    switch (actionType) {
      case 'SEND_EMAIL':
        return localizedStrings.sendEmail
      default:
        return ''
    }
  }

  public static new(payload: unknown): RegisterTrigger {
    const entity = plainToInstance(RegisterTrigger, payload)

    return entity
  }
}
