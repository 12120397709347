import { localizedStrings } from '@core/strings'
import React from 'react'
import { Box, Text } from '@fundamentals'
import { Card, Chip } from '@common/material'
import moment from 'moment/moment'
import { LinkedPeriodicChecksResult } from '@core/entities/linkedProcesses'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

type ComponentProps = {
  linkedPeriodicChecksResult: LinkedPeriodicChecksResult
  sx?: SxProps<Theme>
  children?: React.ReactNode
}

export const LinkedPeriodicCheckCard: React.FC<ComponentProps> = ({
  linkedPeriodicChecksResult,
  sx,
  children,
}) => {
  const { dateString, timeString } = Utils.getDate(
    linkedPeriodicChecksResult?.createdOn,
  )
  const startYear = moment(linkedPeriodicChecksResult?.createdOn).format('YYYY')

  return (
    <Card
      sx={{
        border: '1px solid #E0E0E0',
        borderRadius: 1,
        p: 2,
        pt: 1.5,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <Box>
        <Text fontSize={14} mb={1} color='text.secondary'>
          {linkedPeriodicChecksResult?.name}
        </Text>
        <Chip color='primary' label={localizedStrings.completed} size='small' />
        {linkedPeriodicChecksResult.submittedBy && (
          <Text
            fontSize={12}
            mt={1}
            color='text.secondary'
          >{`${localizedStrings.checklistAuthor}: ${linkedPeriodicChecksResult?.submittedBy?.firstName} ${linkedPeriodicChecksResult?.submittedBy?.lastName}`}</Text>
        )}
        {linkedPeriodicChecksResult?.createdOn && (
          <Text
            fontSize={12}
            color='text.secondary'
          >{`${localizedStrings.dateRaised}: ${dateString} ${startYear} ${timeString}`}</Text>
        )}
      </Box>
      <Box>{children}</Box>
    </Card>
  )
}
