import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { Checklist, IChecklist } from '@core/entities/checklist/Checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const checklistTransform: (checklist: IChecklist) => Checklist = (data) => {
  return Checklist.new(data)
}

export const useGetChecklistById = (
  payload: RequestTypes['getChecklist'],
  options?: UseQueryOptions<IChecklist, RequestErrorType, Checklist>,
) => {
  return useQuery({
    select: checklistTransform,
    ...checklistKeys.byId(payload),
    ...options,
  })
}
