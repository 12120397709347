import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { Text } from '@fundamentals'
import { useHtml } from '@common/hooks'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { PageUrls } from '@core/page-urls'
import { useInviteOrganisationUser } from '@core/react-query/features/organisations/organisation/hooks/useInviteOrganisationUser'
import { OrganisationUser } from '@core/entities/user'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  organisationId: number
  user: OrganisationUser
}

export const ResendInviteModal: React.FC<ComponentType> = ({
  organisationId,
  user,
}) => {
  const { hideModal } = useModal()
  const toast = useToast()
  const { html } = useHtml(
    localizedStrings.formatString(
      localizedStrings.confirmResendInviteUser,
      user?.firstName || '',
      user?.lastName || '',
    ),
  )
  const [error, setError] = useState(null)

  const { mutate, isLoading } = useInviteOrganisationUser()

  const processPending = isLoading

  const handleResendInvite = () => {
    setError(null)

    mutate(
      {
        organisationId: organisationId,
        company: user.company,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        roles: user.roles,
        linkUrl: `${document.location.origin}${PageUrls.organisationInviteUrl}`,
      },
      {
        onSuccess() {
          toast.success(localizedStrings.inviteSent)
          hideModal()
        },
        onError(e) {
          setError(e)
        },
      },
    )
  }

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.areYouSure}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Text dangerouslySetInnerHTML={{ __html: html }} />

        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={handleResendInvite}
          disabled={processPending}
        >
          {localizedStrings.resendInvite}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
