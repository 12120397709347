import { useQuery } from '@tanstack/react-query'
import { projectPlansKeys } from '../projectPlansKeys'

import { RequestTypes } from '../requestTypes'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import { ProjectPlan } from '@core/entities/project/ProjectPlan/ProjectPlan'

const projectPlansTransform = (plans: ProjectPlan[]) =>
  plans.map((plan) => ProjectPlan.new(plan))

export const useGetProjectPlans = (
  payload: RequestTypes['useGetProjectPlans'],
  options?: UseQueryOptions<ProjectPlan[], string>,
) => {
  return useQuery({
    select: projectPlansTransform,
    ...projectPlansKeys.byProject(payload),
    enabled: !!payload.projectId,
    ...options,
  })
}
