import { permitsKeys, RequestTypes } from '@core/react-query/features/permits'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import { IPermit, Permit } from '@core/entities/permit/Permit'
import { usePageableQuery } from '@core/react-query/hooks'

const dataTransformer = ({ content }: PageResponse<IPermit>) => {
  return content.map((permit) => Permit.new(permit))
}

export const useOrganisationDraftPermits = (
  payload: RequestTypes['getDrafts'],
  options: UseInfiniteQueryOptions<
    PageResponse<IPermit>,
    string,
    Array<Permit>
  > = {},
) => {
  const permitDraftsQuery = permitsKeys.drafts(payload)

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: permitDraftsQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<PageResponse<IPermit>, string, Array<Permit>>({
    ...infiniteQueryOptions,
    ...permitDraftsQuery,
    ...options,
  })
}
