import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { RequestTypes } from '@core/react-query/features/businessUnits/requestTypes'

const businessUnitsQueryKeys = createQueryKeys('business-units', {
  getBusinessUnits: ({
    organisationId,
    page,
    size,
  }: RequestTypes['getBusinessUnits']) => {
    return {
      queryKey: [organisationId, page, size],
      queryFn: async ({ pageParam = 1 }) => {
        const queryString = createQueryKeyString({
          organisationId,
          page: page ? page : pageParam,
          size,
        })
        const { data } = await APIAxiosInstance.get(
          `business-units${queryString}`,
        )

        return data
      },
    }
  },
  getBusinessUnit: ({ businessUnitId }: RequestTypes['getBusinessUnit']) => {
    return {
      queryKey: [businessUnitId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `business-units/${businessUnitId}`,
        )

        return data
      },
    }
  },
  getBusinessUnitDashboard: ({
    businessUnitId,
    organisationId,
  }: RequestTypes['getBusinessUnitDashboard']) => {
    return {
      queryKey: [businessUnitId, organisationId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `permits/dashboard?businessUnitId=${businessUnitId}&organisationId=${organisationId}`,
        )

        return data
      },
    }
  },
  getBusinessUnitProjects: ({
    organisationId,
    businessUnitId,
    page,
    size,
    status,
    term,
  }: RequestTypes['getBusinessUnitProjects']) => {
    return {
      queryKey: [organisationId, businessUnitId, page, size, status, term],
      queryFn: async ({ pageParam = 1 }) => {
        const queryString = createQueryKeyString({
          organisationId,
          businessUnitId,
          page: page ? page : pageParam,
          size,
          status,
          term,
        })
        const { data } = await APIAxiosInstance.get(`projects${queryString}`)

        return data
      },
    }
  },
})

const businessUnitsMutationKeys = createMutationKeys('business-units', {
  deleteBusinessUnit: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['deleteBusinessUnit']) => {
      const { businessUnitId } = payload
      const { data } = await APIAxiosInstance.delete(
        `business-units/${businessUnitId}`,
      )

      return data
    },
  },
  createBusinessUnit: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createBusinessUnit']) => {
      const { data } = await APIAxiosInstance.post(
        `business-units?organisationId=${payload.organisationId}`,
        payload,
      )

      return data
    },
  },
  updateBusinessUnit: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateBusinessUnit']) => {
      const { data } = await APIAxiosInstance.put(
        `business-units/${payload.businessUnitId}`,
        payload,
      )

      return data
    },
  },
  addProjectsToBusinessUnit: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['addProjectToBusinessUnit']) => {
      const { data } = await APIAxiosInstance.post(
        `business-units/${payload.businessUnitId}/projects`,
        payload,
      )

      return data
    },
  },
  deleteProjectFromBusinessUnit: {
    mutationKey: null,
    mutationFn: async (
      payload: RequestTypes['deleteProjectFromBusinessUnit'],
    ) => {
      const { data } = await APIAxiosInstance.delete(
        `business-units/${payload.businessUnitId}/projects/${payload.projectId}`,
      )

      return data
    },
  },
})

const businessUnitsKeys = mergeQueryKeys(
  businessUnitsQueryKeys,
  businessUnitsMutationKeys,
)['business-units']

export { businessUnitsKeys }
