import React, { useEffect, useRef } from 'react'
import { TextField } from '@common/material'
import { Box } from '@fundamentals'

import { TextInputFilterInternalArguments } from '@core/providers/filters'

type ComponentType = {
  filter: TextInputFilterInternalArguments
  value: string
  onChange: (value: string) => void
  dataTest?: string
  isFocusedOnMount?: boolean
}

const TextInputFilter: React.FC<ComponentType> = ({
  filter,
  value,
  onChange,
  isFocusedOnMount,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (
      ref.current &&
      isFocusedOnMount &&
      document.activeElement !== ref.current
    ) {
      ref.current.focus()
    }
  }, [ref, isFocusedOnMount])

  return (
    <Box pr={1}>
      <TextField
        inputRef={ref}
        size='small'
        label={filter.label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  )
}

export default TextInputFilter
