import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermitBase } from '@core/entities/permit/PermitBase'
import { permitsKeys } from '@core/react-query/features/permits'
import { useMutateQueryData } from '@core/react-query/hooks'
import { OfflineEntityType } from '@core/react-query/defaultMutations'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const getUpdatePermitDraftMutationOptions = (
  queryClient?: QueryClient,
) => {
  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)
  return {
    ...permitKeys.updateDraft,
    meta: {
      name: 'Update Permit Draft',
      offlineEnabled: true,
      variablesType: OfflineEntityType.PERMIT_SUBMISSION,
    },
    onSuccess: (permit) => {
      updateInfiniteQueryItem(
        permitsKeys.drafts({
          organisationId: permit.template.organisationId,
          projectIds: [permit.projectId],
        }).queryKey,
        permit,
      )
    },
  }
}

export const useUpdatePermitDraft = () => {
  const queryClient = useQueryClient()
  return useMutation<
    IPermitBase,
    RequestErrorType,
    RequestTypes['updateDraft']
  >(getUpdatePermitDraftMutationOptions(queryClient))
}
