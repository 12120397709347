import { TablePagination } from '@elements'
import React from 'react'
import { DropdownButtonOption } from '@common/material/DropdownButton/types'

export type TableProps<T> = {
  columns: Array<TableColumn<T>>
  data: T[]
  onRowClick?: (rowData: T) => void
  rowKey?: (rowData: T) => string
  rowSx?: object
  wrapperSx?: object
  withoutChevron?: boolean
  withoutCard?: boolean
  noItemsFoundText?: string
  dataTestPrefix?: string
  rowDataId?: (rowData: T) => number | string
  pagination?: TablePagination
  noHeader?: boolean
  maxHeight?: string
  loading?: boolean
  rowAccentColor?: (rowData: T) => string
  rowActions?: (rowData: T) => DropdownButtonOption[]
}

export type TableColumn<T = {}> = {
  label?: string
  widthRatio?: number
  justifyContent?: string
  alignSelf?: string
  transform?: (rowData: T) => string | number
  renderColumn?: (rowData: T, columnIndex?: number) => React.ReactNode
  renderHeader?: (headerText: string) => React.ReactNode
  onColumnClick?: (rowData: T) => void
}

type TablePagination = {
  page: {
    number: number
    size: number
    totalElements: number
  }
  onPageChange: (pagination: { size: number; page: number }) => void
}
