import { FilterMapInternal, FilterValueMapInternal } from './types'
import { useState } from 'react'
import { useDeepCompareEffect } from 'use-deep-compare'
import { cloneDeep } from 'lodash'

export type OnChangeListener<FilterMapType extends FilterMapInternal> = (
  onChange: FilterValueMapInternal<FilterMapType>,
) => void

export type FilterStore<FilterMapType extends FilterMapInternal> = {
  setFilterValues: (
    values: FilterValueMapInternal<FilterMapType>,
    options?: {
      partial?: boolean
    },
  ) => void
  removeFilter: (key: string) => void
  addFilter: (key: string) => void
  values: FilterValueMapInternal<FilterMapType>
  visibleFilterKeys: string[]
  notVisibleFilterKeys: string[]
  latestAddedFilterKey: string
  addOnChangeListener: (listener: OnChangeListener<FilterMapType>) => void
}

type UseFilterValueStoreArgs<FilterMapTye extends FilterMapInternal> = {
  filters: FilterMapTye
  initialValues?: FilterValueMapInternal<FilterMapTye>
  // If values is passed, then this is a controlled component
  values?: FilterValueMapInternal<FilterMapTye>
}

export const useFilterValueStore: <FilterMapType extends FilterMapInternal>(
  args: UseFilterValueStoreArgs<FilterMapType>,
) => FilterStore<FilterMapType> = ({
  filters,
  initialValues: initialValuesProp,
  values: valuesProp,
}) => {
  const initialValues = initialValuesProp || {}
  const [values, setValues] = useState(initialValues)
  const [userAddedFilterKeys, setUserAddedFilterKeys] = useState<string[]>([])
  const [onChangeListeners, setOnChangeListeners] = useState<
    OnChangeListener<any>[]
  >([])
  const latestAddedFilterKey = userAddedFilterKeys.slice(-1)?.[0]

  const visibleFilterKeys = [
    // Return pinned filters first
    ...Object.keys(filters).filter((filterKey) => filters[filterKey].isPinned),
    // Then add filters that have values and are not pinned
    ...Object.keys(filters).filter(
      (filterKey) =>
        !userAddedFilterKeys.includes(filterKey) &&
        !filters[filterKey].isPinned &&
        Object.keys(values).includes(filterKey),
    ),
    // Then return filters in the order the user added them
    ...userAddedFilterKeys,
  ]

  const notVisibleFilterKeys = Object.keys(filters).filter(
    (filterKey) => !visibleFilterKeys.includes(filterKey),
  )

  // Compute all the options for select filters so that the next effect can use them as a dependency
  const selectFilterOptions = {}
  Object.keys(filters).forEach((filterKey) => {
    const filter = filters[filterKey]
    if (filter.type === 'SELECT') {
      selectFilterOptions[filterKey] = filter?.options
    }
  })

  useDeepCompareEffect(() => {
    setValues((prevState) => {
      const newValues = {}
      Object.keys(prevState).map((filterValueKey) => {
        const filterValue = prevState[filterValueKey]
        const filter = filters[filterValueKey]

        const shouldClear = () => {
          if (filter?.type === 'SELECT' && filter?.clearIfValueIsNotInOptions) {
            if (Array.isArray(filterValue)) {
              // If there are no options clear the values of the filter
              if (!filter.options?.length) {
                return true
              }

              // If any of the values is not in the options
              for (const v of filterValue) {
                if (!filter.options.includes(v)) {
                  return true
                }
              }
            } else if (filterValue) {
              return !filter.options.includes(filterValue)
            }
          }
          return false
        }
        if (shouldClear()) {
          newValues[filterValueKey] = undefined
        } else {
          newValues[filterValueKey] = filterValue
        }
      })
      return newValues
    })
  }, [values, selectFilterOptions])

  useDeepCompareEffect(() => {
    if (valuesProp) {
      setValues(valuesProp)
    }
  }, [valuesProp])

  useDeepCompareEffect(() => {
    onChangeListeners.forEach((listener) => {
      listener(values)
    })
  }, [values])

  return {
    values,
    visibleFilterKeys,
    notVisibleFilterKeys,
    latestAddedFilterKey,
    addFilter: (filterKey) => {
      setUserAddedFilterKeys((prevState) => [...prevState, filterKey])
    },
    removeFilter: (filterKey) => {
      setValues((prevState) => {
        const newValues = cloneDeep(prevState)
        delete newValues[filterKey]
        return {
          ...newValues,
        }
      })
      setUserAddedFilterKeys((prevState) =>
        prevState.filter((key) => key !== filterKey),
      )
    },
    setFilterValues: (newValues, options) => {
      setValues((prevState) => ({
        // If partial we don't overwrite all the values
        ...(options?.partial ? prevState : {}),
        ...newValues,
      }))

      // Used in advanced cases when a filter needs to be able to change the values in the store
      // All such updates are partial, we don't fire any on changes for other filters
      const filterStore = {
        setValues: (values: FilterValueMapInternal<any>) => {
          setValues((prevState) => ({
            ...prevState,
            ...values,
          }))
        },
        previousValues: values,
      }

      // If the filters have individual onChanges configured then fire them
      Object.keys(newValues).forEach((filterKey) => {
        const filter = filters[filterKey]
        if (filter.onChange) {
          // The filter store doesn't know about the value type so we cast to any
          const value: any = newValues[filterKey]
          filter.onChange(value as never, filterStore)
        }
      })

      // If the update is not partial then we fire the filter onChanges with null for any filters not part of values
      Object.keys(filters)
        .filter((filterKey) => !Object.keys(newValues).includes(filterKey))
        .forEach((filterKey) => {
          const filter = filters[filterKey]
          if (filter.onChange) {
            // @ts-ignore
            filter.onChange(null, filterStore)
          }
        })

      // If the update is not partial this makes sure that the filters added by the user disappear
      if (!options?.partial) {
        setUserAddedFilterKeys([])
      }

      // If there's a select filter with the clearIfValueIsNotInOptions set to true
      // that has a value that's outside of the options then clear it
    },
    addOnChangeListener: (listener) => {
      setOnChangeListeners((prevState) => [...prevState, listener])
    },
  }
}
