import { Box, Text } from '@fundamentals'
import React from 'react'
import {
  MultipleChoiceQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import { MultipleChoice } from '@common/MultipleChoice'
import produce from 'immer'
import { TextField } from '@common/material'
import { Autocomplete } from '@common/material/Autocomplete'
import { localizedStrings } from '@core/strings'

const MultipleChoiceQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange, onBlur, ...inputProps } = {},
  error,
  readOnly,
}) => {
  const renderAdditionalChoiceSubmissionText = (index) => {
    const { requiresSubmissionText, selected } = value.answers[index]
    if (!requiresSubmissionText || !selected) return null
    return (
      <Box mt={1} width={'100%'}>
        {readOnly ? (
          <Text>{value.answers[index].submissionText}</Text>
        ) : (
          <TextField
            value={value.answers[index].submissionText}
            onChange={(e) => {
              const newValue = produce(value, (draft) => {
                draft.answers[index].submissionText = e.target.value
              })
              onChange(newValue)
            }}
            placeholder={'Additional Text ...'}
            onBlur={onBlur}
            minRows={3}
            multiline
            fullWidth
            {...inputProps}
          />
        )}
      </Box>
    )
  }

  if (onChange && !readOnly) {
    if (
      value.answers.length > 5 &&
      value.minAnswers === 1 &&
      value.maxAnswers === 1
    ) {
      const selectedIndex = value.answers.findIndex(({ selected }) => selected)
      return (
        <Box>
          <Autocomplete
            value={value.answers.find((answer) => answer.selected)}
            options={value?.answers}
            onChange={(event, selectedOption, reason, metadata) => {
              const newValue = produce(value, (draft) => {
                draft.answers.forEach((answer) => {
                  if (answer.id === metadata?.option.id) answer.selected = true
                  else answer.selected = false
                })
              })
              onChange(newValue)
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.value}
            freeSolo={false}
            disableClearable
            renderInput={(params) => {
              return (
                <TextField
                  placeholder={localizedStrings.selectOptions(value.minAnswers)}
                  multiline
                  {...params}
                />
              )
            }}
          />
          {value.answers[selectedIndex]?.requiresSubmissionText &&
            renderAdditionalChoiceSubmissionText(selectedIndex)}
        </Box>
      )
    }

    return (
      <MultipleChoice
        options={value.answers.map((answer, index) => {
          return {
            label: answer.value,
            value: answer.id,
            footer: renderAdditionalChoiceSubmissionText(index),
          }
        })}
        selected={value.answers
          .filter((answer) => answer.selected)
          .map(({ id }) => id)}
        onSelect={(selected) => {
          const newValue = produce(value, (draft) => {
            draft.answers.forEach((answer) => {
              if (selected.includes(answer.id)) answer.selected = true
              else answer.selected = false
            })
          })
          onChange(newValue)
          onBlur()
        }}
        maxSelect={question.maxAnswers}
        showCheckbox
        multiSelect
        dataTest={`${question.id}-${'MULTIPLE_CHOICE'}`}
      />
    )
  }

  return (
    <Box data-test={`${question.id}-${'MULTIPLE_CHOICE'}`}>
      {question?.answers.map((answer, index) => {
        if (!answer.selected) return null
        return (
          <Text key={index}>
            {`${answer.value} ${
              answer.requiresSubmissionText ? `(${answer.submissionText})` : ''
            }`}
          </Text>
        )
      })}
    </Box>
  )
}

MultipleChoiceQuestion.rules = MultipleChoiceQuestionRules

export default MultipleChoiceQuestion
