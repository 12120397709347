import {
  authGroupsKeys,
  RequestTypes,
} from '@core/react-query/features/authGroups'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { IGroupPermission } from '@core/entities/Groups/GroupsPermissions/IGroupPermission'
import { GroupPermission } from '@core/entities/Groups/GroupsPermissions/GroupPermission'

const dataTransformer = (data: IGroupPermission) => GroupPermission.new(data)

export const useGetGroupPermission = (
  payload: RequestTypes['getGroupPermission'],
  options?: UseQueryOptions<
    IGroupPermission,
    RequestErrorType,
    GroupPermission
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...authGroupsKeys.byId(payload)._ctx.getGroupPermission(payload),
    retry: 3,
    ...options,
  })
}
