import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  permitTemplatesKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplates'

export const useDeletePermitTemplateRole = (
  options?: UseMutationOptions<never, string, RequestTypes['deletePermitRole']>,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...permitTemplatesKeys.deletePermitRole,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: permitTemplatesKeys.getRoles({
          templateId: variables.templateId,
          templateVersionId: variables.templateVersionId,
        }).queryKey,
      })
    },
    ...options,
  })
}
