import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { supportKeys } from '@core/react-query/features/support'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useUpdateUserProfile = (
  options?: UseMutationOptions<
    Response,
    RequestErrorType,
    RequestTypes['updateUserProfile']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables) =>
      supportKeys.updateUserProfile(variables).queryFn(undefined),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: supportKeys.getUser({ userId: variables.userId }).queryKey,
      })
    },
    ...options,
  })
}
