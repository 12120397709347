import _ from 'lodash'
import { RequestTypes } from './requestTypes'
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import {
  APIAxiosInstance,
  headersWithAccessToken,
} from '@core/utils/api-axios-instance'
import { uploadTemplateAnswerFiles } from '@core/react-query/features/permits/permit/helpers'
import { s3Upload } from '@core/react-query/helpers'
import { attachAuditResultMediaTypes } from '@core/entities/audit/AuditResult'

const checklistQueryKeys = createQueryKeys('checklist', {
  byId: ({ checklistId, accessToken }: RequestTypes['getChecklist']) => {
    return {
      queryKey: [checklistId],
      queryFn: async () => {
        const headers = headersWithAccessToken({ accessToken })
        const { data } = await APIAxiosInstance.get(
          `checklists/${checklistId}`,
          { headers: headers },
        )
        return data
      },
      contextQueries: {
        linkedProcesses: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `checklists/${checklistId}/linked-processes`,
            )
            return data
          },
        },
        timeline: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `audits/checklists?checklistId=${checklistId}`,
            )
            await Promise.all(data.auditList.map(attachAuditResultMediaTypes))
            return data.auditList
          },
        },
        audits: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(`audits/checklists`, {
              params: { checklistId },
            })
            await Promise.all(data.auditList.map(attachAuditResultMediaTypes))

            return data.auditList
          },
        },
        auditsByParticipantId: ({
          participantId,
        }: RequestTypes['getChecklistAuditsByParticipantId']) => ({
          queryKey: [participantId],
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `audits/checklists/${checklistId}/participants/${participantId}/all`,
            )
            await Promise.all(data.auditList.map(attachAuditResultMediaTypes))

            return data.auditList
          },
        }),
        getRoles: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `checklists/${checklistId}/roles`,
            )

            return data.roles
          },
        },
      },
    }
  },
  draftById: ({ draftId }: RequestTypes['getChecklistDraft']) => {
    return {
      queryKey: [draftId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `checklists/drafts/${draftId}`,
        )
        return data
      },
    }
  },
  templateById: ({
    templateId,
    projectId,
  }: RequestTypes['getChecklistTemplate']) => {
    return {
      queryKey: [templateId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `checklists/templates/${templateId}?projectId=${projectId}`,
        )
        return data
      },
    }
  },
})

const checklistMutationKeys = createMutationKeys('checklist', {
  create: {
    mutationKey: null,
    mutationFn: async ({
      projectId,
      mainAnswers,
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['create']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: mainAnswers.answers,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `checklists?projectId=${projectId}`,
        { ...mutation, mainAnswers: { answers: uploadedAnswers } },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  createDraft: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      mainAnswers,
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['createDraft']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: mainAnswers.answers,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `checklists/drafts?organisationId=${organisationId}`,
        { ...mutation, mainAnswers: { answers: uploadedAnswers } },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )

      return data
    },
  },
  updateDraft: {
    mutationKey: null,
    mutationFn: async ({
      id,
      mainAnswers,
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['updateDraft']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: mainAnswers.answers,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.put(
        `checklists/drafts/${id}`,
        {
          ...mutation,
          mainAnswers: { answers: uploadedAnswers },
        },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )

      return data
    },
  },
  migrateDraftToLatestVersion: {
    mutationKey: null,
    mutationFn: async ({ id }: RequestTypes['migrateDraftToLatestVersion']) => {
      const { data } = await APIAxiosInstance.post(
        `checklists/drafts/${id}/migrate-to-latest-version`,
        { id },
      )

      return data
    },
  },
  deleteDraft: {
    mutationKey: null,
    mutationFn: async ({ draftId }: RequestTypes['deleteDraft']) => {
      const { data } = await APIAxiosInstance.delete(
        `checklists/drafts/${draftId}`,
      )

      return data
    },
  },
  clone: {
    mutationKey: null,
    mutationFn: async ({ checklistId }: RequestTypes['clone']) => {
      const { data } = await APIAxiosInstance.post(
        `checklists/${checklistId}/clone`,
        {},
      )
      return data
    },
  },
  addAndBriefParticipants: {
    mutationKey: null,
    mutationFn: async ({
      checklistId,
      participants,
      accessToken,
      offlineSubmittedOn,
    }: RequestTypes['addAndBriefParticipants']) => {
      // Upload signature files
      const participantsWithSignatureKeys = await Promise.all(
        participants.map(async (participant) => {
          const signatureKey = await s3Upload({
            file: participant.signature,
            folderName: 'checklist_role_signature',
            accessToken,
          })

          return {
            ...participant,
            signatureKey,
          }
        }),
      )

      // Add participants
      const participantsToAdd = participantsWithSignatureKeys.filter(
        (participant) => !participant.participantId,
      )

      const headers = {
        'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn,
        ...headersWithAccessToken({ accessToken }),
      }

      if (participantsToAdd.length) {
        await APIAxiosInstance.post(
          `checklists/${checklistId}/participants`,
          { participants: participantsToAdd },
          { headers },
        )
      }

      // Brief participants
      const participantsToBrief = _.differenceWith(
        participantsWithSignatureKeys,
        participantsToAdd,
        _.isEqual,
      )

      if (participantsToBrief.length) {
        await APIAxiosInstance.post(
          `checklists/${checklistId}/participants/briefings`,
          {
            participants: participantsToBrief.map(
              ({ participantId, signatureKey }) => ({
                participantId,
                signatureKey,
              }),
            ),
          },
          { headers },
        )
      }
    },
  },
  removeParticipant: {
    mutationKey: null,
    mutationFn: async ({
      checklistId,
      participantId,
    }: RequestTypes['removeParticipant']) => {
      const { data } = await APIAxiosInstance.delete(
        `checklists/${checklistId}/participants/${participantId}`,
      )

      return data
    },
  },
  pdfDownloadUrl: {
    mutationKey: null,
    mutationFn: async ({ checklistId }: RequestTypes['getPdfDownloadUrl']) => {
      const { data } = await APIAxiosInstance.post(
        `checklists/${checklistId}/download`,
      )

      return data
    },
  },
})

export const checklistKeys = mergeQueryKeys(
  checklistQueryKeys,
  checklistMutationKeys,
).checklist
