import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermitBase } from '@core/entities/permit/PermitBase'
import { permitsKeys } from '@core/react-query/features/permits'
import { useMutateQueryData } from '@core/react-query/hooks'
import { OfflineEntityType } from '@core/react-query/defaultMutations'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const getCreatePermitDraftMutationOptions = (
  queryClient: QueryClient,
) => {
  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)
  return {
    ...permitKeys.createDraft,
    meta: {
      name: 'Create Permit Draft',
      offlineEnabled: true,
      variablesType: OfflineEntityType.PERMIT_SUBMISSION,
    },
    onSuccess: (draft) => {
      updateInfiniteQueryItem(
        permitsKeys.drafts({
          organisationId: draft.template.organisationId,
          projectIds: [draft.projectId],
        }).queryKey,
        draft,
      )
    },
  }
}

export const useCreatePermitDraft = () => {
  const queryClient = useQueryClient()
  return useMutation<
    IPermitBase,
    RequestErrorType,
    RequestTypes['createDraft']
  >(getCreatePermitDraftMutationOptions(queryClient))
}
