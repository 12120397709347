import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson'

/**
 * Generates a consistent hash for a GeoJSON FeatureCollection.
 *
 * This function combines the coordinates and properties of each feature in the FeatureCollection,
 * sorts them, and joins them into a single string to produce a unique hash representation. This
 * allows for efficient comparison between different FeatureCollections to check for modifications.
 *
 * @param featureCollection - A GeoJSON FeatureCollection containing features with any Geometry type.
 * @returns A string representing the hash of the provided FeatureCollection.
 */
export const hashFeatureCollection = (
  featureCollection: FeatureCollection<Geometry, GeoJsonProperties>,
): string => {
  return featureCollection.features
    .map((feature) => {
      // Check if geometry has coordinates (i.e., it's not a GeometryCollection)
      const coordsHash =
        'coordinates' in feature.geometry
          ? JSON.stringify(feature.geometry.coordinates)
          : ''

      const propsHash = JSON.stringify(feature.properties || {})
      return coordsHash + propsHash
    })
    .sort() // Sort to ensure consistent order
    .join('') // Join into a single string as the hash
}
