import distance from '@turf/distance'
import { point } from '@turf/helpers'
import { booleanEqual } from '@turf/turf'

/**
 * Generates a random offset within a circular area around the origin.
 * This offset is used to slightly adjust markers that overlap in location,
 * making them appear within a specified radius but not at the exact same point.
 *
 * @param {number} radius - The maximum radius in degrees for the offset.
 *                           Defaults to approximately 3 meters (0.000027 degrees).
 * @returns {[number, number]} - An array containing the longitude and latitude offset.
 *
 * @example
 * const offset = getRandomCircularOffset(0.000027); // Returns an offset up to 3 meters
 */
const getRandomCircularOffset = (radius = 0.000027): [number, number] => {
  const angle = Math.random() * 2 * Math.PI // Random angle in radians
  const distance = Math.random() * radius // Random distance within the radius
  const xOffset = distance * Math.cos(angle)
  const yOffset = distance * Math.sin(angle)
  return [xOffset, yOffset]
}

/**
 * Returns a function that calculates an offset for a given marker to prevent
 * overlapping with previously processed markers. If the marker overlaps with any
 * other marker within the specified overlap distance, a small random circular offset
 * is applied.
 *
 * @param {number} overlapDistance - The maximum distance (in degrees) between two markers
 *                                   to consider them overlapping. Defaults to approximately
 *                                   1 meter (0.00001 degrees).
 * @returns {(currentMarker: any) => [number, number]} - A function that takes a marker and
 *                                                      returns a [longitude, latitude] offset.
 *
 * @example
 * const getMarkerOffset = calculateMarkerOffset(0.00001);
 * const offset = getMarkerOffset(marker);
 */
export const calculateMarkerOffset = (
  overlapDistance = 0.00001,
): ((currentMarker: any) => [number, number]) => {
  const processedMarkers: any[] = [] // Internal state to track processed markers

  return (currentMarker) => {
    const markerPoint = point(currentMarker.geometry.coordinates)

    // Check if the current marker overlaps with any previously processed marker
    const hasOverlap = processedMarkers.some((otherMarker) => {
      const otherPoint = point(otherMarker.geometry.coordinates)
      return (
        booleanEqual(markerPoint, otherPoint) ||
        distance(markerPoint, otherPoint) < overlapDistance
      )
    })

    // Add the current marker to the list of processed markers
    processedMarkers.push(currentMarker)

    // Apply a random circular offset if there is an overlap
    return hasOverlap ? getRandomCircularOffset() : [0, 0]
  }
}
