import React from 'react'
import { localizedStrings } from '@core/strings'
import { Flex } from '@fundamentals'
import { Filters } from '@elements/Filters'
import { FormControlLabel, Switch } from '@common/material'

type PermitFilterToolbarProps = {
  filters: any
  store: any
  showPermits: boolean
  setShowPermits: (showPermits: boolean) => void
}

export const PermitFilterToolbar: React.FC<PermitFilterToolbarProps> = ({
  filters,
  store,
  showPermits,
  setShowPermits,
}) => {
  return (
    <Flex>
      <Filters noFlex filters={filters} store={store} />
      <FormGroup>
        <FormControlLabel
          style={{ paddingLeft: 8 }}
          control={
            <Switch
              checked={showPermits}
              onChange={(e, checked) => setShowPermits(checked)}
            />
          }
          label={localizedStrings.showPermits}
        />
      </FormGroup>
    </Flex>
  )
}
