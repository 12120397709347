import {
  businessUnitsKeys,
  RequestTypes,
} from '@core/react-query/features/businessUnits'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import { IProject, Project } from '@core/entities/project'
import { usePageableQuery } from '@core/react-query/hooks'
import { useToast } from '@core/toast'

const dataTransformer = (response: PageResponse<IProject>) => {
  return {
    ...response,
    content: response.content.map((project) => Project.new(project)),
  }
}

export const useGetBusinessUnitProjects = (
  payload: RequestTypes['getBusinessUnitProjects'],
  options?: UseInfiniteQueryOptions<
    PageResponse<IProject>,
    string,
    PageResponse<Project>
  >,
) => {
  const businessUnitProjectsQuery =
    businessUnitsKeys.getBusinessUnitProjects(payload)

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options?.enabled,
    queryKey: businessUnitProjectsQuery.queryKey,
    dataTransformer,
  })
  const toast = useToast()

  return useInfiniteQuery<
    PageResponse<IProject>,
    string,
    PageResponse<Project>
  >({
    cacheTime: 0,
    keepPreviousData: true,
    retry: 3,
    ...businessUnitProjectsQuery,
    ...infiniteQueryOptions,
    ...options,
    onError: (e) => toast.error(e),
  })
}
