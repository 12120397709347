import React from 'react'
import { TimelineLinkBlock } from '@common/Timeline'
import { localizedStrings } from '@core/strings'
import { PageUrls } from '@core/page-urls'
import { AuditResult } from '@core/entities/audit/AuditResult'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineTemplateBlocks: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  return (
    <>
      {auditResult.permitTemplate && (
        <TimelineLinkBlock
          title={localizedStrings.permitTemplate}
          href={
            auditResult.action.includes('PROJECT') &&
            auditResult.permitTemplateVersion
              ? PageUrls.projectViewTemplateVersion({
                  orgId: auditResult.organisation?.id,
                  templateId: auditResult.permitTemplate.id,
                  versionId: auditResult.permitTemplateVersion?.id,
                  projectId: auditResult.project?.id,
                })
              : PageUrls.editTemplate(
                  auditResult.organisation?.id,
                  auditResult.permitTemplate.id,
                )
          }
          text={auditResult.permitTemplate.name}
        />
      )}
      {auditResult.permitTemplateVersion &&
        !auditResult.action.includes('PROJECT') && (
          <TimelineLinkBlock
            title={localizedStrings.templateVersion}
            href={PageUrls.editTemplateVersion({
              orgId: auditResult.organisation?.id,
              templateId: auditResult.permitTemplate.id,
              versionId: auditResult.permitTemplateVersion.id,
            })}
            text={`Version ${auditResult.permitTemplateVersion.version}`}
          />
        )}
      {auditResult.checklistTemplate && (
        <TimelineLinkBlock
          title={localizedStrings.checklistTemplate}
          href={
            auditResult.action.includes('PROJECT') &&
            auditResult.checklistTemplateVersion
              ? PageUrls.projectViewChecklistTemplateVersion({
                  orgId: auditResult.organisation?.id,
                  templateId: auditResult.checklistTemplate.id,
                  versionId: auditResult.checklistTemplateVersion.id,
                  projectId: auditResult.project?.id,
                })
              : PageUrls.editChecklistTemplate(
                  auditResult.organisation?.id,
                  auditResult.checklistTemplate.id,
                )
          }
          text={auditResult.checklistTemplate.name}
        />
      )}
      {auditResult.checklistTemplateVersion &&
        !auditResult.action.includes('PROJECT') && (
          <TimelineLinkBlock
            title={localizedStrings.templateVersion}
            href={PageUrls.editChecklistTemplateVersion({
              orgId: auditResult.organisation?.id,
              templateId: auditResult.checklistTemplate.id,
              versionId: auditResult.checklistTemplateVersion.id,
            })}
            text={`Version ${auditResult.checklistTemplateVersion.version}`}
          />
        )}
    </>
  )
}
