import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { IGroupPermissionsResponse } from './IGroupPermissionsResponse'
import { GroupPermission } from '@core/entities/Groups/GroupsPermissions/GroupPermission'
import { PermissionEntityType } from '@core/entities/Groups/GroupsPermissions/types'

export class GroupPermissionsResponse extends Entity<IGroupPermissionsResponse> {
  @Type(() => GroupPermission)
  groupPermissions: Array<GroupPermission>

  public static new(payload: unknown): GroupPermissionsResponse {
    const entity = plainToInstance(GroupPermissionsResponse, payload)

    return entity
  }

  public getPermissionsByType(filterType: PermissionEntityType) {
    return this.groupPermissions.filter(({ type }) => type === filterType)
  }
}
