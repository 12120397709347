import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { SignatureEdit } from 'webapp/components/common/Signature'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import { Text } from '@fundamentals'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { useAcceptTransfer } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  permit: Permit
}

export const AcceptPermitTransferModal: React.FC<ComponentType> = ({
  permit,
}) => {
  const acceptTransferPermit = useAcceptTransfer()
  const { hideModal } = useModal()
  const { data: profile } = useGetProfile()
  const [error, setError] = useState('')
  const { lastTransfer } = permit
  const { message, createdOn } = lastTransfer
  const { firstName, lastName } = lastTransfer.createdBy
  const datetimeString = moment(createdOn).format('D MMMM YYYY HH:mm')
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.acceptTransfer}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Text pt={2} fontSize={12} color='text.secondary'>
          {`Note from ${firstName} ${lastName} - ${datetimeString}`}
        </Text>
        <Text>{message}</Text>
        <Text pt={2} fontSize={12} color='text.secondary'>
          {localizedStrings.acceptPermitTransferAcknowledge}
        </Text>
        <SignatureEdit readonly value={profile?.signatureUrl} />
        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            acceptTransferPermit.mutate(
              { permitId: permit.id },
              {
                onError: (e) => {
                  setError(e.message)
                  toast.error(e.message)
                },
                onSuccess: () => {
                  toast.success(localizedStrings.transferAccepted)
                  hideModal()
                },
              },
            )
          }}
          data-test='permit-transfer-accept'
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
