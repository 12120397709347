import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import {
  TemplateEditorChecklistTemplateVersion,
  ITemplateEditorChecklistTemplateVersion,
} from '@core/entities/checklist/TemplateEditorChecklistTemplateVersion'
import { checklistTemplateKeys } from '@core/react-query/features/templates/checklistTemplates'

export const useGetChecklistTemplateVersion = (
  payload: RequestTypes['getChecklistTemplateVersion'],
  options?: UseQueryOptions<
    ITemplateEditorChecklistTemplateVersion,
    string,
    TemplateEditorChecklistTemplateVersion
  >,
) => {
  return useQuery({
    select: TemplateEditorChecklistTemplateVersion.new,
    ...checklistTemplateKeys.getVersion(payload),
    ...options,
  })
}
