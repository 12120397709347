import React from 'react'
import { Chip } from '@common/material'
import { IProcessTag } from '@core/entities/processTags'
import { ChipProps } from '@mui/material'
import { ProcessTagColorBadge } from '@common/ProcessTagColorBadge'

type ProcessTagChipProps = ChipProps & {
  tag: IProcessTag
  active?: boolean
}

export const ProcessTagChip: React.FC<ProcessTagChipProps> = ({
  tag,
  active,
  ...props
}) => {
  return (
    <Chip
      size='small'
      variant={active ? 'filled' : 'outlined'}
      label={tag?.name}
      icon={<ProcessTagColorBadge color={tag?.color} sx={{ ml: 2 }} />}
      {...props}
      sx={{
        px: 0.6,
        backgroundColor: active ? 'rgba(0,0,0,0.6)' : 'inherith',
        color: active ? 'white' : 'inherith',
        '&:hover': {
          backgroundColor: active ? 'rgba(0,0,0,0.6)' : 'inherith',
        },
        '.MuiChip-deleteIcon': {
          color: active ? 'white !important' : 'inherith',
        },
        ...props.sx,
      }}
    />
  )
}
