import React from 'react'
import { Flex, Text } from '@fundamentals'
import { Card, MenuItem } from '@common/material'

interface GenericComponentType<T> {
  item: T
  dataTestId?: string
  onSelect: () => void
  onDragStart?: (event: React.DragEvent<HTMLLIElement>) => void
  renderIcon: (item: T) => React.ReactNode
  renderText: (item: T) => React.ReactNode
}

export const GenericMenuItem = <T,>({
  item,
  dataTestId,
  onSelect,
  onDragStart,
  renderIcon,
  renderText,
}: GenericComponentType<T>) => {
  return (
    <MenuItem
      sx={{ p: 0, mb: '0.5rem' }}
      onClick={onSelect}
      draggable={!!onDragStart}
      onDragStart={onDragStart}
      data-test={dataTestId}
    >
      <Card sx={{ width: '100%', backgroundColor: 'transparent' }}>
        <Flex py='0.75rem' px='0.5rem'>
          {renderIcon(item)}
          <Text pl='0.25rem'>{renderText(item)}</Text>
        </Flex>
      </Card>
    </MenuItem>
  )
}
