import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  ChecklistDraft,
  IChecklistDraft,
} from '@core/entities/checklist/ChecklistDraft'

const checklistTransform: (checklist: IChecklistDraft) => ChecklistDraft = (
  data,
) => {
  return ChecklistDraft.new(data)
}

export const useChecklistDraftById = (
  payload: RequestTypes['getChecklistDraft'],
  options?: UseQueryOptions<IChecklistDraft, RequestErrorType, ChecklistDraft>,
) => {
  return useQuery({
    select: checklistTransform,
    ...checklistKeys.draftById(payload),
    ...options,
  })
}
