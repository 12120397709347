import React from 'react'
import ModalPdf from '../../modal/pdf/ModalPdf'
import { ButtonText } from '../../Button'
import ModalImage from 'react-modal-image'
import { Box, Flex } from '@fundamentals'
import { DeleteOutlineOutlined } from '@mui/icons-material'

type ComponentType = {
  value: string[] | string | { type: 'img' | 'pdf'; url: string }[]
  isDocument: boolean
  preventRemove: boolean
  clear: (index: number | any, itemType?: 'pdf' | 'img') => void
  onSaveAnnotation?: (blob: Blob, url: string, index: number) => void
  readOnly?: boolean
}

const UploadThumbnailList: React.FC<ComponentType> = ({
  value,
  isDocument,
  preventRemove,
  clear,
  onSaveAnnotation,
  readOnly = false,
}) => {
  return (
    <Flex justifyContent='flex-start' flexWrap='wrap'>
      {value ? (
        Array.isArray(value) ? (
          value.map((item, index) => {
            const isString = typeof item === 'string'
            const url = isString ? item : item.url
            const isDoc = isString ? isDocument : !!(item.type === 'pdf')
            const itemType = isString ? null : item.type
            return (
              <Box
                mr={4}
                mb={4}
                key={index}
                className='upload__image-wrapper'
                data-test={`upload-thumbnail-${index}`}
                sx={{
                  overflow: 'hidden',
                  maxHeight: '100px',
                }}
              >
                {isDoc ? (
                  <ModalPdf
                    previewUrl={url}
                    onSaveAnnotation={(blob, url) => {
                      onSaveAnnotation(blob, url, index)
                    }}
                    readOnly={readOnly}
                  />
                ) : (
                  <ModalImage small={url} large={url} />
                )}
                {!preventRemove && (
                  <ButtonText
                    p={1}
                    className='upload__delete-button flex-row align-items-center border-radius'
                    onClick={() => clear(index, itemType)}
                    data-test={`upload-thumbnail-delete-btn-${index}`}
                  >
                    <DeleteOutlineOutlined color='error' />
                  </ButtonText>
                )}
              </Box>
            )
          })
        ) : (
          <Box
            mr={4}
            mb={4}
            className='upload__image-wrapper'
            data-test={`upload-thumbnail-0`}
          >
            <ModalImage small={value} large={value} />
            {!preventRemove && (
              <ButtonText
                className='btn-white upload__delete-button flex-row align-items-center border-radius'
                onClick={clear}
              >
                <i className='fas fa-trash text-black-50 fa-lg' />
              </ButtonText>
            )}
          </Box>
        )
      ) : (
        <span />
      )}
    </Flex>
  )
}

export default UploadThumbnailList
