import { PageUrls } from '@core/page-urls'
import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { PermitActionCard } from '../PermitActionCard'
import { useRouter } from 'next/router'
import { Permit } from '@core/entities/permit/Permit'
import moment from 'moment'
import { PostPermitChecksSubmitModal } from '../modals/PostPermitChecksSubmitModal'
import { useModal } from '@common/GlobalModal'
import Countdown from '@core/Countdown'
import { useQueryClient } from '@tanstack/react-query'
import { TimerOutlined } from '@mui/icons-material'
import { permitKeys } from '@core/react-query/features/permits/permit'
import { Text } from '@fundamentals'

type PostPermitChecksCardProps = {
  organisationId: number
  projectId: number
  permit: Permit
}
export const PostPermitChecksCard: React.FC<PostPermitChecksCardProps> = ({
  organisationId,
  projectId,
  permit,
}) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { showModal } = useModal()

  const isPermitSuspended = permit.status === 'SUSPENDED'
  if (
    !(
      permit?.hasPermission('SUBMIT_POST_PERMIT_CHECKS') &&
      !isPermitSuspended &&
      permit.countdownExpiry
    )
  )
    return null

  const isPostPermitChecksCountdownActive =
    !!permit?.countdownExpiry && moment().isBefore(permit?.countdownExpiry)

  if (isPostPermitChecksCountdownActive) {
    return (
      <PermitActionCard
        title={permit.countdownName}
        recipientSection={
          <>
            <TimerOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            <Text sx={{ fontSize: '12px', fontWeight: 500, ml: 1 }}>
              <Countdown
                expiredLabel={localizedStrings.done}
                pollFrequency={400}
                value={permit.countdownExpiry}
                onExpire={() => {
                  queryClient.invalidateQueries(
                    permitKeys.byId({ permitId: permit.id }).queryKey,
                  )
                }}
              />{' '}
              Remaining
            </Text>
          </>
        }
        info={localizedStrings.postPermitChecksDescription}
        sx={{ mb: 1.5, borderColor: 'warning.main', borderWidth: 2 }}
      />
    )
  }

  return (
    <PermitActionCard
      title={localizedStrings.postPermitChecks}
      info={localizedStrings.postPermitChecksDescription}
      BottomComponent={
        <Button
          variant='contained'
          data-test='post-permit-checks'
          fullWidth
          onClick={() => {
            if (
              !permit?.template?.postPermitChecksQuestions?.questions?.length
            ) {
              return showModal(
                <PostPermitChecksSubmitModal
                  permitId={permit.id}
                  templateId={permit.template.id}
                />,
              )
            }

            if (projectId) {
              router.push(
                PageUrls.projectPermitSignoffCheck({
                  organisationId,
                  projectId,
                  permitId: permit.id,
                }),
              )
            } else {
              router.push(
                PageUrls.permitSignoffCheck({
                  organisationId,
                  permitId: permit.id,
                }),
              )
            }
          }}
        >
          {localizedStrings.completePostPermitChecks}
        </Button>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
