import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { useGetProjectLocations } from '@core/react-query/features/projects/locations'
import { FilterHookArgumentsBase } from '@core/providers/filters'

type ProjectLocationsFilterArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<string, Multiple>> & {
    multiple?: Multiple
    projectId: number
  }

export const useProjectLocationParentOnlyFilter: <
  Multiple extends boolean | undefined,
>(
  args: ProjectLocationsFilterArgs<Multiple>,
) => SelectFilterInternalArguments<string, Multiple> = (args) => {
  const { data: projectLocations } = useGetProjectLocations(
    {
      projectId: args.projectId,
      statuses: ['ACTIVE'],
    },
    { enabled: !!args.projectId },
  )

  // If the location hierarchy is not enabled for the project then we hide this
  const isHidden = args.isHidden || !projectLocations?.enabled

  return useSelectFilter({
    ...args,
    options: projectLocations?.locations?.map((t) => t.id),
    isHidden: isHidden,
    valueToLabel: (v) =>
      projectLocations?.locations?.find((t) => t.id === v)?.name,
  })
}
