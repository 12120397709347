import { localizedStrings } from '@core/strings'
import React from 'react'
import { PermitDetailsHeader } from './permitDetailsHeader/PermitDetailsHeader'
import { default as legacyTheme } from 'styles/theme'
import { Box, Flex, Text } from '@fundamentals'
import { NavigationAwareTabs } from '@elements'
import {
  PermitTab,
  PermitTimelineTab,
  PermitTasksTab,
  PostPermitChecksTab,
  LinkedDocumentsTab,
} from '@modules/permitDetails/tabs'
import { PermitDetailsProps } from './types'
import { useDeepCompareMemo } from 'use-deep-compare'
import { Button } from '@common/material/Button'
import { PageUrls } from '@core/page-urls'
import LaunchIcon from '@mui/icons-material/Launch'
import { IconButton } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'
import { NavigationTabType } from 'webapp/components/elements/NavigationAwareTabs/types'
import BackdropLoader from '@common/fundamentals/BackdropLoader'
import { usePermitById } from '@core/react-query/features/permits/permit'
import { PermitDetailsSidebar } from '@modules/permitDetails/PermitDetailsSidebar'
import { useRealTimePermit } from '@core/react-query/features/permits/permit/hooks/useRealTimePermit'
import useFeatureFlags from '@core/providers/useFeatureFlags'

export const PermitDetails: React.FC<PermitDetailsProps> = ({
  organisationId,
  projectId,
  permitId,
  isModal,
}) => {
  const { hideModal } = useModal()
  const { flags } = useFeatureFlags(['web_real_time_permit_details'])

  const {
    data: permit,
    isLoading,
    isFetching,
    error,
  } = usePermitById({ permitId })
  useRealTimePermit(permitId, {
    enabled: !isFetching && !!flags.web_real_time_permit_details,
  })

  const tabs = useDeepCompareMemo(() => {
    const postPermitChecksFilter = (tab: NavigationTabType) => {
      if (
        !permit?.postPermitChecksAnswers ||
        permit?.postPermitChecksAnswers?.answers.length === 0
      ) {
        return tab.name === 'PostPermitChecks' ? null : tab
      }
      return tab
    }

    return [
      {
        name: 'Permit',
        label: 'Permit',
        component: <PermitTab permit={permit} />,
      },
      {
        name: 'Timeline',
        label: 'Timeline',
        clearParams: true,
        component: <PermitTimelineTab permit={permit} />,
      },
      {
        name: 'PostPermitChecks',
        label: 'Post Permit Checks',
        component: <PostPermitChecksTab permit={permit} />,
      },
      {
        name: 'Tasks',
        label: 'Tasks',
        component: <PermitTasksTab permit={permit} />,
      },
      {
        name: 'LinkedDocuments',
        label: 'Linked Documents',
        component: <LinkedDocumentsTab permit={permit} />,
      },
    ].filter(postPermitChecksFilter)
  }, [permit])

  console.log('error', error?.originalError.response.status)

  if (error?.originalError.response.status === 403) {
    return (
      <Flex flex={1} justifyContent='center' alignItems='center'>
        <Box mb={10}>
          <Text variant='h5'>{localizedStrings.invalidPermissions}</Text>
          <Text color='grey'>{localizedStrings.permitNoPermission}</Text>
        </Box>
      </Flex>
    )
  }

  return (
    <>
      <BackdropLoader open={!permit && isLoading} />
      {!!permit && (
        <>
          <Box
            data-test='permit-details'
            position='sticky'
            top={isModal ? 0 : legacyTheme.variants.navbar.height}
            zIndex={10}
            {...(!isModal ? { bgcolor: '#FAFAFA' } : { bgcolor: 'white' })}
            px='3'
          >
            {isModal && (
              <Flex justifyContent='space-between'>
                <Button
                  style={{ marginBottom: 4 }}
                  startIcon={<LaunchIcon />}
                  onClick={() => {
                    window.open(
                      PageUrls.projectPermitDetail({
                        organisationId,
                        projectId: permit.project.id,
                        permitId: permit.id,
                      }),
                      '_blank',
                      'noopener, noreferrer',
                    )
                  }}
                >
                  {localizedStrings.openInNewTab}
                </Button>

                <IconButton aria-label='close-modal' onClick={hideModal}>
                  <ClearIcon />
                </IconButton>
              </Flex>
            )}
            <PermitDetailsHeader permit={permit} />
          </Box>
          <Flex>
            <NavigationAwareTabs tabs={tabs} tabParam='tab' isModal={isModal} />
            <Box>
              <PermitDetailsSidebar
                organisationId={organisationId}
                projectId={projectId}
                permit={permit}
              />
            </Box>
          </Flex>
        </>
      )}
    </>
  )
}
