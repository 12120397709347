import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  registerKeys,
  RequestTypes,
} from '@core/react-query/features/registers/registers/register'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  IRegisterTemplateFilterResponse,
  RegisterTemplateFilterResponse,
} from '@core/entities/register/RegisterTemplateFilterResponse'

const dataTransformer = (response: IRegisterTemplateFilterResponse) =>
  RegisterTemplateFilterResponse.new(response)

export const useGetRegisterFilters = (
  payload: RequestTypes['getRegisterFilters'],
  options?: UseQueryOptions<
    IRegisterTemplateFilterResponse,
    RequestErrorType,
    RegisterTemplateFilterResponse
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...registerKeys.filtersList(payload),
    ...options,
  })
}
