import React, { useCallback } from 'react'
import {
  AdvancedTextInputFilterValue,
  AdvancedNumberFilterValue,
  FilterMapInternal,
  AdvancedDateFilterValue,
  AdvancedUserFilterValue,
  AdvancedInspectionFilterValue,
} from '@core/providers/filters'
import { FilterStore } from '@core/providers/filters'
import { SelectFilter } from './SelectFilter'
import TextInputFilter from './TextInputFilter'
import DateRangeFilter from './DateRangeFilter'
import _debounce from 'lodash/debounce'
import {
  AnalyticsCustomDateRangeFilterValue,
  DateRangeFilterValue,
} from '@core/providers/filters'
import AnalyticsDateRangeFilter from './AnalyticsDateRangeFilter'
import { FilterInternalArguments } from '@core/providers/filters'
import { AdvancedNumericFilter } from './AdvancedNumericFilter'
import { AdvancedTextFilter } from './AdvancedTextFilter'
import { AdvancedSelectFilter } from './AdvancedSelectFilter'
import { AdvancedSelectFilterValue } from '@core/providers/filters/fundamentals/hooks/useAdvancedSelectFilter'
import { AdvancedDateFilter } from './AdvancedDateFilter'
import { AdvancedUserFilter } from './AdvancedUserFilter'
import { AdvancedDiagramFilter } from './AdvancedDiagramFilter'
import { AdvancedDiagramFilterValue } from '@core/providers/filters/fundamentals/hooks/useAdvancedDiagramFilter'
import { AdvancedInspectionFilter } from './AdvancedInspectionFilter'

type FocusedFilterProps = {
  filter: FilterInternalArguments
  store: FilterStore<FilterMapInternal>
  storeKey: string
  isFocusedOnMount: boolean
  onUnfocused: () => void
  anchorEl: HTMLButtonElement
  setAnchorEl: (el: HTMLButtonElement | null) => void
}

export const FocusedFilter: React.FC<FocusedFilterProps> = ({
  filter,
  store,
  storeKey,
  isFocusedOnMount,
  onUnfocused,
  anchorEl,
  setAnchorEl,
}) => {
  const onChange = (value: any) => {
    store.setFilterValues(
      // @ts-ignore
      {
        [storeKey]: value,
      },
      { partial: true },
    )
  }

  const debouncedInputChange = filter.onInputChange
    ? useCallback(_debounce(filter.onInputChange, filter.debounceInput), [])
    : null

  switch (filter.type) {
    case 'SELECT':
      return (
        <SelectFilter
          filter={{ ...filter, onInputChange: debouncedInputChange }}
          onChange={onChange}
          value={store.values[storeKey]}
          dataTest={filter?.dataTest}
        />
      )
    case 'TEXT_INPUT':
      return (
        <TextInputFilter
          filter={{ ...filter, onInputChange: debouncedInputChange }}
          // We cast to string because the filter manages its own state
          value={store.values[storeKey] as string}
          onChange={onChange}
          dataTest={filter?.dataTest}
          isFocusedOnMount={isFocusedOnMount}
        />
      )
    case 'DATE_RANGE':
      return (
        <DateRangeFilter
          filter={{ ...filter, onInputChange: debouncedInputChange }}
          // We cast to DateRangeFilterValue because the filter manages its own state
          value={store.values[storeKey] as DateRangeFilterValue}
          onChange={onChange}
          onCalendarClose={onUnfocused}
        />
      )
    case 'ANALYTICS_CUSTOM_DATE_RANGE':
      return (
        <AnalyticsDateRangeFilter
          filter={{ ...filter, onInputChange: debouncedInputChange }}
          // We cast to DateRangeFilterValue because the filter manages its own state
          value={store.values[storeKey] as AnalyticsCustomDateRangeFilterValue}
          onChange={onChange}
          onCalendarClose={onUnfocused}
        />
      )
    case 'ADVANCED_NUMBER':
      return (
        <AdvancedNumericFilter
          filter={filter}
          onChange={onChange}
          storeValue={store.values[storeKey] as AdvancedNumberFilterValue}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )
    case 'ADVANCED_TEXT_INPUT':
      return (
        <AdvancedTextFilter
          filter={filter}
          onChange={onChange}
          storeValue={store.values[storeKey] as AdvancedTextInputFilterValue}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )
    case 'ADVANCED_SELECT':
      return (
        <AdvancedSelectFilter
          filter={filter}
          onChange={onChange}
          storeValue={store.values[storeKey] as AdvancedSelectFilterValue}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )
    case 'ADVANCED_DATE':
      return (
        <AdvancedDateFilter
          filter={filter}
          onChange={onChange}
          storeValue={store.values[storeKey] as AdvancedDateFilterValue}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )
    case 'ADVANCED_USER':
      return (
        <AdvancedUserFilter
          filter={filter}
          onChange={onChange}
          storeValue={store.values[storeKey] as AdvancedUserFilterValue}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )
    case 'ADVANCED_DIAGRAM':
      return (
        <AdvancedDiagramFilter
          filter={filter}
          onChange={onChange}
          storeValue={store.values[storeKey] as AdvancedDiagramFilterValue}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )
    case 'ADVANCED_INSPECTION':
      return (
        <AdvancedInspectionFilter
          onChange={onChange}
          storeValue={store.values[storeKey] as AdvancedInspectionFilterValue}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )
    default:
      return null
  }
}
