import { useGetIdentityProvider } from '@core/react-query/features/auth'
import { Chip } from '@common/material'
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'
import { Flex } from '@fundamentals'

type SupportUserChipsProps = {
  userId: number
  userEmail: string
}

export const SupportUserChips = ({ userId, userEmail }) => {
  const { data: identityProvider } = useGetIdentityProvider(
    {
      email: userEmail,
    },
    {
      enabled: !!userEmail,
    },
  )

  return (
    <Flex flexDirection={'row'} flexWrap={'wrap'} rowGap={1}>
      {!!identityProvider?.identityProvider && (
        <Chip
          icon={<InfoIcon />}
          sx={{ ml: 1 }}
          size='small'
          color='info'
          label={'Single Sign-on'}
        />
      )}
    </Flex>
  )
}
