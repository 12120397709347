import { useMutation, useQueryClient, QueryClient } from '@tanstack/react-query'
import { RequestTypes } from '@core/react-query/features/checklists/checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { checklistKeys } from '../checklistKeys'
import { IChecklist } from '@core/entities/checklist/Checklist'
import { useMutateQueryData } from '@core/react-query/hooks'
import { checklistsKeys } from '@core/react-query/features/checklists'
import { OfflineEntityType } from '@core/react-query/defaultMutations'

export const getCreateChecklistMutationOptions = (queryClient: QueryClient) => {
  const { updateInfiniteQueryItem, deleteInfiniteQueryItem } =
    useMutateQueryData(queryClient)
  return {
    ...checklistKeys.create,
    meta: {
      name: 'Create Checklist',
      offlineEnabled: true,
      variablesType: OfflineEntityType.CHECKLIST_SUBMISSION,
    },
    onSuccess: (checklist, variables) => {
      queryClient.setQueryData(
        checklistKeys.byId({ checklistId: checklist.id }).queryKey,
        checklist,
      )
      if (variables.draftId) {
        deleteInfiniteQueryItem(
          checklistsKeys.drafts({
            organisationId: checklist.organisationId,
            projectIds: [checklist.project.id],
          }).queryKey,
          variables.draftId,
        )
      }
      updateInfiniteQueryItem(
        checklistsKeys
          .allChecklists({
            organisationId: checklist.organisationId,
            projectIds: [checklist.project.id],
          })
          .queryKey.slice(0, -3),
        checklist,
      )
    },
  }
}

export const useCreateChecklist = () => {
  const queryClient = useQueryClient()

  return useMutation<IChecklist, RequestErrorType, RequestTypes['create']>(
    getCreateChecklistMutationOptions(queryClient),
  )
}
