import { useMutation } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { authKeys } from '@core/react-query/features/auth'
import { UseMutationOptions } from '@tanstack/react-query'

export const useSendForgotPasswordEmail = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['sendForgotPasswordEmail']
  >,
) => {
  return useMutation({
    mutationFn: (variables) =>
      authKeys.sendForgotPasswordEmail(variables).queryFn(undefined),
    ...options,
  })
}
