import { localizedStrings } from '@core/strings'
import React from 'react'
import { Flex } from '@fundamentals'
import { useModal } from 'webapp/components/common/GlobalModal'
import { CheckCircle, TimelapseOutlined } from '@mui/icons-material'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { ModalDialogContainer } from '@common/modal'
import { PermitApprovalWrapper } from '@core/entities/permit/PermitApprovalWrapper'

interface ComponentType {
  approvals: PermitApprovalWrapper[]
}

export const ViewApprovalsPermitModal: React.FC<ComponentType> = ({
  approvals,
}) => {
  const { hideModal } = useModal()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.approvals}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        {approvals?.map((a, index) => {
          const Icon = () =>
            a?.action === 'APPROVED' ? (
              <CheckCircle color='success' sx={{ pr: 0.5 }} />
            ) : (
              <TimelapseOutlined color='action' sx={{ pr: 0.5 }} />
            )
          const label =
            a?.action === 'APPROVED'
              ? `${a?.submittedBy?.firstName} ${a?.submittedBy?.lastName} (${a?.approval?.label})`
              : a?.approval?.label

          return (
            <Flex flexDirection='column' key={index}>
              <Flex alignItems='center'>
                <Icon />
                {label}
              </Flex>
            </Flex>
          )
        })}
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          data-test='jsViewApprovals'
          variant='contained'
          onClick={hideModal}
        >
          {localizedStrings.ok}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
