import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  authGroupsKeys,
  RequestTypes,
} from '@core/react-query/features/authGroups'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { IGroupPermission } from '@core/entities/Groups/GroupsPermissions/IGroupPermission'

export const useUpdateAuthGroupPermissions = (
  options?: UseMutationOptions<
    IGroupPermission,
    RequestErrorType,
    RequestTypes['updateAuthGroupPermissions']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...authGroupsKeys.updateAuthGroupPermissions,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: authGroupsKeys._def })
    },
    ...options,
  })
}
