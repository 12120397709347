import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { checklistsKeys } from '@core/react-query/features/checklists/checklistsKeys'
import { useMutateQueryData } from '@core/react-query/hooks'
import { IChecklistDraft } from '@core/entities/checklist/ChecklistDraft'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useMigrateChecklistDraftToLatestVersion = () => {
  const queryClient = useQueryClient()

  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)

  return useMutation<
    IChecklistDraft,
    RequestErrorType,
    RequestTypes['migrateDraftToLatestVersion']
  >({
    ...checklistKeys.migrateDraftToLatestVersion,
    onSuccess: (draft) => {
      queryClient.setQueryData(
        checklistKeys.draftById({ draftId: draft.id }).queryKey,
        draft,
      )

      updateInfiniteQueryItem(
        checklistsKeys.drafts({
          organisationId: draft.organisationId,
          projectIds: [draft.projectId],
        }).queryKey,
        draft,
      )
    },
  })
}
