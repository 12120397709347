import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  permitTemplateKeys,
} from '@core/react-query/features/templates/permitTemplates/permitTemplate'

export const useCreatePermitTemplate = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['createPermitTemplate']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...permitTemplateKeys.createPermitTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: permitTemplateKeys._def })
    },
    ...options,
  })
}
