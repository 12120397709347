import { plainToInstance } from 'class-transformer'
import { FilterBase } from '@core/entities/filters/FilterBase'
import { ISelectFilter } from './ISelectFilter'
import { SelectFilterOperatorTypes } from '../types'

export class SelectFilter extends FilterBase<ISelectFilter> {
  propertyType: 'SELECT'
  operator: SelectFilterOperatorTypes
  selectedOptionUuids: string[]

  public static new(payload: unknown): SelectFilter {
    return plainToInstance(SelectFilter, payload)
  }

  public getValue = ({
    readOnly = false,
    options,
  }: {
    readOnly?: boolean
    options: any[]
  }): string => {
    const isMultiple = this.selectedOptionUuids.length > 1

    if (isMultiple) {
      if (readOnly) {
        const selectValues = options
          .filter(({ uuid }) => this.selectedOptionUuids.includes(uuid))
          .map(({ label }) => ` ${label}`)

        return `${selectValues}`
      }
      return `${this.selectedOptionUuids.length} selected`
    } else {
      const [firstValue] = this.selectedOptionUuids

      const filterLabel = options.find(
        (option) => option.uuid === firstValue,
      )?.label

      return `${filterLabel}`
    }
  }
}
