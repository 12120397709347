import React, { FunctionComponent } from 'react'
import ModalPdfViewer from './ModalPdfViewer'
import { Box, Flex } from '@fundamentals'
import { useModal } from '@common/GlobalModal'

type ComponentType = {
  previewUrl: string
  downloadUrl?: string
  showAcknowledgeButton?: boolean
  onAcknowledge?: () => void
  setShow?: (value: boolean) => void
  children?: (props: any) => React.ReactNode
  onSaveAnnotation?: (blob: Blob, url: string) => void
  readOnly?: boolean
}

const ModalPdf: FunctionComponent<ComponentType> = ({
  previewUrl,
  downloadUrl,
  showAcknowledgeButton = false,
  onAcknowledge = () => {},
  setShow,
  children,
  readOnly,
  onSaveAnnotation,
}) => {
  const { showModal, hideModal } = useModal()

  const handleShowModal = () => {
    showModal(
      <ModalPdfViewer
        onSaveAnnotation={onSaveAnnotation}
        readOnly={readOnly}
        title='pdf'
        downloadUrl={downloadUrl}
        previewUrl={previewUrl}
        toggle={() => {
          setShow ? setShow(false) : hideModal()
        }}
        onDismiss={hideModal}
        showAcknowledgeButton={showAcknowledgeButton}
        onAcknowledge={onAcknowledge}
      />,
    )
  }

  const childrenAsFunction = typeof children === 'function'
  return (
    <>
      <Flex width='100%'>
        <Box
          sx={{ minWidth: 100, cursor: 'pointer' }}
          onClick={() => {
            handleShowModal()
          }}
        >
          <embed
            src={previewUrl}
            style={{ pointerEvents: 'none' }}
            type='application/pdf'
            height='100%'
            width='100%'
          />
        </Box>
        {children && (
          <Box>
            {childrenAsFunction ? children({ handleShowModal }) : children}
          </Box>
        )}
      </Flex>
    </>
  )
}

export default ModalPdf
