import { Entity } from '@core/entities/entity'
import { IChecklistDraft } from './IChecklistDraft'
import { plainToInstance, Type } from 'class-transformer'
import { ChecklistTemplate } from '@core/entities/checklist/ChecklistTemplate'
import { User } from '@core/entities/user'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { TemplateProjectLocations } from '@core/entities/template/TemplateProjectLocations'

export class ChecklistDraft extends Entity<IChecklistDraft> {
  id: number
  draftName: string
  summary: string
  organisationId: number
  projectId: number
  projectName: string
  templateVersionId: number
  @Type(() => ChecklistTemplate)
  template: ChecklistTemplate
  // currentTemplate is present only if there is a newer version of the template
  @Type(() => ChecklistTemplate)
  currentTemplate?: ChecklistTemplate
  locationName: string
  locationId: string
  @Type(() => User)
  createdBy: User
  createdOn: string
  @Type(() => TemplateFormAnswers)
  mainAnswers: TemplateFormAnswers
  @Type(() => TemplateProjectLocations)
  projectLocations: TemplateProjectLocations
  permittedActions: Array<'DELETE' | 'EDIT' | 'READ'>

  public static new(payload: unknown): ChecklistDraft {
    const entity = plainToInstance(ChecklistDraft, payload)

    return entity
  }
}
