import React from 'react'
import { Flex, Text } from '@fundamentals'
import { localizedStrings } from '@core/strings'
import { Card } from '@common/material'
import { palette } from '@core/theme/palette'
import { lighten } from '@mui/system'
import { AuditResult } from '@core/entities/audit/AuditResult'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineEventBlock: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  const color = auditResult.getActionColor()
  const borderColor = palette[color]?.main
  const bgcolor = lighten(palette[color]?.main, 0.88)
  return (
    <Card
      sx={{
        p: 1.5,
        mt: 1.5,
        borderColor,
        bgcolor,
      }}
    >
      <Flex alignItems='center' justifyContent='flex-start'>
        <Text variant='body2' fontWeight='500'>
          {localizedStrings.actionBy(
            auditResult.getActionLabel(),
            !!auditResult?.user,
          )}
        </Text>
        {auditResult?.user && (
          <Text variant='body2' sx={{ ml: 0.5 }}>
            {`${auditResult.user.firstName} ${auditResult.user.lastName}`}
          </Text>
        )}
      </Flex>
      {auditResult.user && (
        <Text fontSize={12}>{auditResult.user.company}</Text>
      )}
    </Card>
  )
}
