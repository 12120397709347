import { Checklist } from '@core/entities/checklist/Checklist'
import {
  checklistsKeys,
  RequestTypes,
} from '@core/react-query/features/checklists'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const dataTransform = (data: Array<Checklist>) => {
  return data.map((checklist) => Checklist.new(checklist))
}

export const useProjectMapChecklists = (
  payload: RequestTypes['getProjectMapChecklists'],
  options?: UseQueryOptions<Checklist[], RequestErrorType>,
) => {
  return useQuery({
    select: dataTransform,
    ...checklistsKeys.projectMapChecklists(payload),
    ...options,
  })
}
