import { useMutation } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermitBase } from '@core/entities/permit/PermitBase'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useCreatePermitApprovalDraft = () => {
  return useMutation<
    IPermitBase,
    RequestErrorType,
    RequestTypes['createApprovalDraft']
  >(permitKeys.createApprovalDraft)
}
