import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectPlanArea } from './IProjectPlanArea'
import {
  IProjectPlanPSPDFArea,
  PROJECT_PLAN_AREA_COLORS,
} from '../ProjectPlanPSPDFArea'

export class ProjectPlanArea extends Entity<IProjectPlanArea> {
  uuid: string

  name: string

  color: 'LIGHT_PRIMARY_BLUE'

  annotationData: {
    type: 'RECTANGLE'
    pageIndex: number
    bbox: number[]
    points: number[]
  }

  public static newFromInstantJSON(
    instantJSONAnnotation: IProjectPlanPSPDFArea,
  ): ProjectPlanArea {
    const color = PROJECT_PLAN_AREA_COLORS.find(
      (color) => color.hex === instantJSONAnnotation.fillColor.toUpperCase(),
    )

    const transformToInstanceJSON = {
      uuid: instantJSONAnnotation.id,
      color: color.enum,
      name: instantJSONAnnotation.name,
      annotationData: {
        pageIndex: instantJSONAnnotation.pageIndex,
        type: instantJSONAnnotation.type.split('/').pop().toUpperCase(),
        bbox: instantJSONAnnotation.bbox,
        points: instantJSONAnnotation?.points,
      },
    }

    const entity = plainToInstance(ProjectPlanArea, transformToInstanceJSON)

    return entity
  }

  public static new(payload: unknown): ProjectPlanArea {
    const entity = plainToInstance(ProjectPlanArea, payload)

    return entity
  }
}
