import { PageUrls } from '@core/page-urls'
import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { Box } from '@fundamentals'
import { PermitActionCard } from '../PermitActionCard'
import { useRouter } from 'next/router'
import { Permit } from '@core/entities/permit/Permit'
import moment from 'moment'

type ReceivePermitCardProps = {
  projectId: number
  permit: Permit
}
export const ReceivePermitCard: React.FC<ReceivePermitCardProps> = ({
  projectId,
  permit,
}) => {
  const router = useRouter()
  if (
    !(
      permit.hasPermission('RECEIVE') &&
      permit.status === 'SENT_TO_PERMIT_HOLDER'
    )
  )
    return null

  const { permitAuthor, startsOn } = permit
  const datetimeString = moment(startsOn).format('D MMMM YYYY HH:mm')

  return (
    <PermitActionCard
      title={localizedStrings.receivePermit}
      info={
        <Box>
          <Box>{`${
            localizedStrings.issuedBy
          }: ${permitAuthor.getFullName()}`}</Box>
          <Box>{`${localizedStrings.date}: ${datetimeString}`}</Box>
        </Box>
      }
      BottomComponent={
        <Button
          data-test='receive-permit'
          variant='contained'
          fullWidth
          onClick={() => {
            if (projectId) {
              router.push(
                PageUrls.receiveProjectPermit(
                  permit.organisationId,
                  projectId,
                  permit.id,
                ),
              )
            } else {
              router.push(
                PageUrls.receivePermit(permit.organisationId, permit.id),
              )
            }
          }}
        >
          {localizedStrings.accept}
        </Button>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
