import { PageResponse } from '@core/entities/pagination'
import { Checklist, IChecklist } from '@core/entities/checklist/Checklist'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import {
  checklistsKeys,
  RequestTypes,
} from '@core/react-query/features/checklists'
import { usePageableQuery } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const dataTransformer = ({ content }: PageResponse<IChecklist>) => {
  return content.map((checklist) => Checklist.new(checklist))
}

export const useMyOrganisationChecklists = (
  payload: RequestTypes['getAllChecklists'],
  options: UseInfiniteQueryOptions<
    PageResponse<IChecklist>,
    RequestErrorType,
    Array<Checklist>
  > = {},
) => {
  const myChecklistsQuery = checklistsKeys.allChecklists({
    ...payload,
    filterMine: true,
  })

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: myChecklistsQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<IChecklist>,
    RequestErrorType,
    Array<Checklist>
  >({
    ...infiniteQueryOptions,
    ...myChecklistsQuery,
    ...options,
  })
}
