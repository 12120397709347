import React, { useCallback } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { Permit } from '@core/entities/permit/Permit'
import { localizedStrings } from '@core/strings'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useModal } from '@common/GlobalModal'
import { PermitCard } from '@elements'
import { Button } from '@common/material'
import { Flex, Text } from '@fundamentals'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { zoomToFeatures } from '@modules/geolocation'
import { MapRefType } from '@common/material/MapBox'

type PermitListSidebarProps = {
  mapRef: MapRefType
  permits: Permit[]
  hoveredEntityId?: string | number
  setHoveredEntityId?: (id: string | number) => void
}

export const PermitListSidebar: React.FC<PermitListSidebarProps> = ({
  mapRef,
  permits,
  hoveredEntityId,
  setHoveredEntityId,
}) => {
  const { showModal } = useModal()

  const handleOnZoomToPermit = useCallback((permit: Permit) => {
    // Initialize an empty array for features.
    const permitFeature: GeoJSON.Feature[] = []

    // Add the What3Words location to the array if it exists.
    const w3wLocation = permit?.getW3wLocation()
    if (w3wLocation) {
      permitFeature.push(w3wLocation)
    }

    // Add geolocation features if they exist.
    const geolocationFeatures = permit?.getGeolocationAreas()?.features
    if (geolocationFeatures && Array.isArray(geolocationFeatures)) {
      permitFeature.push(...geolocationFeatures)
    }

    zoomToFeatures({
      mapRef,
      features: permitFeature,
      radius: 100,
    })
  }, [])

  const handleOnPermitDetails = useCallback((permit: Permit) => {
    showModal(
      <PermitDetailsModal
        permitId={permit.id}
        organisationId={permit.organisationId}
      />,
    )
  }, [])

  const handleOnPermitListHover = useCallback(
    (
      permitId: number,
      featureType: 'point' | 'polygon',
      action: 'select' | 'deselect',
    ) => {
      // Select the permit
      if (action == 'select') {
        setHoveredEntityId(permitId)

        mapRef.current.setFeatureState(
          { source: 'permit-markers-shape-source', id: permitId },
          { selected: true },
        )

        mapRef.current.setFeatureState(
          { source: 'permit-zones-shape-source', id: permitId },
          { selected: true },
        )
        return
      }

      // De-select the permit
      setHoveredEntityId(null)

      if (featureType === 'point') {
        mapRef.current.setFeatureState(
          { source: 'permit-markers-shape-source', id: permitId },
          { selected: false },
        )
      }

      if (featureType === 'polygon') {
        mapRef.current.setFeatureState(
          { source: 'permit-zones-shape-source', id: permitId },
          { selected: false },
        )
      }
    },
    [],
  )

  return (
    <Virtuoso
      data={permits}
      components={{
        EmptyPlaceholder: () => <Text>No Permits in the area</Text>,
      }}
      itemContent={(index, permit) => {
        const isHovered = hoveredEntityId === permit?.id
        const featureType = !permit.getW3wLocation() ? 'polygon' : 'point'

        return (
          <PermitCard
            {...(permit as Permit)}
            templateName={permit.template.name}
            onMouseOver={() =>
              handleOnPermitListHover(permit.id, featureType, 'select')
            }
            onMouseLeave={() =>
              handleOnPermitListHover(permit.id, featureType, 'deselect')
            }
            sx={{
              mx: 1,
              mb: 1,
              borderColor: isHovered && 'primary.main',
            }}
          >
            <Flex mt={1}>
              <Button
                variant='contained'
                size='small'
                sx={{ flexGrow: 1, mr: 1 }}
                endIcon={<ZoomInMapIcon />}
                onClick={() => handleOnZoomToPermit(permit)}
              >
                {localizedStrings.zoom}
              </Button>
              <Button
                variant='contained'
                size='small'
                sx={{ flexGrow: 1, mr: 1 }}
                endIcon={<KeyboardArrowRightIcon />}
                onClick={() => handleOnPermitDetails(permit)}
              >
                {localizedStrings.details}
              </Button>
            </Flex>
          </PermitCard>
        )
      }}
    />
  )
}
