import { plainToInstance } from 'class-transformer'
import { IParticipantListItem } from './IParticipantListItem'
import { User } from '@core/entities/user'

export class ParticipantListItem extends User<IParticipantListItem> {
  externalId?: string
  userId?: number

  public static new(payload: unknown): ParticipantListItem {
    const entity = plainToInstance(ParticipantListItem, payload)

    return entity
  }
}
