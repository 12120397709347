import React, { useCallback, useEffect, useRef } from 'react'
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@common/material'
import { localizedStrings } from '@core/strings'
import ClearIcon from '@mui/icons-material/Clear'
import { CommentSecondaryReferenceObjectTypes } from '@core/entities/comments'
import { Box } from '@fundamentals'
import AddCommentForm from '@modules/comments/AddCommentForm'
import { TransitionGroup } from 'react-transition-group'
import { Collapse } from '@mui/material'
import CommentItem from '@modules/comments/CommentItem'
import { useComments } from '@core/react-query/features/comments/hooks/CommentsContext'
import { useCreateComment } from '@core/react-query/features/comments'
import { useNavigationContext } from '@common/hooks'

type CommentsModalProps = {
  subType?: CommentSecondaryReferenceObjectTypes
  subTypeId?: number
  subTypeIdText?: string
  readOnly?: boolean
  open: boolean
  onDismiss: () => void
  subTypeComponent?: React.ReactNode
  onCreateComment?: (referenceObjectId: number) => void
}

const CommentsModal: React.FC<CommentsModalProps> = ({
  subType,
  subTypeId,
  subTypeIdText,
  readOnly,
  open,
  onDismiss,
  subTypeComponent,
  onCreateComment,
}) => {
  const { organisationId, projectId } = useNavigationContext()
  const { commentsBySubTypeId, createCommentCreationPayload, commentsPayload } =
    useComments()
  const createComment = useCreateComment()
  const comments = commentsBySubTypeId?.[subTypeId || subTypeIdText] || []
  const MIN_WIDTH_FOR_FITTING_3_ATTACHMENTS_IN_ONE_ROW = '688px'
  const modalElementRef = useRef<Element>(null)
  const modalRef = useCallback((ref: HTMLDivElement) => {
    if (ref) {
      ref.scrollTop = ref.scrollHeight
      modalElementRef.current = ref.lastElementChild
    }
  }, [])

  useEffect(() => {
    if (!modalElementRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      const lastElement = modalElementRef.current?.lastChild as HTMLElement

      lastElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    })
    resizeObserver.observe(modalElementRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  return (
    <Dialog
      open={open}
      maxWidth='md'
      sx={{
        maxWidth: MIN_WIDTH_FOR_FITTING_3_ATTACHMENTS_IN_ONE_ROW,
        margin: 'auto',
      }}
      fullWidth
    >
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20, mb: 0 }}
      >
        {localizedStrings.commentsTitle}
        <IconButton aria-label='close-modal' onClick={onDismiss}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        ref={modalRef}
        sx={{
          overflowY: 'scroll',
        }}
      >
        <Card sx={{ p: 3, mt: 3, mb: 1 }}>{subTypeComponent}</Card>
        <TransitionGroup>
          {comments.map((comment) => (
            <Collapse key={comment.id}>
              <CommentItem comment={comment} />
            </Collapse>
          ))}
        </TransitionGroup>
      </DialogContent>
      {!readOnly && (
        <>
          <Divider />
          <Box px={3} pb={2} pt={3}>
            <AddCommentForm
              onDismiss={onDismiss}
              id={subTypeId || subTypeIdText}
              submitting={createComment.isLoading}
              onSubmit={({ message, attachmentsKeys }) => {
                createComment.mutate(
                  createCommentCreationPayload({
                    organisationId,
                    projectId,
                    subType,
                    subTypeIdText,
                    message,
                    attachmentsKeys,
                  }),
                  {
                    onSuccess: () => {
                      onCreateComment?.(commentsPayload.id)
                    },
                  },
                )
              }}
            />
          </Box>
        </>
      )}
    </Dialog>
  )
}

export default CommentsModal
