import React from 'react'
import { useDeepCompareCallback } from 'use-deep-compare'
import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import { useToast } from '@core/toast'
import { useGetRegisterDetails } from '@core/react-query/features/registers/registers/register/hooks'

export type AdvancedDiagramFilterOperators = 'EQUALS'

export type AdvancedDiagramFilterValue = {
  operator: AdvancedDiagramFilterOperators
  value?: number
}

export const advancedDiagramFilterValueIsPartial = (
  value: AdvancedDiagramFilterValue,
) => {
  if (!value?.operator) {
    return true
  }

  if (!value?.value) {
    return true
  }

  return false
}

export const AdvancedDiagramFilterOptionOperators: {
  value: AdvancedDiagramFilterOperators
  label: string
}[] = [{ value: 'EQUALS', label: '=' }]

export type AdvancedDiagramFilterHookArguments =
  FilterHookArgumentsBase<AdvancedDiagramFilterValue> & {
    registerId: number
    renderItem?: (documentId: any) => React.ReactElement
    valueToLabel?: (documentId: number) => string
    loading?: boolean
  }

export type AdvancedDiagramFilterInternalArguments<AdvancedDiagramFilterValue> =
  AdvancedDiagramFilterHookArguments &
    FilterInternalArgumentsBase<
      AdvancedDiagramFilterValue,
      'ADVANCED_DIAGRAM'
    > & {
      options: number[]
    }

export const useAdvancedDiagramFilter = (
  filter: AdvancedDiagramFilterHookArguments,
): AdvancedDiagramFilterInternalArguments<AdvancedDiagramFilterValue> => {
  const toast = useToast()
  const unfocusedText = useDeepCompareCallback(
    (
      filterValue: AdvancedDiagramFilterValue,
      { noLabel } = { noLabel: false },
    ) => {
      const label = noLabel ? '' : `${filter.label}: `
      const value = filterValue?.value

      const nothingSelectedText =
        filter.nothingSelectedText || 'No item selected'

      if (value) {
        const filterLabel =
          register?.diagrams?.find(
            (diagram) => diagram.annotationSetId === value,
          )?.siteBoxDocumentName || ''

        const operatorLabel = AdvancedDiagramFilterOptionOperators[0]?.label
        return `${label}( ${operatorLabel} ) ${filterLabel}`
      }

      return `${label}${nothingSelectedText}`
    },
    [filter],
  )

  const { data: register, isLoading: registerLoading } = useGetRegisterDetails(
    {
      registerId: !!filter?.registerId && filter.registerId,
    },
    {
      enabled: !!filter?.registerId,
      onError: (e) => toast.error(e?.message),
    },
  )

  return {
    type: 'ADVANCED_DIAGRAM',
    unfocusedText,
    serialization: 'object',
    options: register?.diagrams?.map((diagram) => diagram.annotationSetId),
    valueToLabel: (annotationSetId) =>
      register?.diagrams?.find(
        (diagram) => diagram.annotationSetId === annotationSetId,
      )?.siteBoxDocumentName,
    loading: registerLoading,
    ...filter,
    renderItem:
      filter?.renderItem &&
      ((annotationSetId) =>
        filter?.renderItem(
          register?.diagrams?.find(
            (diagram) => diagram.annotationSetId === annotationSetId,
          ),
        )),
  }
}
