import { localizedStrings } from '@core/strings'
import React from 'react'
// App crashes if I don't use it like this.
import { Text } from 'webapp/components/common/fundamentals'
import { useModal } from 'webapp/components/common/GlobalModal'
import { DialogTitle, IconButton, DialogContent, Card } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'

import {
  TimelineLeggacy,
  ChecklistTimelineItemLeggacy,
  Timeline,
} from '@common/Timeline'
import ParticipantItem from 'components/lists/ParticipantItem'
import { ModalDialogContainer } from '@common/modal'
import { Checklist } from '@core/entities/checklist/Checklist'
import { useGetChecklistAuditsByParticipantId } from '@core/react-query/features/checklists/checklist'
import { Participant } from '@core/entities/participant/Participant'
import useFeatureFlags from '@core/providers/useFeatureFlags'

interface ComponentType {
  participantDetail: Participant
  checklist: Checklist
}

export const ParticipantLogChecklistModal: React.FC<ComponentType> = ({
  participantDetail,
  checklist,
}) => {
  const { flags } = useFeatureFlags(['timeline_redesign'])
  const { data: timeline } = useGetChecklistAuditsByParticipantId({
    checklistId: checklist.id,
    participantId: participantDetail.id,
  })

  const { hideModal } = useModal()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.participantInformation}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Card sx={{ p: 3 }}>
          <ParticipantItem participant={participantDetail} />
        </Card>
        {!!timeline?.length && (
          <>
            <Text variant='h5' mt={4}>
              {localizedStrings.briefingLog}
            </Text>
            {flags.timeline_redesign ? (
              <Timeline auditResults={timeline} />
            ) : (
              <TimelineLeggacy>
                {timeline?.map((timelineItem, index) => (
                  <ChecklistTimelineItemLeggacy
                    key={index}
                    timelineItem={timelineItem as any}
                    checklistId={checklist.id}
                  />
                ))}
              </TimelineLeggacy>
            )}
          </>
        )}
      </DialogContent>
    </ModalDialogContainer>
  )
}
