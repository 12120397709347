import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitTemplateEditorKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplateEditor'
import {
  IPermitTemplateVersion,
  PermitTemplateVersion,
} from '@core/entities/permit-template-editor'
import { PageResponse } from '@core/entities/pagination'

const dataTransformer = (response: PageResponse<IPermitTemplateVersion>) => ({
  ...response,
  content: response.content.map(PermitTemplateVersion.new),
})

export const useGetPermitTemplateVersions = (
  payload: RequestTypes['getPermitTemplateVersions'],
  options?: UseQueryOptions<
    PageResponse<IPermitTemplateVersion>,
    string,
    PageResponse<PermitTemplateVersion>
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...permitTemplateEditorKeys.getPermitTemplateVersions(payload),
    ...options,
  })
}
