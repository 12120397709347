import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Flex, Text } from '@fundamentals'
import { PermitDetailsHeaderProps } from '@modules/permitDetails/types'
import Chips from '@modules/permitDetails/permitDetailsHeader/Chips'
import SecondaryActions from './SecondaryActions'

const PermitDetailsHeader: React.FC<PermitDetailsHeaderProps> = ({
  permit,
}) => {
  const theme = useTheme()

  return (
    <Box boxShadow={`inset 0 -1px ${theme.palette.grey[10]}`}>
      <Flex mb={1.5} alignItems={'center'}>
        {permit?.template?.colour && (
          <Box
            bgcolor={permit?.template?.colour}
            borderRadius={2}
            width={15}
            height={15}
            mr={1}
          />
        )}
        <Text variant='h5'>{`${permit.template.name} (V${permit?.template?.version}) - ${permit.shortUUID}`}</Text>
      </Flex>

      <Flex pb='1.2rem' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Chips permit={permit} />
        </Box>
        <Box>
          <SecondaryActions permit={permit} />
        </Box>
      </Flex>
    </Box>
  )
}

export { PermitDetailsHeader }
