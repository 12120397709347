import {
  checklistTemplateKeys,
  checklistTemplateEditorMutationKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { ChecklistTemplateRole } from '@core/entities/checklist/ChecklistTemplateRoles/ChecklistTemplateRole'

export const useCreateChecklistTemplateRole = (
  options?: UseMutationOptions<
    ChecklistTemplateRole,
    string,
    RequestTypes['createChecklistTemplateRole']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...checklistTemplateEditorMutationKeys.createTemplateRole,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: checklistTemplateKeys.getRoles._def,
      })
    },
  })
}
