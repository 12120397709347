import { useMutation } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermit } from '@core/entities/permit/Permit'
import { useMutateQueryData } from '@core/react-query/hooks'
import { permitsKeys } from '../../permitsKeys'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useClonePermit = () => {
  const { updateInfiniteQueryItem } = useMutateQueryData()

  return useMutation<
    { draftId: number; permitDraftResult: IPermit },
    RequestErrorType,
    RequestTypes['clone']
  >({
    ...permitKeys.clone,
    onSuccess: ({ permitDraftResult: draft }) => {
      updateInfiniteQueryItem(
        permitsKeys.drafts({
          organisationId: draft.organisationId,
          projectIds: [draft.projectId],
        }).queryKey,
        draft,
      )
    },
  })
}
