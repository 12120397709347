import React from 'react'
import { Card } from '@common/material'
import { Text } from '@fundamentals'
import { Palette } from '@core/theme/color-palette-siteassist'

type ComponentProps = {
  title: string
}

export const TimelineBlockBase: React.FC<ComponentProps> = ({
  title,
  children,
}) => {
  return (
    <Card
      sx={{
        p: 1.5,
        mt: 1.5,
        borderColor: Palette.Grey[100],
        bgcolor: Palette.Grey[100],
      }}
    >
      <Text sx={{ fontSize: 12 }} color='text.secondary' gutterBottom>
        {title}
      </Text>
      {children}
    </Card>
  )
}
