import {
  FilterInternalArgumentsBase,
  FilterHookArgumentsBase,
} from '@core/providers/filters'

export type TextInputFilterHookArguments = FilterHookArgumentsBase<string> & {
  placeholder?: string
}

export type TextInputFilterInternalArguments = TextInputFilterHookArguments &
  FilterInternalArgumentsBase<string, 'TEXT_INPUT'>

export const useTextInputFilter: (
  filter: TextInputFilterHookArguments,
) => TextInputFilterInternalArguments = (filter) => {
  const unfocusedText = (value: any) => {
    const emptyText = filter.nothingSelectedText || 'Search'

    if (typeof value === 'object') {
      return `${filter.label}: ${value?.value ? value.value : emptyText}`
    }

    return `${filter.label}: ${value ? value : emptyText}`
  }
  return {
    type: 'TEXT_INPUT',
    unfocusedText,
    serialization: 'string',
    ...filter,
  }
}
