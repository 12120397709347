import { plainToInstance } from 'class-transformer'
import { IStringFilter } from '@core/entities/filters'
import { FilterBase } from '@core/entities/filters/FilterBase'
import { StringFilterOperatorTypes } from '../types'

export class StringFilter extends FilterBase<IStringFilter> {
  propertyType: 'STRING'
  operator: StringFilterOperatorTypes
  textValue: string

  public static new(payload: unknown): StringFilter {
    return plainToInstance(StringFilter, payload)
  }

  public getTextValue(): string {
    return this.textValue
  }
}
