import { useMutation } from '@tanstack/react-query'
import {
  RequestTypes,
  checklistKeys,
} from '@core/react-query/features/checklists/checklist'
import { useMutateQueryData } from '@core/react-query/hooks'
import { checklistsKeys } from '@core/react-query/features/checklists'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useCloneChecklist = () => {
  const { updateInfiniteQueryItem } = useMutateQueryData()

  return useMutation<unknown, RequestErrorType, RequestTypes['clone']>({
    ...checklistKeys.clone,
    onSuccess: ({ checklistDraftResult: draft }) => {
      updateInfiniteQueryItem(
        checklistsKeys.drafts({
          organisationId: draft?.template?.organisationId,
          projectIds: [draft?.projectId],
        }).queryKey,
        draft,
      )
    },
  })
}
