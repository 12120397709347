import { localizedStrings } from '@core/strings'
import { SelectFilterInternalArguments } from './fundamentals'
import { useSelectFilter } from '@core/providers/filters'
import { SelectFilterValue } from '@core/providers/filters'
import { ProjectRole } from '@core/entities/user/ProjectRole'
import { ProjectUser } from '@core/entities/user'
import { FilterHookArgumentsBase } from '@core/providers/filters'

type ProjectPermissionsFilterHookArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<string, Multiple>>

export const useProjectPermissionsFilter: (
  args: ProjectPermissionsFilterHookArgs<true>,
) => SelectFilterInternalArguments<string, true> = (args) => {
  const roles: ProjectRole[] = [
    'PROJECT_ADMIN',
    'PROJECT_VIEWER',
    'AUTHORISED_PERSON',
    'SUPERVISOR',
  ]

  return useSelectFilter({
    label: localizedStrings.role,
    valueToLabel: (role) => ProjectUser.getRoleString(role),
    options: roles,
    multiple: true,
    nothingSelectedText: localizedStrings.all,
    ...args,
  })
}
