import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermit, Permit } from '@core/entities/permit/Permit'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const permitTransform = (data) => Permit.new(data)

export const usePermitById = (
  payload: RequestTypes['getPermit'],
  options?: UseQueryOptions<IPermit, RequestErrorType, Permit>,
) =>
  useQuery({
    select: permitTransform,
    ...permitKeys.byId(payload),
    ...options,
  })
