import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'

const projectMapsQueryKeys = createQueryKeys('project-maps', {
  byProjectMapConfiguration: ({
    projectId,
    timeout,
  }: RequestTypes['getProjectMapConfiguration']) => {
    return {
      queryKey: [projectId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects/${projectId}/map-configuration`,
          { timeout },
        )

        return data
      },
    }
  },
})

const projectMapsMutationKeys = createMutationKeys('project-maps', {
  updateProjectMapConfiguration: {
    mutationKey: [null],
    mutationFn: async ({
      projectId,
      ...rest
    }: RequestTypes['updateProjectMapConfiguration']) => {
      const { data } = await APIAxiosInstance.put(
        `projects/${projectId}/map-configuration`,
        rest,
      )

      return data
    },
  },
})

const projectMapsKeys = mergeQueryKeys(
  projectMapsQueryKeys,
  projectMapsMutationKeys,
)['project-maps']

export { projectMapsKeys }
