import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'
import { Text } from '@fundamentals'
import { ModalDialogContainer } from '@common/modal'

type ComponentType = {
  onConfirmReject: (comment: string) => void
}

const RejectResumePermitRequestModal: React.FC<ComponentType> = ({
  onConfirmReject,
}) => {
  const { hideModal } = useModal()
  const [comment, setComment] = useState<string>('')

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.reasonForRejection}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Text sx={{ fontSize: 12 }} color='text.secondary' gutterBottom>
          {localizedStrings.rejectResumeDeclaration}
        </Text>
        <TextField
          name='comment'
          label={localizedStrings.reasonForRejection}
          value={comment}
          multiline
          rows={3}
          sx={{ width: '100%', mt: 1, mb: 2 }}
          placeholder={localizedStrings.enterReason}
          onChange={(e) => setComment(Utils.safeParseEventValue(e))}
        />
      </DialogContent>
      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            onConfirmReject(comment)
            hideModal()
          }}
          disabled={!comment.length}
        >
          {localizedStrings.reject}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}

export default RejectResumePermitRequestModal
