import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  authGroupsKeys,
  RequestTypes,
} from '@core/react-query/features/authGroups'
import { IAuthGroup, AuthGroup } from '@core/entities/Groups/AuthGroups'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { PageResponse } from '@core/entities/pagination'

const dataTransformer = (response: PageResponse<IAuthGroup>) => {
  return {
    ...response,
    content: response.content.map((user) => AuthGroup.new(user)),
  }
}

export const useGetAuthGroups = (
  payload: RequestTypes['getAuthGroups'],
  options?: UseQueryOptions<
    PageResponse<IAuthGroup>,
    RequestErrorType,
    PageResponse<AuthGroup>
  >,
) => {
  return useQuery({
    ...authGroupsKeys.list(payload),
    select: dataTransformer,
    ...options,
  })
}
