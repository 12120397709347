import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { projectKeys } from '../projectKeys'
import { PageResponse } from '@core/entities/pagination'
import {
  IPermitTemplate,
  PermitTemplate,
} from '@core/entities/permit/PermitTemplate'

const select = (response: PageResponse<IPermitTemplate>) => ({
  ...response,
  content: response?.content.map((permitTemplate) =>
    PermitTemplate.new(permitTemplate),
  ),
})

export const useGetProjectPermitTemplates = (
  payload: RequestTypes['getProjectPermitTemplates'],
  options?: UseQueryOptions<
    PageResponse<IPermitTemplate>,
    string,
    PageResponse<PermitTemplate>
  >,
) => {
  return useQuery({
    select,
    ...projectKeys
      .byId({ projectId: payload.projectId })
      ._ctx.projectPermitTemplates(payload),
    keepPreviousData: true,
    ...options,
  })
}
