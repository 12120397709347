import React from 'react'
import { match } from 'ts-pattern'
import { localizedStrings } from '@core/strings'
import { Flex } from '@fundamentals'
import { Button } from '@common/material'
import AddIcon from '@mui/icons-material/Add'
import FormatShapesIcon from '@mui/icons-material/FormatShapes'

type DrawControlToolbarProps = {
  onDrawNewArea: () => void
  onCloseArea: () => void
  onSaveAreas: () => void
  onConfirmAreas: () => void
  featureCount: number
  mode: 'draw' | 'close' | 'confirm'
}

export const DrawControlToolbar: React.FC<DrawControlToolbarProps> = ({
  mode,
  onDrawNewArea,
  onCloseArea,
  onSaveAreas,
  onConfirmAreas,
  featureCount,
}) => {
  return (
    <Flex
      alignItems={'center'}
      position={'absolute'}
      bgcolor={'white'}
      borderRadius={2}
      p={2}
      bottom={40}
      left={330}
    >
      {match(mode)
        .with('close', () => (
          <Button
            sx={{ mr: 3 }}
            startIcon={<FormatShapesIcon />}
            onClick={onCloseArea}
          >
            {localizedStrings.closeShape}
          </Button>
        ))
        .otherwise(() => (
          <Button
            sx={{ mr: 3 }}
            startIcon={<AddIcon />}
            onClick={onDrawNewArea}
          >
            {localizedStrings.drawNewArea}
          </Button>
        ))}

      {match(mode)
        .with('confirm', () => (
          <Button
            variant={'contained'}
            onClick={onConfirmAreas}
            disabled={!featureCount}
          >
            {localizedStrings.confirmAreas(featureCount)}
          </Button>
        ))
        .otherwise(() => (
          <Button
            variant={'contained'}
            onClick={onSaveAreas}
            disabled={!featureCount || mode === 'close'}
          >
            {featureCount
              ? localizedStrings.saveAreas(featureCount)
              : localizedStrings.save}
          </Button>
        ))}
    </Flex>
  )
}
