import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { authMutationKeys } from '@core/react-query/features/auth'
export const useCognitoLogin = (
  options?: UseMutationOptions<string, string, RequestTypes['cognitoLogin']>,
) => {
  return useMutation({
    ...authMutationKeys.cognitoLogin,
    retry: 0,
    ...options,
  })
}
