import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  projectKeys,
} from '@core/react-query/features/projects/project'
import {
  APIAxiosInstance,
  RequestErrorType,
} from '@core/utils/api-axios-instance'
import { useUploadMedia } from '@core/react-query/features/media'

export const useUpdateProjectLogo = (
  options?: UseMutationOptions<
    any,
    RequestErrorType,
    RequestTypes['updateProjectLogo']
  >,
) => {
  const queryClient = useQueryClient()
  const uploadMutation = useUploadMedia()

  return useMutation({
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateProjectLogo']) => {
      const uploadedLogoKey = await uploadMutation.mutateAsync({
        file: payload.imageFile,
        folderName: 'organisation',
      })

      const { projectId } = payload
      const { data } = await APIAxiosInstance.put(
        `projects/${projectId}/logo`,
        {
          logoKey: uploadedLogoKey,
        },
      )
      return data
    },
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        projectKeys.byId({ projectId: variables.projectId }).queryKey,
        {},
      )
    },
  })
}
