import {
  RegisterItem,
  RegisterItemFlatPropertyData,
} from '@core/entities/register/RegisterItem'
import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { RegisterItemPropertyStringValue } from '@modules/registers/propertyValue/RegisterItemPropertyStringValue'
import { RegisterItemPropertySelectValue } from '@modules/registers/propertyValue/RegisterItemPropertySelectValue'
import { RegisterItemPropertyDateValue } from '@modules/registers/propertyValue/RegisterItemPropertyDateValue'
import { RegisterItemPropertyNumberValue } from '@modules/registers/propertyValue/RegisterItemPropertyNumberValue'
import { RegisterItemPropertyUserValue } from '@modules/registers/propertyValue/RegisterItemPropertyUserValue'
import { RegisterItemPropertyDiagramValue } from '@modules/registers/propertyValue/RegisterItemPropertyDiagramValue'
import { RegisterItemPropertyInspectionValue } from '@modules/registers/propertyValue/RegisterItemPropertyInspectionValue'

type RegisterItemContentProps = {
  registerItemPropertyData: RegisterItemFlatPropertyData
  registerItem?: RegisterItem
  variant: RegisterItemContentVariant
}
export const RegisterItemPropertyValue: React.FC<RegisterItemContentProps> = ({
  registerItemPropertyData,
  registerItem,
  variant,
}) => {
  switch (registerItemPropertyData?.type) {
    case 'STRING':
      return (
        <RegisterItemPropertyStringValue
          registerItemPropertyData={registerItemPropertyData}
          variant={variant}
        />
      )
    case 'SELECT':
      return (
        <RegisterItemPropertySelectValue
          registerItemPropertyData={registerItemPropertyData}
          variant={variant}
        />
      )
    case 'DATE':
      return (
        <RegisterItemPropertyDateValue
          registerItemPropertyData={registerItemPropertyData}
          variant={variant}
        />
      )
    case 'NUMBER':
      return (
        <RegisterItemPropertyNumberValue
          registerItemPropertyData={registerItemPropertyData}
          variant={variant}
        />
      )
    case 'USER':
      return (
        <RegisterItemPropertyUserValue
          registerItemPropertyData={registerItemPropertyData}
          variant={variant}
        />
      )
    case 'DIAGRAM':
      return (
        <RegisterItemPropertyDiagramValue
          registerItemPropertyData={registerItemPropertyData}
          variant={variant}
        />
      )
    case 'INSPECTION':
      return (
        <RegisterItemPropertyInspectionValue
          registerItemPropertyData={registerItemPropertyData}
          registerItem={registerItem}
          variant={variant}
        />
      )
    default:
      return <></>
  }
}
