import React, { useEffect } from 'react'
import { BasicLayout, BasicLayoutProps } from '@elements'
import { isDesktop } from 'react-device-detect'
import { PageUrls } from '@core/page-urls'

type MobileOnlyLayoutProps = BasicLayoutProps & {
  webRerouteUrl?: string
}

const MobileOnlyLayout: React.FC<MobileOnlyLayoutProps> = ({
  webRerouteUrl = PageUrls.home,
  router,
  ...props
}) => {
  useEffect(() => {
    if (isDesktop) {
      router.replace(webRerouteUrl)
    }
  }, [])

  return <BasicLayout router={router} {...props} />
}

export { MobileOnlyLayout }
