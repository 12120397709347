import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { RequestTypes, permitsKeys } from '@core/react-query/features/permits'
import { PageResponse } from '@core/entities/pagination'
import { usePageableQuery } from '@core/react-query/hooks'
import { IPermitBase, PermitBase } from '@core/entities/permit/PermitBase'

const dataTransformer = ({ content }: PageResponse<IPermitBase>) => {
  return content.map((permit) => PermitBase.new(permit))
}

export const useMyOrganisationPermits = (
  payload: RequestTypes['useAllOrganisationPermits'],
  options: UseInfiniteQueryOptions<
    PageResponse<IPermitBase>,
    string,
    Array<PermitBase>
  > = {},
) => {
  const myPermitsQuery = permitsKeys.allPermits({
    ...payload,
    filterMine: true,
  })

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: myPermitsQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<PageResponse<IPermitBase>, string, Array<PermitBase>>(
    {
      ...infiniteQueryOptions,
      ...myPermitsQuery,
      ...options,
    },
  )
}
