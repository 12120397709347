import { FC } from 'react'
import { FeatureCollection, Point } from 'geojson'
import { Layer, Source } from 'react-map-gl'

/**
 * GeoPointMarkerLayer component displays point markers on a map using GeoJSON data.
 *
 * Props:
 * - `key`: Unique key to identify the source, ensuring it re-renders as necessary.
 * - `id`: Unique identifier for the layer to ensure map layers are distinct.
 * - `promoteId`: Field used as a unique identifier for each feature in the data source.
 * - `featureCollection`: GeoJSON feature collection containing point data for markers.
 */
interface GeoPointMarkerLayerProps {
  key: string | number
  id: string | number
  promoteId: string
  featureCollection: FeatureCollection<Point>
}

export const GeoPointMarkerLayer: FC<GeoPointMarkerLayerProps> = ({
  key,
  id,
  promoteId,
  featureCollection,
}) => {
  return (
    <Source
      key={`${key}-shape-source`}
      id={`${id}-shape-source`}
      type='geojson'
      promoteId={promoteId}
      data={featureCollection}
    >
      {/* Layer for unselected markers */}
      <Layer
        id={`${id}-symbol-layer-unselected`}
        type='symbol'
        layout={{
          'icon-image': 'unselected-marker-icon', // Uses the unselected marker icon defined in the map's image assets
        }}
        paint={{
          // Sets icon opacity based on feature state: fully opaque if unselected, transparent if selected
          'icon-opacity': [
            'case',
            ['==', ['coalesce', ['feature-state', 'selected'], false], true],
            0, // Transparent when selected
            1, // Fully opaque when not selected
          ],
        }}
      />

      {/* Layer for selected markers */}
      <Layer
        id={`${id}-symbol-layer-selected`}
        type='symbol'
        layout={{
          'icon-image': 'selected-marker-icon', // Uses the selected marker icon defined in the map's image assets
          'icon-allow-overlap': true, // Allows the selected icon to overlap with unselected markers for clear visibility
        }}
        paint={{
          // Sets icon opacity based on feature state: fully opaque if selected, transparent if unselected
          'icon-opacity': [
            'case',
            ['==', ['coalesce', ['feature-state', 'selected'], false], true],
            1, // Fully opaque when selected
            0, // Transparent when not selected
          ],
        }}
        beforeId={`${id}-symbol-layer-unselected`} // Ensures selected markers are drawn on top of unselected markers
      />
    </Source>
  )
}
