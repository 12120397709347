import {
  createMutationKeys,
  createQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from './requestTypes'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { ITemplateEditorChecklistTemplateVersion } from '@core/entities/checklist/TemplateEditorChecklistTemplateVersion'
import { ITemplateEditorChecklistTemplate } from '@core/entities/checklist/TemplateEditorChecklistTemplate/ITemplateEditorChecklistTemplate'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { PageResponse } from '@core/entities/pagination'
import { uploadTemplateAnswerFiles } from '@core/react-query/features/permits/permit/helpers'
import { headersWithAccessToken } from '@core/utils/api-axios-instance'

export const checklistTemplateKeys = createQueryKeys('checklist-templates', {
  availableTemplates: ({
    projectId,
    filterCreatable,
  }: RequestTypes['getAvailableTemplates']) => {
    return {
      queryKey: ['v2', projectId, filterCreatable],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `checklists/templates/create-list`,
          { params: { projectId, filterCreatable } },
        )
        return data
      },
    }
  },
  getVersions: (payload: RequestTypes['getChecklistTemplateVersions']) => {
    return {
      queryKey: [payload.templateId, payload.page, payload.size],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          page: payload.page,
          size: payload.size,
        })
        const { data } = await APIAxiosInstance.get(
          `checklists/admin/templates/${payload.templateId}/versions${queryString}`,
        )
        return data as PageResponse<ITemplateEditorChecklistTemplateVersion>
      },
    }
  },
  getVersion: (payload: RequestTypes['getChecklistTemplateVersion']) => {
    return {
      queryKey: [payload.templateVersionId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `checklists/admin/templates/${payload.templateId}/versions/${payload.templateVersionId}`,
        )
        return data as ITemplateEditorChecklistTemplateVersion
      },
    }
  },
  getProjectVersion: ({
    projectId,
    templateId,
  }: RequestTypes['getProjectChecklistTemplateVersion']) => {
    return {
      queryKey: [projectId, templateId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `projects/${projectId}/checklists/templates/${templateId}/versions`,
          {},
        )

        return data
      },
    }
  },
  getTemplate: (payload: RequestTypes['getChecklistTemplate']) => {
    return {
      queryKey: [payload.templateId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `checklists/admin/templates/${payload.templateId}`,
        )
        return data as ITemplateEditorChecklistTemplate
      },
    }
  },
  getTemplates: ({
    organisationId,
    projectIds,
    status,
    page,
    size,
    excludeExistingProjectAssignments,
    term,
  }: RequestTypes['getChecklistTemplates']) => {
    return {
      queryKey: [
        organisationId,
        projectIds,
        status,
        page,
        size,
        excludeExistingProjectAssignments,
        term,
      ],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          organisationId,
          projectIds,
          page,
          size,
          status,
          excludeExistingProjectAssignments,
          term,
        })
        const { data } = await APIAxiosInstance.get(
          `checklists/admin/templates${queryString}`,
        )
        return data as PageResponse<ITemplateEditorChecklistTemplate>
      },
    }
  },
  getRoles: (payload: RequestTypes['getChecklistTemplateRoles']) => {
    return {
      queryKey: [payload.templateVersionId],
      queryFn: async () => {
        const headers = headersWithAccessToken(payload)

        const { data } = await APIAxiosInstance.get(
          `checklists/templates/${payload.templateId}/versions/${payload.templateVersionId}/roles`,
          { headers: headers },
        )

        return data.roles
      },
    }
  },
})

export const checklistTemplateEditorMutationKeys = createMutationKeys(
  'checklist-templates',
  {
    updateSettings: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['updateChecklistTemplateVersionSettings'],
      ) => {
        const { data } = await APIAxiosInstance.put(
          `checklists/admin/templates/${payload.templateId}/versions/${payload.templateVersionId}`,
          {
            ...payload,
          },
        )
        return data
      },
    },
    updateQuestions: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['updateChecklistTemplateVersionQuestions'],
      ) => {
        const uploadedQuestions = await uploadTemplateAnswerFiles({
          templateId: payload.templateId,
          answers: payload.checklistQuestions.questions,
        })

        const { data } = await APIAxiosInstance.put(
          `checklists/admin/templates/${payload.templateId}/versions/${payload.templateVersionId}/questions`,
          {
            ...payload,
            checklistQuestions: {
              ...payload.checklistQuestions,
              questions: uploadedQuestions,
            },
          },
        )
        return data
      },
    },
    createVersion: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['createChecklistTemplateVersion'],
      ) => {
        const { data } = await APIAxiosInstance.post(
          `checklists/admin/templates/${payload.templateId}/versions`,
          payload,
        )
        return data
      },
    },
    activateTemplateVersion: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['activateChecklistTemplateVersion'],
      ) => {
        const { data } = await APIAxiosInstance.put(
          `checklists/admin/templates/${payload.templateId}/activate`,
          payload,
        )
        return data
      },
    },
    discartTemplateVersion: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['activateChecklistTemplateVersion'],
      ) => {
        const { data } = await APIAxiosInstance.put(
          `checklists/admin/templates/${payload.templateId}/versions/${payload.versionId}/discard`,
          payload,
        )
        return data
      },
    },
    createTemplateRole: {
      mutationKey: null,
      mutationFn: async ({
        templateId,
        templateVersionId,
        ...payload
      }: RequestTypes['createChecklistTemplateRole']) => {
        const data = await APIAxiosInstance.post(
          `checklists/templates/${templateId}/versions/${templateVersionId}/roles`,
          payload,
        )

        return data
      },
    },
    updateTemplateRole: {
      mutationKey: null,
      mutationFn: async ({
        templateId,
        templateVersionId,
        roleId,
        ...payload
      }: RequestTypes['updateChecklistTemplateRole']) => {
        const data = await APIAxiosInstance.put(
          `checklists/templates/${templateId}/versions/${templateVersionId}/roles/${roleId}`,
          payload,
        )

        return data
      },
    },
    deleteTemplateRole: {
      mutationKey: null,
      mutationFn: async ({
        templateId,
        templateVersionId,
        roleId,
      }: RequestTypes['deleteTemplateRole']) => {
        const data = await APIAxiosInstance.delete(
          `checklists/templates/${templateId}/versions/${templateVersionId}/roles/${roleId}`,
        )

        return data
      },
    },
    updateProjectChecklistTemplateVersion: {
      mutationKey: null,
      mutationFn: async ({
        projectId,
        templateId,
        ...rest
      }: RequestTypes['updateProjectChecklistTemplateVersion']) => {
        const { data } = await APIAxiosInstance.post(
          `projects/${projectId}/checklists/templates/${templateId}/versions`,
          {
            ...rest,
          },
        )

        return data
      },
    },
    createChecklistTemplate: {
      mutationKey: null,
      mutationFn: async (payload: RequestTypes['createChecklistTemplate']) => {
        const { data } = await APIAxiosInstance.post(
          `checklists/admin/templates?organisationId=${payload.organisationId}`,
          {
            ...payload,
          },
        )

        return data
      },
    },
    updateChecklistTemplate: {
      mutationKey: null,
      mutationFn: async (payload: RequestTypes['updateChecklistTemplate']) => {
        const { data } = await APIAxiosInstance.put(
          `checklists/admin/templates/${payload.id}`,
          {
            ...payload,
          },
        )

        return data
      },
    },
    archiveChecklistTemplate: {
      mutationKey: null,
      mutationFn: async (payload: RequestTypes['archiveChecklistTemplate']) => {
        const { data } = await APIAxiosInstance.put(
          `checklists/admin/templates/${payload.id}/archive`,
        )

        return data
      },
    },
    unArchiveChecklistTemplate: {
      mutationKey: null,
      mutationFn: async (
        payload: RequestTypes['unarchiveChecklistTemplate'],
      ) => {
        const { data } = await APIAxiosInstance.put(
          `checklists/admin/templates/${payload.id}/unarchive`,
        )

        return data
      },
    },
  },
)
