import {
  checklistTemplateKeys,
  checklistTemplateEditorMutationKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const useUpdateChecklistQuestions = (
  options?: UseMutationOptions<
    string,
    string,
    RequestTypes['updateChecklistTemplateVersionQuestions']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...checklistTemplateEditorMutationKeys.updateQuestions,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: checklistTemplateKeys._def,
      })
    },
  })
}
