import { localizedStrings } from '@core/strings'
import React from 'react'
import {
  Card,
  Chip,
  DialogContent,
  Divider,
  IconButton,
  Tooltip,
} from '@common/material'
import { useModal } from '@common/GlobalModal'
import { Box, Flex, Text } from '@fundamentals'
import { NavigationAwareTabs } from '@elements'
import { RenderAnswers } from 'webapp/components/elements/Questions/RenderAnswers'
import { CardContent, Typography } from '@mui/material'
import moment from 'moment/moment'
import ClearIcon from '@mui/icons-material/Clear'
import { usePeriodicCheckEntry } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'

type ComponentType = {
  periodicCheckId: number
  periodicCheckTemplateId: number
  permitId: number
  organisationId: number
}
const PeriodicCheckEntryModal: React.FC<ComponentType> = ({
  periodicCheckId,
  periodicCheckTemplateId,
  permitId,
  organisationId,
}) => {
  const { data } = usePeriodicCheckEntry({
    periodicCheckId,
    periodicCheckTemplateId,
    permitId,
  })
  const { hideModal } = useModal()

  const tabs = [
    {
      label: 'Checklist',
      component: (
        <RenderAnswers
          questionBuilderAnswers={data?.answersBody}
          organisationId={organisationId}
        />
      ),
    },
  ]

  const sidebarData = [
    {
      heading: localizedStrings.project,
      subHeading: data?.projectResult?.name,
    },
    {
      heading: localizedStrings.completedBy,
      subHeading: `${data?.submittedBy?.firstName} ${data?.submittedBy?.lastName}`,
    },
    {
      heading: localizedStrings.date,
      subHeading: moment(data?.createdOn).format('Do MMM YYYY HH:mm'),
    },
  ]

  return (
    <ModalDialogContainer
      PaperProps={{
        sx: {
          maxWidth: '80%',
        },
      }}
    >
      <DialogContent
        sx={{
          overflow: 'auto',
          height: '100vh',
          flexDirection: 'column',
          my: '20px',
          py: 0,
        }}
      >
        <Box>
          <Box
            top={0}
            zIndex={10}
            position='sticky'
            bgcolor='#ffffff'
            px='3'
            pb={4}
          >
            <Flex justifyContent='space-between'>
              <Text variant='h5' mb={1.5}>
                {data?.name}
              </Text>
              <IconButton aria-label='close-modal' onClick={hideModal}>
                <ClearIcon />
              </IconButton>
            </Flex>
            <Chip color='primary' label={'Complete'} />
          </Box>
          <Flex>
            <NavigationAwareTabs tabs={tabs} tabParam='tab' />
            <Box
              pl={3}
              width='23rem'
              position='sticky'
              top={108}
              maxHeight='400px'
              overflow='auto'
            >
              <Card>
                <CardContent>
                  <Typography
                    sx={{ fontSize: '16px' }}
                    variant='h6'
                    color='text.primary'
                    gutterBottom
                  >
                    General Information
                  </Typography>
                  <Divider />
                  {sidebarData.map((item, index) => (
                    <Box
                      pt={index === 0 ? 2 : 0}
                      pb={index === sidebarData.length - 1 ? 0 : 2}
                      key={index}
                    >
                      <Typography
                        sx={{ fontSize: 12 }}
                        color='text.secondary'
                        gutterBottom
                      >
                        {item.heading}
                      </Typography>
                      <Tooltip title={item.subHeading}>
                        <Typography noWrap>{item.subHeading}</Typography>
                      </Tooltip>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Box>
          </Flex>
        </Box>
      </DialogContent>
    </ModalDialogContainer>
  )
}

export default PeriodicCheckEntryModal
