import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { authKeys } from '@core/react-query/features/auth'

type Response = { email: string }
export const useCreatePassword = (
  options?: UseMutationOptions<
    Response,
    string,
    RequestTypes['createPassword']
  >,
) => {
  return useMutation({
    mutationFn: (variables) =>
      authKeys.createPassword(variables).queryFn(undefined),
    ...options,
  })
}
