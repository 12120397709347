import { localizedStrings } from '@core/strings'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Box } from '@fundamentals'
import React, { useState } from 'react'
import { useModal } from '@common/GlobalModal'
import { TableUserSummaryCard } from '@common/Table'
import { OrganisationUser } from '@core/entities/user/OrganisationUser'
import { useSetOrganisationUserRole } from '@core/react-query/features/organisations/organisation/hooks/useSetOrganisationUserRole'
import { Organisation } from '@core/entities/organisation'
import { OrganisationUserPermissionsSelect } from '@modules/organisationDetail/OrganisationUserPermissionsSelect'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

type ChangeOrganisationRoleModalProps = {
  organisation: Organisation
  user: OrganisationUser
}

export const ChangeOrganisationUserPermissions: React.FC<
  ChangeOrganisationRoleModalProps
> = ({ organisation, user }) => {
  const { hideModal } = useModal()
  const [roles, setRoles] = useState(user.roles)
  const { mutate } = useSetOrganisationUserRole()
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.changePermissions}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <TableUserSummaryCard user={user} />
        <Box height={20} />
        <OrganisationUserPermissionsSelect
          organisationId={organisation.id}
          roles={roles}
          onRolesChange={setRoles}
        />
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          data-test='confirm-yes'
          onClick={() => {
            mutate(
              {
                organisationId: organisation.id,
                userId: user.id,
                roles,
              },
              {
                onSuccess: () => {
                  toast.success(localizedStrings.userRoleUpdated)
                  hideModal()
                },
                onError: (e) => toast.error(e),
              },
            )
          }}
        >
          {localizedStrings.confirm}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
