import { localizedStrings } from '@core/strings'
import { SelectFilterInternalArguments } from './fundamentals'
import { useSelectFilter } from '@core/providers/filters'
import { SelectFilterValue } from '@core/providers/filters'
import { OrganisationRole } from '@core/entities/user'
import { OrganisationUser } from '@core/entities/user/OrganisationUser'
import { FilterHookArgumentsBase } from '@core/providers/filters'

type OrganisationRoleFilterArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<string, Multiple>>

export const useOrganisationRoleFilter: (
  args: OrganisationRoleFilterArgs<true>,
) => SelectFilterInternalArguments<string, true> = (args) => {
  const roles: OrganisationRole[] = ['USER', 'ACCOUNT_OWNER', 'ACCOUNT_VIEWER']

  return useSelectFilter({
    label: localizedStrings.role,
    valueToLabel: (role) => OrganisationUser.roleString(role),
    options: roles,
    multiple: true,
    nothingSelectedText: localizedStrings.all,
    ...args,
  })
}
