import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { SignatureEdit } from 'webapp/components/common/Signature'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { useCancelTransfer } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  permit: Permit
}

export const CancelPermitTransferModal: React.FC<ComponentType> = ({
  permit,
}) => {
  const cancelTransferPermit = useCancelTransfer()
  const { hideModal } = useModal()
  const { data: profile } = useGetProfile()
  const [transferReason, setTransferReason] = useState<string>('')
  const [error, setError] = useState('')
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.cancelTransfer}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <TextField
          name='transfer-cancel-reason'
          label={localizedStrings.cancellationReason}
          placeholder={localizedStrings.reasonForCancelDots}
          multiline
          rows={4}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setTransferReason(e.target.value)}
          value={transferReason}
          sx={{ mt: 4 }}
        />
        <SignatureEdit readonly value={profile?.signatureUrl} />
        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            cancelTransferPermit.mutate(
              {
                permitId: permit.id,
                cancellationReason: transferReason,
              },
              {
                onError: (e) => {
                  setError(e.message)
                  toast.error(e.message)
                },
                onSuccess: () => {
                  toast.success(localizedStrings.transferCanceled)
                  hideModal()
                },
              },
            )
          }}
          disabled={!transferReason.length}
          data-test='permit-transfer-cancel'
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
