import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { supportKeys } from '../supportKeys'
import { RequestTypes } from '../requestTypes'
import { profileKeys } from '@core/react-query/features/profile/profileKeys'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useUnimpersonateUser = (
  options?: UseMutationOptions<
    Response,
    RequestErrorType,
    RequestTypes['unimpersonateUser']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables) =>
      supportKeys.unimpersonateUser(variables).queryFn(undefined),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: profileKeys.profile.queryKey })
    },
    ...options,
  })
}
