import {
  checklistTemplateKeys,
  checklistTemplateEditorMutationKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { foldersKeys } from '@core/react-query/features/folders'

export const useCreateChecklistTemplate = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['createChecklistTemplate']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...checklistTemplateEditorMutationKeys.createChecklistTemplate,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(checklistTemplateKeys.getTemplate._def)
      queryClient.invalidateQueries(foldersKeys._def)
    },
  })
}
