import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMutateQueryData } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { IChecklist } from '@core/entities/checklist/Checklist'
import { checklistsKeys } from '@core/react-query/features/checklists'

export const useDeleteChecklistDraft = () => {
  const { deleteInfiniteQueryItem } = useMutateQueryData()
  const queryClient = useQueryClient()

  return useMutation<IChecklist, RequestErrorType, RequestTypes['deleteDraft']>(
    {
      ...checklistKeys.deleteDraft,
      onSuccess: (draft, variables) => {
        deleteInfiniteQueryItem(
          checklistsKeys.drafts({
            organisationId: variables.organisationId,
            projectIds: [variables.projectId],
          }).queryKey,
          variables.draftId,
        )
        // Used for the web to get refetch the drafts page
        queryClient.invalidateQueries(checklistsKeys.draftsPage._def)
      },
    },
  )
}
