import React from 'react'
import { NextRouter } from 'next/router'
import { Flex, BoxProps } from '@fundamentals'

export type BasicLayoutProps = BoxProps & {
  page: React.ReactNode
  router: NextRouter
}

const BasicLayout: React.FC<BasicLayoutProps> = ({
  page,
  router,
  ...props
}) => {
  return (
    <Flex
      flexDirection='column'
      minHeight='100vh'
      width='100%'
      bgcolor='#FAFAFA'
      {...props}
    >
      {page}
    </Flex>
  )
}

export { BasicLayout }
