import {
  permitTemplatesKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplates'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import {
  IPermitTemplate,
  PermitTemplate,
} from '@core/entities/permit/PermitTemplate'

const dataTransformer = (response: PageResponse<IPermitTemplate>) => {
  return {
    ...response,
    content: response.content.map((template) => PermitTemplate.new(template)),
  }
}

export const usePermitTemplates = (
  payload: RequestTypes['getOrganisationTemplates'],
  options: UseQueryOptions<
    PageResponse<IPermitTemplate>,
    string,
    PageResponse<PermitTemplate>
  > = {},
) => {
  return useQuery({
    select: dataTransformer,
    ...permitTemplatesKeys.byOrganisation(payload),
    ...options,
  })
}
