import React from 'react'
import { Box } from '@fundamentals'

type ComponentType = {
  children: React.ReactNode
  onClickAway: () => void
  isInModal?: boolean
}

const ClickAwayArea: React.FC<ComponentType> = ({
  children,
  onClickAway = () => {},
  isInModal,
}) => {
  return (
    <>
      <Box
        sx={{
          zIndex: 11,
          position: 'relative',
        }}
      >
        {children}
      </Box>
      <Box
        data-test='click-away-area'
        onClick={onClickAway}
        sx={{
          opacity: 0,
          position: 'absolute',
          width: '100%',
          height: isInModal ? '100%' : '100vh',
          top: 0,
          left: 0,
          zIndex: 10,
        }}
      />
    </>
  )
}

export default ClickAwayArea
