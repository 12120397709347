import React, { useEffect, useState } from 'react'
import { Collapse } from '@mui/material'
import { Box, BoxProps } from '@fundamentals'
import { Button } from '@common/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

type CollapsibleProps = BoxProps & {
  collapsed?: boolean
  label?: string | { collapsed: string; open: string }
  buttonProps?: {
    size?: 'small' | 'medium' | 'large'
    sx?: BoxProps['sx']
  }
}

const Collapsible: React.FC<CollapsibleProps> = ({
  collapsed,
  label,
  children,
  buttonProps,
  ...props
}) => {
  const [processedCollapsed, setCollapsed] = useState<boolean>(collapsed)
  useEffect(() => {
    setCollapsed(collapsed)
  }, [collapsed])

  return (
    <Box {...props}>
      <Collapse in={!processedCollapsed} timeout='auto' unmountOnExit>
        {children}
      </Collapse>
      <Button
        variant='text'
        size={buttonProps?.size || 'medium'}
        endIcon={processedCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        onClick={() => setCollapsed(!processedCollapsed)}
        sx={buttonProps?.sx || { ml: '-8px', pb: 0, mt: 1 }}
      >
        {typeof label === 'string'
          ? label
          : label[processedCollapsed ? 'collapsed' : 'open']}
      </Button>
    </Box>
  )
}

export { Collapsible }
