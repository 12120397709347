import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectLocation } from './IProjectLocation'
import { ProjectLocationNode } from '../ProjectLocationNode/ProjectLocationNode'

export class ProjectLocation extends Entity<IProjectLocation> {
  id: number

  enabled: boolean

  @Type(() => ProjectLocationNode)
  locations: ProjectLocationNode[]

  public static new(payload: unknown): ProjectLocation {
    const entity = plainToInstance(ProjectLocation, payload)

    return entity
  }
}
