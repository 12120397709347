import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { supportKeys } from '@core/react-query/features/support'
import { UserDetails } from '@core/entities/support'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useGetUser = (
  payload: RequestTypes['getUser'],
  options?: UseQueryOptions<UserDetails, RequestErrorType>,
) => {
  return useQuery({ ...supportKeys.getUser(payload), ...options })
}
