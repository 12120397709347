import {
  organisationKeys,
  RequestTypes,
} from '@core/react-query/features/organisations/organisation'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import { IOrganisationUser, OrganisationUser } from '@core/entities/user'
import { usePageableQuery } from '@core/react-query/hooks'

const dataTransformer = (response: PageResponse<IOrganisationUser>) => ({
  ...response,
  content: response.content.map((user) => OrganisationUser.new(user)),
})

export const useOrganisationUsers = (
  payload: RequestTypes['getOrganisationUsers'],
  options: UseInfiniteQueryOptions<
    PageResponse<IOrganisationUser>,
    string,
    PageResponse<OrganisationUser>
  > = {},
) => {
  const orgUsersQuery = organisationKeys
    .byId(payload)
    ._ctx.organisationUsers(payload)

  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options.enabled,
    queryKey: orgUsersQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<IOrganisationUser>,
    string,
    PageResponse<OrganisationUser>
  >({
    cacheTime: 0,
    ...infiniteQueryOptions,
    ...orgUsersQuery,
    ...options,
  })
}
