import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from './requestTypes'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'

export const registersQueryKeys = createQueryKeys('registers', {
  registers: (payload: RequestTypes['getRegisters']) => ({
    queryKey: [payload],
    queryFn: async ({ pageParam = 1 }) => {
      const queryString = createQueryKeyString({ page: pageParam, ...payload })
      const { data } = await APIAxiosInstance.get(`registers${queryString}`)
      return data
    },
  }),
  linkableRegisters: ({
    organisationId,
    projectId,
    filter,
  }: RequestTypes['getRegistersLinkable']) => ({
    queryKey: [organisationId, projectId, filter],
    queryFn: async () => {
      const queryString = createQueryKeyString({
        organisationId,
        projectId,
        filter: JSON.stringify(filter),
      })
      const { data } = await APIAxiosInstance.get(
        `registers/linkable${queryString}`,
      )
      return data?.linkableRegisters
    },
  }),
})

export const registerMutationKeys = createMutationKeys('registers', {
  createOrganisationRegister: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      ...rest
    }: RequestTypes['createOrganisationRegister']) => {
      const { data } = await APIAxiosInstance.post(
        `registers/organisations/${organisationId}`,
        {
          ...rest,
        },
      )
      return data
    },
  },
  createProjectRegister: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      projectId,
      ...rest
    }: RequestTypes['createProjectRegister']) => {
      const { data } = await APIAxiosInstance.post(
        `registers/organisations/${organisationId}/projects/${projectId}`,
        {
          ...rest,
        },
      )
      return data
    },
  },
})

const registersKeys = mergeQueryKeys(
  registersQueryKeys,
  registerMutationKeys,
).registers

export { registersKeys }
