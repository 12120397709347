import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IPermit } from '@core/entities/permit/Permit'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useNominateApprovers = () => {
  const queryClient = useQueryClient()

  return useMutation<
    IPermit,
    RequestErrorType,
    RequestTypes['nominateApprovers']
  >({
    ...permitKeys.nominateApprovers,
    onSuccess: (permit) => {
      queryClient.setQueryData(
        permitKeys.byId({ permitId: permit.id }).queryKey,
        permit,
      )
    },
  })
}
