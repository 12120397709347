import {
  RequestTypes,
  permitTemplateKeys,
} from '@core/react-query/features/templates/permitTemplates/permitTemplate'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  IPermitTemplate,
  PermitTemplate,
} from '@core/entities/permit/PermitTemplate'

const dataTransformer = (response: IPermitTemplate) =>
  PermitTemplate.new(response)

export const useGetPermitTemplate = (
  payload: RequestTypes['getPermitTemplate'],
  options?: UseQueryOptions<IPermitTemplate, string, PermitTemplate>,
) => {
  return useQuery({
    ...permitTemplateKeys.byTemplateId(payload),
    select: dataTransformer,
    enabled: !!payload.id,
    ...options,
  })
}
