import React from 'react'
import { RegisterItemDiagramProperty } from '@core/entities/register/RegisterItem'
import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { Text } from '@fundamentals'
import { Button, Tooltip } from '@common/material'
import { PageUrls } from '@core/page-urls'
import { useRouter } from 'next/router'
import { useNavigationContext } from '@common/hooks'

type RegisterItemPropertyDiagramValueProps = {
  registerItemPropertyData: RegisterItemDiagramProperty<string>
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertyDiagramValue: React.FC<
  RegisterItemPropertyDiagramValueProps
> = ({ registerItemPropertyData, variant }) => {
  const router = useRouter()
  const { organisationId, projectId, registerId } = useNavigationContext()
  return (
    <Tooltip title={registerItemPropertyData?.siteBoxDocumentName} arrow>
      <Button
        variant='text'
        sx={{ textDecoration: 'underline' }}
        onClick={() => {
          const {
            registerId: regId,
            projectId: projId,
            id,
            '-1': _,
            ...query
          } = router.query

          const commonArgs = {
            organisationId,
            registerId,
            query: {
              ...query,
              documentId: registerItemPropertyData?.siteBoxDocumentId,
            },
          }

          const url = projectId
            ? PageUrls.projectRegisterDetailsDiagram({
                ...commonArgs,
                projectId,
              })
            : PageUrls.organisationRegisterDetailsDiagram(commonArgs)

          router.push(url)
        }}
      >
        <Text noWrap>{registerItemPropertyData?.siteBoxDocumentName}</Text>
      </Button>
    </Tooltip>
  )
}
