import _DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'
import Input from 'components/base/forms/Input'

export type DatePickerProps = Omit<
  ReactDatePickerProps,
  'value' | 'onChange' | 'minDate'
> & {
  value?: Date | string
  title?: string
  onChange?: (newDate: Date) => void
  minDate?: string
  isValid?: boolean
  // Makes the component return all dates in UTC format
  UTC?: boolean
}

const getDate = (d: string | Date): Date => {
  if (typeof d === 'string') {
    return new Date(d)
  }
  return d
}

const getDates = (dates: Array<string | Date>): Date[] =>
  (dates || []).map(getDate)

const convertUTCToLocalDate = (date: Date) => {
  if (!date) {
    return date
  }
  date = new Date(date)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}

function convertLocalToUTCDate(date: Date | Date[]) {
  if (!date) {
    return date
  }

  if (Array.isArray(date)) {
    return date.map((d) => convertLocalToUTCDate(d))
  } else {
    date = new Date(date)
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )
    return date
  }
}

export const DatePicker: React.FC<DatePickerProps> = ({
  minDate,
  maxDate,
  startDate,
  endDate,
  selected,
  highlightDates,
  excludeDates,
  onChange,
  title,
  UTC,
  ...rest
}) => {
  if (Constants.E2E) {
    const { name, value } = rest
    const [testDateValue, setTestDateValue] = React.useState('')

    if (name === 'startDate' && testDateValue.length < 1 && selected) {
      setTestDateValue(selected.toString())
    } else if (name === 'endDate' && testDateValue.length < 1 && value) {
      setTestDateValue(value.toString())
    }

    return (
      <Input
        {...{ 'data-test': rest['data-test'], 'name': rest.name }}
        value={testDateValue}
        onChange={(e) => {
          const date = Utils.safeParseEventValue(e)

          if (!isNaN(new Date(date).valueOf())) {
            setTestDateValue(new Date(date).toString())
            onChange(new Date(date))
          }
        }}
      />
    )
  }

  return (
    <>
      {title ? <label className='select__text mb-2'>{title}</label> : null}
      {/* @ts-ignore */}
      <_DatePicker
        minDate={getDate(minDate)}
        maxDate={getDate(maxDate)}
        excludeDates={getDates(excludeDates)}
        startDate={UTC ? convertUTCToLocalDate(startDate) : startDate}
        endDate={UTC ? convertUTCToLocalDate(endDate) : endDate}
        selected={UTC ? convertUTCToLocalDate(selected) : selected}
        onChange={(date) => onChange(UTC ? convertLocalToUTCDate(date) : date)}
        {...rest}
      />
    </>
  )
}

DatePicker.displayName = 'DatePicker'
export default DatePicker
