import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  registerKeys,
  RequestTypes,
} from '@core/react-query/features/registers/registers/register'
import { registersKeys } from '@core/react-query/features/registers/registers'

export const useSetRegisterStatus = (
  options?: UseMutationOptions<
    any,
    RequestErrorType,
    RequestTypes['setRegisterStatus']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...registerKeys.setRegisterStatus,
    // We don't retry mutations since they're not idempotent
    // We can allow retries if we implement idempotency keys and idempotency checking on the back-end
    retry: 0,
    ...options,
    onSuccess: (data, variables, context) => {
      console.log(data)
      queryClient.invalidateQueries(
        registerKeys.details({ registerId: data.id }).queryKey,
      )
      queryClient.invalidateQueries(registersKeys.registers._def)
    },
  })
}
