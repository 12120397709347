import { Entity } from '@core/entities/entity'
import { OrganisationRole, ProjectRole, User } from '@core/entities/user'
import { Exclude, Type } from 'class-transformer'
import { PermitSuspensionReasons } from '@core/entities/permit/PermitSuspension'
import { RegisterItemWithLinksPropertyData } from '@core/entities/register/RegisterItem'
import { GeoJSON } from 'geojson'
import { CommentSecondaryReferenceObjectTypes } from '@core/entities/comments'
import { TemplateQuestion } from '@core/entities/template/TemplateQuestion'
import { PermitWarning } from '@core/entities/template/TemplateWarnings'
import { IAuditResultMetadata } from '@core/entities/audit/AuditResult/IAuditResultMetadata'
import { PermissionsUpdatedData } from '@core/entities/audit/AuditResult/PermissionsUpdatedData'
import { PermitStatus } from '@core/entities/permit/PermitBase'
import { ProjectStatus } from '@core/entities/project'
import {
  PermissionAssignmentType,
  PermissionEntityType,
} from '@core/entities/Groups/GroupsPermissions'

export class AuditResultMetadata extends Entity<IAuditResultMetadata> {
  mediaUrls?: Array<string>

  permitRoleName?: string

  checklistRoleName?: string

  permitGeoJson?: GeoJSON

  distanceToPermitInMeters?: number

  @Type(() => User)
  transferReceiver?: User

  taskChecklistId?: number

  suspensionReasonCategory?: PermitSuspensionReasons

  delta: {
    itemValues: Array<{
      postValue: RegisterItemWithLinksPropertyData
      preValue?: RegisterItemWithLinksPropertyData
    }>
  }

  postValues: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }

  preValues: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }

  updateNote?: string

  inputType?: string

  commentSecondaryReferenceObjectId: number

  commentSecondaryReferenceObjectIdText: string

  commentSecondaryReferenceObjectType: CommentSecondaryReferenceObjectTypes

  @Type(() => TemplateQuestion)
  commentAnsweredQuestionDataResult: TemplateQuestion

  offlineSubmittedOn?: string

  @Exclude({ toPlainOnly: true })
  mediaUrlsWithType?: Array<{ getUrl: string; type: string }>

  @Type(() => PermitWarning)
  warnings?: Array<PermitWarning>

  emailAddress?: string

  permitExtensionEndsOn?: string

  permitShortenedEndsOn?: string

  permitPreviousEndsOn?: string

  @Type(() => User)
  changedPermitHolderFrom?: User

  @Type(() => User)
  changedPermitHolderTo?: User

  @Type(() => PermissionsUpdatedData)
  permissionsUpdatedData?: PermissionsUpdatedData

  permitStatusBefore?: PermitStatus

  permitStatusAfter?: PermitStatus

  projectStatusBefore?: ProjectStatus

  projectStatusAfter?: ProjectStatus

  permissionAssignmentReferenceObjectId?: number

  permissionAssignmentReferenceObjectName?: string

  permissionAssignmentReferenceObjectType?: PermissionEntityType

  permissionAssignmentAuthGroupIds?: Array<number>

  permissionAssignmentAuthGroupNames?: Array<string>

  permissionAssignmentType?: PermissionAssignmentType

  preProjectMemberData?: {
    roles: Array<ProjectRole>
  }

  postProjectMemberData?: {
    roles: Array<ProjectRole>
  }

  preOrgMemberData?: {
    roles: Array<OrganisationRole>
  }

  postOrgMemberData?: {
    roles: Array<OrganisationRole>
  }

  inspectionChecklist?: {
    checklistId: number
    templateName: string
  }

  inspection?: {
    id: string
    name: string
  }
}
