import { ITemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import { s3Upload } from '@core/react-query/helpers'
import { localizedStrings } from '@core/strings'
import axios from 'axios'

export const uploadProofs = async (
  proofs: any[],
  folderName: string,
): Promise<any[]> => {
  const errors: string[] = [] // Collect all errors

  // First, try fetching all proofs
  const verifiedProofs = await Promise.all(
    proofs.map(async (proof) => {
      if (!proof.downloadUrl) {
        try {
          if (API.isMobile()) {
            return proof
          } else {
            // Attempt to fetch the proof using the URI
            const response = await axios.get(proof.uri, {
              responseType: 'blob',
            })

            // If fetch is successful, replace the `uri` with the Blob in the proof object
            return new File([response.data], proof.path, { type: proof.type })
          }
        } catch (error) {
          errors.push(proof.path) // Collect individual fetch error
        }
      }
      return proof
    }),
  )

  // If any errors occurred during fetching, throw them all at once
  if (errors.length > 0) {
    throw new Error(`${localizedStrings.errorUploading}: ${errors.join(', ')}`) // Throw a new error containing all fetch errors
  }

  // If all fetches succeed, proceed to upload the proofs to S3
  const uploadedProofs = await Promise.all(
    verifiedProofs.map(async (proof: any) => {
      if (proof.downloadUrl) return proof

      try {
        // Use the Blob we fetched earlier for uploading
        const uploadedFileKey = await s3Upload({
          file: proof, // Use the blob obtained from the fetch step
          folderName,
        })

        // Return the proof with the uploaded S3 key
        return { ...proof, key: uploadedFileKey }
      } catch (error) {
        errors.push(proof.path) // Collect individual fetch error
      }
    }),
  )

  // If any errors occurred during the S3 upload, throw them all at once
  if (errors.length > 0) {
    throw new Error(`${localizedStrings.errorUploading}: ${errors.join(', ')}`) // Throw a new error containing all fetch errors
  }

  return uploadedProofs
}

const cleanupUploadedAnswerFiles: (
  answers: Array<ITemplateQuestionGroup>,
) => Array<ITemplateQuestionGroup> = (answers) => {
  const cleanedAnswers: Array<ITemplateQuestionGroup> = []
  for (const group of answers) {
    const cleanedGroupData = group.data.map((question) => {
      const cleanedQuestion = { ...question }
      if (question.localSignature) {
        // @ts-ignore
        API.removeFile?.(question.localSignature.uri)
        delete cleanedQuestion.localSignature
      }
      return cleanedQuestion
    })
    cleanedAnswers.push({ ...group, data: cleanedGroupData })
  }

  return cleanedAnswers
}

export const uploadTemplateAnswerFiles = async ({
  answers,
  templateId,
}: {
  answers: Array<ITemplateQuestionGroup>
  templateId: number
}): Promise<Array<ITemplateQuestionGroup>> => {
  if (!answers) return []

  try {
    const uploadedAnswers = await Promise.all(
      answers.map(async (group) => {
        const uploadedGroupData = await Promise.all(
          group.data.map(async (question) => {
            const uploadedQuestion = { ...question, signatureKey: undefined }

            // Handle signature upload
            if (question.localSignature) {
              const uploadedFileKey = await s3Upload({
                file: question.localSignature,
                folderName: `${templateId}-signature`,
              })
              uploadedQuestion.signatureKey = uploadedFileKey
            } else if (question.signatureUrl) {
              uploadedQuestion.signatureKey = question.signatureKey
            }

            // Handle submission proofs upload
            if (question.submissionProofs) {
              const uploadedProofs = await uploadProofs(
                question.submissionProofs,
                `${templateId}-proof`,
              )
              uploadedQuestion.submissionProofs = uploadedProofs
            }

            // Handle submission documents upload
            if (question.submissionDocuments) {
              const uploadedDocuments = await uploadProofs(
                question.submissionDocuments,
                `${templateId}-documents`,
              )
              uploadedQuestion.submissionDocuments = uploadedDocuments
            }

            // Handle acknowledge information document upload
            if (question.acknowledgeInformationDocument) {
              const [uploadedProof] = await uploadProofs(
                [question.acknowledgeInformationDocument],
                `${templateId}-documents`,
              )
              uploadedQuestion.acknowledgeInformationDocumentKey =
                uploadedProof.key
              delete question.acknowledgeInformationDocument
            }

            return uploadedQuestion
          }),
        )

        return { ...group, data: uploadedGroupData }
      }),
    )

    return cleanupUploadedAnswerFiles(uploadedAnswers)
  } catch (error) {
    throw new Error(error.message)
  }
}
