import { NestedLinksHeaderProps } from './types'
import { Box, Flex, Text } from '@fundamentals'
import { Edit } from '@mui/icons-material'
import React from 'react'

const NestedLinksHeader: React.FC<NestedLinksHeaderProps> = ({
  href,
  label,
  ...props
}) => {
  return (
    <Link href={href}>
      <Flex
        p={3}
        alignItems='center'
        justifyContent='space-between'
        sx={{ cursor: 'pointer' }}
        {...props}
      >
        <Text variant='h5' fontWeight={600} fontSize={20}>
          {label}
        </Text>
        <Box>
          <Edit sx={{ color: 'grey.500' }} />
        </Box>
      </Flex>
    </Link>
  )
}

export { NestedLinksHeader }
