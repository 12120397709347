import React from 'react'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { Box, Flex, Text } from '@fundamentals'
import EastIcon from '@mui/icons-material/East'
import { TimelineBlockBase } from '@common/Timeline'

type ComponentProps = {
  user: AuditResult['user']
  transferReceiver: AuditResult['metadata']['transferReceiver']
  title: string
}

export const TimelineTransferBlock: React.FC<ComponentProps> = ({
  user,
  transferReceiver,
  title,
}) => {
  return (
    <TimelineBlockBase title={title}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Box>
          <Text>{user.getFullName()}</Text>
          <Text fontSize={12}>{user.company}</Text>
        </Box>
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Box>
          <Text>{transferReceiver.getFullName()}</Text>
          <Text fontSize={12}>{transferReceiver.company}</Text>
        </Box>
      </Flex>
    </TimelineBlockBase>
  )
}
