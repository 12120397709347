import { Entity } from '@core/entities/entity'
import { Type } from 'class-transformer'
import { GroupPermissionAssignment } from '@core/entities/Groups/GroupsPermissions/GroupPermissionAssignment'
import { IPermissionsUpdatedData } from '@core/entities/audit/AuditResult/IPermissionsUpdatedData'

export class PermissionsUpdatedData extends Entity<IPermissionsUpdatedData> {
  groupId?: number

  groupName: string

  @Type(() => GroupPermissionAssignment)
  newPermissions: Array<GroupPermissionAssignment>

  @Type(() => GroupPermissionAssignment)
  oldPermissions: Array<GroupPermissionAssignment>
}
