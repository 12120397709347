import { useModal } from 'webapp/components/common/GlobalModal'
import { LoadingButton } from 'webapp/components/common/material'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Permit } from '@core/entities/permit/Permit'
import { useRejectExtensionRequest } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'
import { localizedStrings } from '@core/strings'
import { useState } from 'react'
import { Text } from '@fundamentals'
import { TextEditor } from '@common/TextEditor'

interface ComponentType {
  permit: Permit
}

export const RejectExtensionRequestPermitModal: React.FC<ComponentType> = ({
  permit,
}) => {
  const rejectPermitExtension = useRejectExtensionRequest()
  const [reason, setReason] = useState<string>(null)
  const [error, setError] = useState('')
  const { hideModal } = useModal()
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.rejectExtension}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <Text fontWeight='bold'>
          {localizedStrings.extendRejectDescription}
        </Text>

        <TextEditor
          value={reason}
          onChange={setReason}
          name='reason'
          placeholderChar={localizedStrings.enterReason}
          plainText
          sx={{ mb: 3 }}
        />

        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal} data-test='cancel'>
          {localizedStrings.cancel}
        </Button>
        <LoadingButton
          variant='outlined'
          color='error'
          onClick={() =>
            rejectPermitExtension.mutate(
              {
                permitId: permit.id,
                reason,
              },
              {
                onSuccess() {
                  hideModal()
                  setReason(null)
                  toast.success(localizedStrings.permitExtensionRejected)
                },
                onError: (e) => setError(e.message),
              },
            )
          }
          loading={rejectPermitExtension.isLoading}
          data-test='jsReject'
        >
          {localizedStrings.reject}
        </LoadingButton>
      </DialogActions>
    </ModalDialogContainer>
  )
}
