import React from 'react'
import {
  ThumbDownOutlined,
  InfoOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material'
import { Box, Flex, Text } from '@fundamentals'
import { Alert, Card, Button } from '@common/material'
import { useTheme, Link } from '@mui/material'
import { localizedStrings } from '@core/strings'
import { useModal } from '@common/GlobalModal'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { useNavigationContext } from '@common/hooks'
import { DuplicatedImagePermitModal } from '@modules/permitDetails/modals/DuplicatedImagePermitModal'
import { PermitWarning } from '@core/entities/template/TemplateWarnings'
import { useToast } from '@core/toast'
import { useProvideWarningFeedback } from '@core/react-query/features/warnings'
import { SxProps } from '@mui/system'

type ComponentProps = {
  warning: PermitWarning
  showFeedbackButton?: boolean
  sx?: SxProps
}

const WarningCard: React.FC<ComponentProps> = ({
  warning,
  showFeedbackButton,
  sx,
}) => {
  const theme: any = useTheme()
  const toasts = useToast()
  const { showModal } = useModal()
  const { organisationId } = useNavigationContext()

  const { mutate: provideWarningFeedback, isLoading } =
    useProvideWarningFeedback({
      onSuccess: () =>
        toasts.success(localizedStrings.warningReportedAlertMessage),
    })

  const onReportAsIncorrect = () => {
    provideWarningFeedback({ warningId: warning.id })
  }

  return (
    <Card sx={{ p: 1.5, borderColor: theme.palette.warning.main, ...sx }}>
      <Flex alignItems='center'>
        <WarningAmberOutlined color='warning' />
        <Text variant='body2' sx={{ mx: 1, fontWeight: 500 }}>
          {warning.getTitle()}
        </Text>
        {warning.type !== 'IMAGE_OBJECT_DETECTION' && (
          <Link
            component='button'
            variant='body2'
            onClick={() => {
              if (warning.type === 'CLONED_PERMIT') {
                showModal(
                  <PermitDetailsModal
                    organisationId={warning.details.organisationId}
                    permitId={warning.details.id}
                  />,
                )
              }

              if (warning.type === 'IMAGE_DUPLICATE') {
                showModal(
                  <DuplicatedImagePermitModal
                    organisationId={organisationId}
                    imageDuplicates={warning.details?.duplicates || []}
                  />,
                )
              }
            }}
          >
            {localizedStrings.seeDetails}
          </Link>
        )}
      </Flex>

      {!!warning.text && (
        <Alert severity='warning' icon={false} sx={{ mt: 1 }}>
          <Box
            dangerouslySetInnerHTML={{ __html: warning.text }}
            color='grey.800'
            sx={{ '& p': { mb: 0, fontSize: '14px' } }}
          />
        </Alert>
      )}

      {!!showFeedbackButton && warning.type === 'IMAGE_OBJECT_DETECTION' && (
        <Box display='flex' justifyContent='flex-end' mt={1}>
          {warning?.feedbackProvided ? (
            <Flex py={0.5}>
              <InfoOutlined sx={{ color: 'grey', fontSize: 20, mr: 0.5 }} />
              <Text variant='body2' color='grey'>
                {localizedStrings.warningReportedMessage}
              </Text>
            </Flex>
          ) : (
            <Button disabled={isLoading} onClick={onReportAsIncorrect}>
              <Flex>
                <ThumbDownOutlined
                  sx={{
                    color: isLoading ? 'grey' : 'primary.main',
                    fontSize: 20,
                    mr: 1,
                  }}
                />
                <Text
                  variant='body2'
                  color={isLoading ? 'grey' : 'primary.main'}
                >
                  {localizedStrings.reportAsIncorrect}
                </Text>
              </Flex>
            </Button>
          )}
        </Box>
      )}
    </Card>
  )
}

export default WarningCard
