import React from 'react'
import { DragIndicator } from '@mui/icons-material'
import { Box } from '@fundamentals'
import { useSortable } from '@dnd-kit/sortable'
import { Tooltip } from '@common/material'

type ComponentType = {
  id: string | number
  disabled?: boolean
}

export const DraggableIcon: React.FC<ComponentType> = ({ id, disabled }) => {
  const { attributes, listeners } = useSortable({ id })

  if (disabled) {
    return (
      <Tooltip title='Cannot drag with unsaved changes'>
        <DragIndicator sx={{ opacity: 0.5, cursor: 'not-allowed' }} />
      </Tooltip>
    )
  }

  return (
    <Box {...attributes} {...listeners} sx={{ cursor: 'grab' }}>
      <DragIndicator sx={{ opacity: 0.5 }} />
    </Box>
  )
}
