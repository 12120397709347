import { useEffect, useRef } from 'react'
import { Box } from '@fundamentals'

type ComponentType = {
  document: string
  readOnly?: boolean
  onAnnotate?: (blob: Blob, url: string) => void
}

const PspdfkitComponent: React.FC<ComponentType> = ({
  document,
  readOnly = true,
  onAnnotate,
}) => {
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current
    let PSPDFKit
    ;(async function () {
      PSPDFKit = await import('pspdfkit')
      const initialViewState = new PSPDFKit.ViewState({
        readOnly,
      })

      const licenseKey = Project.pspdfkitLicenseKey

      const hiddenTools = [
        'sidebar-bookmarks',
        'sidebar-document-outline',
        'sidebar-annotations',
        'pan',
        'signature',
        'stamp',
        'note',
        'text-highlighter',
        'line',
        'polygon',
        'polyline',
        'print',
        'search',
        'export-pdf',
        'highlighter',
        'document-crop',
        'pager',
      ]

      const customTools = [{ type: 'undo' }, { type: 'redo' }]

      await PSPDFKit.load({
        licenseKey,
        container,
        document,
        baseUrl: `${window.location.protocol}//${window.location.host}/`,
        initialViewState,
        autoSaveMode: PSPDFKit.AutoSaveMode.DISABLED,
      }).then(async (instance) => {
        instance.history.enable()

        const items = instance.toolbarItems
        instance.setToolbarItems([
          ...items.filter((item) => hiddenTools.indexOf(item.type) === -1),
          ...customTools,
        ])

        const handleAnnotation = () => {
          instance.save()
          instance
            .exportPDF({
              flatten: true,
              // incremental: true,
              includeComments: true,
              saveForPrinting: true,
              excludeAnnotations: false,
            })
            .then((res) => {
              const blob = new Blob([res], { type: 'application/pdf' })
              const setFile = () => {
                const documentBlobObjectUrl = URL.createObjectURL(blob)
                const formData = new FormData()
                formData.append('file', blob)
                onAnnotate(blob, documentBlobObjectUrl)
              }
              setFile()
            })
        }

        instance.addEventListener('annotations.create', async () => {
          handleAnnotation()
        })
        instance.addEventListener('annotations.update', async () => {
          handleAnnotation()
        })
        instance.addEventListener('annotations.change', async () => {
          handleAnnotation()
        })
        instance.addEventListener('document.change', async () => {
          handleAnnotation()
        })
      })
    })()

    return () => PSPDFKit && PSPDFKit.unload(container)
  }, [])

  return <Box ref={containerRef} style={{ height: '100%', width: '100%' }} />
}

export default PspdfkitComponent
