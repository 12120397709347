import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { warningsKeys, RequestTypes } from '@core/react-query/features/warnings'
import { permitKeys } from '@core/react-query/features/permits/permit'
import { IPermit } from '@core/entities/permit/Permit'

type ProvideWarningFeedbackResponse = {
  referenceObjectId: number
  referenceObjectType: 'PERMIT'
}

export const useProvideWarningFeedback = (
  options?: UseMutationOptions<
    ProvideWarningFeedbackResponse,
    RequestErrorType,
    RequestTypes['provideWarningFeedback']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...warningsKeys.provideWarningFeedback,
    ...options,
    onSuccess: (...params) => {
      const [{ referenceObjectId, referenceObjectType }, { warningId }] = params

      if (referenceObjectType === 'PERMIT') {
        queryClient.setQueryData(
          permitKeys.byId({ permitId: referenceObjectId }).queryKey,
          (previousPermit: IPermit) => {
            if (previousPermit) {
              return {
                ...previousPermit,
                warnings: previousPermit.warnings.map((warning) => {
                  if (warning.id === warningId) {
                    return {
                      ...warning,
                      feedbackProvided: true,
                    }
                  }

                  return warning
                }),
              } as IPermit
            }
          },
        )
      }

      options?.onSuccess?.(...params)
    },
  })
}
