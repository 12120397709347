import { localizedStrings } from '@core/strings'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { useGetBusinessUnits } from '@core/react-query/features/businessUnits'
import { FilterHookArgumentsBase } from './fundamentals'

type BusinessUnitFilterHookArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    multiple?: Multiple
    organisationId: number
  }

export const useBusinessUnitsFilter: <Multiple extends boolean | undefined>(
  arg: BusinessUnitFilterHookArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = (args) => {
  const { data } = useGetBusinessUnits(
    {
      organisationId: args.organisationId,
      size: 10000,
    },
    {
      enabled: !!args.organisationId,
    },
  )

  const businessUnits = data?.pages?.[0]?.content

  return useSelectFilter({
    ...args,
    options: businessUnits?.map((p) => p.id),
    valueToLabel: (v) => businessUnits?.find((t) => t.id === v)?.name,
    nothingSelectedText: localizedStrings.all,
  })
}
