import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IProcessTag, ProcessTag } from '@core/entities/processTags'
import {
  processTagsKeys,
  RequestTypes,
} from '@core/react-query/features/processTags'
import { PageResponse } from '@core/entities/pagination'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const dataTransformer = (response: PageResponse<IProcessTag>) => {
  return {
    ...response,
    content: response.content.map((tag) => ProcessTag.new(tag)),
  }
}

export const useGetProcessTags = (
  payload: RequestTypes['getProcessTags'],
  options?: UseQueryOptions<
    PageResponse<IProcessTag>,
    RequestErrorType,
    PageResponse<ProcessTag>
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...processTagsKeys.getProcessTags(payload),
    keepPreviousData: true,
    ...options,
  })
}
