import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { supportKeys } from '@core/react-query/features/support'
import { UserListItem } from '@core/entities/support'
import { PageResponse } from '@core/entities/pagination'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useSearchUsers = (
  payload: RequestTypes['searchUsers'],
  options?: UseQueryOptions<PageResponse<UserListItem>, RequestErrorType>,
) => {
  return useQuery({
    ...supportKeys.searchUsers(payload),
    cacheTime: 0,
    keepPreviousData: true,
    ...options,
  })
}
