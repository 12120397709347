import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  permitTemplateEditorKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplateEditor'
import { IPermitTemplateVersion } from '@core/entities/permit-template-editor'

export const useUpdatePermitTemplateVersionWarning = (
  options?: UseMutationOptions<
    IPermitTemplateVersion,
    RequestErrorType,
    RequestTypes['updatePermitTemplateVersionWarning']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...permitTemplateEditorKeys.updatePermitTemplateVersionWarning,
    ...options,
    onSuccess: (res, variables) => {
      const queryKey = permitTemplateEditorKeys.getVersion({
        templateId: variables.templateId,
        versionId: variables.versionId,
      }).queryKey

      queryClient.setQueriesData<IPermitTemplateVersion>(
        { queryKey },
        () => res,
      )
    },
  })
}
