import { useContext, useState } from 'react'
import { Divider, IconButton } from '@common/material'
import { Flex } from '@fundamentals'
import {
  PanToolAltOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@mui/icons-material'
import { ToggleButton, alpha } from '@mui/material'
import { useSelector } from '@xstate/react'
import {
  ProjectPlanStateContext,
  selectInstance,
  selectPSPDFKit,
} from 'webapp/state-machines/projectPlan'

export const ProjectPlanNavigationTools: React.FC = () => {
  const projectPlanStateContext = useContext(ProjectPlanStateContext)

  const instance = useSelector(
    projectPlanStateContext.projectPlanService,
    selectInstance,
  )

  const PSPDFKit = useSelector(
    projectPlanStateContext.projectPlanService,
    selectPSPDFKit,
  )
  const [panEnabled, setPanEnabled] = useState<boolean>(false)

  return (
    <Flex
      flexDirection='column'
      position='absolute'
      bgcolor='white'
      mr={2}
      mt={2}
      right='19rem'
      boxShadow={`inset -1px -1px ${alpha('#000', 0.12)}, inset 1px 1px ${alpha(
        '#000',
        0.12,
      )}`}
    >
      <IconButton
        sx={{ borderRadius: 0, width: '3rem', height: '3rem' }}
        onClick={() => instance.setViewState((viewState) => viewState.zoomIn())}
      >
        <ZoomInOutlined />
      </IconButton>
      <Divider />
      <IconButton
        sx={{ borderRadius: 0, width: '3rem', height: '3rem' }}
        onClick={() =>
          instance.setViewState((viewState) => viewState.zoomOut())
        }
      >
        <ZoomOutOutlined />
      </IconButton>
      <Divider />
      <Divider />
      <ToggleButton
        value
        selected={panEnabled}
        sx={{
          borderRadius: 0,
          border: 'none',
          width: '3rem',
          height: '3rem',
          ':focus': {
            outline: 'none',
          },
        }}
        onClick={async () => {
          instance.setIsEditableAnnotation(() => panEnabled)

          instance.setViewState((viewState) =>
            viewState.set(
              'interactionMode',
              panEnabled ? null : PSPDFKit.InteractionMode.PAN,
            ),
          )
          setPanEnabled(!panEnabled)
        }}
      >
        <PanToolAltOutlined />
      </ToggleButton>
    </Flex>
  )
}
