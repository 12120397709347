import { useRouter } from 'next/router'
import { useSelectFilter } from '@core/providers/filters'
import {
  auditActions,
  mockContextOptions,
  mockResults,
} from '@common/Timeline/mockData/mockdata'
import { useQueryParamFilterStore } from '@elements/Filters'

export const useTimelineMockData = () => {
  const router = useRouter()
  const mock = router.query.mock
  const permitId = router.query?.permitId
  const context =
    router.query.context === undefined
      ? []
      : Array.isArray(router.query.context)
      ? router.query.context
      : [router.query.context]
  const actions =
    router.query.action === undefined
      ? []
      : Array.isArray(router.query.action)
      ? router.query.action
      : [router.query.action]

  const filters = {
    context: useSelectFilter({
      label: 'Context',
      isPinned: true,
      options: mockContextOptions,
      valueToLabel: (v) => mockContextOptions.find((o) => o.value === v)?.label,
      multiple: true,
    }),
    action: useSelectFilter({
      label: 'Action',
      isPinned: true,
      options: auditActions
        .map((a) => ({
          label: a,
          value: a,
        }))
        .filter((a) => {
          return context.length
            ? context.some((ctx) => a.label.includes(ctx))
            : true
        }),
      valueToLabel: (v) => v,
      multiple: true,
    }),
  }

  const filterStore = useQueryParamFilterStore({
    filters,
    queryParams: {
      context: 'context',
      action: 'action',
    },
  })

  const mockAuditResultsFiltered = mockResults(permitId).filter((r) => {
    return (
      (!context.length || context.some((ctx) => r.action.includes(ctx))) &&
      (!actions.length || actions.some((a) => r.action.includes(a)))
    )
  })

  return {
    filters,
    filterStore,
    mockAuditResults: mockAuditResultsFiltered,
    mock,
  }
}
