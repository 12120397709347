import { commentsKeys, RequestTypes } from '@core/react-query/features/comments'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { Comment, IComment } from '@core/entities/comments'
import { PageResponse } from '@core/entities/pagination'

const dataTransformer = (response: PageResponse<IComment>) => {
  return {
    ...response,
    content: response.content.map((comment) => Comment.new(comment)),
  }
}

export const useGetComments = (
  payload: RequestTypes['getComments'],
  options?: UseQueryOptions<
    PageResponse<IComment>,
    RequestErrorType,
    PageResponse<Comment>
  >,
) => {
  return useQuery({
    keepPreviousData: true,
    select: dataTransformer,
    ...commentsKeys.getComments(payload),
    ...options,
  })
}
