import { localizedStrings } from '@core/strings'
import React, { useMemo } from 'react'
import { Text, Box, Flex } from 'webapp/components/common/fundamentals'
import { Card, Divider, IconButton } from '@common/material'
import { CardContent, Typography } from '@mui/material'
import moment from 'moment'
import { Checklist } from '@core/entities/checklist/Checklist'
import { useModal } from '@common/GlobalModal'
import { PageUrls } from '@core/page-urls'
import { Section } from '@common/Section'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { ParticipantLogChecklistModal } from '@modules/checklistDetails/modals/ParticipantLogChecklistModal'
import { RegisterItemFlatPropertyData } from '@core/entities/register/RegisterItem'
import { Property } from '@elements/Property/Property'
import { RegisterItemPropertyValue } from '@modules/registers/propertyValue/RegisterItemPropertyValue'
import { Collapsible } from '@common/Collapsible'
import { OpenInNewOutlined } from '@mui/icons-material'
import { SimpleMapModal } from '@modules/geolocation'
import useConstants from '@core/providers/useConstants'

type ChecklistDetailsSidebarProps = {
  checklist: Checklist
}

export const ChecklistDetailsSidebar: React.FC<
  ChecklistDetailsSidebarProps
> = ({ checklist }) => {
  const { showModal } = useModal()
  const { constants } = useConstants()

  const registerItem = checklist.registerInspection?.registerItem
  const registerId = checklist.registerInspection?.registerId

  const memoisedUniqueTableColumns: RegisterItemFlatPropertyData[] =
    useMemo(() => {
      const nameCounts = new Map<string, number>()

      const uniqueColumnNames = registerItem?.data?.map((column) => {
        const currentCount = nameCounts.get(column.name) || 0
        const columnName = `${column.name}${
          currentCount ? ` (${currentCount})` : ''
        }`

        // Update the count in the map for the next occurrence
        nameCounts.set(column.name, currentCount + 1)

        const columnValue =
          registerItem.getRegisterItemPropertyDisplayValues(column)

        return { ...column, name: columnName, value: columnValue }
      })

      return uniqueColumnNames || []
    }, [])

  const handlePersonClick = (participant) => {
    showModal(
      <ParticipantLogChecklistModal
        participantDetail={participant}
        checklist={checklist}
      />,
    )
  }

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography
            sx={{ fontSize: '16px' }}
            variant='h6'
            color='text.primary'
            gutterBottom
          >
            General Information
          </Typography>
          <Divider />

          <Section label={localizedStrings.summary} mt={2}>
            <Text data-test='permit-summary' showTooltip>
              {checklist.summary}
            </Text>
          </Section>

          <Section label={localizedStrings.project} mt={2}>
            <Text showTooltip>{checklist.project.name}</Text>
          </Section>

          <Section label={localizedStrings.location} mt={2}>
            <Text showTooltip>{checklist.locationName}</Text>
          </Section>

          <Section label={localizedStrings.uniqueCode} mt={2}>
            <Text showTooltip>{checklist.shortUUID}</Text>
          </Section>

          {checklist.getCheckinLocation() && (
            <Section label={localizedStrings.geoLocation} mt={2}>
              <Text
                color='primary.main'
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  showModal(
                    <SimpleMapModal
                      projectId={checklist.project.id}
                      featurePoint={checklist.getCheckinLocation()}
                    />,
                  )
                }}
              >
                {localizedStrings.showOnMap}
              </Text>
            </Section>
          )}

          {checklist?.ownedByRegisterItem && (
            <Section label={localizedStrings.registerItem} mt={2}>
              <Text
                onClick={() => {
                  window.open(
                    PageUrls.projectRegisterItemDetails({
                      organisationId: checklist.organisationId,
                      projectId: checklist.project.id,
                      registerId: checklist.ownedByRegisterItem.register.id,
                      registerItemId:
                        checklist.ownedByRegisterItem.registerItem.id,
                    }),
                    '_blank',
                    'noopener, noreferrer',
                  )
                }}
                color='primary.main'
                sx={{ cursor: 'pointer' }}
                showTooltip
              >{`${checklist.ownedByRegisterItem.register.name} - ${checklist.ownedByRegisterItem.registerItem.uniqueId}`}</Text>
            </Section>
          )}

          <Section label={localizedStrings.date} mt={2}>
            <Text showTooltip>
              {moment(checklist.createdOn).format('Do MMM YYYY HH:mm')}
            </Text>
          </Section>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Text
            showTooltip
            sx={{ fontSize: '16px' }}
            variant='h6'
            color='text.primary'
            gutterBottom
          >
            {localizedStrings.people}
          </Text>
          <Divider />

          {checklist?.createdBy && (
            <Section label={localizedStrings.checklistAuthor} mt={2}>
              <Flex justifyContent='space-between'>
                <Text showTooltip>{checklist.createdBy.getFullName()}</Text>
              </Flex>
            </Section>
          )}

          {!!checklist?.participants?.length && (
            <>
              <Text
                mt={4}
                sx={{ fontSize: '16px' }}
                variant='h6'
                color='text.primary'
                gutterBottom
                showTooltip
              >
                {localizedStrings.participants}
              </Text>
              <Divider sx={{ mb: 1 }} />

              {checklist?.participants.map((participant) => {
                return (
                  <Section
                    key={participant.id}
                    label={participant.getRole()}
                    mt={2}
                  >
                    <Flex
                      justifyContent='space-between'
                      onClick={() => handlePersonClick(participant)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Text showTooltip>{participant.getFullName()}</Text>
                      <ChevronRight />
                    </Flex>
                  </Section>
                )
              })}
            </>
          )}
        </CardContent>
      </Card>

      {!!registerItem && (
        <Card sx={{ marginTop: 2 }}>
          <CardContent>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text
                showTooltip
                sx={{ fontSize: '16px' }}
                variant='h6'
                color='text.primary'
                gutterBottom
              >
                {localizedStrings.registerItem}
              </Text>

              <IconButton
                color='primary'
                sx={{ height: 'fit-content', alignSelf: 'center' }}
                onClick={() => {
                  const url = PageUrls.projectRegisterItemDetails({
                    organisationId: checklist.organisationId,
                    projectId: checklist.project.id,
                    registerId,
                    registerItemId: registerItem.id,
                  })

                  window.open(url, '_blank', 'noopener, noreferrer')
                }}
              >
                <OpenInNewOutlined />
              </IconButton>
            </Flex>
            <Divider sx={{ mb: 2 }} />

            {memoisedUniqueTableColumns
              ?.slice(0, constants.max_register_card_properties)
              ?.map(
                (item: RegisterItemFlatPropertyData & { value: string }) => {
                  if (item.type === 'INSPECTION') return null

                  return (
                    <Property
                      key={item.registerPropertyId}
                      heading={item.name}
                      subHeadingComponent={
                        <RegisterItemPropertyValue
                          variant='details'
                          registerItemPropertyData={item}
                        />
                      }
                      sx={{
                        display: 'flex',
                        '& > p': { width: `150px` },
                      }}
                    />
                  )
                },
              )}
            <Collapsible
              label={{
                collapsed: localizedStrings.seeMore,
                open: localizedStrings.hideAll,
              }}
              collapsed
            >
              {memoisedUniqueTableColumns
                ?.slice(constants.max_register_card_properties)
                ?.map(
                  (item: RegisterItemFlatPropertyData & { value: string }) => {
                    if (item.type === 'INSPECTION') return null

                    return (
                      <Property
                        key={item.registerPropertyId}
                        heading={item.name}
                        subHeadingComponent={
                          <RegisterItemPropertyValue
                            variant='details'
                            registerItemPropertyData={item}
                          />
                        }
                        sx={{
                          display: 'flex',
                          '& > p': { width: `150px` },
                        }}
                      />
                    )
                  },
                )}
            </Collapsible>
          </CardContent>
        </Card>
      )}
    </Box>
  )
}
