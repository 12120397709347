import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { Box } from '@fundamentals'
import DatePicker from 'components/DatePicker'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import { useModal } from 'webapp/components/common/GlobalModal'
import { useShortenPermit } from '@core/react-query/features/permits/permit/hooks/useShortenPermit'
import { Permit } from '@core/entities/permit/Permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  permit: Permit
}

export const ShortenPermitModal: React.FC<ComponentType> = ({ permit }) => {
  const { hideModal } = useModal()
  const [endDate, setEndDate] = useState<string>(permit.endsOn)
  const [reason, setReason] = useState<string>('')
  const mutation = useShortenPermit()
  const toast = useToast()

  const extensionRequest = () => {
    mutation.mutate(
      {
        permitId: permit.id,
        endsOn: endDate,
        reason,
      },
      {
        onError(e) {
          toast.error(e.message)
        },
        onSuccess() {
          toast.success(localizedStrings.updated)
          hideModal()
        },
      },
    )
  }

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.shortenPermit}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <Box>
          <label className='d-block'>{localizedStrings.endDateAndTime}</label>
          <DatePicker
            name='endDate'
            showTimeSelect
            timeIntervals={15}
            onChange={(v) => setEndDate(moment(v).toISOString())}
            maxDate={new Date(permit.endsOn)}
            value={endDate ? new Date(endDate) : new Date(permit.endsOn)}
            dateFormat='do MMM yyyy, h:mm aa'
            selected={endDate ? new Date(endDate) : new Date(permit.endsOn)}
          />
        </Box>

        <TextField
          name='extension-reason'
          label={localizedStrings.reason}
          placeholder={`${localizedStrings.reason}...`}
          multiline
          rows={4}
          required
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
          sx={{ mt: 4, mb: 10 }}
        />
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={extensionRequest}
          disabled={mutation.isLoading || !reason || !endDate}
          data-test='modal-extension-request'
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
