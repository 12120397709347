import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  registerKeys,
  RequestTypes,
} from '@core/react-query/features/registers/registers/register'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  IRegisterSettings,
  RegisterSettings,
} from '@core/entities/register/RegisterSettings'

const dataTransformer = (settings: IRegisterSettings) =>
  RegisterSettings.new(settings)

export const useGetRegisterSettings = (
  payload: RequestTypes['getRegisterSettings'],
  options?: UseQueryOptions<
    IRegisterSettings,
    RequestErrorType,
    RegisterSettings
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...registerKeys.registerSettings(payload),
    ...options,
  })
}
