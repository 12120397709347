import { useDeepCompareCallback } from 'use-deep-compare'
import moment from 'moment'
import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import { localizedStrings } from '@core/strings'
import _isEmpty from 'lodash/isEmpty'

export type AdvancedDateFilterOperators =
  | 'IS_BEFORE'
  | 'IS_AFTER'
  | 'IS_BETWEEN'
  | 'IS_PRESENT'
  | 'IS_NOT_PRESENT'

export const advancedDateFilterOperatorsWithoutArguments: AdvancedDateFilterOperators[] =
  ['IS_PRESENT', 'IS_NOT_PRESENT']

export type AdvancedDateFilterValue = {
  operator: AdvancedDateFilterOperators
  value: {
    startTime?: string
    endTime?: string
    dateValue?: string
  }
}

export const advancedDateFilterValueIsPartial = (
  value: AdvancedDateFilterValue,
) => {
  if (!value?.operator) {
    return true
  }

  if (advancedDateFilterOperatorsWithoutArguments.includes(value?.operator)) {
    return false
  }

  if (_isEmpty(value?.value)) {
    return true
  }

  return false
}

export const advancedDateFilterOptionConditions: {
  value: AdvancedDateFilterOperators
  label: string
}[] = [
  { value: 'IS_BEFORE', label: 'Is before' },
  { value: 'IS_AFTER', label: 'Is after' },
  { value: 'IS_BETWEEN', label: 'Is between' },
  { value: 'IS_PRESENT', label: localizedStrings.isPresent },
  { value: 'IS_NOT_PRESENT', label: localizedStrings.isNotPresent },
]

export type AdvancedDateFilterHookArguments =
  FilterHookArgumentsBase<AdvancedDateFilterValue> & {
    // Any extra arguments here
    UTC?: boolean
  }

export type AdvancedDateFilterInternalArguments =
  AdvancedDateFilterHookArguments &
    FilterInternalArgumentsBase<AdvancedDateFilterValue, 'ADVANCED_DATE'>

export const useAdvancedDateFilter: (
  filter: AdvancedDateFilterHookArguments,
) => AdvancedDateFilterInternalArguments = (filter) => {
  const unfocusedText = useDeepCompareCallback(
    (
      filterValue: AdvancedDateFilterValue,
      { noLabel } = { noLabel: false },
    ) => {
      const value = filterValue?.value
      const label = noLabel ? '' : `${filter.label}: `

      const operatorLabel = advancedDateFilterOptionConditions.find(
        (operator) => operator.value === filterValue?.operator,
      )?.label

      if (
        filterValue?.operator === 'IS_PRESENT' ||
        filterValue?.operator === 'IS_NOT_PRESENT'
      ) {
        return `${label}${operatorLabel}`
      }

      const dateString = (date) => moment(date).format('Do MMM YYYY')
      if (!value?.startTime && !value?.endTime && !value?.dateValue) {
        return `${label}${localizedStrings.noDateSelected}`
      }

      const startDateString = value?.startTime
        ? dateString(value?.startTime)
        : ''
      const endDateString = value?.endTime
        ? ` - ${dateString(value?.endTime)}`
        : ''

      const dateValueString = value?.dateValue
        ? dateString(value?.dateValue)
        : ''

      return value?.dateValue
        ? `${
            noLabel ? '' : filter.label
          } ( ${operatorLabel} ) ${dateValueString}`
        : `${
            noLabel ? '' : filter.label
          } ( ${operatorLabel} ) ${startDateString}${endDateString}`
    },
    [filter],
  )

  return {
    type: 'ADVANCED_DATE',
    unfocusedText,
    serialization: 'object',
    ...filter,
  }
}
