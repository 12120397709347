import { useQuery } from '@tanstack/react-query'
import { imageDetails } from '../imageDetails'

import { RequestTypes } from '../requestTypes'
import { IImageDetails } from '@core/entities/permit/ImageDetails/IImageDetails'
import { UseQueryOptions } from '@tanstack/react-query/src/types'

export const useGetImageDetails = (
  payload: RequestTypes['useGetImageDetails'],
  options?: UseQueryOptions<IImageDetails, string, IImageDetails>,
) => {
  return useQuery({
    ...imageDetails.byImageKeys(payload),
    ...options,
  })
}
