import { localizedStrings } from '@core/strings'
import React from 'react'
import { useGetPermitLinkedProcesses } from '@core/react-query/features/permits/permit'
import { Box, Text } from '@common/fundamentals'

import { LinkedDocumentsList } from '@elements/LinkingProcesses/LinkedDocumentsList'
import { Permit } from '@core/entities/permit/Permit'
import { useToast } from '@core/toast'

type ComponentProps = {
  permit: Permit
}

export const LinkedDocumentsTab: React.FC<ComponentProps> = ({ permit }) => {
  const toast = useToast()

  const { data, isFetching } = useGetPermitLinkedProcesses(
    {
      permitId: permit?.id,
    },
    {
      onError: (e) => toast.error(e),
    },
  )

  return (
    <Box>
      {!data?.length && !isFetching && (
        <Text>{localizedStrings.noResultsFound}</Text>
      )}
      {data && (
        <LinkedDocumentsList
          items={data}
          organisationId={permit.template.organisationId}
          permitId={permit.id}
        />
      )}
    </Box>
  )
}
