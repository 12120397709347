import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectPlanPermits } from './IProjectPlanPermits'
import { Permit } from '@core/entities/permit/Permit'
import { ProjectPlanDashboard } from '../ProjectPlanDashboard'

export class ProjectPlanPermits extends Entity<IProjectPlanPermits> {
  @Type(() => ProjectPlanDashboard)
  permitStats: ProjectPlanDashboard

  @Type(() => Permit)
  permits: Permit[]

  public static new(payload: unknown): ProjectPlanPermits {
    const entity = plainToInstance(ProjectPlanPermits, payload)

    return entity
  }

  public getSelectedPermits(selectedAreas: string[]): Permit[] {
    if (selectedAreas?.length === 0) return this.permits
    return this.permits.filter((permit) =>
      permit.projectLocations.areas.some((area) =>
        area.selectedAreas.some((selectedArea) =>
          selectedAreas?.includes(selectedArea.uuid),
        ),
      ),
    )
  }

  public getPermit(permitId: number): Permit {
    return this.permits.find((permit) => permit.id === permitId)
  }
}
