import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { DepGraph } from 'dependency-graph'
import { ITemplateRule } from '@core/entities/template/TemplateRule'
import { ITemplateForm } from '@core/entities/template/TemplateForm/ITemplateForm'

const useQuestionVisibility = ({
  rules = [],
  questions = [],
}: ITemplateForm) => {
  const questionsById = questions?.reduce((acc, questionGroup) => {
    const questionGroupQuestionsByKey = _.keyBy(questionGroup.data, 'id')
    return { ...acc, ...questionGroupQuestionsByKey }
  }, {})

  const hiddenConditionalQuestionGroupIdsSet = new Set(
    questions
      .filter((questionGroup) => {
        if (!questionGroup.conditionalQuestionGroup) return false
        const { submissionText, showIfAnswerIs } =
          questionGroup.conditionalQuestionGroup
        return showIfAnswerIs !== submissionText
      })
      .map((questionGroup) => questionGroup.id),
  )

  const questionGroupsById = _.keyBy(questions, 'id')

  const rulesByDependentQuestionId = useMemo(() => {
    const rulesByDependentQuestionId = _.groupBy(
      rules,
      ({ conditions: [condition] }) => condition.questionId,
    )

    return rulesByDependentQuestionId
  }, [rules])

  const rulesByTargetQuestionId = useMemo(() => {
    return _.groupBy(
      rules,
      ({ shownQuestions: [thenShow] }) => thenShow.questionId,
    )
  }, [rules])

  const conditionalQuestionGroupIds = useMemo(() => {
    return questions.reduce((acc, questionGroup) => {
      if (questionGroup.conditionalQuestionGroup) {
        return [...acc, questionGroup.id]
      }
      return acc
    }, [] as Array<string>)
  }, [])

  const ruleDependantQuestionsIds = useMemo(() => {
    return rules?.flatMap((rule) =>
      rule.shownQuestions.map((question) => question.questionId),
    )
  }, [rules])

  const dependencyGraph = useMemo(() => {
    const graph = new DepGraph()
    rules?.forEach((rule) => {
      //when multiple questions are supported in a single rule, this will
      //need to be made to work with an array of conditions
      const [{ questionId }] = rule.conditions
      if (!graph.hasNode(questionId)) {
        graph.addNode(questionId, rulesByDependentQuestionId[questionId] || [])
      }

      rule.shownQuestions.forEach((shownQuestion) => {
        const shownQuestionId = String(shownQuestion.questionId)
        if (!graph.hasNode(shownQuestionId)) {
          graph.addNode(
            shownQuestionId,
            rulesByDependentQuestionId[shownQuestionId] || [],
          )
        }
        graph.addDependency(shownQuestionId, questionId)
      })
    })

    conditionalQuestionGroupIds.forEach((questionGroupId) => {
      const questionGroup = questionGroupsById[questionGroupId]
      questionGroup?.data.forEach((questionGroupQuestion) => {
        if (!graph.hasNode(questionGroupId)) graph.addNode(questionGroupId, [])
        if (!graph.hasNode(questionGroupQuestion.id)) {
          graph.addNode(questionGroupQuestion.id, [])
        }
        graph.addDependency(questionGroupQuestion.id, questionGroupId)
      })
    })

    return graph
  }, [rules, rulesByDependentQuestionId, conditionalQuestionGroupIds])

  const calculateQuestionVisibility = useCallback(
    (questionId: string, visibleQuestionIds: Set<string>) => {
      const isConditionalGroup =
        !!questionGroupsById[questionId]?.conditionalQuestionGroup
      const questionDependents = dependencyGraph.directDependantsOf(questionId)
      const questionNodeData = dependencyGraph.getNodeData(questionId)

      const questionRules = (
        Array.isArray(questionNodeData) ? questionNodeData : []
      ) as Array<ITemplateRule>

      const evaluatedQuestionRules = questionRules.map((rule) => {
        const [{ questionId, conjoiningOperator, terms }] = rule.conditions
        const firstConditionQuestion = questionsById[questionId]
        const termChecker = (term) => {
          const selectedAnswers = firstConditionQuestion?.answers.filter(
            ({ selected }) => selected,
          )
          return selectedAnswers?.some((answer) => answer.id === term.answerId)
        }
        const conditionPasses =
          conjoiningOperator === 'OR'
            ? terms.some(termChecker)
            : terms.every(termChecker)

        return { ...rule, passes: conditionPasses }
      })

      dependencyGraph.setNodeData(questionId, evaluatedQuestionRules)

      questionDependents.forEach((questionDependent) => {
        const questionDependentRules = evaluatedQuestionRules.filter((rule) => {
          return rule.shownQuestions.some(
            ({ questionId }) => questionId === questionDependent,
          )
        })

        const childNodeDirectDependencies =
          dependencyGraph.directDependenciesOf(questionDependent)

        const hasHiddenQuestionGroupDependencies =
          childNodeDirectDependencies.some((nodeDependant) =>
            hiddenConditionalQuestionGroupIdsSet.has(nodeDependant),
          )

        const nodePassesRules =
          (isConditionalGroup && childNodeDirectDependencies.length === 1) ||
          questionDependentRules.some(({ passes }) => passes)

        if (!hasHiddenQuestionGroupDependencies && nodePassesRules) {
          visibleQuestionIds.add(questionDependent)
          calculateQuestionVisibility(questionDependent, visibleQuestionIds)
        }
      })
    },
    [dependencyGraph, questionsById],
  )

  const hiddenQuestionIds = useMemo(() => {
    const rootNodes = dependencyGraph.overallOrder(true)
    const visibleQuestionIds = new Set<string>(rootNodes)
    rootNodes.forEach((node) =>
      calculateQuestionVisibility(node, visibleQuestionIds),
    )

    return ruleDependantQuestionsIds
      .concat(
        conditionalQuestionGroupIds.flatMap((id) =>
          questionGroupsById[id]?.data.map((question) => question.id),
        ),
      )
      .filter((questionId) => {
        return !visibleQuestionIds.has(String(questionId))
      })
  }, [dependencyGraph, calculateQuestionVisibility, ruleDependantQuestionsIds])

  return {
    hiddenQuestionIds,
    rulesByDependentQuestionId,
    rulesByTargetQuestionId,
  }
}

export { useQuestionVisibility }
