import {
  businessUnitsKeys,
  RequestTypes,
} from '@core/react-query/features/businessUnits'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { BusinessUnit, IBusinessUnit } from '@core/entities/businessUnit'

export const useGetBusinessUnit = (
  payload: RequestTypes['getBusinessUnit'],
  options?: UseQueryOptions<BusinessUnit, string, IBusinessUnit>,
) => {
  return useQuery({
    select: BusinessUnit.new,
    ...businessUnitsKeys.getBusinessUnit(payload),
    enabled: !!payload.businessUnitId,
    retry: 3,
    ...options,
  })
}
