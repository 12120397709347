import React from 'react'
import { Link } from '@common/material'
import { TimelineBlockBase } from '@common/Timeline'
import { Text } from '@fundamentals'

type ComponentProps = {
  title: string
  href: string
  text: string
}

export const TimelineLinkBlock: React.FC<ComponentProps> = ({
  title,
  href,
  text,
}) => {
  return (
    <TimelineBlockBase title={title}>
      <Link href={href} target='_blank'>
        <Text variant='body2'>{text}</Text>
      </Link>
    </TimelineBlockBase>
  )
}
