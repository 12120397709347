import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPeriodicCheck, PeriodicCheck } from '@core/entities/PeriodicCheck'

const periodicCheckTransform = (data: IPeriodicCheck) => PeriodicCheck.new(data)

export const usePeriodicCheckEntry = (
  payload: RequestTypes['getPeriodicCheck'],
  options?: UseQueryOptions<IPeriodicCheck, string, PeriodicCheck>,
) => {
  return useQuery({
    select: periodicCheckTransform,
    ...permitKeys.byId(payload)._ctx.periodicChecks(payload),
    ...options,
  })
}
