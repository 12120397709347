import {
  organisationKeys,
  RequestTypes,
} from '@core/react-query/features/organisations/organisation'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { IOrganisation, Organisation } from '@core/entities/organisation'

export const useGetOrganisation = (
  payload: RequestTypes['getOrganisation'],
  options: UseQueryOptions<IOrganisation, string, Organisation> = {},
) => {
  return useQuery<IOrganisation, string, Organisation>({
    select: Organisation.new,
    ...organisationKeys.byId(payload),
    enabled: !!payload.organisationId,
    ...options,
  })
}
