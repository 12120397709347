import React from 'react'
import { Box, Flex } from '@fundamentals'
import { SxProps, Typography } from '@mui/material'
import { IconButton, Tooltip } from '@common/material'
import { Edit } from '@mui/icons-material'

type PropertyProps = {
  heading: string
  subHeading?: string
  subHeadingComponent?: React.ReactNode
  onEdit?: () => void
  sx?: SxProps
}
export const Property: React.FC<PropertyProps> = ({
  heading,
  subHeading,
  subHeadingComponent,
  onEdit,
  sx,
}) => {
  return (
    <Box sx={{ mb: 1, ...sx }}>
      <Typography sx={{ fontSize: 12 }} color='text.secondary' gutterBottom>
        {heading}
      </Typography>
      <Flex
        flexWrap={'wrap'}
        rowGap={1}
        flexDirection={'row'}
        alignItems={'center'}
      >
        {subHeading && (
          <Tooltip title={subHeading}>
            <Typography noWrap>{subHeading}</Typography>
          </Tooltip>
        )}
        {subHeadingComponent && subHeadingComponent}
        {onEdit && (
          <IconButton size={'small'} onClick={onEdit} sx={{ ml: 1 }}>
            <Edit />
          </IconButton>
        )}
      </Flex>
    </Box>
  )
}
