import { localizedStrings } from '@core/strings'
import React from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { ModalDialogContainer } from '@common/modal'
import { Permit } from '@core/entities/permit/Permit'

interface ComponentType {
  permit: Permit
}

export const ReasonForCancelPermitModal: React.FC<ComponentType> = ({
  permit,
}) => {
  const { hideModal } = useModal()
  const { cancelReason, canceledBy } = permit
  const { firstName, lastName } = canceledBy

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.cancelReason}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <TextField
          label={`${firstName} ${lastName}, Permit Approver`}
          multiline
          maxRows={4}
          value={cancelReason}
          InputProps={{
            readOnly: true,
          }}
          sx={{ mt: 1 }}
        />
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          variant='contained'
          data-test='jsOkayCancelReason'
          onClick={hideModal}
        >
          {localizedStrings.ok}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
