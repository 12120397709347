import { MenuItem, TextField } from '@common/material'
import { Flex } from '@fundamentals'
import React, { memo } from 'react'
import {
  AdvancedNumberFilterInternalArguments,
  AdvancedNumberFilterValue,
  advancedNumberFilterOptionConditions,
  advancedNumberFilterOperatorsWithoutArguments,
} from '@core/providers/filters'
import { AdvancedFilterPopover } from './AdvancedFilterPopover'
import { localizedStrings } from '@core/strings'

type AdvancedNumericFilterProps = {
  filter: AdvancedNumberFilterInternalArguments
  onChange: ({ value, operator }: AdvancedNumberFilterValue) => void
  storeValue: AdvancedNumberFilterValue
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (el: HTMLButtonElement | null) => void
}

export const AdvancedNumericFilter: React.FC<AdvancedNumericFilterProps> = memo(
  ({ onChange, storeValue, filter, anchorEl, setAnchorEl }) => {
    const operator =
      storeValue?.operator ?? advancedNumberFilterOptionConditions[0].value
    const hideArguments =
      advancedNumberFilterOperatorsWithoutArguments.includes(operator)

    return (
      <AdvancedFilterPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <Flex flexDirection='column' p={2} pr={1}>
          <TextField
            value={
              storeValue?.operator ??
              advancedNumberFilterOptionConditions[0].value
            }
            select
            size='small'
            label={localizedStrings.selectFilterCondition}
            sx={{
              pr: 1,
              mb: 2,
              minWidth: 200,
            }}
            SelectProps={{
              onChange(e) {
                onChange({
                  value: storeValue?.value,
                  operator: e.target
                    .value as AdvancedNumberFilterValue['operator'],
                })
              },
            }}
          >
            {advancedNumberFilterOptionConditions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {!hideArguments && (
            <TextField
              size='small'
              type='number'
              InputLabelProps={{ shrink: true }}
              label={filter.label}
              value={storeValue?.value}
              onChange={(e) =>
                onChange({
                  value: e.target
                    .value as unknown as AdvancedNumberFilterValue['value'],
                  operator: operator,
                })
              }
              data-test={filter?.dataTest}
            />
          )}
        </Flex>
      </AdvancedFilterPopover>
    )
  },
)
