import { IFilterBase } from '@core/entities/filters/IFilterBase'
import { Entity } from '@core/entities/entity'

export class FilterBase<
  ExtendedType extends IFilterBase = IFilterBase,
> extends Entity<ExtendedType> {
  propertyType:
    | 'STRING'
    | 'NUMBER'
    | 'DATE'
    | 'SELECT'
    | 'USER'
    | 'DIAGRAM'
    | 'INSPECTION'
  propertyId: string
}
