import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import {
  IPermitTemplateVersionPeriodicChecklist,
  PermitTemplateVersionPeriodicChecklist,
} from '@core/entities/permit-template-editor'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const permitPeriodicChecklistTemplatesTransform = (
  data: Array<IPermitTemplateVersionPeriodicChecklist>,
) => data.map(PermitTemplateVersionPeriodicChecklist.new)

export const usePermitPeriodicChecklistTemplates = (
  payload: RequestTypes['getPermitPeriodicChecklistTemplates'],
  options?: UseQueryOptions<
    Array<IPermitTemplateVersionPeriodicChecklist>,
    RequestErrorType,
    Array<PermitTemplateVersionPeriodicChecklist>
  >,
) => {
  return useQuery({
    select: permitPeriodicChecklistTemplatesTransform,
    ...permitKeys.byId(payload)._ctx.periodicChecklistTemplates,
    ...options,
  })
}
