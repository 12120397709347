import { useGetUser } from '@core/react-query/features/support'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { SupportUserChips } from '@modules/support/SupportUserChips'
import { Alert, Button } from '@common/material'
import React from 'react'
import { OpenInNew } from '@mui/icons-material'
import { PageUrls } from '@core/page-urls'
import { Property } from '@elements/Property/Property'

type SupportUserInAppContextProps = {
  userId: number
}
export const SupportUserInAppContext: React.FC<
  SupportUserInAppContextProps
> = ({ userId }) => {
  const { data: profile } = useGetProfile()
  if (!profile?.isSuperUser()) {
    return null
  }

  const { data: user } = useGetUser({ userId })

  return (
    <Property
      heading={'Support User Info'}
      subHeadingComponent={
        <>
          <Button
            startIcon={<OpenInNew />}
            variant={'outlined'}
            onClick={() => {
              window.open(
                PageUrls.adminUser(user?.id),
                '_blank',
                'noopener, noreferrer',
              )
            }}
          >
            View Profile
          </Button>
          <SupportUserChips userEmail={user?.email} userId={user?.id} />
          {user?.userSupportInformation?.note && (
            <Alert severity={'info'} sx={{ ml: 1 }}>
              {user?.userSupportInformation?.note}
            </Alert>
          )}
        </>
      }
    />
  )
}
