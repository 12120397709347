import { Entity } from '@core/entities/entity'
import { IGroupsPermissionsByEntity } from '@core/entities/Groups/GroupsPermissions/IGroupsPermissionsByEntity'
import { plainToInstance } from 'class-transformer'
import { PermissionAssignmentType } from '@core/entities/Groups/GroupsPermissions/types'
import { localizedStrings } from '@core/strings'
import { AuthGroup } from '@core/entities/Groups/AuthGroups'

export class GroupsPermissionsByEntity extends Entity<IGroupsPermissionsByEntity> {
  groupsPermissions: Array<{
    group: AuthGroup
    permissions: Array<{
      id: number
      type: PermissionAssignmentType
      approval?: {
        id: string
        label: string
      }
    }>
  }>

  public getAssigmentTypeTitle(type: PermissionAssignmentType): string {
    switch (type) {
      case 'PERMIT_INITIATION':
        return localizedStrings.initiatorRoleQuestion
      case 'PERMIT_REVIEW_EXTENSION':
        return localizedStrings.extensionsReviewTitle
      case 'REVIEW_PERMIT_TRANSFER_REQUEST':
        return localizedStrings.approvePermitTransfersTitle
      default:
        return ''
    }
  }

  public getAuthGroupIdsByPermissionType(
    permissionType: PermissionAssignmentType,
  ): number[] {
    return this.groupsPermissions
      .filter((group) => {
        return group.permissions.some(
          (permission) => permission.type === permissionType,
        )
      })
      .map((group) => group.group.id)
  }

  public static new(payload: unknown): GroupsPermissionsByEntity {
    const entity = plainToInstance(GroupsPermissionsByEntity, payload)

    return entity
  }
}
