import { Box, Flex, Text, Icon, BoxProps } from '@fundamentals'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material'
import { Palette } from 'styles/color-palette-siteassist'

type CheckboxProps = BoxProps & {
  label?: string
  checked: boolean
  onClick?: (checked) => void
  disabled?: boolean
  size?: number
  nowrap?: boolean
}

const StyledCheckbox = styled(Flex)<{
  checked?: boolean
  disabled?: boolean
  size?: number
}>`
  align-items: center;
  justify-content: center;
  i {
    ${({ checked }) => ({
      color: checked ? 'white' : 'transparent',
    })}
  }
  ${({ size }) => {
    return (
      `min-height: ${size}px; 
    min-width: ${size}px;` +
      // @ts-ignore
      `font-size: ${size / 2 + 2}px;`
    )
  }}
  ${({ checked, disabled }) => ({
    border: '2px solid',
    borderColor: checked ? Palette.Blue[500] : Palette.Grey[400],
    backgroundColor: checked ? Palette.Blue[500] : Palette.Common.White,
    borderRadius: '2px',
    opacity: disabled ? 0.7 : 1,
  })}
`

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onClick,
  disabled,
  size = 20,
  nowrap,
  ...props
}) => {
  const theme: any = useTheme()
  const dataTest = checked ? 'selected' : 'not-selected'
  return (
    <Box
      data-test={dataTest}
      onClick={() => !disabled && onClick && onClick(!checked)}
      {...props}
    >
      <Flex alignItems='center'>
        <StyledCheckbox size={size} disabled={disabled} checked={checked}>
          <Icon icon='check' />
        </StyledCheckbox>
        {label && (
          <Text
            color='legacy.blue.oxford.7'
            fontSize='15px'
            ml={1}
            style={{ whiteSpace: nowrap ? 'nowrap' : 'inherit' }}
          >
            {label}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export { Checkbox }
