import { localizedStrings } from '@core/strings'
import React from 'react'
import { Box, Text } from '@common/fundamentals'

import { Checklist } from '@core/entities/checklist/Checklist'
import { useGetChecklistLinkedProcesses } from '@core/react-query/features/checklists/checklist'
import { LinkedDocumentsList } from 'webapp/components/elements/LinkingProcesses/LinkedDocumentsList'
import { useToast } from '@core/toast'

type ComponentProps = {
  checklist: Checklist
}

export const LinkedDocumentsTab: React.FC<ComponentProps> = ({ checklist }) => {
  const toast = useToast()
  const { data, isFetching } = useGetChecklistLinkedProcesses(
    {
      checklistId: checklist?.id,
    },
    {
      onError: (e) => toast.error(e.message),
    },
  )

  return (
    <Box>
      {!data?.length && !isFetching && (
        <Text>{localizedStrings.noResultsFound}</Text>
      )}
      {data && (
        <LinkedDocumentsList
          items={data}
          organisationId={checklist.organisationId}
        />
      )}
    </Box>
  )
}
