import { useDeepCompareCallback, useDeepCompareMemo } from 'use-deep-compare'
import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import React from 'react'

// If
//   multiple = false | undefined => OptionType
//   multiple = true => OptionType[]
export type SelectFilterValue<
  OptionType,
  Multiple extends boolean | false,
  P = OptionType extends { value?: unknown } ? OptionType['value'] : OptionType,
> = Multiple extends undefined | false ? P : P[]

export type SelectFilterHookArguments<
  OptionType,
  Multiple extends boolean | undefined,
> = FilterHookArgumentsBase<SelectFilterValue<OptionType, Multiple>> & {
  options: OptionType[]
  valueToLabel: <G>(
    value: OptionType extends { value?: unknown }
      ? OptionType['value']
      : OptionType,
  ) => string
  renderItem?: (value: OptionType) => React.ReactElement
  renderTags?: (value: OptionType[]) => React.ReactElement[]
  disableClientSideFilter?: boolean
  loading?: boolean
  multiple?: Multiple
  noOptionsText?: string
  serialization?: 'number' | 'string' | 'object'
}

export type SelectFilterInternalArguments<
  OptionType,
  Multiple extends boolean | undefined,
> = SelectFilterHookArguments<OptionType, Multiple> &
  FilterInternalArgumentsBase<SelectFilterValue<OptionType, Multiple>, 'SELECT'>
export const useSelectFilter: <
  OptionType,
  Multiple extends boolean | undefined = undefined,
>(
  filter: SelectFilterHookArguments<OptionType, Multiple>,
) => SelectFilterInternalArguments<OptionType, Multiple> = (filter) => {
  const unfocusedText = useDeepCompareCallback(
    (filterValue: any) => {
      const value = filterValue?.[0]?.value
        ? filterValue?.[0]?.value
        : filterValue

      const nothingSelectedText =
        filter.nothingSelectedText || 'No item selected'

      if (filter.multiple) {
        if (value && !Array.isArray(value)) {
          return `${filter.label}: ${filter.valueToLabel(value)}`
        }
        if (value && value.length === 1) {
          const [firstValue] = value
          return `${filter.label}: ${filter.valueToLabel(firstValue)}`
        }
        if (value && value.length > 1) {
          return `${filter.label}: ${value.length} selected`
        }
        return `${filter.label}: ${nothingSelectedText}`
      } else {
        if (value) {
          return `${filter.label}: ${filter.valueToLabel(value)}`
        }
        return `${filter.label}: ${nothingSelectedText}`
      }
    },
    [filter],
  )

  // We dynamically set the serialization based on the type of the first option
  const serialization = useDeepCompareMemo(() => {
    if (!filter?.options?.length) {
      return 'string'
    }

    const [firstOption] = filter.options

    // @ts-ignore
    switch (typeof (firstOption?.value || firstOption)) {
      case 'number':
        return 'number'
      case 'string':
        return 'string'
      default:
        return 'object'
    }
  }, [filter])

  return {
    type: 'SELECT',
    unfocusedText: unfocusedText,
    serialization: filter.serialization || serialization,
    ...filter,
  }
}
