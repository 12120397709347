import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { ITask, Task } from '@core/entities/task/Task'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const taskTransform = (tasksData: Array<ITask>) => {
  return tasksData.map((task) => Task.new(task))
}

export const usePermitTasks = (
  payload: RequestTypes['getPermitTasks'],
  options?: UseQueryOptions<Task[], RequestErrorType>,
) => {
  return useQuery({
    select: taskTransform,
    ...permitKeys.byId(payload)._ctx.tasks,
    ...options,
  })
}
