import { Avatar } from '@common/material'
import { Box, Text } from '@common/fundamentals'

type UserAvatarProps = {
  user: {
    firstName: string
    lastName: string
  }
  onClick?: () => void
}
export const UserAvatar: React.FC<UserAvatarProps> = ({ user, onClick }) => {
  const capitalFirstLetter = (name: string) => {
    if (!name) {
      return ''
    }
    return name?.[0]?.toUpperCase()
  }

  const userInitials =
    capitalFirstLetter(user?.firstName) + capitalFirstLetter(user?.lastName)

  return (
    <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
      <Avatar sx={{ bgcolor: '#A4B5D2' }}>
        <Text>{userInitials}</Text>
      </Avatar>
    </Box>
  )
}
