import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  projectKeys,
  RequestTypes,
} from '@core/react-query/features/projects/project'
import { IProject, Project } from '@core/entities/project'

const dataTransformer = (response: IProject) => {
  return Project.new(response)
}

export const useGetProject = (
  payload: RequestTypes['getProject'],
  options?: UseQueryOptions<IProject, string, Project>,
) => {
  const projectUsersQuery = projectKeys.byId(payload)
  return useQuery<IProject, string, Project>({
    ...projectUsersQuery,
    select: dataTransformer,
    enabled: !!payload?.projectId,
    ...options,
  })
}
