import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { IRegister, Register } from '@core/entities/register/Register'
import {
  RequestTypes,
  registerKeys,
} from '@core/react-query/features/registers/registers/register'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const dataTransformer = (response: IRegister) => {
  return Register.new(response)
}

export const useGetRegisterDetails = (
  payload: RequestTypes['getRegisterDetails'],
  options?: UseQueryOptions<IRegister, RequestErrorType, Register>,
) => {
  return useQuery({
    ...registerKeys.details(payload),
    select: dataTransformer,
    ...options,
  })
}
