import { localizedStrings } from '@core/strings'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { FilterHookArgumentsBase } from '@core/providers/filters'
import Utils from '@core/utils/utils'
import { PermitStatus } from '@core/entities/permit/PermitBase'
import { useMemo } from 'react'

type PermitStatusFilter<Multiple extends boolean | undefined> = Omit<
  FilterHookArgumentsBase<SelectFilterValue<PermitStatus, Multiple>>,
  'label'
> & {
  multiple?: Multiple
  whitelist?: PermitStatus[]
  readOnly?: boolean
}

export const PermitStatusFilterOptions: PermitStatus[] = [
  'REQUESTED',
  'SENT_TO_PERMIT_HOLDER',
  'ISSUED',
  'OPEN',
  'EXTENSION_REQUESTED',
  'SUSPENDED',
  'EXPIRED',
  'AWAITING_CHECKS',
  'AWAITING_FINAL_SIGN_OFF',
  'CLOSED',
  'REJECTED',
  'DISCARDED',
  'CANCELLED',
]

export const usePermitStatusFilter: <Multiple extends boolean | undefined>(
  arg: PermitStatusFilter<Multiple>,
) => SelectFilterInternalArguments<PermitStatus, Multiple> = (args) => {
  const filteredStatuses = useMemo(() => {
    if (args?.whitelist) {
      return PermitStatusFilterOptions.filter((status: PermitStatus) =>
        args?.whitelist.includes(status),
      )
    }
    return PermitStatusFilterOptions
  }, [args?.whitelist])

  return useSelectFilter({
    ...args,
    label: localizedStrings.status,
    options: filteredStatuses,
    valueToLabel: (v) => Utils.getTextForStatus(v),
    multiple: args.multiple,
    dataTest: 'statuses-filter',
  })
}
