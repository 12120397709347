import { RequestTypes } from '../requestTypes'
import { registerKeys } from '../registerKeys'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
export const useCreateRegisterTrigger = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['createRegisterTrigger']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...registerKeys.createRegisterTrigger,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(registerKeys.registerSettings._def)
    },
  })
}
