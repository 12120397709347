import React from 'react'
import { localizedStrings } from '@core/strings'
import { Box, Flex, Text } from '@fundamentals'
import EastIcon from '@mui/icons-material/East'
import { TimelineBlockBase } from '@common/Timeline'
import { isEqual } from 'lodash'

type ComponentProps = {
  title?: string
  preValues: Array<string>
  postValues: Array<string>
}

export const TimelineUserRoleUpdateBlock: React.FC<ComponentProps> = ({
  title = localizedStrings.permissionUpdate,
  preValues = [],
  postValues = [],
}) => {
  if (isEqual(preValues, postValues)) {
    return null
  }
  return (
    <TimelineBlockBase title={title}>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Box>
          {preValues.map((preValue, index) => (
            <Text variant='body2' key={index}>
              {preValue}
            </Text>
          ))}
        </Box>
        <EastIcon sx={{ mx: 2, opacity: 0.5 }} />
        <Box>
          {postValues.map((postValue, index) => (
            <Text variant='body2' key={index}>
              {postValue}
            </Text>
          ))}
        </Box>
      </Flex>
    </TimelineBlockBase>
  )
}
