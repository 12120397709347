import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '@core/react-query/features/processTags/index'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { PageResponse } from '@core/entities/pagination'
import { IProcessTag } from '@core/entities/processTags/IProcessTag'

const processTagsQueryKeys = createQueryKeys('process-tags', {
  getProcessTags: ({
    organisationId,
    projectId,
    entityType,
    page,
    size,
    term,
  }: RequestTypes['getProcessTags']) => {
    return {
      queryKey: [organisationId, projectId, entityType, term, page, size],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          organisationId,
          projectId,
          entityType,
          page,
          size,
          term,
        })
        const { data } = await APIAxiosInstance.get(
          `process-tags${queryString}`,
        )
        return data as PageResponse<IProcessTag>
      },
    }
  },
})

const processTagsMutationsKeys = createMutationKeys('process-tags', {
  createProcessTag: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createProcessTag']) => {
      const { data } = await APIAxiosInstance.post(`process-tags`, payload)
      return data
    },
  },
  editProcessTag: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['editProcessTag']) => {
      const { data } = await APIAxiosInstance.put(
        `process-tags/${payload.id}`,
        payload,
      )
      return data
    },
  },
  deleteProcessTag: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['deleteProcessTag']) => {
      const { data } = await APIAxiosInstance.delete(
        `process-tags/${payload.id}`,
      )
      return data
    },
  },
})

export const processTagsKeys = mergeQueryKeys(
  processTagsQueryKeys,
  processTagsMutationsKeys,
)['process-tags']
