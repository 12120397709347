import { bbox, buffer, featureCollection } from '@turf/turf'
import { Feature, FeatureCollection, Geometry } from 'geojson'

/**
 * Function to calculate the map bounds from separate arrays of GeoJSON Features and FeatureCollections.
 * @param features Array of GeoJSON Features.
 * @param featureCollections Array of GeoJSON FeatureCollections.
 * @param bufferDistance Distance in meters to expand the bounding box.
 * @returns Map view state with bounds or null if no valid geometries.
 */
export function calculateMapBounds(
  features: Array<Feature<Geometry>> = [],
  featureCollections: Array<FeatureCollection<Geometry>> = [],
  bufferDistance = 100,
) {
  const allFeatures: Feature<Geometry>[] = [...features]

  // Safely add all features from each valid feature collection to the allFeatures array
  if (Array.isArray(featureCollections)) {
    featureCollections.forEach((fc) => {
      if (fc && Array.isArray(fc.features)) {
        allFeatures.push(...fc.features.filter((feat) => feat && feat.geometry))
      }
    })
  }

  if (allFeatures.length > 0) {
    const geoJson = featureCollection(allFeatures)
    const bufferedGeoJson = buffer(geoJson, bufferDistance, {
      units: 'meters',
    })
    const boundingBox = bbox(bufferedGeoJson)

    const [minLng, minLat, maxLng, maxLat] = boundingBox
    const bounds = [
      [minLng, minLat],
      [maxLng, maxLat],
    ] as [[number, number], [number, number]]

    return bounds
  }

  return null // Return null if no features to process
}
