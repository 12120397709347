import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  permitTemplateKeys,
} from '@core/react-query/features/templates/permitTemplates/permitTemplate'
import { foldersKeys } from '@core/react-query/features/folders'

export const useArchivePermitTemplate = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['archivePermitTemplate']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...permitTemplateKeys.archivePermitTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: permitTemplateKeys._def })
      queryClient.invalidateQueries({ queryKey: foldersKeys._def })
    },
    ...options,
  })
}
