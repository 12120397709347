import { BusinessUnit } from '@core/entities/businessUnit'
import { Entity } from '@core/entities/entity'
import { IProjectSummary, ProjectStatus } from '@core/entities/project'
import { plainToInstance } from 'class-transformer'

export class ProjectSummary extends Entity<IProjectSummary> {
  organisationId: number
  id: number
  name: string
  code: string
  description: string
  startDate: string
  endDate: string
  status: ProjectStatus
  businessUnits: BusinessUnit[]

  public static new(payload: unknown): ProjectSummary {
    const entity = plainToInstance(ProjectSummary, payload)

    return entity
  }
}
