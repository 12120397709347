import {
  createMutationKeys,
  createQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '@core/react-query/features/organisations/organisation/requestTypes'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'

export const organisationKeys = createQueryKeys('organisation', {
  byId: ({ organisationId }: RequestTypes['getOrganisation']) => {
    return {
      queryKey: [organisationId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `organisations/${organisationId}`,
        )

        return data
      },
      contextQueries: {
        organisationUsers: ({
          term,
          size,
          page,
          roles,
          memberStatus,
        }: RequestTypes['getOrganisationUsers']) => ({
          queryKey: [page, size, term, roles, memberStatus],
          queryFn: async () => {
            const queryString = createQueryKeyString({
              page,
              size,
              term,
              roles,
              memberStatus,
            })
            const { data } = await APIAxiosInstance.get(
              `organisations/${organisationId}/users${queryString}`,
            )
            return data
          },
        }),
        organisationUser: ({ userId }) => ({
          queryKey: [userId],
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `organisations/${organisationId}/users/${userId}`,
            )
            return data
          },
        }),
        organisationDashboard: ({
          projectIds,
          templateIds,
          processTagIds,
        }: RequestTypes['getOrganisationDashboard']) => ({
          queryKey: [projectIds, templateIds, processTagIds],
          queryFn: async () => {
            const queryString = createQueryKeyString({
              projectIds,
              templateIds,
              processTagIds,
            })

            const { data } = await APIAxiosInstance.get(
              `organisations/${organisationId}/dashboard${queryString}`,
            )
            return data
          },
        }),
      },
    }
  },
  list: (payload: RequestTypes['getOrganisations']) => ({
    queryKey: [
      payload.page,
      payload.size,
      payload.term,
      payload.isTestOrganisation,
      payload.isVisibleTestOrganisation,
    ],
    queryFn: async () => {
      const queryString = createQueryKeyString(payload)
      const { data } = await APIAxiosInstance.get(`organisations${queryString}`)
      return data
    },
  }),
})

export const organisationMutationKeys = createMutationKeys('project', {
  setOrganisationUserRole: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['setOrganisationUserRole']) => {
      const { organisationId, userId, roles } = payload
      const { data } = await APIAxiosInstance.put(
        `organisations/${organisationId}/users/${userId}/roles`,
        {
          roles,
        },
      )
      return data
    },
  },
  setOrganisationUserStatus: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['setOrganisationUserStatus']) => {
      const { organisationId, userId, status } = payload
      const { data } = await APIAxiosInstance.put(
        `organisations/${organisationId}/users/${userId}/status?status=${status}`,
      )
      return data
    },
  },
  inviteOrganisationUser: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['inviteOrganisationUser']) => {
      const { organisationId } = payload
      const { data } = await APIAxiosInstance.post(
        `organisations/${organisationId}/invites`,
        {
          ...payload,
        },
      )
      return data
    },
  },
  updateOrganisation: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateOrganisation']) => {
      const { organisationId } = payload
      const { data } = await APIAxiosInstance.put(
        `organisations/${organisationId}`,
        {
          ...payload,
        },
      )
      return data
    },
  },
})
