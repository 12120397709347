import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { ProcessTag } from '@core/entities/processTags'
import {
  processTagsKeys,
  RequestTypes,
} from '@core/react-query/features/processTags'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useCreateProcessTag = (
  options?: UseMutationOptions<
    ProcessTag,
    RequestErrorType,
    RequestTypes['createProcessTag']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...processTagsKeys.createProcessTag,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: processTagsKeys.getProcessTags._def,
      })
    },
  })
}
