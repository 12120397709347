import { useContext, useEffect, useRef } from 'react'
import { Box } from '@fundamentals'
import { useActor, useSelector } from '@xstate/react'
import {
  ProjectPlanStateContext,
  selectColorOpacity,
  selectInstance,
  selectPSPDFKit,
  selectProjectPlanPSPDFSelectedAreas,
} from 'webapp/state-machines/projectPlan'

type ComponentType = {
  pspdfkitRef: React.MutableRefObject<any>
}

const PspdfkitPlansComponent: React.FC<ComponentType> = ({ pspdfkitRef }) => {
  const projectPlanStateContext = useContext(ProjectPlanStateContext)

  const [projectPlanState, projectPlanSend] = useActor(
    projectPlanStateContext.projectPlanService,
  )

  const instance = useSelector(
    projectPlanStateContext.projectPlanService,
    selectInstance,
  )

  const PSPDFKit = useSelector(
    projectPlanStateContext.projectPlanService,
    selectPSPDFKit,
  )

  const colorOpacity = useSelector(
    projectPlanStateContext.projectPlanService,
    selectColorOpacity,
  )

  const projectPlanPSPDFSelectedAreas = useSelector(
    projectPlanStateContext.projectPlanService,
    selectProjectPlanPSPDFSelectedAreas,
  )

  const selectedAnnotationIdsRef = useRef([])

  useEffect(() => {
    if (instance) {
      const DASHED_STROKE_COLOR = new PSPDFKit.Color({ r: 0, g: 0, b: 0 })
      const DASHED_STROKE_WIDTH = 10
      const DASHED_STROKE_ARRAY = [3]

      const setInitialAnnotations = async () => {
        const annotations = await instance.getAnnotations(0)

        projectPlanPSPDFSelectedAreas.forEach((area) => {
          const annotation = annotations.find(
            (annotation) => annotation.id === area.id,
          )
          const updated = annotation
            .set('opacity', colorOpacity.selected)
            .set('strokeColor', DASHED_STROKE_COLOR)
            .set('strokeDashArray', DASHED_STROKE_ARRAY)
            .set('strokeWidth', DASHED_STROKE_WIDTH)
            .set('pageIndex', 0)
          instance.update(updated)
          instance.setSelectedAnnotation(null)
          instance.setSelectedAnnotation(annotation.id)
          instance.setSelectedAnnotation(null)
        })
      }

      setInitialAnnotations()

      instance.addEventListener(
        'annotations.create',
        async (createdAnnotation) => {
          const annotations = await instance.getAnnotations(0)
          const selectedAnnotation = createdAnnotation.get(0)
          const temp = []
          annotations.forEach((annotation) => {
            if (annotation.id !== selectedAnnotation.id) {
              temp.push(annotation.set('opacity', colorOpacity.deselected))
            } else {
              temp.push(annotation.set('name', `Zone ${annotations.size}`))
            }
          })
          instance.update(temp)
        },
      )

      // Multiple select
      if (
        projectPlanState.matches('viewingZonesWithPermits') ||
        projectPlanState.matches('choosingZonesWithPermits')
      ) {
        instance.addEventListener('annotations.change', async () => {
          projectPlanSend({
            type: 'SET_AREAS',
          })
        })
        // Single select
      } else {
        instance.addEventListener('annotations.update', async () => {
          projectPlanSend({
            type: 'SET_AREAS',
          })
        })

        instance.addEventListener('annotations.delete', async () => {
          projectPlanSend({
            type: 'SET_AREAS',
          })
        })
      }

      // Multiple select
      if (
        projectPlanState.matches('viewingZonesWithPermits') ||
        projectPlanState.matches('choosingZonesWithPermits')
      ) {
        instance.addEventListener(
          'annotationSelection.change',
          async (annotation) => {
            if (annotation) {
              if (selectedAnnotationIdsRef.current.includes(annotation.id)) {
                const updated = annotation
                  .set('opacity', colorOpacity.deselected)
                  .set('strokeColor', annotation.fillColor)
                  .set('strokeDashArray', [])
                  .set('strokeWidth', 5)
                  .set('pageIndex', 0)
                instance.update(updated)
                selectedAnnotationIdsRef.current =
                  selectedAnnotationIdsRef.current.filter(
                    (prevSelectedId) => prevSelectedId !== annotation.id,
                  )
                instance.setSelectedAnnotation(null)
              } else {
                const updated = annotation
                  .set('opacity', colorOpacity.selected)
                  .set('strokeColor', DASHED_STROKE_COLOR)
                  .set('strokeDashArray', DASHED_STROKE_ARRAY)
                  .set('strokeWidth', DASHED_STROKE_WIDTH)
                  .set('pageIndex', 0)
                instance.update(updated)
                !selectedAnnotationIdsRef.current.includes(annotation.id) &&
                  selectedAnnotationIdsRef.current.push(annotation.id)
                instance.setSelectedAnnotation(null)
              }
            }
          },
        )
        // Single select
      } else {
        instance.addEventListener(
          'annotationSelection.change',
          async (annotation) => {
            const annotations = await instance.getAnnotations(0)
            const selectedAnnotation = annotations.find(
              (annotation) =>
                annotation.id === selectedAnnotationIdsRef.current[0],
            )
            if (annotation) {
              const updated = annotation
                .set('opacity', colorOpacity.selected)
                .set('pageIndex', 0)
              instance.update(updated)

              selectedAnnotationIdsRef.current = [annotation.id]
            } else {
              if (!selectedAnnotation) return
              if (
                selectedAnnotation.boundingBox.width === 0 &&
                selectedAnnotation.boundingBox.height === 0
              ) {
                instance.delete(selectedAnnotation.id)
                selectedAnnotationIdsRef.current = []
                return
              }
              const updated = selectedAnnotation
                .set('opacity', colorOpacity.deselected)
                .set('pageIndex', 0)
              instance.update(updated)
              selectedAnnotationIdsRef.current = []
            }
          },
        )

        instance.addEventListener(
          'annotations.blur',
          async ({ annotation, nativeEvent }) => {
            const target = nativeEvent.target

            if (!target) {
              const updated = annotation
                .set('opacity', colorOpacity.deselected)
                .set('pageIndex', 0)
              instance.update(updated)
              selectedAnnotationIdsRef.current = []
            }
          },
        )
      }
      // Read-only
      if (
        projectPlanState.matches('viewingZones') ||
        projectPlanState.matches('viewingZonesWithPermits')
      ) {
        const stopPropagation = (event) => event.stopImmediatePropagation()

        instance.addEventListener('annotations.transform', async (e, t) => {
          instance.contentDocument.addEventListener(
            'pointermove',
            stopPropagation,
            { capture: true },
          )
          instance.contentDocument.addEventListener(
            'mousemove',
            stopPropagation,
            {
              capture: true,
            },
          )
          instance.contentDocument.addEventListener(
            'touchmove',
            stopPropagation,
            {
              capture: true,
            },
          )

          instance.contentDocument.addEventListener(
            'keydown',
            stopPropagation,
            {
              capture: true,
            },
          )
        })
      }
    }

    return () => {
      PSPDFKit && PSPDFKit.unload(instance)
    }
  }, [instance])

  return <Box ref={pspdfkitRef} style={{ height: '100%', width: '100%' }} />
}

export default PspdfkitPlansComponent
