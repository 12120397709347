import { ModalDialogContainer } from '@common/modal'
import { useModal } from '@common/GlobalModal'
import { Card, DialogContent, DialogTitle, IconButton } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import { SubmissionProof } from '@core/entities/template/TemplateQuestion'
import { Box, Flex, Image, Text } from '@fundamentals'
import { PageTitle } from '@elements'
import ImageModal from '@common/modal/ImageModal'
import { localizedStrings } from '@core/strings'

type ObjectDetectionImageListByObjectProps = {
  objectName: string
  proofs: {
    present: SubmissionProof[]
    notPresent: SubmissionProof[]
  }
}

export const ObjectDetectionImageListModal: React.FC<
  ObjectDetectionImageListByObjectProps
> = ({ objectName, proofs }) => {
  const { hideModal, showModal } = useModal()

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '90%', flex: 1 } }}>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        <PageTitle title={objectName} />
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Text variant='h6'>Present</Text>
        <ImageList proofs={proofs.present} />
        <Box mt={4} />

        <Text variant='h6'>Not Present</Text>
        <ImageList proofs={proofs.notPresent} />
      </DialogContent>
    </ModalDialogContainer>
  )
}

const ImageList: React.FC<{ proofs: SubmissionProof[] }> = ({ proofs }) => {
  const { showModal } = useModal()
  return (
    <Flex flexWrap='wrap'>
      {proofs?.map((proof, index) => {
        return (
          <Card key={index} sx={{ p: 2, mr: 1.5, mt: 1.5 }}>
            <Flex
              height={200}
              width={300}
              overflow='hidden'
              sx={{ 'img': { height: '100%', 'object-fit': 'scale-down' } }}
              bgcolor='black'
              borderRadius={1}
              justifyContent='center'
              alignItems='center'
              data-test={`upload-thumbnail-${index}`}
            >
              <Box
                height='100%'
                onClick={() => {
                  showModal(
                    <ImageModal
                      title={localizedStrings.image}
                      previewUrl={proof.downloadUrl}
                      s3Key={proof?.key}
                      canDownload
                    />,
                  )
                }}
              >
                <Image
                  src={proof.downloadUrl}
                  sx={{
                    pointerEvents: 'none',
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                />
              </Box>
            </Flex>
          </Card>
        )
      })}
    </Flex>
  )
}
