import React from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import { useToast } from '@core/toast'
import { localizedStrings } from '@core/strings'
import { useGetProfile } from '@core/react-query/features/profile'
import { useSignOffPermit } from '@core/react-query/features/permits/permit'
import ConfirmModal from '@common/modal/ConfirmModal'
import { SignatureEdit } from '@common/Signature'

interface ComponentType {
  permitId: number
  templateId: number
}

export const PostPermitChecksSubmitModal: React.FC<ComponentType> = ({
  permitId,
  templateId,
}) => {
  const toast = useToast()
  const { hideModal } = useModal()
  const signOffPermit = useSignOffPermit()
  const { data: profile } = useGetProfile()

  return (
    <ConfirmModal
      title={localizedStrings.postPermitChecksDisclaimerTitle}
      confirmText={localizedStrings.submit}
      onConfirm={() => {
        signOffPermit.mutate(
          {
            permitId,
            templateId,
          },
          {
            onSuccess: () => hideModal(),
            onError: (e) => toast.error(e.message),
          },
        )
      }}
    >
      {localizedStrings.completePostPermitChecks}
      <SignatureEdit readonly value={profile?.signatureUrl} />
    </ConfirmModal>
  )
}
