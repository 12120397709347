import React from 'react'
import {
  PreconditionQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import { Flex, Text } from '@fundamentals'
import { Check } from '@mui/icons-material'
import { MultipleChoice } from '@common/MultipleChoice'

const PreConditionQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange, ...inputProps } = {},
  error,
  readOnly,
}) => {
  const answers = [
    {
      label: question.validAnswer,
      value: question.validAnswer,
    },
    {
      label: question.invalidAnswer,
      value: question.invalidAnswer,
    },
  ]

  const onChangeText = (submissionText) => {
    onChange({
      ...value,
      submissionText,
    })
  }

  if (readOnly) {
    return (
      !!value?.submissionText && (
        <Flex data-test={`${question.id}-${'PRE_CONDITION'}`}>
          <Check sx={{ mr: 1 }} />
          <Text>{question?.validAnswer}</Text>
        </Flex>
      )
    )
  }

  return (
    <MultipleChoice
      options={answers}
      selected={[value?.submissionText]}
      onSelect={(selected) => {
        onChangeText(selected[0])
      }}
      showCheckbox
      dataTest={`${question.id}-${'PRE_CONDITION'}`}
    />
  )
}

PreConditionQuestion.rules = PreconditionQuestionRules

export default PreConditionQuestion
