import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  businessUnitsKeys,
} from '@core/react-query/features/businessUnits'

export const useDeleteBusinessUnit = (
  options?: UseMutationOptions<
    string,
    string,
    RequestTypes['deleteBusinessUnit']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...businessUnitsKeys.deleteBusinessUnit,
    retry: 0,
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: businessUnitsKeys._def })
    },
  })
}
