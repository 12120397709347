import { localizedStrings } from '@core/strings'
import { DialogContent, DialogTitle, IconButton } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import { useModal } from '@common/GlobalModal'
import { Box } from '@fundamentals'
import { OrganisationUserActions } from '@modules/organisationDetail/OrganisationUserActions'
import { useOrganisationUser } from '@core/react-query/features/organisations/organisation/hooks/useOrganisationUser'
import { SupportUserInAppContext } from '@modules/support/SupportUserInAppContext'
import { UserStatusChip } from '@elements/User/UserStatusChip'
import { Property } from '@elements/Property/Property'
import { OrganisationUserPermissionsChips } from '@modules/organisationDetail/OrganisationUserPermissionsChips'
import { useGetOrganisation } from '@core/react-query/features/organisations/organisation/hooks/useGetOrganisation'
import BackdropLoader from '@common/fundamentals/BackdropLoader'
import { ChangeOrganisationUserPermissions } from '@modules/organisationDetail/modals/ChangeOrganisationUserPermissionsModal'
import { ModalDialogContainer } from '@common/modal'

type UserDetailsModalProps = {
  organisationId: number
  userId: number
}

export const OrganisationUserDetailsModal: React.FC<UserDetailsModalProps> = ({
  organisationId,
  userId,
}) => {
  const { hideModal, showModal } = useModal()
  const { data: user, isFetching: userIsFetching } = useOrganisationUser({
    organisationId: organisationId,
    userId,
  })
  const { data: organisation, isFetching: organisationIsFetching } =
    useGetOrganisation({ organisationId })
  const isFetching = userIsFetching || organisationIsFetching

  return (
    <ModalDialogContainer>
      <BackdropLoader open={isFetching} />
      {organisation && user && (
        <>
          <DialogTitle
            variant='h5'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ pr: 1, fontSize: 20 }}
          >
            {organisation?.name}

            <Box flexGrow={1} />
            <OrganisationUserActions
              variant={'button'}
              organisation={organisation}
              user={user}
            />
            <IconButton
              sx={{ ml: 1 }}
              aria-label='close-modal'
              onClick={hideModal}
            >
              <ClearIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box ml={2}>
              <Property
                heading={localizedStrings.email}
                subHeading={user.email}
              />
              <Property
                heading={localizedStrings.name}
                subHeading={user.getFullName()}
              />
              <Property
                heading={localizedStrings.company}
                subHeading={user.company}
              />
              <Property
                heading={localizedStrings.status}
                subHeadingComponent={<UserStatusChip status={user.status} />}
              />
              <Property
                heading={localizedStrings.telephoneNumber}
                subHeading={user.phone || localizedStrings.none}
              />
              <Property
                heading={localizedStrings.permissions}
                subHeadingComponent={
                  <OrganisationUserPermissionsChips roles={user.roles} />
                }
                onEdit={() => {
                  showModal(
                    <ChangeOrganisationUserPermissions
                      user={user}
                      organisation={organisation}
                    />,
                  )
                }}
              />
              <SupportUserInAppContext userId={user.id} />
            </Box>
          </DialogContent>
        </>
      )}
    </ModalDialogContainer>
  )
}
