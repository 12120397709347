import React, { useMemo } from 'react'
import { Box, Text } from '@fundamentals'
import { localizedStrings } from '@core/strings'
import { AuditResult } from '@core/entities/audit/AuditResult'
import {
  checklistKeys,
  useGetChecklistById,
} from '@core/react-query/features/checklists/checklist'
import CommentItem from '@modules/comments/CommentItem'
import { Comment } from '@core/entities/comments'
import CommentsButton from '@modules/comments/CommentsButton'
import { QuestionWrapperBase } from '@elements/Questions/QuestionWrapperBase'
import { useQueryClient } from '@tanstack/react-query'
import { TimelineBlockBase } from '@common/Timeline'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineChecklistCommentBlocks: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  const queryClient = useQueryClient()
  const { data: checklistData } = useGetChecklistById(
    { checklistId: auditResult.checklist?.id },
    {
      refetchOnMount: false,
    },
  )

  const commentQuestion = useMemo(() => {
    for (const questionGroupIndex in checklistData?.mainAnswers.answers || []) {
      if (
        Object.hasOwn(checklistData?.mainAnswers.answers, questionGroupIndex)
      ) {
        const questionGroup =
          checklistData?.mainAnswers.answers[questionGroupIndex]
        for (const questionIndex in questionGroup.data) {
          if (Object.hasOwn(questionGroup.data, questionIndex)) {
            const question = questionGroup.data[questionIndex]
            if (
              question.id ===
              auditResult.metadata.commentSecondaryReferenceObjectIdText
            ) {
              return {
                groupIndex: parseInt(questionGroupIndex),
                answerIndex: parseInt(questionIndex),
                questionNumber: parseInt(questionIndex) + 1,
                answer: question,
              }
            }
          }
        }
      }
    }
  }, [])

  if (!commentQuestion) {
    return null
  }

  return (
    <>
      {commentQuestion && (
        <TimelineBlockBase
          title={`${localizedStrings.question}: ${
            commentQuestion?.groupIndex + 1
          }-${commentQuestion?.questionNumber}`}
        >
          <Text color='text.primary'>
            {commentQuestion.answer.getBodyFormattedText()}
          </Text>
        </TimelineBlockBase>
      )}
      <CommentItem
        showInfo={false}
        comment={Comment.new({
          message: auditResult.info,
          mediaUrlsWithType: auditResult.metadata.mediaUrlsWithType,
        })}
      />
      <Box mt={1}>
        <CommentsButton
          variant='text'
          subType='QUESTION'
          subTypeId={auditResult.metadata.commentAnsweredQuestionDataResult.id}
          label={localizedStrings.openAllComments}
          showIcon={false}
          onCreateComment={(referenceObjectId) => {
            queryClient.invalidateQueries({
              queryKey: checklistKeys.byId({
                checklistId: referenceObjectId,
              })._ctx.timeline.queryKey,
            })
          }}
          subTypeComponent={
            <QuestionWrapperBase
              groupIndex={commentQuestion.groupIndex}
              questionNumber={commentQuestion.questionNumber}
              questionIndex={commentQuestion.answerIndex}
              question={auditResult.metadata.commentAnsweredQuestionDataResult.toPlain()}
              readOnly
            />
          }
        />
      </Box>
    </>
  )
}
