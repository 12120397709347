import {
  businessUnitsKeys,
  RequestTypes,
} from '@core/react-query/features/businessUnits'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

export const useAddProjectsToBusinessUnit = (
  options?: UseMutationOptions<
    string,
    string,
    RequestTypes['addProjectToBusinessUnit']
  >,
) => {
  return useMutation({
    ...businessUnitsKeys.addProjectsToBusinessUnit,
    retry: 0,
    ...options,
  })
}
