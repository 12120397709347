import { plainToInstance } from 'class-transformer'
import { IDiagramFilter } from '@core/entities/filters'
import { FilterBase } from '@core/entities/filters/FilterBase'
import { DiagramFilterOperatorTypes } from '../types'

export class DiagramFilter extends FilterBase<IDiagramFilter> {
  propertyType: 'DIAGRAM'
  operator: DiagramFilterOperatorTypes
  annotationSetId: number

  public static new(payload: unknown): DiagramFilter {
    return plainToInstance(DiagramFilter, payload)
  }
}
