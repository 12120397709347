import { MapMouseEvent } from 'mapbox-gl'
import { MapboxGeoJSONFeature, MapRef } from 'react-map-gl'

/**
 * Sets up click listener for a map layer.
 *
 * @param {mapboxgl.Map} map - The Mapbox map instance.
 * @param {(event: MapMouseEvent, feature: MapboxGeoJSONFeature) => void} onClickCallback - Callback function triggered on click events.
 */
export function setupClickEffect(
  map: MapRef,
  onClickCallback: (
    event: MapMouseEvent,
    feature: MapboxGeoJSONFeature,
  ) => void,
) {
  map.on('click', (event) => {
    const featuresUnderMouseClick = map
      ?.getMap()
      ?.queryRenderedFeatures(event.point)

    onClickCallback &&
      onClickCallback(
        event,
        featuresUnderMouseClick ? featuresUnderMouseClick?.[0] : null,
      )
  })
}
