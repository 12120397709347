import { useDeepCompareCallback } from 'use-deep-compare'
import moment from 'moment'
import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'

// [startDate, endDate]
export type AnalyticsCustomDateRangeFilterValue = string[]

export type AnalyticsCustomDateRangeFilterHookArguments =
  FilterHookArgumentsBase<AnalyticsCustomDateRangeFilterValue> & {}

export type AnalyticsCustomDateRangeInternalArguments =
  AnalyticsCustomDateRangeFilterHookArguments &
    FilterInternalArgumentsBase<
      AnalyticsCustomDateRangeFilterValue,
      'ANALYTICS_CUSTOM_DATE_RANGE'
    >

export const useAnalyticsCustomDateRangeFilter: (
  filter: AnalyticsCustomDateRangeFilterHookArguments,
) => AnalyticsCustomDateRangeInternalArguments = (filter) => {
  const unfocusedText = useDeepCompareCallback(
    (value: AnalyticsCustomDateRangeFilterValue) => {
      const dateString = (date) => moment(date).format('Do MMM YYYY')
      if (!value?.length) {
        return `${filter.label}: no date selected`
      }

      const startDateString = value?.[0] ? dateString(value?.[0]) : ''
      const endDateString = value?.[1] ? dateString(value?.[1]) : ''

      return `Date: ${startDateString} - ${endDateString}`
    },
    [filter],
  )

  return {
    type: 'ANALYTICS_CUSTOM_DATE_RANGE',
    unfocusedText,
    serialization: 'string',
    ...filter,
  }
}
