import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IRegisterSettingsAuthGroups } from './IRegisterSettingsAuthGroups'

export class RegisterSettingsAuthGroups extends Entity<IRegisterSettingsAuthGroups> {
  authGroups: {
    id: number
    name: string
    projectId: number
    permittedActions: 'READ'
  }[]

  public static new(payload: unknown): RegisterSettingsAuthGroups {
    const entity = plainToInstance(RegisterSettingsAuthGroups, payload)

    return entity
  }
}
