import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { supportKeys } from '../supportKeys'

import { RequestTypes } from '../requestTypes'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useUpdateUserEmail = (
  options?: UseMutationOptions<
    Response,
    RequestErrorType,
    RequestTypes['updateUserEmail']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables) =>
      supportKeys.updateUserEmail(variables).queryFn(undefined),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: supportKeys._def })
    },
    ...options,
  })
}
