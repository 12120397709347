import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  permitTemplateEditorKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplateEditor'
import {
  PermitTemplateVersionPeriodicChecklistResponse,
  IPermitTemplateVersionPeriodicChecklistResponse,
} from '@core/entities/permit-template-editor'

export const useGetPermitTemplateVersionPeriodicChecklists = (
  payload: RequestTypes['getPeriodicChecklists'],
  options: UseQueryOptions<
    IPermitTemplateVersionPeriodicChecklistResponse,
    RequestErrorType,
    PermitTemplateVersionPeriodicChecklistResponse
  >,
) => {
  return useQuery<
    IPermitTemplateVersionPeriodicChecklistResponse,
    RequestErrorType,
    PermitTemplateVersionPeriodicChecklistResponse
  >({
    select: PermitTemplateVersionPeriodicChecklistResponse.new,
    ...permitTemplateEditorKeys.periodicChecklists(payload),
    ...options,
  })
}
