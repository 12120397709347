import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'
import { localizedStrings } from '@core/strings'

export type AdvancedInspectionFilterOperators =
  | 'IS_DUE'
  | 'IS_DUE_IN_LESS_THAN'
  | 'HAS_NOT_BEEN_DONE'

export const advancedInspectionFilterOperatorsWithoutArguments: AdvancedInspectionFilterOperators[] =
  ['IS_DUE', 'HAS_NOT_BEEN_DONE']

export type AdvancedInspectionFilterValue = {
  operator: AdvancedInspectionFilterOperators
  value?: number
}

export const advancedInspectionFilterValueIsPartial = (
  value: AdvancedInspectionFilterValue,
) => {
  if (!value?.operator) {
    return true
  }

  if (
    advancedInspectionFilterOperatorsWithoutArguments.includes(value?.operator)
  ) {
    return false
  }

  if (!value?.value) {
    return true
  }

  return false
}

export const AdvancedInspectionFilterOptionOperators: {
  value: AdvancedInspectionFilterOperators
  label: string
}[] = [
  { value: 'IS_DUE', label: localizedStrings.isDue },
  { value: 'IS_DUE_IN_LESS_THAN', label: localizedStrings.isDueInLessThan },
  { value: 'HAS_NOT_BEEN_DONE', label: localizedStrings.hasNotBeenDone },
]

export type AdvancedInspectionFilterHookArguments =
  FilterHookArgumentsBase<AdvancedInspectionFilterValue>

export type AdvancedInspectionFilterInternalArguments<
  AdvancedInspectionFilterValue,
> = AdvancedInspectionFilterHookArguments &
  FilterInternalArgumentsBase<
    AdvancedInspectionFilterValue,
    'ADVANCED_INSPECTION'
  >

export const useAdvancedInspectionFilter = (
  filter: AdvancedInspectionFilterHookArguments,
): AdvancedInspectionFilterInternalArguments<AdvancedInspectionFilterValue> => {
  const unfocusedText = (
    filterValue: AdvancedInspectionFilterValue,
    { noLabel } = { noLabel: false },
  ) => {
    const label = noLabel ? '' : `${filter.label}: `
    const value = filterValue?.value
    const operator = filterValue?.operator

    const nothingSelectedText = filter.nothingSelectedText || 'No item selected'

    if (value || operator) {
      const operatorLabel = AdvancedInspectionFilterOptionOperators.find(
        (option) => option.value === operator,
      )?.label

      const filterValue = value ? ` ${value}` : ''

      return `${label}( ${operatorLabel} )${filterValue}`
    }

    return `${label}${nothingSelectedText}`
  }

  return {
    ...filter,
    type: 'ADVANCED_INSPECTION',
    unfocusedText,
    serialization: 'object',
  }
}
