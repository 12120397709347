import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  permitTemplateEditorKeys,
  RequestTypes,
} from '@core/react-query/features/templates/permitTemplateEditor'

export const useUpdatePermitTemplateVersionFinalSignOffs = (
  options?: UseMutationOptions<
    unknown,
    RequestErrorType,
    RequestTypes['updateFinalSignOffs']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...permitTemplateEditorKeys.updateFinalSignOffs,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: permitTemplateEditorKeys.getVersion({
          templateId: variables.templateId,
          versionId: variables.templateVersionId,
        }).queryKey,
      })
    },
    ...options,
  })
}
