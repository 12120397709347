import { plainToInstance } from 'class-transformer'
import { FilterBase } from '@core/entities/filters/FilterBase'
import { InspectionFilterOperatorTypes } from '../types'
import { IInspectionFilter } from './IInspectionFilter'

export class InspectionFilter extends FilterBase<IInspectionFilter> {
  propertyType: 'INSPECTION'
  operator: InspectionFilterOperatorTypes
  inspectionId: number

  public static new(payload: unknown): InspectionFilter {
    return plainToInstance(InspectionFilter, payload)
  }
}
