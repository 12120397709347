import { localizedStrings } from '@core/strings'
import React, { ChangeEvent, FunctionComponent, useRef, useState } from 'react' // we need this to make JSX compile
import Dropzone, { Accept } from 'react-dropzone'
import ReactCrop, { Crop } from 'react-image-crop'
import { Modal, ModalBody, ModalHeader } from '../modal/Modal'
import { ButtonText } from '../Button'
import { Flex } from '@fundamentals'
import UploadDropBox from './components/UploadDropBox'
import UploadThumbnailList from './components/UploadThumbnailList'
import { formatBytes } from './Upload.helpers'
import { useToast } from '@core/toast'
import { ProofType } from '@core/entities/template/TemplateQuestion'

type ComponentType = {
  value?: string | string[] | { type: ProofType; url: string }[]
  onChange: (value: string, file: File, filetype?: string) => void
  onClear?: (index: number, type?: ProofType) => void
  onSaveAnnotation?: (blob: Blob, url: string, index: number) => void
  width?: number
  preventRemove?: boolean
  cropImageStyle?: any
  accept?: Accept
  height?: number
  proofRequired?: boolean
  uploadText?: string
  uploadIcon?: string
  'data-test'?: string
  limitBytes?: number
  disabled?: boolean
  isDocument?: boolean
  proofRequiredText?: string
  allowImageAndPdf?: boolean
  allowAnnotation?: boolean
  optionalText?: string
}

const Upload: FunctionComponent<ComponentType> = ({
  preventRemove,
  'data-test': dataTest,
  width,
  proofRequired,
  proofRequiredText = localizedStrings.proofRequired,
  uploadText,
  uploadIcon,
  cropImageStyle,
  accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
  },
  height,
  limitBytes,
  value,
  onChange,
  onClear,
  disabled = false,
  isDocument = false,
  allowImageAndPdf = false,
  onSaveAnnotation,
  allowAnnotation = true,
  optionalText,
}) => {
  const [crop, setCrop] = useState<Crop>({})
  const [isOpen, setIsOpen] = useState<boolean>()
  const [file, setFile] = useState<File>()
  const [imageToCrop, setImageToCrop] = useState<string>()
  const imgRef = useRef<HTMLImageElement>(null)
  const toast = useToast()
  const onDismissClick = () => {
    setIsOpen(false)
  }

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles?.[0]
    if (limitBytes && limitBytes < file?.size) {
      toast.error(
        `${localizedStrings.fileSize}. Max-size: ${formatBytes(limitBytes)}`,
      )
      return
    }
    if (file) {
      if (!width && !height) {
        const url = URL.createObjectURL(file)
        onChange(url, file, file.type)
        return
      }
      setFile(file)
      const url = URL.createObjectURL(file)
      setImageToCrop(url)
      setIsOpen(true)
    } else {
      toast.error(localizedStrings.fileFormatInvalid)
    }
  }

  function getCroppedImg() {
    const image: HTMLImageElement = imgRef.current
    if (!image) {
      return
    }
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )
    // As Base64 string
    canvas.toBlob((s) => {
      const newFile: File = s as File
      // @ts-ignore
      newFile.name = file.name
      // @ts-ignore
      newFile.lastModified = file.lastModified
      const url = URL.createObjectURL(s)
      onChange(url, newFile)
      setIsOpen(false)
    }, 'image/jpeg')
    onDismissClick()
  }

  const clear = (index: number | any, type?: ProofType) => {
    onClear ? onClear(index, type) : onChange(null, null)
  }

  return (
    <>
      <Dropzone
        multiple={false}
        accept={
          allowImageAndPdf
            ? {
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg'],
                'image/jpg': ['.jpg'],
                'application/pdf': ['.pdf'],
              }
            : accept
        }
        onDrop={onDrop}
        disabled={disabled}
        maxFiles={4}
      >
        {({ getRootProps, getInputProps }) => (
          <div className='width__100'>
            {Constants.E2E && (
              <input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const file = e.target.files[0]
                  const reader = new FileReader()
                  reader.onload = function (event) {
                    const the_url = event.target.result
                    // @ts-ignore
                    onChange(event.target.result, file)
                  }
                  reader.readAsDataURL(file)
                }}
                type='file'
                data-test={dataTest}
              />
            )}

            <UploadThumbnailList
              clear={clear}
              isDocument={isDocument}
              value={value}
              preventRemove={preventRemove}
              onSaveAnnotation={onSaveAnnotation}
              readOnly={!allowAnnotation}
            />

            <Flex>
              <UploadDropBox
                disabled={disabled}
                icon={uploadIcon || 'fas fa-camera'}
                secondaryIcon={allowImageAndPdf ? 'fas fa-file-pdf' : null}
                rootProps={getRootProps()}
                inputProps={getInputProps()}
                proofRequired={proofRequired}
                proofRequiredText={proofRequiredText}
                proofOptionalText={optionalText}
              >
                {uploadText || localizedStrings.uploadProof}
              </UploadDropBox>
            </Flex>
          </div>
        )}
      </Dropzone>
      <Modal unmountOnClose isOpen={isOpen} toggle={onDismissClick}>
        <ModalHeader toggle={onDismissClick}>Edit Image</ModalHeader>
        <ModalBody>
          <div className='text-center'>
            <ReactCrop
              cropImageStyle={{
                margin: 'auto',
                minWidth: 200,
                ...(cropImageStyle || {}),
              }}
              onImageLoaded={(img) => {
                const aspect = width && height ? width / height : 1
                imgRef.current = img
                setCrop({
                  unit: '%',
                  aspect,
                  width: width,
                })
                return false
              }}
              src={imageToCrop}
              onChange={(newCrop) => {
                setCrop(newCrop)
              }}
              crop={crop}
            />
          </div>
          <div className='d-flex justify-content-end'>
            <div className='flex-row'>
              <ButtonText
                onClick={() => {
                  setIsOpen(false)
                }}
                className='btn-text--navy'
              >
                Cancel
              </ButtonText>

              <ButtonPrimary
                className='ml-3'
                onClick={() => {
                  getCroppedImg()
                }}
              >
                Save
              </ButtonPrimary>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Upload
