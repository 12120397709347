import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { checklistTemplateKeys } from '@core/react-query/features/templates/checklistTemplates'
import { ITemplateEditorChecklistTemplate } from '@core/entities/checklist/TemplateEditorChecklistTemplate'
import { TemplateEditorChecklistTemplate } from '@core/entities/checklist/TemplateEditorChecklistTemplate'

export const useGetChecklistTemplate = (
  payload: RequestTypes['getChecklistTemplate'],
  options?: UseQueryOptions<
    ITemplateEditorChecklistTemplate,
    string,
    TemplateEditorChecklistTemplate
  >,
) => {
  return useQuery({
    select: TemplateEditorChecklistTemplate.new,
    ...checklistTemplateKeys.getTemplate(payload),
    ...options,
  })
}
