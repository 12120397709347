import React from 'react'
import { localizedStrings } from '@core/strings'
import { Text } from '@fundamentals'
import { TimelineBlockBase, TimelineLinkBlock } from '@common/Timeline'
import { orderBy } from 'lodash'
import { PermissionsUpdatedData } from '@core/entities/audit/AuditResult/PermissionsUpdatedData'
import { PageUrls } from '@core/page-urls'
import { AuditResultScopeTypes } from '@core/entities/audit/AuditResult'

type ComponentProps = {
  permissionsUpdatedData: PermissionsUpdatedData
  scope: AuditResultScopeTypes
  organisationId: number
  projectId: number
}

export const TimelineGroupPermissionsBlocks: React.FC<ComponentProps> = ({
  permissionsUpdatedData,
  scope,
  organisationId,
  projectId,
}) => {
  const { oldPermissions, newPermissions, groupId, groupName } =
    permissionsUpdatedData
  return (
    <>
      {groupId && scope !== 'AUTH_GROUP' && (
        <TimelineLinkBlock
          title={localizedStrings.authorisationGroup}
          text={groupName}
          href={
            projectId
              ? PageUrls.projectAuthorisationGroup({
                  organisationId,
                  projectId,
                  groupId,
                  tab: 'Permissions',
                })
              : PageUrls.organisationAuthorisationGroup({
                  organisationId,
                  groupId,
                })
          }
        />
      )}
      {oldPermissions && (
        <TimelineBlockBase
          title={localizedStrings.oldPermissions + ` (${groupName})`}
        >
          {orderBy(
            oldPermissions,
            (permission) => permission.getOrder(),
            'asc',
          ).map(
            (permission) =>
              permission.enabled && (
                <Text key={permission.id}>{permission.getLabel()}</Text>
              ),
          )}
          {oldPermissions?.filter(({ enabled }) => enabled).length === 0 && (
            <Text>{localizedStrings.noPermissionsSet}</Text>
          )}
        </TimelineBlockBase>
      )}
      {newPermissions && (
        <TimelineBlockBase
          title={localizedStrings.newPermissions + ` (${groupName})`}
        >
          {orderBy(
            newPermissions,
            (permission) => permission.getOrder(),
            'asc',
          ).map(
            (permission) =>
              permission.enabled && (
                <Text key={permission.id}>{permission.getLabel()}</Text>
              ),
          )}
          {newPermissions?.filter(({ enabled }) => enabled).length === 0 && (
            <Text>{localizedStrings.noPermissionsSet}</Text>
          )}
        </TimelineBlockBase>
      )}
    </>
  )
}
