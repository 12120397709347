import React, { useEffect, useRef, useState } from 'react'
import ClickAwayArea from '@common/ClickAwayArea'
import { AdvancedFilterTypes, FilterMapInternal } from '@core/providers/filters'
import { UnfocusedFilter } from './UnfocusedFilter'
import { FilterStore } from '@core/providers/filters'
import { FocusedFilter } from './FocusedFilter'
import { FilterInternalArguments } from '@core/providers/filters'
import { Chip } from '@common/material'
import { styled } from '@mui/material'
import { experimental_sx as sx } from '@mui/system'

type FundamentalFilterProps = {
  filter: FilterInternalArguments
  store: FilterStore<FilterMapInternal>
  storeKey: string
  readOnly?: boolean
  isInModal?: boolean
}
const WrappableChip = styled(Chip)(
  sx({
    py: 0.5,
    px: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiChip-label': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      textOverflow: 'clip',
    },
  }),
)

export const FundamentalFilter: React.FC<FundamentalFilterProps> = ({
  filter,
  store,
  storeKey,
  readOnly,
  isInModal,
}) => {
  const isFocusedOnMount = store.latestAddedFilterKey === storeKey
  const isBooleanFilter = filter.type === 'BOOLEAN'
  const unfocusedFilterRef = useRef(null)

  // We focus new filters on mount, but not boolean filters
  const [isFocused, setIsFocused] = useState(
    isFocusedOnMount && !isBooleanFilter,
  )
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (isFocused && unfocusedFilterRef.current) {
      setAnchorEl(unfocusedFilterRef.current)
    }
  }, [isFocused])

  if (readOnly) {
    return (
      <WrappableChip
        size='small'
        label={filter.unfocusedText(store.values[storeKey] as any, {
          readOnly: true,
        })}
        color='info'
        variant='outlined'
        sx={{
          mr: '0.5rem',
          borderRadius: '4px',
          width: 'fit-content',
        }}
      />
    )
  }

  useEffect(() => {
    if (isBooleanFilter) {
      store.setFilterValues(
        // @ts-ignore
        {
          [storeKey]: true,
        },
        { partial: true },
      )
    }
  }, [isBooleanFilter])

  return (
    <>
      {(!isFocused ||
        AdvancedFilterTypes.includes(filter.type) ||
        isBooleanFilter) && (
        <UnfocusedFilter
          onClick={(e) => {
            if (isBooleanFilter) return

            setIsFocused(true)
            setAnchorEl(e.currentTarget)
          }}
          onRemove={() => store.removeFilter(storeKey)}
          // We use any because this component is not aware of the value type of filters
          text={filter.unfocusedText(store.values[storeKey] as any)}
          isPinned={filter.isPinned}
          dataTest={filter.dataTest}
          ref={unfocusedFilterRef}
        />
      )}
      {isFocused && (
        <ClickAwayArea
          onClickAway={() => setIsFocused(false)}
          isInModal={isInModal}
        >
          <FocusedFilter
            filter={filter}
            store={store}
            storeKey={storeKey}
            isFocusedOnMount={isFocusedOnMount}
            onUnfocused={() => setIsFocused(false)}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </ClickAwayArea>
      )}
    </>
  )
}
