import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  registerKeys,
  RequestTypes,
} from '@core/react-query/features/registers/registers/register'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  IRegisterSettingsAuthGroups,
  RegisterSettingsAuthGroups,
} from '@core/entities/register/RegisterSettingsAuthGroup'

const dataTransformer = (settings: IRegisterSettingsAuthGroups) =>
  RegisterSettingsAuthGroups.new(settings)

export const useGetRegisterSettingsAuthGroups = (
  payload: RequestTypes['getRegisterSettingsAuthGroups'],
  options?: UseQueryOptions<
    IRegisterSettingsAuthGroups,
    RequestErrorType,
    RegisterSettingsAuthGroups
  >,
) => {
  return useQuery({
    select: dataTransformer,
    ...registerKeys.registerSettingsAuthGroups(payload),
    ...options,
  })
}
