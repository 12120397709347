import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { ChecklistTemplateVersion } from '@core/entities/checklist/ChecklistTemplateVersion'
import { IListChecklist } from '@core/entities/checklist/ListChecklist/IListChecklist'
import { User } from '@core/entities/user'

export class ListChecklist extends Entity<IListChecklist> {
  id: number
  uuid: string
  shortUUID: string
  project: {
    id: number
    name: string
    timezone: string
  }
  @Type(() => ChecklistTemplateVersion)
  template: ChecklistTemplateVersion
  organisationId: number
  status: string
  createdOn: string
  @Type(() => User)
  createdBy: User
  locationName: string
  summary: string
  projectLocations: {
    locations: Array<{
      locationNodeId: string
      locationName: string
    }>
  }

  public static new(payload: unknown): ListChecklist {
    const entity = plainToInstance(ListChecklist, payload)

    return entity
  }
}
