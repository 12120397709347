import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Permit } from '@core/entities/permit/Permit'
import { useRequestPermitChanges } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'

interface ComponentType {
  permit: Permit
}

export const RequestPermitChangesModal: React.FC<ComponentType> = ({
  permit,
}) => {
  const requestPermitChanges = useRequestPermitChanges()
  const [requestChangeValue, setRequestChangeValue] = useState<string>(null)
  const [error, setError] = useState('')
  const { hideModal } = useModal()
  const toast = useToast()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.requestChanges}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <TextField
          data-test='text-field'
          label={localizedStrings.enterRequestChanges}
          multiline
          maxRows={4}
          value={requestChangeValue}
          onChange={(e) => setRequestChangeValue(e.target.value)}
          sx={{ mt: 1 }}
        />

        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          variant='outlined'
          key='cancel-button'
          onClick={hideModal}
          sx={{ mr: 2 }}
        >
          {localizedStrings.cancel}
        </Button>
        <Button
          data-test='confirm-button'
          variant='contained'
          key='request-changes-button'
          disabled={requestPermitChanges.isLoading || !requestChangeValue}
          onClick={() => {
            requestPermitChanges.mutate(
              {
                permitId: permit.id,
                comment: requestChangeValue,
                approvalId: permit.approvalReviews?.nextApprovalId,
              },
              {
                onSuccess: () => {
                  hideModal()
                  setRequestChangeValue(null)
                  toast.success(localizedStrings.permitChangesRequested)
                },
                onError: (e) => setError(e.message),
              },
            )
          }}
        >
          {localizedStrings.requestChanges}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
