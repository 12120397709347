import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { IPermit } from '@core/entities/permit/Permit'
import { permitsKeys } from '@core/react-query/features/permits'
import { useMutateQueryData } from '@core/react-query/hooks'
import { OfflineEntityType } from '@core/react-query/defaultMutations'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const getIssuePermitMutationOptions = (queryClient: QueryClient) => {
  const { updateInfiniteQueryItem, deleteInfiniteQueryItem } =
    useMutateQueryData(queryClient)
  return {
    ...permitKeys.issue,
    meta: {
      name: 'Issue Permit',
      offlineEnabled: true,
      variablesType: OfflineEntityType.PERMIT_SUBMISSION,
    },
    onSuccess: (permit, variables) => {
      queryClient.setQueryData(
        permitKeys.byId({ permitId: permit.id }).queryKey,
        permit,
      )
      if (variables.draftId) {
        deleteInfiniteQueryItem(
          permitsKeys.drafts({
            organisationId: permit.organisationId,
            projectIds: [permit.project.id],
          }).queryKey,
          variables.draftId,
        )
      }
      updateInfiniteQueryItem(
        permitsKeys
          .allPermits({
            organisationId: permit.organisationId,
            projectIds: [permit.project.id],
          })
          .queryKey.slice(0, -3),
        permit,
      )
    },
  }
}

export const useIssuePermit = () => {
  const queryClient = useQueryClient()
  return useMutation<IPermit, RequestErrorType, RequestTypes['issue']>(
    getIssuePermitMutationOptions(queryClient),
  )
}
