import { AllFilterTypes } from '@core/entities/filters'
import { createContext, MutableRefObject, useContext } from 'react'
import { Register } from '@core/entities/register/Register'
import { RegisterTemplate } from '@core/entities/register/RegisterTemplate'
import { FilterStore } from '@core/providers/filters'
import { RegisterItemColumn } from '@core/entities/register/RegisterItemColumn'
import { PageResponse } from '@core/entities/pagination'
import {
  RegisterItem,
  RegisterItemStatus,
} from '@core/entities/register/RegisterItem'
import { RegisterFilterScopes } from '@modules/registers/types'

type RegisterContext = {
  scope?: RegisterFilterScopes
  filters: AllFilterTypes[]
  setFilters: (filters: AllFilterTypes[]) => void
  filterStoreRef?: MutableRefObject<FilterStore<any>>
  viewId: number
  setViewId: (viewId: number) => void
  sorting: SortingState
  setSorting: (sorting: SortingState) => void
  registerId: number
  register?: Register
  registerTemplate?: RegisterTemplate
  registerItems?: PageResponse<RegisterItem>
  registerColumns: RegisterItemColumn[]
  term: string
  setTerm: (term: string) => void
  diagramSidebarReadOnly?: boolean
  setDiagramSidebarReadOnly?: (readOnly: boolean) => void
  setStatuses?: (statuses: RegisterItemStatus[]) => void
}

const initalState: RegisterContext = {
  scope: undefined,
  filters: [],
  setFilters: () => {},
  filterStoreRef: undefined,
  viewId: null,
  setViewId: () => {},
  sorting: [],
  setSorting: () => {},
  registerId: 0,
  register: undefined,
  registerTemplate: undefined,
  registerItems: undefined,
  registerColumns: [],
  term: '',
  setTerm: () => {},
  diagramSidebarReadOnly: true,
  setDiagramSidebarReadOnly: () => {},
  setStatuses: () => {},
}

export const RegisterContext = createContext(initalState)
export const useRegisterContext = () => useContext(RegisterContext)

export interface ColumnSort {
  desc: boolean
  id: string
}

export type SortingState = ColumnSort[]
