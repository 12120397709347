import { localizedStrings } from '@core/strings'
import React, { FC, useState } from 'react'
import { Collapse } from '@mui/material'
import { Box, Flex, Text } from '@fundamentals'
import { Button } from '@common/material'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material'
import { Image } from '@fundamentals'
import { useModal } from '@common/GlobalModal'
import { TimelineItemBaseLeggacy } from '@common/Timeline/leggacy/TimelineItemBaseLeggacy'

import { Permit } from '@core/entities/permit/Permit'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { WarningDetailsLeggacy } from '@common/Timeline/leggacy/WarningDetailsLeggacy'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'
import { SimpleMapModal, TimelineMap } from '@modules/geolocation'

export type PermitTimelineItemProps = {
  timelineItem: AuditResult
  permit: Permit
}

export const PermitTimelineItemLeggacy: FC<PermitTimelineItemProps> = ({
  timelineItem,
  permit,
}) => {
  const { info, signatureUrl, metadata } = timelineItem
  const timelineUserCoordinates = timelineItem.getCoordinates()

  const { showModal } = useModal()
  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleOnMapClick = () => {
    showModal(
      <SimpleMapModal
        projectId={permit.project.id}
        featurePoint={permit?.getW3wLocation()}
        featurePolygon={permit?.getGeolocationAreas()}
        userFeaturePoint={timelineUserCoordinates}
        featurePointAccuracyRadius={timelineItem?.getAuditPointAccuracyRadius()}
      />,
    )
  }

  return (
    <TimelineItemBaseLeggacy timelineItem={timelineItem}>
      {metadata?.transferReceiver && (
        <Text fontSize={12}>
          {`${localizedStrings.intendedRecipient}: ${metadata.transferReceiver.firstName} ${metadata.transferReceiver.lastName}`}
        </Text>
      )}

      {metadata?.permitRoleName && (
        <Text fontWeight='bold' fontSize={12}>
          {metadata?.permitRoleName}
        </Text>
      )}
      {(info ||
        signatureUrl ||
        timelineUserCoordinates ||
        metadata.warnings?.length > 0 ||
        metadata.mediaUrls.length > 0) &&
        !expanded && (
          <Box>
            <Button
              variant='text'
              size='medium'
              endIcon={<ExpandMoreIcon />}
              onClick={handleExpandClick}
              sx={{ mt: 2, ml: '-8px', pb: 0, opacity: expanded ? 0 : 1 }}
            >
              {localizedStrings.showInfo}
            </Button>
          </Box>
        )}
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {timelineItem.metadata.distanceToPermitInMeters && (
          <Text mt={1}>{`${localizedStrings.distanceToPermit}: ${Math.round(
            timelineItem.metadata.distanceToPermitInMeters,
          )} m`}</Text>
        )}

        {timelineUserCoordinates && (
          <TimelineMap
            projectId={permit.project.id}
            featurePoint={permit?.getW3wLocation()}
            featurePolygon={permit?.getGeolocationAreas()}
            userFeaturePoint={timelineUserCoordinates}
            featurePointAccuracyRadius={timelineItem?.getAuditPointAccuracyRadius()}
            onClick={handleOnMapClick}
            style={{ height: '600px' }}
          />
        )}

        {timelineItem.metadata.mediaUrlsWithType?.length > 0 && (
          <>
            <Text
              sx={{ fontSize: 12, mt: 2 }}
              color='text.secondary'
              gutterBottom
            >
              {localizedStrings.proofs}
            </Text>
            <Flex flexWrap='wrap' my={2}>
              <AttachmentInput
                size='small'
                inputProps={{
                  value: timelineItem.metadata.mediaUrlsWithType,
                }}
                readOnly
                borderLess
              />
            </Flex>
          </>
        )}

        {signatureUrl && (
          <>
            <Text
              sx={{ fontSize: 12, mt: 2 }}
              color='text.secondary'
              gutterBottom
            >
              {localizedStrings.signature}
            </Text>
            <Image mt={2} src={signatureUrl} width={170} />
          </>
        )}

        {!!metadata?.warnings?.length &&
          metadata.warnings.map((warning) => (
            <WarningDetailsLeggacy
              key={warning.id}
              warning={warning}
              permit={permit}
            />
          ))}

        <Box>
          <Button
            variant='text'
            size='medium'
            endIcon={<ExpandLessIcon />}
            onClick={handleExpandClick}
            sx={{ mt: 2, ml: '-8px', pb: 0, opacity: !expanded ? 0 : 1 }}
          >
            {localizedStrings.hideAll}
          </Button>
        </Box>
      </Collapse>
    </TimelineItemBaseLeggacy>
  )
}
