import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  checklistTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'
import {
  ITemplateEditorChecklistTemplateVersion,
  TemplateEditorChecklistTemplateVersion,
} from '@core/entities/checklist/TemplateEditorChecklistTemplateVersion'

const projectChecklistTransform = (
  data: ITemplateEditorChecklistTemplateVersion,
) => TemplateEditorChecklistTemplateVersion.new(data)

export const useGetProjectChecklistTemplateVersion = (
  payload: RequestTypes['getProjectChecklistTemplateVersion'],
  options?: UseQueryOptions<
    ITemplateEditorChecklistTemplateVersion,
    string,
    TemplateEditorChecklistTemplateVersion
  >,
) => {
  return useQuery<
    ITemplateEditorChecklistTemplateVersion,
    string,
    TemplateEditorChecklistTemplateVersion
  >({
    select: projectChecklistTransform,
    ...checklistTemplateKeys.getProjectVersion(payload),
    ...options,
  })
}
