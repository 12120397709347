import { MenuItem, TextField } from '@common/material'
import { Flex } from '@fundamentals'
import {
  AdvancedTextInputFilterInternalArguments,
  AdvancedTextInputFilterValue,
  advancedTextInputFilterOptionConditions,
  advancedTextInputFilterOperatorsWithoutArguments,
} from '@core/providers/filters'
import { AdvancedFilterPopover } from './AdvancedFilterPopover'

type AdvancedTextFilterProps = {
  filter: AdvancedTextInputFilterInternalArguments
  onChange: ({ value, operator }: AdvancedTextInputFilterValue) => void
  storeValue: AdvancedTextInputFilterValue
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (el: HTMLButtonElement | null) => void
}

export const AdvancedTextFilter: React.FC<AdvancedTextFilterProps> = ({
  onChange,
  storeValue,
  filter,
  anchorEl,
  setAnchorEl,
}) => {
  const operator =
    storeValue?.operator ?? advancedTextInputFilterOptionConditions[0].value
  const hideArguments =
    advancedTextInputFilterOperatorsWithoutArguments.includes(operator)

  return (
    <AdvancedFilterPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <Flex flexDirection='column' p={2} pr={1}>
        <TextField
          value={operator}
          select
          size='small'
          label='Select filter operator'
          sx={{
            pr: 1,
            mb: 2,
            minWidth: 200,
          }}
          SelectProps={{
            onChange(e) {
              onChange({
                value: storeValue?.value,
                operator: e.target
                  .value as AdvancedTextInputFilterValue['operator'],
              })
            },
          }}
        >
          {advancedTextInputFilterOptionConditions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {!hideArguments && (
          <TextField
            size='small'
            InputLabelProps={{ shrink: true }}
            label={filter.label}
            value={storeValue?.value}
            onChange={(e) =>
              onChange({
                value: e.target
                  .value as unknown as AdvancedTextInputFilterValue['value'],
                operator: storeValue?.operator
                  ? storeValue?.operator
                  : advancedTextInputFilterOptionConditions[0].value,
              })
            }
            data-test={filter?.dataTest}
          />
        )}
      </Flex>
    </AdvancedFilterPopover>
  )
}
