import { IPermitQuestionGroup } from '@core/entities/permit/PermitQuestionGroup'
import React from 'react'
import { MultipleChoice } from '@common/MultipleChoice'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import { useHtml } from '@common/hooks'
import { Box } from '@fundamentals'

type ConditionalQuestionGroupProps = {
  inputProps?: Partial<ControllerRenderProps>
  conditionalQuestionGroup: IPermitQuestionGroup['conditionalQuestionGroup']
  readOnly?: boolean
}

const ConditionalQuestionGroup: React.FC<ConditionalQuestionGroupProps> = ({
  conditionalQuestionGroup,
  inputProps: {
    value = conditionalQuestionGroup.submissionText,
    onChange,
    onBlur,
  } = {},
  readOnly,
}) => {
  const { html } = useHtml(conditionalQuestionGroup?.question)

  return (
    <>
      <Box
        dangerouslySetInnerHTML={{ __html: html }}
        color='grey.600'
        data-test='body'
        sx={{ '& p': { mb: 1, fontSize: '14px' } }}
      />
      <MultipleChoice
        allowUnselect
        options={[
          {
            label: conditionalQuestionGroup.showIfAnswerIs,
            value: conditionalQuestionGroup.showIfAnswerIs,
          },
          {
            label: conditionalQuestionGroup.hideIfAnswerIs,
            value: conditionalQuestionGroup.hideIfAnswerIs,
          },
        ]}
        selected={[value]}
        onSelect={(selected) => {
          onChange(selected?.[0] || null)
          onBlur()
        }}
        mb={conditionalQuestionGroup?.showIfAnswerIs === value && 2}
        disabled={readOnly}
        showCheckbox
        dataTest='conditional-question-group'
      />
    </>
  )
}

export { ConditionalQuestionGroup }
