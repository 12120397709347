import { RequestTypes } from '@core/react-query/features/templates/permitTemplateEditor'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { permitTemplateEditorKeys } from '@core/react-query/features/templates/permitTemplateEditor'
import {
  IPermitTemplateVersion,
  TemplateEditorPermitTemplateVersion,
} from '@core/entities/permit-template-editor'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useGetPermitTemplateVersion = (
  payload: RequestTypes['getPermitTemplateVersion'],
  options?: UseQueryOptions<
    IPermitTemplateVersion,
    RequestErrorType,
    TemplateEditorPermitTemplateVersion
  >,
) => {
  return useQuery({
    select: TemplateEditorPermitTemplateVersion.new,
    ...permitTemplateEditorKeys.getVersion(payload),
    ...options,
  })
}
