import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { LinkedProcessItemResult } from '@core/entities/linkedProcesses'
import {
  checklistKeys,
  RequestTypes,
} from '@core/react-query/features/checklists/checklist'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const checklistLinkedProcessTransform: (
  data,
) => Array<LinkedProcessItemResult> = (data) => {
  return data?.linkedProcessItemResults.map((linkedProcessItemResult) => {
    return LinkedProcessItemResult.new(linkedProcessItemResult)
  })
}

export const useGetChecklistLinkedProcesses = (
  payload: RequestTypes['getChecklistLinkedProcesses'],
  options?: UseQueryOptions<LinkedProcessItemResult[], RequestErrorType>,
) => {
  return useQuery({
    select: checklistLinkedProcessTransform,
    ...checklistKeys.byId(payload)._ctx.linkedProcesses,
    ...options,
  })
}
