import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RequestTypes,
  projectPlansKeys,
} from '@core/react-query/features/projects/plans'

import { useUploadMedia } from '@core/react-query/features/media'
import { projectLocationsKeys } from '@core/react-query/features/projects/locations'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'

export const useUploadProjectPlan = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['uploadProjectPlan']
  >,
) => {
  const queryClient = useQueryClient()
  const uploadMedia = useUploadMedia()
  return useMutation({
    mutationFn: async (variables) => {
      let planKey: string
      try {
        planKey = await uploadMedia.mutateAsync(variables)
      } catch (e) {
        throw e?.message || e
      }

      let planImageKey: string
      if (variables.imageFile) {
        try {
          planImageKey = await uploadMedia.mutateAsync({
            file: variables.imageFile,
            folderName: variables.folderName,
          })
        } catch (e) {
          throw e?.message || e
        }
      }

      const { data } = await APIAxiosInstance.post(
        `projects/${variables.projectId}/locations/nodes/${variables.nodeId}/plans/upload`,
        {
          locationPlanDocumentKey: planKey,
          locationPlanImageKey: planImageKey,
          pdfHeightInPoints: variables.pdfHeightInPoints,
          pdfWidthInPoints: variables.pdfWidthInPoints,
          imageWidthInPixels: variables.imageWidthInPixels,
        },
      )

      return data
    },
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: projectLocationsKeys._def,
      })
      queryClient.invalidateQueries({
        queryKey: projectPlansKeys._def,
      })
    },
    ...options,
  })
}
