import { FC } from 'react'
import { FeatureCollection, Polygon } from 'geojson'
import { FillPaint, LinePaint } from 'mapbox-gl'
import {
  existingZoneFillStyleWeb,
  existingZoneLineStyleWeb,
} from '@core/utils/map-styling'
import { Layer, Source } from 'react-map-gl'

/**
 * GeoPolygonLayer component displays a collection of polygonal zones on a map using GeoJSON data.
 *
 * Props:
 * - `key`: Unique key to identify the source, ensuring it re-renders as necessary.
 * - `id`: Unique identifier for the layer to avoid conflicts in map rendering.
 * - `promoteId`: Field used as a unique identifier for each feature in the data source.
 * - `fillLayerStyle`: Optional custom styling for the polygon fill layer; defaults to `existingZoneFillStyleWeb`.
 * - `lineLayerStyle`: Optional custom styling for the polygon line layer; defaults to `existingZoneLineStyleWeb`.
 * - `featureCollection`: GeoJSON feature collection containing polygonal data for multiple zones.
 */
interface GeoPolygonLayerProps {
  key: string | number
  id: string | number
  promoteId: string
  fillLayerStyle?: FillPaint
  lineLayerStyle?: LinePaint
  featureCollection: FeatureCollection<Polygon>
}

export const GeoPolygonLayer: FC<GeoPolygonLayerProps> = ({
  key,
  id,
  promoteId,
  fillLayerStyle = existingZoneFillStyleWeb() as FillPaint,
  lineLayerStyle = existingZoneLineStyleWeb() as LinePaint,
  featureCollection,
}) => {
  return (
    <Source
      key={`${key}-shape-source`}
      id={`${id}-shape-source`}
      type='geojson'
      promoteId={promoteId}
      data={featureCollection}
    >
      {/* Fill layer for the polygons, using the specified or default fill style */}
      <Layer id={`${id}-fill-layer`} type={'fill'} paint={fillLayerStyle} />

      {/* Line layer for the polygon borders, using the specified or default line style */}
      <Layer id={`${id}-line-layer`} type={'line'} paint={lineLayerStyle} />
    </Source>
  )
}
