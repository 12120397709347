import {
  authGroupsKeys,
  RequestTypes,
} from '@core/react-query/features/authGroups'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { PageResponse } from '@core/entities/pagination'
import { GroupMember } from '@core/entities/Groups/AuthGroups/types'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useGetAuthGroupMembers = (
  payload: RequestTypes['getAuthGroupMembers'],
  options?: UseQueryOptions<PageResponse<GroupMember>, RequestErrorType>,
) => {
  return useQuery({
    ...authGroupsKeys.byId(payload)._ctx.getMembers(payload),
    enabled: !!payload.groupId,
    retry: 3,
    ...options,
  })
}
