import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { authKeys } from '@core/react-query/features/auth'

export const useResetPassword = (
  options?: UseMutationOptions<unknown, string, RequestTypes['resetPassword']>,
) => {
  return useMutation({
    mutationFn: (variables) =>
      authKeys.resetPassword(variables).queryFn(undefined),
    ...options,
  })
}
