import { useDeepCompareCallback } from 'use-deep-compare'
import moment from 'moment'
import {
  FilterHookArgumentsBase,
  FilterInternalArgumentsBase,
} from '@core/providers/filters'

export type DateRangeFilterValue = { startTime: string; endTime: string }

export type DateRangeFilterHookArguments =
  FilterHookArgumentsBase<DateRangeFilterValue> & {
    UTC?: boolean
  }

export type DateRangeFilterInternalArguments = DateRangeFilterHookArguments &
  FilterInternalArgumentsBase<DateRangeFilterValue, 'DATE_RANGE'>

export const useDateRangeFilter: (
  filter: DateRangeFilterHookArguments,
) => DateRangeFilterInternalArguments = (filter) => {
  const unfocusedText = useDeepCompareCallback(
    (value: DateRangeFilterValue) => {
      const dateString = (date) => moment(date).format('Do MMM YYYY')
      if (!value?.startTime && !value?.endTime) {
        return `${filter.label}: no date selected`
      }

      const startDateString = value?.startTime
        ? dateString(value?.startTime)
        : ''
      const endDateString = value?.endTime
        ? ` - ${dateString(value?.endTime)}`
        : ''

      return `Date: ${startDateString}${endDateString}`
    },
    [filter],
  )

  return {
    type: 'DATE_RANGE',
    unfocusedText,
    serialization: 'object',
    ...filter,
  }
}
