import { localizedStrings } from '@core/strings'
import React, { useCallback, useState } from 'react'
import { Text, Flex } from '@fundamentals'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
  MenuItem,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Autocomplete } from '@mui/material'
import { SignatureEdit } from '@common/Signature'
import { ErrorMessage } from 'components/Messages'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { useGetProject } from '@core/react-query/features/projects/project/hooks/useGetProject'
import { Permit } from '@core/entities/permit/Permit'
import { useTransferPermit } from '@core/react-query/features/permits/permit'
import { ModalDialogContainer } from '@common/modal'
import { useToast } from '@core/toast'
import { useProjectUsers } from '@core/react-query/features/projects/project'
import { useDebouncedValue } from '@core/utils/useDebouncedValue'

interface ComponentType {
  permit: Permit
}

export const TransferPermitModal: React.FC<ComponentType> = ({ permit }) => {
  const transferPermit = useTransferPermit()
  const { data: profile } = useGetProfile()
  const projectId = permit.project.id
  const { data: project } = useGetProject({ projectId })
  const { hideModal } = useModal()

  const [transferReason, setTransferReason] = useState<string>('')
  const [error, setError] = useState('')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const params = useDebouncedValue({
    projectId,
    term: searchTerm,
    authGroupsIds: permit?.permitInitiationAuthGroupsIds,
    memberStatus: 'ACTIVE' as const,
  })
  const { data: projectUsers } = useProjectUsers(params, {
    onSuccess: (data) => {
      setOptions(
        data?.pages?.[0]?.content
          .filter((user) => user.id !== permit.permitHolder.id)
          .map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
            email: user.email,
            id: user.id,
            company: user.company,
          })),
      )
    },
    enabled: !!project,
  })

  const toast = useToast()

  type SelectedUser = {
    value: number
    label: string
    email: string
    id: number
    company: string
  }
  const [selectedUser, setSelectedUser] = useState<SelectedUser>()

  const [options, setOptions] = useState<SelectedUser[]>([])

  const onSubmit = () => {
    transferPermit.mutate(
      {
        permitId: permit.id,
        receiverId: selectedUser.id,
        message: transferReason,
      },
      {
        onError: (e) => {
          setError(e.message)
          toast.error(e.message)
        },
        onSuccess: () => {
          toast.success(localizedStrings.transferInitiated)
          hideModal()
        },
      },
    )
  }

  const debouncedSearch = useCallback(
    _.debounce((term) => setSearchTerm(term), 300),
    [],
  )

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.transferPermit}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <Autocomplete
          disablePortal
          options={options}
          onChange={(e, value) => {
            setSelectedUser(value as SelectedUser)
          }}
          onInputChange={(e, value) => debouncedSearch(value)}
          sx={{ width: 300, mt: 1 }}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              key={option.id}
              data-test={`permit-transfer-user-select-${props['data-option-index']}`}
            >
              <Flex flexDirection='column'>
                <Text>{option.label}</Text>
                <Text fontSize={12} color='text.secondary'>
                  {option.email}
                </Text>
                <Text fontSize={12} color='text.secondary'>
                  {option.company}
                </Text>
              </Flex>
            </MenuItem>
          )}
          // Disables client side filter since the back-end returns filtered results
          filterOptions={(options, state) => options}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={localizedStrings.searchByNameOrEmail}
              label={localizedStrings.transferTo}
              InputLabelProps={{ shrink: true }}
            />
          )}
          noOptionsText={localizedStrings.pleaseSeachByNameOrEmail}
          data-test='permit-transfer-user-select'
        />
        <TextField
          name='transfer-description'
          disabled={!selectedUser}
          label={localizedStrings.transferDetails}
          placeholder={localizedStrings.reasonForTransferDots}
          multiline
          rows={4}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setTransferReason(e.target.value)}
          value={transferReason}
          sx={{ mt: 4 }}
        />
        <Text pt={4} fontSize={12} color='text.secondary'>
          {localizedStrings.acceptPermitTransferAcknowledge}
        </Text>
        <SignatureEdit readonly value={profile?.signatureUrl} />
        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={onSubmit}
          disabled={!(transferReason?.length && selectedUser?.label?.length)}
          data-test='transfer-permit-confirm'
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
