// import propTypes from 'prop-types';
import RCSwitch from 'rc-switch'
import React, { FunctionComponent } from 'react'
import { Box, BoxProps } from '@fundamentals'

type ComponentType = BoxProps & {
  readOnly?: boolean
  checked: boolean
  'data-test'?: string
  onChange: (v: boolean) => void
}

const Switch: FunctionComponent<ComponentType> = ({
  'data-test': dataTest,
  checked,
  onChange,
  readOnly,
  ...props
}) => {
  return (
    <Box {...props}>
      <RCSwitch
        disabled={!!readOnly}
        data-test={dataTest}
        checked={checked}
        onClick={() => onChange(!checked)}
      />
    </Box>
  )
}

export default Switch
