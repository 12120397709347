import { localizedStrings } from '@core/strings'
import React from 'react'
import { useModal } from '@common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import { Text } from '@fundamentals'
import ClearIcon from '@mui/icons-material/Clear'
import { ProjectPlanArea } from '@core/entities/project/ProjectPlanArea'
import { TableSecondaryText } from '@common/Table'
import { ModalDialogContainer } from '@common/modal'

interface ComponentType {
  newAreas: ProjectPlanArea[]
  onSuccess: () => void
}

export const SaveSelectedProjectPlanZonesModal: React.FC<ComponentType> = ({
  newAreas,
  onSuccess,
}) => {
  const { hideModal } = useModal()
  const isMoreThanOneArea = newAreas.length > 1

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {`${newAreas?.length} Permit zone${
          isMoreThanOneArea || !newAreas?.length ? 's' : ''
        } selected`}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        <TableSecondaryText>
          {localizedStrings.thisPermitWillBeAppliedToSelectedZone}
        </TableSecondaryText>
        <Text>{localizedStrings.saveSelectedProjectPlanAreaDescription}</Text>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            hideModal()
            onSuccess()
          }}
        >
          {localizedStrings.save}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
