import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const usePermitPdfDownloadUrl = (
  options?: UseMutationOptions<
    { urlGET: string; urlDownload: string; expiryInMinutes: number },
    RequestErrorType,
    RequestTypes['getPermitPdfDownloadUrl']
  >,
) => {
  return useMutation({
    ...permitKeys.pdfDownloadUrl,
    ...options,
  })
}
