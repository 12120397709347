import { PermitStatus } from '@core/entities/permit/PermitBase'
import React from 'react'
import Countdown from '@core/Countdown'
import { Theme, Tooltip } from '@mui/material'
import { Chip } from '@common/material'
import {
  CheckCircleOutlined,
  ErrorOutlineOutlined,
  HighlightOff,
  TimelapseOutlined,
  PlayCircleFilledWhiteOutlined,
  PauseCircleOutline,
} from '@mui/icons-material'
import { SxProps } from '@mui/system'

export const usePermitChips = () => {
  const primaryChip = (
    permit: { status: PermitStatus; endsOn: string },
    chipProps?: {
      sx?: SxProps<Theme>
      dataTest?: string
      size?: 'small' | 'medium'
    },
  ) => {
    // We want to consider the EXTENSION_REQUESTED status as open for the purpose of the primary chip
    const permitStatus =
      permit?.status === 'EXTENSION_REQUESTED' ? 'OPEN' : permit?.status
    const primaryChipLabel = () => {
      return (
        <>
          <span>{Utils.getTextForStatus(permitStatus)}</span>
          {permitStatus === 'OPEN' && (
            <span>
              <> – </>
              <span>
                <Countdown pollFrequency={400} value={permit?.endsOn} />
              </span>
            </span>
          )}
        </>
      )
    }
    return (
      <Tooltip title={primaryChipLabel()}>
        <Chip
          data-test={chipProps?.dataTest}
          color={Utils.getPermitStatusColor(permit?.status)}
          label={primaryChipLabel()}
          icon={statusChipIcon(permit?.status)}
          sx={chipProps?.sx}
          size={chipProps?.size}
        />
      </Tooltip>
    )
  }

  return {
    primaryChip,
  }
}

const statusChipIcon = (
  status: PermitStatus,
): React.ReactElement<any, string | React.JSXElementConstructor<any>> => {
  switch (status) {
    case 'REQUESTED':
    case 'AWAITING_FINAL_SIGN_OFF':
    case 'AWAITING_CHECKS':
    case 'SENT_TO_PERMIT_HOLDER':
    case 'SUSPENDED_RESUME_REQUESTED':
      return <TimelapseOutlined />

    case 'ISSUED':
      return <TimelapseOutlined />

    case 'CANCELLED':
    case 'DISCARDED':
    case 'REJECTED':
      return <HighlightOff />

    case 'EXPIRED':
      return <ErrorOutlineOutlined />

    case 'CLOSED':
      return <CheckCircleOutlined />

    case 'OPEN':
    case 'EXTENSION_REQUESTED':
      return <PlayCircleFilledWhiteOutlined />

    case 'SUSPENDED':
      return <PauseCircleOutline />

    default:
      return null
  }
}
