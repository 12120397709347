import { RequestTypes } from '../requestTypes'
import { authKeys } from '..'
import { IdentityProvider } from '@core/entities/auth'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
export const useGetIdentityProvider = (
  payload: RequestTypes['getIdentityProvider'],
  options?: UseQueryOptions<IdentityProvider, string>,
) => {
  return useQuery({
    ...authKeys.getIdentityProvider(payload),
    networkMode: 'always',
    ...options,
  })
}
