import { localizedStrings } from '@core/strings'
import React, { ReactNode, useState } from 'react'
import { DialogTitle, IconButton } from '@mui/material'
import { useModal } from '@common/GlobalModal'
import PspdfkitComponent from 'components/modal/pdf/PspdfkitComponent'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, Flex } from '@fundamentals'
import { Button } from '@common/material'
import { ModalDialogContainer } from '@common/modal/ModalDialogContainer'

interface PdfModal {
  title: string
  previewUrl: string
  footerActions?: ReactNode
  readOnly?: boolean
  onAnnotate?: (blob: Blob, url: string) => void
}

export const PdfModal: React.FC<PdfModal> = ({
  title,
  previewUrl,
  footerActions,
  readOnly,
  onAnnotate,
}) => {
  const { hideModal } = useModal()

  const [annotations, setAnnotations] = useState({ blob: null, url: null })

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '80%', flex: 1 } }}>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {title}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <Box width='100%' height='80vh' mb={1.5}>
        {previewUrl && (
          <Flex width='100%' height='100%'>
            <PspdfkitComponent
              document={previewUrl}
              readOnly={readOnly}
              onAnnotate={(blob, url) => setAnnotations({ blob, url })}
            />
          </Flex>
        )}
      </Box>

      {(!readOnly || !!footerActions) && (
        <Flex justifyContent='flex-end' sx={{ pb: 2, pr: 2, pt: 0 }}>
          {footerActions}
          {!readOnly && (
            <Button
              variant='contained'
              onClick={() => onAnnotate(annotations.blob, annotations.url)}
              disabled={!annotations.url}
            >
              {localizedStrings.saveAnnotations}
            </Button>
          )}
        </Flex>
      )}
    </ModalDialogContainer>
  )
}
