import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { Flex, Box } from '@fundamentals'
import { SignatureEdit } from 'webapp/components/common/Signature'
import DatePicker from 'components/DatePicker'
import { ErrorMessage } from 'components/Messages'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import { useModal } from '@common/GlobalModal'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { useApprovePermit } from '@core/react-query/features/permits/permit'
import { ITemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { ModalDialogContainer } from '@common/modal'
import NominateApproverModal from '@modules/createPermit/modals/NominateApproverModal'

interface ComponentType {
  permit: Permit
  approvalAnswers?: ITemplateFormAnswers
  onApprove?: () => void
}

export const ApprovePermitModal: React.FC<ComponentType> = ({
  permit,
  approvalAnswers,
  onApprove,
}) => {
  const { hideModal, showModal } = useModal()
  const { mutate, isLoading } = useApprovePermit()

  const { data: profile } = useGetProfile()
  const [error, setError] = useState('')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  const approvePermit = () => {
    const answers = approvalAnswers?.answers?.map((questionGroup) => {
      const questionGroupData = questionGroup?.data?.map((question) => {
        const submissionProofs = [
          ...(question?.submissionProofs || []),
          // @ts-ignore
          ...(question?.submissionProofFiles || []),
        ]

        const submissionDocuments = [
          ...(question?.submissionDocuments || []),
          // @ts-ignore
          ...(question?.submissionProofDocuments || []),
        ]

        return {
          ...question,
          submissionProofs,
          submissionDocuments,
        }
      })
      return { ...questionGroup, data: questionGroupData }
    })
    mutate(
      {
        permitId: permit.id,
        approvalId: permit.approvalReviews?.nextApprovalId,
        startsOn: startDate ? startDate : permit.startsOn,
        endsOn: endDate ? endDate : permit.endsOn,
        templateId: permit.template.id,
        approvalAnswers: { answers },
      },
      {
        onSuccess: (res) => {
          hideModal()

          const permit = Permit.new(res)
          const suggestedApprovers =
            res?.approvalReviews?.nominatedApprovers?.suggestedApprovers

          if (suggestedApprovers?.length) {
            showModal(
              <NominateApproverModal
                onDismiss={() => {
                  onApprove && onApprove()
                  hideModal()
                }}
                permit={permit}
                onNominate={() => {
                  onApprove && onApprove()
                  hideModal()
                }}
              />,
            )
          } else {
            onApprove && onApprove()
          }
        },
        onError: (e) => {
          setError(e.message)
        },
      },
    )
  }

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.issuePermit}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {!permit.template?.calculateDurationBasedOnDays && (
          <Flex>
            <Box pr={2}>
              <label className='d-block'>{localizedStrings.startDate}</label>
              <DatePicker
                name='startDate'
                showTimeSelect
                timeIntervals={15}
                onChange={(v) => setStartDate(moment(v).toISOString())}
                value={startDate ? new Date() : new Date(permit.startsOn)}
                dateFormat='do MMM yyyy, h:mm aa'
                selected={startDate ? new Date() : new Date(permit.startsOn)}
              />
            </Box>
            <Box>
              <label className='d-block'>{localizedStrings.endDate}</label>
              <DatePicker
                name='endDate'
                showTimeSelect
                timeIntervals={15}
                onChange={(v) => setEndDate(moment(v).toISOString())}
                value={endDate ? new Date() : new Date(permit.endsOn)}
                dateFormat='do MMM yyyy, h:mm aa'
                selected={endDate ? new Date() : new Date(permit.endsOn)}
              />
            </Box>
          </Flex>
        )}

        <SignatureEdit readonly value={profile?.signatureUrl} />
        <ErrorMessage>{error}</ErrorMessage>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          onClick={approvePermit}
          disabled={isLoading}
          data-test='jsConfirmIssue'
        >
          {localizedStrings.approve}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
