import {
  authGroupsKeys,
  RequestTypes,
} from '@core/react-query/features/authGroups'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const useDeleteAuthGroupMember = (
  options?: UseMutationOptions<
    any,
    RequestErrorType,
    RequestTypes['deleteAuthGroupMember']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...authGroupsKeys.deleteAuthGroupMember,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        authGroupsKeys.byId({ groupId: variables.groupId }).queryKey,
      )
    },
    ...options,
  })
}
