import React from 'react'
import { Image } from '@fundamentals'
import { localizedStrings } from '@core/strings'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { TimelineBlockBase } from '@common/Timeline'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineSignatureBlock: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  return (
    <TimelineBlockBase title={localizedStrings.signature}>
      <Image mt={1} src={auditResult.signatureUrl} width={170} />
    </TimelineBlockBase>
  )
}
