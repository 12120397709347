import React from 'react'
import { TimelineLeggacy, ChecklistTimelineItemLeggacy } from '@common/Timeline'
import { useChecklistTimeline } from '@core/react-query/features/checklists/checklist'
import { Timeline } from '@common/Timeline'
import useFeatureFlags from '@core/providers/useFeatureFlags'

type ChecklistTimelineTabProps = {
  checklistId: number
}

export const ChecklistTimelineTab: React.FC<ChecklistTimelineTabProps> = ({
  checklistId,
}) => {
  const { flags } = useFeatureFlags(['timeline_redesign'])
  const {
    data: timelineData,
    isFetching: checklistTimelineLoading,
    error: checklistTimelineError,
  } = useChecklistTimeline({ checklistId })

  return (
    <>
      <ErrorMessage>{checklistTimelineError}</ErrorMessage>
      {flags.timeline_redesign ? (
        <Timeline
          isLoading={checklistTimelineLoading}
          auditResults={timelineData}
          scope='CHECKLIST'
        />
      ) : (
        <TimelineLeggacy loading={checklistTimelineLoading}>
          {timelineData?.map((timelineItem, index) => (
            <ChecklistTimelineItemLeggacy
              key={index}
              timelineItem={timelineItem}
              checklistId={checklistId}
            />
          ))}
        </TimelineLeggacy>
      )}
    </>
  )
}
