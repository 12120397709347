import React from 'react'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { localizedStrings } from '@core/strings'
import { Image, Text } from '@fundamentals'
import turf from 'turf'
import { useModal } from '@common/GlobalModal'
import { useDeepCompareMemo } from 'use-deep-compare'
import { Marker } from 'react-map-gl'
import { TimelineBlockBase } from '@common/Timeline'
import { usePermitById } from '@core/react-query/features/permits/permit'
import { TimelineMap, TimelineMapModal } from '@modules/geolocation'

type ComponentProps = {
  auditResult: AuditResult
  auditResults: Array<AuditResult>
}

export const TimelinePermitLocationBlock: React.FC<ComponentProps> = ({
  auditResult,
  auditResults,
}) => {
  const { data: permit } = usePermitById(
    { permitId: auditResult.permit?.id },
    {
      refetchOnMount: false,
      enabled: !!auditResult.permit?.id,
    },
  )
  const { showModal } = useModal()
  const timelineCoordinates = auditResult.getCoordinates()
  const auditPointAccuracyRadius = auditResult?.getAuditPointAccuracyRadius()
  const auditEventPoint = timelineCoordinates
    ? turf.point(timelineCoordinates.geometry.coordinates)
    : null

  const locationMarkers = useDeepCompareMemo(() => {
    const markers = []
    if (timelineCoordinates) {
      const [longitude, latitude] = timelineCoordinates.geometry.coordinates
      markers.push(
        <Marker
          // VERY IMPORTANT: this makes the bottom point of the marker be exactly at the coordinates
          anchor='bottom'
          latitude={latitude}
          longitude={longitude}
        >
          <Image
            alt='User marker'
            src='/images/icons/markers/user_marker_blue.svg'
          />
        </Marker>,
      )
    }
    return markers
  }, [timelineCoordinates])

  const handleMapClick = () => {
    showModal(
      <TimelineMapModal
        projectId={permit.project.id}
        featurePoint={permit.getW3wLocation()}
        featurePolygon={permit.getGeolocationAreas()}
        timeline={auditResults}
      />,
    )
  }

  if (!auditEventPoint || !permit) return null

  return (
    <TimelineBlockBase title={localizedStrings.location}>
      {auditResult.metadata.distanceToPermitInMeters && (
        <Text my={0.5}>{`${
          localizedStrings.distanceToPointOfWork
        }: ${Math.round(
          auditResult.metadata.distanceToPermitInMeters,
        )}m`}</Text>
      )}

      <TimelineMap
        projectId={permit.project.id}
        featurePoint={permit.getW3wLocation()}
        featurePolygon={permit?.getGeolocationAreas()}
        onClick={handleMapClick}
        userFeaturePoint={auditEventPoint}
        featurePointAccuracyRadius={auditPointAccuracyRadius}
        style={{ height: '600px' }}
      >
        {locationMarkers}
      </TimelineMap>
    </TimelineBlockBase>
  )
}
