import { OfflineEntityType } from '@core/react-query/defaultMutations'
import {
  permitKeys,
  RequestTypes,
} from '@core/react-query/features/permits/permit'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const getAddAndBriefPermitParticipantsMutationOptions = (
  queryClient: QueryClient,
  options?: Parameters<typeof useAddAndBriefPermitParticipants>[0],
) => {
  return {
    ...(options ?? {}),
    ...permitKeys.addAndBriefParticipants,
    meta: {
      name: 'Brief Participants',
      offlineEnabled: true,
      variablesType: OfflineEntityType.PARTICIPANT_BRIEF,
      ...options?.meta,
    },
    onSuccess: (data, { permitId }) => {
      queryClient.invalidateQueries({
        queryKey: permitKeys.byId({ permitId }).queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: permitKeys._def,
      })
    },
  }
}

export const useAddAndBriefPermitParticipants = (
  options?: UseMutationOptions<
    void,
    RequestErrorType,
    RequestTypes['addAndBriefParticipants']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation(
    getAddAndBriefPermitParticipantsMutationOptions(queryClient, options),
  )
}
