import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { projectLocationsKeys } from '../locationsKeys'
import { RequestTypes } from '../requestTypes'
import { ProjectLocation } from '@core/entities/project/ProjectLocation/ProjectLocation'
import { IProjectLocation } from '@core/entities/project/ProjectLocation'

const projectLocationTransform = (data: IProjectLocation) =>
  ProjectLocation.new(data)

export const useGetProjectLocations = (
  payload: RequestTypes['getProjectLocations'],
  options?: UseQueryOptions<IProjectLocation, string, ProjectLocation>,
) => {
  const disable = !payload.projectId

  return useQuery<IProjectLocation, string, ProjectLocation>({
    select: projectLocationTransform,
    ...projectLocationsKeys.byProject(payload),
    ...options,
    ...(disable ? { enabled: false } : {}),
  })
}

export const prefetchGetProjectLocations = (
  queryClient: QueryClient,
  payload: RequestTypes['getProjectLocations'],
  options?: UseQueryOptions<IProjectLocation, string, ProjectLocation>,
) => {
  const disable = !payload.projectId

  return queryClient.prefetchQuery({
    select: projectLocationTransform,
    ...projectLocationsKeys.byProject(payload),
    ...options,
    ...(disable ? { enabled: false } : {}),
    cacheTime: Infinity,
  })
}
