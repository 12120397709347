import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  checklistTemplateKeys,
  checklistTemplateEditorMutationKeys,
  RequestTypes,
} from '@core/react-query/features/templates/checklistTemplates'

export const useUpdateProjectChecklistTemplateVersion = (
  options?: UseMutationOptions<
    unknown,
    string,
    RequestTypes['updateProjectChecklistTemplateVersion']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...checklistTemplateEditorMutationKeys.updateProjectChecklistTemplateVersion,
    retry: 0,
    onSuccess: (data, { projectId, templateId }) => {
      queryClient.invalidateQueries({
        queryKey: checklistTemplateKeys.getProjectVersion({
          projectId,
          templateId,
        }).queryKey,
      })
    },
    ...options,
  })
}
