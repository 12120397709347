import { localizedStrings } from '@core/strings'
import React from 'react'
import { Text } from '@fundamentals'
import {
  NumericQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import { TextField } from '@common/material'
import { useHtml } from '@common/hooks'

const NumericQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange, ...inputProps } = {},
  error,
  readOnly,
}) => {
  const { html } = useHtml(value?.submissionText)

  const onChangeText = ({ target: { value: submissionText } }) => {
    onChange({
      ...value,
      submissionText,
    })
  }

  if (readOnly) {
    return (
      !!value?.submissionText && (
        <Text
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          data-test={`${question.id}-${'NUMERIC'}`}
        />
      )
    )
  }

  return (
    <TextField
      value={value?.submissionText}
      onChange={onChangeText}
      placeholder={localizedStrings.enterNumber}
      fullWidth
      type='number'
      inputProps={{
        step: 'any',
        'data-test': 'request-numeric-input',
      }}
      data-test={`${question.id}-${'NUMERIC'}`}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      {...inputProps}
    />
  )
}

NumericQuestion.rules = NumericQuestionRules

export default NumericQuestion
