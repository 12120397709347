import React from 'react'
import { Flex, Text } from '@fundamentals'
import { AuditResult } from '@core/entities/audit/AuditResult'
import ComputerIcon from '@mui/icons-material/Computer'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import moment from 'moment'

type ComponentProps = {
  auditResult: AuditResult
}

export const TimelineEventHeader: React.FC<ComponentProps> = ({
  auditResult,
}) => {
  const platformIcon = (platform: AuditResult['platform']) => {
    switch (platform) {
      case 'WEB':
        return <ComputerIcon sx={{ ml: 1 }} fontSize='small' />
      case 'IOS':
        return <PhoneIphoneIcon sx={{ ml: 1 }} fontSize='small' />
      case 'ANDROID':
        return <PhoneAndroidIcon sx={{ ml: 1 }} fontSize='small' />
      default:
        return null
    }
  }

  const eventTime =
    auditResult.metadata?.offlineSubmittedOn || auditResult.createdOn
  const day = moment(eventTime).format('DD MMM YYYY')
  const time = moment(eventTime).format('HH:mm')

  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <Flex alignItems='center' justifyContent='flex-start'>
        <Text variant='body2'>{day}</Text>
        <Text variant='body2' sx={{ ml: 1 }} color='text.secondary'>
          {time}
        </Text>
      </Flex>
      <Flex alignItems='center' justifyContent='flex-end' sx={{ opacity: 0.4 }}>
        <Text fontSize={12}>{auditResult.getPlatformText()}</Text>
        {platformIcon(auditResult.platform)}
      </Flex>
    </Flex>
  )
}
