import { localizedStrings } from '@core/strings'
import React from 'react'
import { Box, Text } from '@fundamentals'
import {
  QuestionTypeComponentType,
  DateQuestionRules,
} from '@core/components/templateAnswersForm'
import { TextInput } from '@common/material'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import { useTheme } from '@mui/material'

const DateQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value = question, onChange, ...inputProps } = {},
  error,
  readOnly,
}) => {
  const theme: any = useTheme()

  const onChangeText = (date) => {
    onChange({
      ...value,
      submissionText: date?.toISOString(),
    })
  }

  if (readOnly) {
    return (
      !!question.submissionText && (
        <Text data-test={`${question.id}-${'DATE'}`}>
          {moment(question.submissionText).format('DD MMM YYYY HH:mm')}
        </Text>
      )
    )
  }

  return (
    <Box
      sx={{
        '.react-datepicker__input-container input': {
          height: 'auto',
          background: 'none',
          border: 'none!important',
          color: 'inherit!important',
        },
        '.react-datepicker__day--keyboard-selected,.react-datepicker__day--selected':
          {
            backgroundColor: `${theme.palette.primary.main}!important`,
          },
      }}
      data-test={`${question.id}-${'DATE'}`}
    >
      <DatePicker
        name='startDate'
        data-test='request-date-input'
        selected={
          value?.submissionText ? new Date(value?.submissionText) : null
        }
        dateFormat='do MMM yyyy, h:mm aa'
        showTimeSelect
        timeIntervals={5}
        placeholderText={localizedStrings.selectDateAndTime}
        onChange={onChangeText}
        popperProps={{ strategy: 'fixed' }}
        customInput={<TextInput fullWidth {...inputProps} />}
      />
    </Box>
  )
}

DateQuestion.rules = DateQuestionRules

export default DateQuestion
