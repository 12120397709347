import React from 'react'
import { ModalDialogContainer } from '@common/modal'
import { ClickToCopy } from '@common/ClickToCopy'
import { Text } from '@fundamentals'
import { DialogContent } from '@common/material'
import { Checklist } from '@core/entities/checklist/Checklist'

type BetaPermitModalProps = {
  checklist: Checklist
}

export const BetaChecklistModal: React.FC<BetaPermitModalProps> = ({
  checklist,
}) => {
  const intentUrl = checklist.getOpenIntentUrl(document.location.origin)

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '80%', flex: 1 } }}>
      <DialogContent>
        {checklist && (
          <>
            <Text>Intent URL</Text>
            <ClickToCopy value={intentUrl}>
              <Text>{intentUrl}</Text>
            </ClickToCopy>
          </>
        )}
      </DialogContent>
    </ModalDialogContainer>
  )
}
