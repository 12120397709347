import { useEffect, useState } from 'react'
import { Box, BoxProps, Flex } from '@fundamentals'
import { Button, ButtonStyleTypes } from '../../../components/Button'
import { useRouter } from 'next/router'
import { Dictionary } from 'ts-essentials'

type NavigationTabType = {
  label: String
  component?: React.ReactNode
  queryParams?: Dictionary<number | string>
  tabQueryParam?: string
  dataTest?: string
  name?: string
  clearParams?: boolean
}

type NavigationTabsPropsType = BoxProps & {
  tabs: NavigationTabType[]
  initialTab?: number
  justifyTabs?: 'flex-start' | 'center' | 'flex-end'
  activeButtonStyle?: ButtonStyleTypes
  buttonStyle?: ButtonStyleTypes
  onChangeTab?: () => void
  tabParam?: string
  size?: 'small' | 'medium' | 'large'
}

const getNewPath = ({
  name,
  clearParams = false,
  tabParam = 'tab',
  asPath,
}) => {
  const [pathWithoutParam, params] = asPath.split('?')
  const newValue = `${tabParam}=${name}`
  let newQuery = newValue
  if (params && !clearParams) {
    const otherParams = params && [...params.split('&')]
    const newIndex = otherParams.findIndex((item) => item.includes(tabParam))
    const nextIndex = newIndex !== -1 ? newIndex : otherParams.length
    otherParams[nextIndex] = newValue
    newQuery = otherParams.join('&')
  }
  return `${pathWithoutParam}?${newQuery}`
}

const NavigationTabs: React.FC<NavigationTabsPropsType> = ({
  tabs,
  initialTab = 0,
  justifyTabs,
  size = 'medium',
  activeButtonStyle = 'primary',
  buttonStyle = 'text',
  onChangeTab = () => {},
  tabParam = 'tab',
  children,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(initialTab)
  const [tabLength, setTabLength] = useState(tabs.length)
  const { push, asPath, query } = useRouter()
  const { [tabParam]: tabString, ...otherQuery } = query

  const changeTab = async ({ name, clearParams }, index) => {
    await setActiveTab(index)
    await onChangeTab()
    if (name) {
      const path = getNewPath({ name, clearParams, tabParam, asPath })
      push(path, undefined, { shallow: true })
    }
  }

  const goToFirstTab = (pushUrl = false) => {
    setActiveTab(0)
    onChangeTab()
    if (pushUrl && tabs[0]?.name) {
      const path = getNewPath({
        name: tabs[0]?.name,
        clearParams: false,
        tabParam,
        asPath,
      })
      push(path, undefined, { shallow: true })
    }
  }

  useEffect(() => {
    if (tabString) {
      const index = tabs.findIndex((tab) => tab?.name === tabString)
      if (index !== -1 && index !== activeTab) {
        setActiveTab(index)
        onChangeTab()
      }
    } else {
      goToFirstTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    const resetTabs = () => {
      setTabLength(tabs.length)
      goToFirstTab(true)
    }

    if (tabLength !== tabs.length) {
      resetTabs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs])

  useEffect(() => {
    if (!tabString) {
      goToFirstTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderTabButton = (tab, index) => {
    const { label, dataTest } = tab
    const isActiveTab = activeTab === index
    if (isActiveTab) {
      return (
        <Button
          key={index}
          buttonStyle={activeButtonStyle}
          size={size}
          fontWeight={800}
          data-test={`tab${dataTest || index}`}
        >
          {label}
        </Button>
      )
    }
    return (
      <Button
        key={index}
        onClick={() => changeTab(tab, index)}
        buttonStyle={buttonStyle}
        color='legacy.blue.oxford.10'
        fontWeight={800}
        size={size}
        data-test={`tab${dataTest || index}`}
      >
        {label}
      </Button>
    )
  }

  return (
    <Box {...props}>
      <Flex mb={2} justifyContent={justifyTabs}>
        {tabs.map(renderTabButton)}
      </Flex>
      {tabs[activeTab]?.component}
    </Box>
  )
}

export { NavigationTabs, getNewPath }
