import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { Box } from '@fundamentals'
import { PermitActionCard } from '../PermitActionCard'
import { Permit } from '@core/entities/permit/Permit'
import moment from 'moment'
import ReasonForSuspensionPermitModal from '../modals/ReasonForSuspensionPermitModal'
import { useModal } from '@common/GlobalModal'

type PermitSuspensionReasonCardProps = {
  permit: Permit
}
export const PermitSuspensionReasonCard: React.FC<
  PermitSuspensionReasonCardProps
> = ({ permit }) => {
  const { showModal } = useModal()
  if (!permit?.permitSuspensionResult?.isPending()) return null

  const { suspendedBy, startedOn } = permit.permitSuspensionResult
  const datetimeString = moment(startedOn).format('D MMMM YYYY HH:mm')

  return (
    <PermitActionCard
      title={localizedStrings.permitSuspended}
      info={
        <Box>
          <Box>{`${
            localizedStrings.suspendedBy
          }: ${suspendedBy.getFullName()}`}</Box>
          <Box>{`${localizedStrings.date}: ${datetimeString}`}</Box>
        </Box>
      }
      BottomComponent={
        <Button
          variant='outlined'
          fullWidth
          onClick={() => {
            showModal(
              <ReasonForSuspensionPermitModal
                permit={permit}
                title={
                  permit.status === 'SUSPENDED'
                    ? localizedStrings.suspensionReason
                    : localizedStrings.resumeRequestReason
                }
              />,
            )
          }}
        >
          {localizedStrings.viewInformation}
        </Button>
      }
      sx={{ mb: 1.5, borderColor: 'error.main', borderWidth: 2 }}
    />
  )
}
