import { createContext } from 'react'
import { InterpreterFrom } from 'xstate'

import { projectPlanMachine } from './projectPlanMachine'

export interface IProjectPlanStateContext {
  projectPlanService: InterpreterFrom<typeof projectPlanMachine>
}

export const ProjectPlanStateContext =
  createContext<IProjectPlanStateContext>(null)
