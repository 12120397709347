import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { projectLocationsKeys } from '../locationsKeys'
import { RequestTypes } from '../requestTypes'
import { ProjectLocation } from '@core/entities/project/ProjectLocation/ProjectLocation'

const projectLocationTransform = (data) => ProjectLocation.new(data)

export const useGetProjectLocationsCreateList = (
  payload: RequestTypes['useGetProjectLocationsCreateList'],
  options?: UseQueryOptions<ProjectLocation, string>,
) => {
  return useQuery({
    select: projectLocationTransform,
    ...projectLocationsKeys.byProjectFiltered(payload),
    enabled: !!payload.projectId,
    ...options,
  })
}
