import { Entity } from '@core/entities/entity'
import { IPermitDraft } from './IPermitDraft'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { TemplateProjectLocations } from '@core/entities/template/TemplateProjectLocations'
import { PermitTemplate } from '@core/entities/permit/PermitTemplate'
import { TemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import { FeatureCollection, Feature, GeoJSON, Point, Polygon } from 'geojson'
import {
  getGeolocationAreas,
  getW3wLocation,
} from '@core/utils/geolocation-helpers'

export class PermitDraft extends Entity<IPermitDraft> {
  id: number
  draftName: string
  summary: string
  organisationId: number
  projectId: number
  projectName: string
  templateId: number
  templateVersionId: number
  @Type(() => PermitTemplate)
  template: PermitTemplate
  // currentTemplate is present only if there is a newer version of the template
  @Type(() => PermitTemplate)
  currentTemplate?: PermitTemplate
  location: string
  locationId: string
  geoJSON?: GeoJSON
  @Type(() => User)
  createdBy: User
  permitHolderId: number
  @Type(() => User)
  permitHolder: User
  startsOn: string
  endsOn: string
  participants: Array<{ id: number; firstName: string; lastName: string }>
  @Type(() => TemplateQuestionGroup)
  body: Array<TemplateQuestionGroup>
  @Type(() => TemplateQuestionGroup)
  authorisedPersonInitiateBody: Array<TemplateQuestionGroup>
  @Type(() => TemplateFormAnswers)
  permitHolderAnswers?: TemplateFormAnswers
  @Type(() => TemplateProjectLocations)
  projectLocations: TemplateProjectLocations
  permittedActions: Array<'DELETE' | 'EDIT' | 'READ'>

  public static new(payload: unknown): PermitDraft {
    const entity = plainToInstance(PermitDraft, payload)

    return entity
  }

  getW3wLocation(): Feature<Point> {
    return getW3wLocation(this.geoJSON)
  }

  getGeolocationAreas(): FeatureCollection<Polygon> {
    return getGeolocationAreas(this.geoJSON)
  }
}
