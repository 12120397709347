import { localizedStrings } from '@core/strings'
import React from 'react'
import { PermitTimelineTabProps } from '../types'
import { Box } from '@fundamentals'
import {
  Timeline,
  TimelineLeggacy,
  PermitTimelineItemLeggacy,
} from '@common/Timeline'
import { Button } from '@common/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useModal } from '@common/GlobalModal'
import { usePermitTimeline } from '@core/react-query/features/permits/permit/hooks/usePermitTimeline'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { useTimelineMockData } from '@common/Timeline/mockData/useTimelineMockData'
import { Filters } from '@elements/Filters'
import { TimelineMapModal } from '@modules/geolocation'

export const PermitTimelineTab: React.FC<PermitTimelineTabProps> = ({
  permit,
}) => {
  const { showModal } = useModal()
  const { flags } = useFeatureFlags(['timeline_redesign'])
  const {
    data: timeline,
    error,
    isLoading,
  } = usePermitTimeline({ permitId: permit?.id })
  const { filters, filterStore, mockAuditResults, mock } = useTimelineMockData()
  const auditResults = mock ? mockAuditResults : timeline

  const hasItemsWithCoordinates =
    auditResults?.filter((t) => !!t?.getCoordinates())?.length > 0

  return (
    <>
      {mock && <Filters filters={filters} store={filterStore} />}
      {hasItemsWithCoordinates && (
        <Button
          sx={{ mb: 2 }}
          startIcon={<LocationOnIcon />}
          onClick={() => {
            showModal(
              <TimelineMapModal
                projectId={permit.project.id}
                featurePoint={permit.getW3wLocation()}
                featurePolygon={permit.getGeolocationAreas()}
                timeline={auditResults}
              />,
            )
          }}
        >
          {localizedStrings.viewOnMap}
        </Button>
      )}
      <ErrorMessage>{error}</ErrorMessage>
      {flags.timeline_redesign ? (
        <Timeline
          scope='PERMIT'
          isLoading={isLoading}
          auditResults={auditResults}
        />
      ) : (
        <TimelineLeggacy loading={isLoading}>
          {auditResults?.map((timelineItem) => (
            <Box key={timelineItem.id} mb={2}>
              <PermitTimelineItemLeggacy
                timelineItem={timelineItem}
                permit={permit}
              />
            </Box>
          ))}
        </TimelineLeggacy>
      )}
    </>
  )
}
