import { Entity } from '@core/entities/entity'
import { IDashboard } from '@core/entities/dashboard/IDashboard'
import { plainToInstance } from 'class-transformer'

export class Dashboard extends Entity<IDashboard> {
  awaitingPostPermitChecksPermits: number

  awaitingSignoffPermits: number

  expiredPermits: number

  issuedPermits: number

  openPermits: number

  extensionRequestedPermits: number

  requestedPermits: number

  suspendedPermits: number

  totalPermits: number

  totalChecklists: number

  topPermitTemplates: {
    name: string
    totalCount: number
  }[]

  topChecklistTemplates: {
    name: string
    totalCount: number
  }[]

  totalPermitsGraphSeries: {
    day: string
    totalCount: number
  }[]

  totalChecklistsGraphSeries: {
    day: string
    totalCount: number
  }[]

  public static new(payload: unknown): Dashboard {
    const entity = plainToInstance(Dashboard, payload)

    return entity
  }
}
