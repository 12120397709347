import { localizedStrings } from '@core/strings'
import React from 'react'
import { useAvailableChecklistTemplates } from '@core/react-query/features/templates/checklistTemplates'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Section } from '@common/Section'
import { Text } from '@fundamentals'
import { ChecklistTemplate } from '@core/entities/checklist/ChecklistTemplate'
import { TemplatePickerWrapper } from '@elements/Pickers/TemplatePickerWrapper'

export type ChecklistTemplatePickerProps = TextFieldProps & {
  organisationId: number
  projectId: number
  value: ChecklistTemplate
  onChange?: (template: ChecklistTemplate) => void
  readOnly?: boolean
}

const ChecklistTemplatePicker: React.FC<ChecklistTemplatePickerProps> = ({
  organisationId,
  projectId,
  value,
  onChange,
  readOnly,
  ...props
}) => {
  const { data } = useAvailableChecklistTemplates({
    projectId,
  })

  const findTemplateById = (id) => data?.templates?.find((t) => t.id === id)

  if (readOnly) {
    const templateName = data?.templates?.find(
      (template) => template.id === value?.id,
    )?.name

    return (
      <Section sx={props?.sx} label={localizedStrings.checklistTemplate}>
        <Text>{templateName}</Text>
      </Section>
    )
  }

  return (
    <TemplatePickerWrapper
      label={localizedStrings.checklistTemplate}
      availableTemplates={data}
      organisationId={organisationId}
      projectId={projectId}
      entityType='CHECKLIST_TEMPLATE'
      value={value}
      onChange={(templateId) => {
        const template = findTemplateById(templateId)
        onChange(template)
      }}
      {...props}
    />
  )
}

export { ChecklistTemplatePicker }
