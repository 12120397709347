import { localizedStrings } from '@core/strings'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { FilterHookArgumentsBase } from '@core/providers/filters/index'
import { useGetProcessTags } from '@core/react-query/features/processTags/hooks'
import { ProcessTagEntityTypes } from '@core/react-query/features/processTags'
import { ProcessTag } from '@core/entities/processTags'
import React from 'react'

export type ProcessTagFilterScopes =
  | ProcessTagFilterOrganisationScope
  | ProcessTagFilterProjectScope
  | ProcessTagFilterBusinessUnitScope

export type ProcessTagFilterOrganisationScope = {
  type: 'ORGANISATION'
  organisationId: number
  projectIds?: number[]
  entityType?: ProcessTagEntityTypes
}

export type ProcessTagFilterProjectScope = {
  type: 'PROJECT'
  organisationId: number
  projectId: number
  entityType?: ProcessTagEntityTypes
}

export type ProcessTagFilterBusinessUnitScope = {
  type: 'BUSINESS_UNIT'
  organisationId: number
  businessUnitId: number
  entityType?: ProcessTagEntityTypes
}

type TemplatesFilterArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    multiple?: Multiple
    readOnly?: boolean
    scope?: ProcessTagFilterScopes
    renderItem?: (tag: ProcessTag) => React.ReactElement
    renderTags?: (value: ProcessTag[]) => React.ReactElement[]
  }

export const useProcessTagsFilter: <Multiple extends boolean | undefined>(
  args: TemplatesFilterArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = ({
  renderItem,
  renderTags,
  ...args
}) => {
  const { type, ...scope } = args.scope
  const { data } = useGetProcessTags({
    ...scope,
    size: 10000,
  })
  const tags = data?.content || []

  return useSelectFilter({
    ...args,
    options: tags?.map((t) => t.id) || [],
    valueToLabel: (v) => tags?.find((t) => t.id === v)?.name,
    nothingSelectedText: localizedStrings.allTags,
    isHidden: !tags?.length,
    renderItem: (v) => renderItem(tags.find((t) => t.id === v)),
    renderTags: (v) => renderTags(v.map((id) => tags.find((t) => t.id === id))),
  })
}
