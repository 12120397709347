import { useCallback, useState } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'
import {
  isGeoPointInBounds,
  isGeoPolygonInBounds,
} from '@core/utils/geolocation-helpers'
import { Feature, FeatureCollection, Point, Polygon } from 'geojson'
import debounce from 'lodash/debounce'
import { MapBounds } from '@core/entities/map'

type Entity = {
  getW3wLocation?: () => Feature<Point>
  getCheckinLocation?: () => Feature<Point>
  getGeolocationAreas?: () => FeatureCollection<Polygon>
}

type UseVisibleEntitiesOnMapConfig = {
  debounceWait?: number
}

export default function useVisibleEntitiesOnMap<T extends Entity>(
  entities: T[] = [],
  { debounceWait = 300 }: UseVisibleEntitiesOnMapConfig = {},
) {
  const [mapBounds, setBounds] = useState<MapBounds>()

  const setMapBounds = useCallback(
    debounce((bounds: MapBounds) => {
      setBounds(bounds)
    }, debounceWait),
    [debounceWait, setBounds],
  )

  const visibleEntitiesOnMap: T[] = useDeepCompareMemo(() => {
    return entities.filter((entity) => {
      if (!mapBounds) return false

      const checkinLocation = entity?.getCheckinLocation?.()
      if (checkinLocation) {
        return isGeoPointInBounds(checkinLocation, mapBounds)
      }

      const w3wLocation = entity?.getW3wLocation?.()
      if (w3wLocation) {
        return isGeoPointInBounds(w3wLocation, mapBounds)
      }

      const geoLocationAreas = entity?.getGeolocationAreas?.()
      if (geoLocationAreas) {
        return isGeoPolygonInBounds(geoLocationAreas, mapBounds)
      }

      return false
    })
  }, [mapBounds, entities])

  return { setMapBounds, visibleEntitiesOnMap }
}
