import { RequestTypes } from './requestTypes'
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { uploadTemplateAnswerFiles } from '@core/react-query/features/permits/permit/helpers'
import { s3Upload } from '@core/react-query/helpers'
import { attachAuditResultMediaTypes } from '@core/entities/audit/AuditResult'

const permitQueryKeys = createQueryKeys('permit', {
  byId: ({ permitId }: RequestTypes['getPermit']) => {
    return {
      queryKey: [permitId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`permits/${permitId}`)
        return data
      },
      contextQueries: {
        checklists: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `checklists/permits/${permitId}`,
            )

            return data.items
          },
        },
        tasks: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `permits/${permitId}/tasks`,
            )
            return data.tasks
          },
        },
        periodicChecklistTemplates: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `permits/${permitId}/periodic-checklists/templates`,
            )

            return data.periodicChecklistsTemplates
          },
        },
        periodicChecks: ({
          periodicCheckTemplateId,
          periodicCheckId,
        }: RequestTypes['getPeriodicCheck']) => {
          return {
            queryKey: [
              'periodicCheck',
              periodicCheckTemplateId,
              periodicCheckId,
            ],
            queryFn: async () => {
              const { data } = await APIAxiosInstance.get(
                `permits/${permitId}/periodic-checks/${periodicCheckTemplateId}/entries/${periodicCheckId}`,
              )
              return data
            },
          }
        },
        timeline: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `audits/permits/all?permitId=${permitId}`,
            )
            await Promise.all(data.auditList.map(attachAuditResultMediaTypes))

            return data.auditList
          },
        },
        participantTimeline: ({
          participantId,
        }: RequestTypes['getTimelineByParticipant']) => {
          return {
            queryKey: [participantId],
            queryFn: async () => {
              const { data } = await APIAxiosInstance.get(
                `audits/permits/${permitId}/participants/${participantId}/all`,
              )
              await Promise.all(data.auditList.map(attachAuditResultMediaTypes))

              return data.auditList
            },
          }
        },
        linkedProcesses: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `permits/${permitId}/linked-processes`,
            )
            return data
          },
        },
        getRoles: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `permits/${permitId}/roles`,
            )
            return data.roles
          },
        },
      },
    }
  },
  draftById: ({ draftId }: RequestTypes['getPermitDraft']) => {
    return {
      queryKey: [draftId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`permits/drafts/${draftId}`)
        return data
      },
    }
  },
})

const permitMutationKeys = createMutationKeys('permit', {
  request: {
    mutationKey: null,
    mutationFn: async ({
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['request']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: mutation.body,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits?projectId=${mutation.projectId}`,
        { ...mutation, body: uploadedAnswers },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  issue: {
    mutationKey: null,
    mutationFn: async ({
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['issue']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: mutation.authorisedPersonInitiateBody,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/send-to-permit-holder?projectId=${mutation.projectId}`,
        { ...mutation, authorisedPersonInitiateBody: uploadedAnswers },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  createApprovalDraft: {
    mutationKey: null,
    mutationFn: async ({
      answeredQuestions,
      templateId,
      permitId,
      referenceObjectId,
    }: RequestTypes['createApprovalDraft']) => {
      const answeredQuestionsWithUpload = await uploadTemplateAnswerFiles({
        answers: answeredQuestions,
        templateId: templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/stage-drafts/approvals`,
        { referenceObjectId, answeredQuestions: answeredQuestionsWithUpload },
      )
      return data
    },
  },
  createDraft: {
    mutationKey: null,
    mutationFn: async ({
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['createDraft']) => {
      const uploadedBodyAnswers = await uploadTemplateAnswerFiles({
        answers: mutation.body,
        templateId: mutation.templateId,
      })

      const uploadedInitiateBodyAnswers = await uploadTemplateAnswerFiles({
        answers: mutation.authorisedPersonInitiateBody,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/drafts?organisationId=${mutation.organisationId}`,
        {
          ...mutation,
          body: uploadedBodyAnswers,
          authorisedPersonInitiateBody: uploadedInitiateBodyAnswers,
        },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  updateDraft: {
    mutationKey: null,
    mutationFn: async ({
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['updateDraft']) => {
      const uploadedBodyAnswers = await uploadTemplateAnswerFiles({
        answers: mutation.body,
        templateId: mutation.templateId,
      })

      const uploadedInitiateBodyAnswers = await uploadTemplateAnswerFiles({
        answers: mutation.authorisedPersonInitiateBody,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.put(
        `permits/drafts/${mutation.id}`,
        {
          ...mutation,
          body: uploadedBodyAnswers,
          authorisedPersonInitiateBody: uploadedInitiateBodyAnswers,
        },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  migrateDraftToLatestVersion: {
    mutationKey: null,
    mutationFn: async ({ id }: RequestTypes['migrateDraftToLatestVersion']) => {
      const { data } = await APIAxiosInstance.post(
        `permits/drafts/${id}/migrate-to-latest-version`,
        { id },
      )

      return data
    },
  },
  deleteDraft: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['deleteDraft']) => {
      const { data } = await APIAxiosInstance.delete(
        `permits/drafts/${mutation.draftId}`,
      )
      return data
    },
  },
  receive: {
    mutationKey: null,
    mutationFn: async ({
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['receive']) => {
      const { permitId, body, participants } = mutation

      const uploadedBodyAnswers = await uploadTemplateAnswerFiles({
        answers: body,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/permit-holder-receive`,
        {
          body: uploadedBodyAnswers,
          participants,
        },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  approve: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['approve']) => {
      const {
        permitId,
        startsOn,
        endsOn,
        approvalAnswers,
        approvalId,
        templateId,
      } = mutation

      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: approvalAnswers?.answers,
        templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/issue`,
        {
          approvalId,
          startsOn,
          endsOn,
          approvalAnswers: { answers: uploadedAnswers },
        },
      )
      return data
    },
  },
  reject: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['reject']) => {
      const { permitId, reason, approvalId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/reject`,
        {
          reason,
          approvalId,
        },
      )
      return data
    },
  },
  cancel: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['cancel']) => {
      const { permitId, reason } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/cancel`,
        {
          reason,
        },
      )
      return data
    },
  },
  requestChanges: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['requestChanges']) => {
      const { permitId, comment, approvalId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/request-changes`,
        { comment, approvalId },
      )
      return data
    },
  },
  submitChanges: {
    mutationKey: null,
    mutationFn: async ({
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['submitChanges']) => {
      const { permitId, body, ...mutationData } = mutation

      const uploadedBodyAnswers = await uploadTemplateAnswerFiles({
        answers: body,
        templateId: mutation.templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/address-requested-changes`,
        { ...mutationData, body: uploadedBodyAnswers },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  requestExtension: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['requestExtension']) => {
      const { permitId, reason, endsOn } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/extend`,
        {
          reason,
          endsOn,
        },
      )
      return data
    },
  },
  shorten: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['shorten']) => {
      const { permitId, reason, endsOn } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/shorten`,
        {
          reason,
          endsOn,
        },
      )
      return data
    },
  },
  rejectExtensionRequest: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['rejectExtensionRequest']) => {
      const { permitId, reason } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/extend-reject`,
        { reason },
      )
      return data
    },
  },
  approveExtensionRequest: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['approveExtensionRequest']) => {
      const { permitId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/extend-approve`,
        {},
      )
      return data
    },
  },
  transfer: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['transfer']) => {
      const { permitId, receiverId, message } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/transfers/initiate`,
        {
          receiverId,
          message,
        },
      )
      return data
    },
  },
  cancelTransfer: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['cancelTransfer']) => {
      const { permitId, cancellationReason } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/transfers/cancel`,
        { cancellationReason },
      )
      return data
    },
  },
  acceptTransfer: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['acceptTransfer']) => {
      const { permitId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/transfers/accept`,
        {},
      )
      return data
    },
  },
  rejectTransfer: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['rejectTransfer']) => {
      const { permitId, rejectionReason } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/transfers/reject`,
        { rejectionReason },
      )
      return data
    },
  },
  approveTransfer: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['approveTransfer']) => {
      const { permitId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/transfers/approve`,
        {},
      )
      return data
    },
  },
  declineTransfer: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['declineTransfer']) => {
      const { permitId, rejectionReason } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/transfers/decline`,
        { rejectionReason },
      )
      return data
    },
  },
  markWorkComplete: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['markWorkComplete']) => {
      const { permitId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/complete`,
        {},
      )
      return data
    },
  },
  clone: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['clone']) => {
      const { permitId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/clone`,
        {},
      )
      return data
    },
  },
  close: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['close']) => {
      const { permitId } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/close`,
        {},
      )
      return data
    },
  },
  signOff: {
    mutationKey: null,
    mutationFn: async ({
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['signOff']) => {
      const { permitId, postPermitChecksBody, coordinates, templateId } =
        mutation

      const uploadedBodyAnswers = await uploadTemplateAnswerFiles({
        answers: postPermitChecksBody,
        templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/signoff`,
        {
          postPermitChecksBody: uploadedBodyAnswers,
          coordinates,
        },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data
    },
  },
  addAndBriefParticipants: {
    mutationKey: null,
    mutationFn: async ({
      permitId,
      participants,
      proofFiles,
      coordinates,
      offlineSubmittedOn,
    }: RequestTypes['addAndBriefParticipants']) => {
      // Upload proofs
      const proofKeys = await Promise.all(
        proofFiles.map(async (file) => {
          const key = await s3Upload({
            file,
            folderName: 'permit_briefing_proof',
          })

          return key
        }),
      )

      // Upload signature files
      const participantsWithSignatureKeys = await Promise.all(
        participants.map(async (participant) => {
          const signatureKey = await s3Upload({
            file: participant.signature,
            folderName: 'permit_role_signature',
          })

          return {
            ...participant,
            signatureKey,
          }
        }),
      )

      const headers = { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn }

      // Add participants
      const participantsToAdd = participantsWithSignatureKeys.filter(
        (participant) => !participant.participantId,
      )

      if (participantsToAdd.length) {
        await APIAxiosInstance.post(
          `permits/${permitId}/participants`,
          {
            proofKeys,
            coordinates,
            participants: participantsToAdd,
          },
          { headers },
        )
      }

      // Brief participants
      const participantsToBrief = participantsWithSignatureKeys.filter(
        (participant) => !!participant.participantId,
      )

      if (participantsToBrief.length) {
        await APIAxiosInstance.post(
          `permits/${permitId}/participants/briefings`,
          {
            proofKeys,
            coordinates,
            participants: participantsToBrief.map(
              ({ participantId, signatureKey, roleId }) => ({
                roleId,
                participantId,
                signatureKey,
              }),
            ),
          },
          { headers },
        )
      }
    },
  },
  removeParticipant: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['removeParticipant']) => {
      const { permitId, participantId } = mutation
      const { data } = await APIAxiosInstance.delete(
        `permits/${permitId}/participants/${participantId}`,
      )
      return data
    },
  },
  suspendPermit: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['suspendPermit']) => {
      const { permitId, comment, attachmentsKeys, suspensionReasonCategory } =
        mutation
      const { data } = await APIAxiosInstance.put(
        `permits/${permitId}/suspend`,
        {
          comment,
          suspensionReasonCategory,
          attachmentsKeys: attachmentsKeys || [],
        },
      )
      return data
    },
  },
  resumePermit: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['resumePermit']) => {
      const { permitId, comment, attachmentsKeys } = mutation
      const { data } = await APIAxiosInstance.put(
        `permits/${permitId}/resume`,
        {
          comment,
          attachmentsKeys: attachmentsKeys || [],
        },
      )
      return data
    },
  },
  requestResumePermit: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['requestResumePermit']) => {
      const { permitId, comment, attachmentsKeys } = mutation
      const { data } = await APIAxiosInstance.put(
        `permits/${permitId}/request-permit-resume`,
        {
          comment,
          attachmentsKeys: attachmentsKeys || [],
        },
      )
      return data
    },
  },
  approveResumePermitRequest: {
    mutationKey: null,
    mutationFn: async (
      mutation: RequestTypes['approveResumePermitRequest'],
    ) => {
      const { permitId } = mutation
      const { data } = await APIAxiosInstance.put(
        `permits/${permitId}/approve-suspension-resume-request`,
      )
      return data
    },
  },
  rejectResumePermitRequest: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['rejectResumePermitRequest']) => {
      const { permitId, comment } = mutation
      const { data } = await APIAxiosInstance.put(
        `permits/${permitId}/decline-suspension-resume-request`,
        { comment },
      )
      return data
    },
  },
  nominateApprovers: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['nominateApprovers']) => {
      const { permitId, approvers } = mutation
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/nominate-approvers`,
        { permitId, approvers },
      )
      return data
    },
  },
  multipleFinalSignOff: {
    mutationKey: null,
    mutationFn: async (mutation: RequestTypes['multipleFinalSignOff']) => {
      const { permitId, templateId, ...mutationData } = mutation
      const uploadedBodyAnswers = await uploadTemplateAnswerFiles({
        answers: mutationData.finalSignoffAnswers.answers,
        templateId: templateId,
      })

      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/final-signoff`,
        {
          ...mutationData,
          finalSignoffAnswers: { answers: uploadedBodyAnswers },
        },
      )

      return data
    },
  },
  pdfDownloadUrl: {
    mutationKey: null,
    mutationFn: async ({
      permitId,
    }: RequestTypes['getPermitPdfDownloadUrl']) => {
      const { data } = await APIAxiosInstance.post(
        `permits/${permitId}/download`,
      )

      return data
    },
  },
})

const permitKeys = mergeQueryKeys(permitQueryKeys, permitMutationKeys).permit

export { permitKeys }
