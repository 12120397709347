import React from 'react'
import { Box } from '@fundamentals'
import { RenderAnswers } from '@elements/Questions/RenderAnswers'
import { Checklist } from '@core/entities/checklist/Checklist'

type ChecklistQuestionsTabProps = {
  checklist: Checklist
}

export const ChecklistTab: React.FC<ChecklistQuestionsTabProps> = ({
  checklist,
}) => {
  return (
    <Box flex={1}>
      <RenderAnswers
        questionBuilderAnswers={checklist?.mainAnswers}
        organisationId={checklist.organisationId}
      />
    </Box>
  )
}
