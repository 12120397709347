import { Flex, Text } from '@common/fundamentals'
import { Card, Divider } from '@common/material'
import { CardContent, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import React from 'react'

type PermitActionCardProps = {
  title: string
  description?: string
  info: string | React.ReactNode
  recipientSection?: string | React.ReactNode
  BottomComponent?: React.ReactNode
  sx?: SxProps<Theme>
}

export const PermitActionCard: React.FC<PermitActionCardProps> = ({
  title,
  description,
  info,
  recipientSection,
  BottomComponent,
  sx,
}) => {
  return (
    <Card sx={sx}>
      <CardContent sx={{ pb: !BottomComponent && '4px!important' }}>
        <Text
          sx={{ fontSize: '16px' }}
          variant='h6'
          color='text.primary'
          gutterBottom
        >
          {title}
        </Text>
        <Divider sx={{ mb: 1.5 }} />

        {recipientSection && (
          <Flex alignItems='center' mb={1.5}>
            {recipientSection}
          </Flex>
        )}

        {description?.length && (
          <Text sx={{ fontSize: '14px', mb: 1.5 }}>{description}</Text>
        )}

        {info && (
          <Text sx={{ fontSize: '12px', mb: 1.5 }} color='text.disabled'>
            {info}
          </Text>
        )}

        <Flex gap={1} flexDirection='column'>
          {BottomComponent}
        </Flex>
      </CardContent>
    </Card>
  )
}
