import { useMemo } from 'react'
import { featureCollection } from '@turf/helpers'
import { ControllerFieldState } from 'react-hook-form'
import { Section } from '@common/Section'
import { Button } from '@common/material'
import { Text } from '@fundamentals'
import { TimelineMap } from '@modules/geolocation'
import { GeoLocationArea } from '@core/entities/map'
import { localizedStrings } from '@core/strings'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { geoLocationToPolygon } from '@core/utils/geolocation-helpers'

type ProjectMapGeoDrawAreaCardProps = {
  projectId: number
  geoLocationAreas: GeoLocationArea[]
  readOnly?: boolean
  fieldState?: ControllerFieldState
  onClick: () => void
}

export const ProjectMapGeoDrawAreaCard: React.FC<
  ProjectMapGeoDrawAreaCardProps
> = ({ projectId, geoLocationAreas, readOnly, fieldState, onClick }) => {
  const featurePolygon = useMemo(() => {
    if (!geoLocationAreas) return undefined

    const featurePolygons = geoLocationAreas.map((area) => {
      const featurePolygon = geoLocationToPolygon([area])
      featurePolygon.properties.selected = false

      return featurePolygon
    })

    return featureCollection(featurePolygons)
  }, [geoLocationAreas])

  return (
    <Section
      label={localizedStrings.pointOfWorks}
      labelColor='text.secondary'
      sx={{
        mr: 1,
        mt: 2,
        mb: !readOnly ? 3 : 1,
        width: '100%',
      }}
    >
      {!readOnly && !geoLocationAreas && (
        <Button
          color={fieldState.error ? 'error' : undefined}
          startIcon={<LocationOnIcon />}
          sx={{ mr: 1, mt: 1 }}
          variant='outlined'
          fullWidth
          size='large'
          onClick={onClick}
        >
          {localizedStrings.drawOnMap}
        </Button>
      )}

      {featurePolygon && (
        <TimelineMap
          projectId={projectId}
          featurePolygon={featurePolygon}
          style={{ height: 350, marginTop: 8, cursor: 'pointer' }}
          interactive={false}
          enableNavigationControl={false}
          onClick={onClick}
        />
      )}

      <Text color='error' fontSize={12} ml={2} mt={1}>
        {fieldState?.error?.message}
      </Text>
    </Section>
  )
}
