import React from 'react'
import { TextField } from '@common/material'
import { Autocomplete } from '@mui/material'
import { Checkbox } from '@common/Checkbox'
import _ from 'lodash'
import { Box, Flex } from '@fundamentals'
import { SelectFilterInternalArguments } from '@core/providers/filters'

type SelectFilterProps = {
  filter: SelectFilterInternalArguments<unknown, boolean | undefined>
  value?: any
  onChange: (value: any) => void
  dataTest?: string
}

export const SelectFilter: (p: SelectFilterProps) => React.ReactElement = ({
  filter,
  onChange,
  value,
  dataTest,
}) => {
  const handleRenderTags = (value) => {
    return filter.renderTags(value)
  }
  return (
    <Box minWidth={350}>
      <Autocomplete
        noOptionsText={filter.noOptionsText}
        openOnFocus
        value={value}
        options={filter.options || []}
        getOptionLabel={(o) => {
          if (!o && !o?.length) {
            return ''
          }
          return filter.valueToLabel(o?.value || o)
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li
              {...props}
              data-test={`${dataTest}-option-${props['data-option-index']}`}
            >
              <Flex alignItems='center'>
                <Checkbox checked={selected} mr={1} />
                {/*@ts-ignore*/}
                {!filter.renderItem &&
                  filter.valueToLabel(option?.value || option)}
                {/*@ts-ignore*/}
                {filter.renderItem &&
                  filter.renderItem(option?.value || option)}
              </Flex>
            </li>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={filter.label}
              multiline
              sx={{ pr: 1 }}
              autoFocus
              data-test={dataTest}
            />
          )
        }}
        renderTags={filter.renderTags ? handleRenderTags : undefined}
        onChange={(event, value) => {
          filter.multiple
            ? onChange(value.map((v) => v?.value || v))
            : onChange(value?.value || value)
        }}
        onInputChange={(e, input) => {
          // Don't fire on when option is selected in the dropdown
          if (e?.type === 'click') {
            return
          }
          // If selection is single then the input is changed by Autocomplete when the filter is focused
          // This stops it firing unnecessarily
          if (!filter.multiple && input === filter.valueToLabel(value)) {
            return
          }
          if (filter?.onInputChange) {
            filter?.onInputChange(input)
          }
        }}
        isOptionEqualToValue={(option, value) => {
          const _option = option?.value || option
          const _value = value?.value || value
          return _.isEqual(_option, _value)
        }}
        style={{ minWidth: 200, maxWidth: 420 }}
        sx={{
          '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            minWidth: 0,
          },
        }}
        limitTags={1}
        componentsProps={{ popper: { placement: 'bottom-start' } }}
        size='small'
        multiple={filter.multiple}
        filterOptions={
          filter?.disableClientSideFilter
            ? (options, state) => options
            : undefined
        }
        disableCloseOnSelect
        loading={filter.loading}
      />
    </Box>
  )
}
