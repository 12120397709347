import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { permitKeys } from '@core/react-query/features/permits/permit'
import { useRealTimeSubscription } from '@core/providers/useRealTimeSubscription'
import { PusherOptions } from '@core/providers/usePusher'

export const useRealTimePermit = (
  permitId: number,
  options?: PusherOptions,
) => {
  const { channel, bindEventHandler } = useRealTimeSubscription(
    `private-permit.${permitId}`,
    options,
  )
  const queryClient = useQueryClient()

  useEffect(() => {
    bindEventHandler('permit.updated', (data) => {
      const queryKey = permitKeys.byId({ permitId }).queryKey
      queryClient.invalidateQueries(queryKey)
    })
  }, [channel])
}
